import {
  Contact_Update,
  Driver_Read_Nested,
  Job_Load_Read,
  Job_Load_Update,
  Job_Read,
  Job_Read_Nested,
  Job_Update,
  JobAssignment_Read,
  JobAssignment_Read_Nested,
  JobEvent_Read,
  JobEvent_Update,
  JobEventName,
  JobState,
  JobSummary_Read,
  JobTimeline_Read,
  Load_Create,
  Load_Read,
  Load_Read_Nested,
  LoadState,
  Order_Read,
  OrderUnitOfMeasure,
  Principal_Read_Nested,
  RateType,
  User_Me_Read,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { LoadCycle_Read } from '@treadinc/horizon-api-spec/types/models/LoadCycle_Read';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { sortBy } from 'lodash';

import { ContactTypes, RateTypes } from '~constants/enums';
import { AccountBasic } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { LoadOptionsProps } from '~hooks/useJob/useJob';
import { BasicMaterial, Material } from '~hooks/useMaterials';
import { Order } from '~hooks/useOrders';
import {
  contactTypeMap,
  ExtendedContactItem,
  filterContact,
  ProjectBasic,
  transformContact,
} from '~hooks/useProjects/models';
import { RateBasicWithValue } from '~hooks/useRates';
import { Service } from '~hooks/useServices';
import { WayPoint, WayPointFormProps } from '~hooks/useSites';
import { Ticket, TicketBasic } from '~hooks/useTickets/models';
import { User } from '~hooks/useUsers';
import { JobApprovalSchemaInterface } from '~pages/Approvals/ApprovalsComponents/jobApproveFormSchema';
import { ACTIVE_JOB_STATES } from '~pages/LiveMap/TreadLive';
import {
  deparseUpdateRateDetailsDTO,
  RateDetailsDTO,
} from '~pages/Sales/Orders/NewOrderFormComponents/schema';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

export interface JobFormProps {
  id: string;
  jobStartAt: Nullable<Dayjs>;
  notes: string | null;
  quantity: number;
  unitOfMeasure: ItemNameAndId;
  service: Service;
  material: ItemNameAndId | Material;
  equipment: ItemNameAndId;
  additionalEquipment?: ItemNameAndId;
  pickUpWayPoint: WayPoint;
  dropOffWayPoint: WayPoint;
  salesContact: ExtendedContactItem;
  foremanContact: ExtendedContactItem;
  supervisorContact: ExtendedContactItem;
  collaboratorContact: ExtendedContactItem;
  priority?: number;
  vendorAccount?: AccountBasic;
  loads: Array<JobLoad>;
  foremen: Array<User>;
  jobNotes: string;
  orderNotes: string;
  projectNotes: string;
  internalNotes: string;
  vendor: { label: string; value: string } | null;
  driver: { label: string; value: string } | null;
}

export interface JobFormPropsWithRates extends JobFormProps {
  customerRate: RateDetailsDTO['targetRate'];
  customerRateType: RateDetailsDTO['targetRateType'];
  customerRateValue: RateDetailsDTO['targetRateValue'];
  assigneeRate?: {
    target: 'vendor' | 'driver';
    rate: RateDetailsDTO['targetRate'];
    rateType: RateDetailsDTO['targetRateType'];
    rateValue: RateDetailsDTO['targetRateValue'];
  };
}

export class JobTimeline {
  public static parse(proto: JobTimeline_Read) {
    return new JobTimeline(
      dayjs(proto.created_at),
      proto.created_by,
      proto.object_changes,
    );
  }

  public get createdAt(): Dayjs {
    return this._created_at;
  }
  public get createdBy(): Principal_Read_Nested {
    return this._created_by;
  }
  public get changes(): Record<string, any> {
    return this._object_changes;
  }

  constructor(
    private _created_at: Dayjs,
    private _created_by: Principal_Read_Nested,
    private _object_changes: Record<string, any>,
  ) {}
}

export class JobTripEvent {
  public static parse(proto: JobEvent_Read) {
    return new JobTripEvent(
      proto.id,
      proto.lat ?? '',
      proto.lon ?? '',
      proto.state,
      proto.event_name,
      proto.created_by ?? null,
      dayjs(proto.created_at),
      dayjs(proto.occurred_at) ?? dayjs(),
    );
  }
  public static deparseUpdate(jobEvent: any): JobEvent_Update {
    return {
      id: jobEvent.id,
      occurred_at: dayjs(jobEvent?.occurredAt).toISOString() || dayjs().toISOString(),
    };
  }
  public get id(): string | undefined {
    return this._id;
  }

  public get lat(): number {
    return parseFloat(this._lat);
  }

  public get lng(): number {
    return parseFloat(this._lon);
  }

  public get state(): string | JobState {
    return this._state;
  }

  public get eventName(): JobEventName {
    return this._event_name;
  }

  public get createdBy(): Nullable<Principal_Read_Nested> {
    return this._created_by;
  }
  public get createdAt(): Dayjs {
    return this._created_at;
  }
  public get occurredAt(): Dayjs {
    return this._occurred_at;
  }
  constructor(
    private _id: string | undefined,
    private _lat: string,
    private _lon: string,
    private _state: string | JobState,
    private _event_name: JobEventName,
    private _created_by: Nullable<Principal_Read_Nested>,
    private _created_at: Dayjs,
    private _occurred_at: Dayjs,
  ) {}
}

export class JobLoadCycle {
  public static parse(proto: LoadCycle_Read) {
    return new JobLoadCycle(proto.from_load_id, proto.to_load_id, proto.time_minutes);
  }

  get fromLoadId(): string {
    return this._from_load_id;
  }

  get toLoadId(): string {
    return this._to_load_id;
  }

  get timeMinutes(): number {
    return this._time_minutes || 0;
  }

  constructor(
    private _from_load_id: string,
    private _to_load_id: string,
    private _time_minutes: number,
  ) {}
}

export class JobLoadBasic {
  constructor(
    private _id: Nullable<string>,
    private _quantity: string,
    private _unit_of_measure: OrderUnitOfMeasure,
    private _load_id: string,
    private _ordinality: number,
    private _ticket: Nullable<TicketBasic>,
  ) {}

  public get id() {
    return this._id;
  }

  public get quantity() {
    return this._quantity;
  }

  public get unitOfMeasure() {
    return this._unit_of_measure;
  }

  public get loadId() {
    return this._load_id;
  }

  public get ordinality() {
    return this._ordinality;
  }

  public get ticket() {
    return this._ticket;
  }

  public static parse(proto: Load_Read_Nested) {
    return new JobLoadBasic(
      proto.id,
      proto.quantity,
      proto.unit_of_measure,
      proto.load_id,
      proto.ordinality,
      proto.ticket ? TicketBasic.parse(proto.ticket) : null,
    );
  }
}

export class JobLoad {
  public static parse(proto: Load_Read) {
    return new JobLoad(
      proto.id ?? '',
      proto.created_at ? dayjs(proto.created_at) : dayjs(),
      proto.updated_at ? dayjs(proto.updated_at) : dayjs(),
      proto.ordinality || 0,
      proto.quantity ?? '',
      proto.unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.unit_of_measure,
            id: proto.unit_of_measure,
          })
        : null,
      proto.state ?? LoadState.CREATED,
      proto.load_id || '',
      proto.job_id || '',
    );
  }

  public static deparse(proto: LoadOptionsProps): Load_Create {
    return {
      // WFT BE with the `quantity` types for create and update??
      quantity: Number(proto.quantity),
      unit_of_measure: proto.unitOfMeasure?.id as OrderUnitOfMeasure,
    };
  }

  public static deparseUpdate(proto: JobLoad): Job_Load_Update {
    return {
      id: proto.id,
      quantity: String(proto.quantity),
      unit_of_measure: (proto.unitOfMeasure?.id ||
        proto._unit_of_measure?.id ||
        undefined) as OrderUnitOfMeasure,
    };
  }

  public get id(): string {
    return this._id;
  }

  public get createdAt(): Dayjs {
    return this._created_at;
  }

  public get updatedAt(): Dayjs {
    return this._updated_at;
  }

  public get ordinality(): number {
    return this._ordinality;
  }

  public get quantity(): string {
    return this._quantity;
  }

  public set quantity(value: string) {
    this._quantity = value;
  }

  public get unitOfMeasure(): Nullable<ItemNameAndId> {
    return this._unit_of_measure;
  }

  public set unitOfMeasure(value: Nullable<ItemNameAndId>) {
    this._unit_of_measure = ItemNameAndId.parse(value);
  }

  public get status(): LoadState {
    return this._status;
  }

  public get loadId(): string {
    return this._load_id || '';
  }

  public get jobId(): string {
    return this._job_id || '';
  }
  constructor(
    private _id: string,
    private _created_at: Dayjs,
    private _updated_at: Dayjs,
    private _ordinality: number,
    private _quantity: string,
    private _unit_of_measure: Nullable<ItemNameAndId>,
    private _status: LoadState,
    private _load_id?: string,
    private _job_id?: string,
  ) {}
}

export class Job {
  public static parse(proto: Job_Load_Read | Job_Read): Job {
    return new Job(
      proto.id ?? '',
      proto.editable,
      proto.state ?? '',
      proto.state_changed_at ? dayjs(proto.state_changed_at) : null,
      proto.job_start_at ? dayjs(proto.job_start_at) : null,
      proto.notes ?? '',
      proto.quantity ?? '',
      proto.unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.unit_of_measure,
            id: proto.unit_of_measure,
          })
        : null,
      proto.job_id ?? '',
      proto.order ? Order.parse(proto.order as unknown as Order_Read) : null,
      proto.project ? ProjectBasic.parse(proto.project) : null,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.additional_equipment?.length
        ? BasicEquipment.parse(proto.additional_equipment[0])
        : null,
      proto.material ? BasicMaterial.parse(proto.material) : null,
      proto.service ? Service.parse(proto.service) : null,
      proto.waypoints.map(WayPoint.parse),
      proto.waypoints
        .filter((point) => {
          return point.type === WaypointType.PICKUP;
        })
        .map(WayPoint.parse),
      proto.waypoints
        .filter((point) => {
          return point.type === WaypointType.DROP_OFF;
        })
        .map(WayPoint.parse),
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto.state_events,
      proto.sales_contact
        ? ExtendedContactItem.parse({ ...proto.sales_contact, type: ContactTypes.SALES })
        : null,
      proto.foreman_contact
        ? ExtendedContactItem.parse({
            ...proto.foreman_contact,
            type: ContactTypes.FOREMAN,
          })
        : null,
      proto.collaborator_contact
        ? ExtendedContactItem.parse({
            ...proto.collaborator_contact,
            type: ContactTypes.COLLABORATOR,
          })
        : null,
      proto.supervisor_contact
        ? ExtendedContactItem.parse({
            ...proto.supervisor_contact,
            type: ContactTypes.SUPERVISOR,
          })
        : null,
      proto.driver ? DriverBasic.parse(proto.driver as Driver_Read_Nested) : null,
      proto.priority ?? 0,
      proto.tickets?.length ? proto.tickets.map(Ticket.parse) : [],
      proto.billable_hours ?? '',
      proto.missing_ticket_info,
      proto.created_at ? dayjs(proto.created_at) : dayjs(),
      proto.updated_at ? dayjs(proto.updated_at) : dayjs(),
      // @ts-ignore
      proto.loads?.length
        ? // @ts-ignore
          sortBy(proto.loads.map(JobLoad.parse), 'ordinality')
        : [],
      proto.load_cycle_avg ?? 0,
      // @ts-ignore
      proto.customer_job_assignment
        ? JobAssignmentNested.parse(proto.customer_job_assignment)
        : null,
      proto.vendor_job_assignment
        ? JobAssignmentNested.parse(proto.vendor_job_assignment)
        : null,
      proto.foremen?.length
        ? proto?.foremen.map((item) => User.parse(item as User_Me_Read))
        : [],
      proto.hauler_rate_type ? (proto.hauler_rate_type as RateTypes) : null,
      proto.driver_rate ? RateBasicWithValue.parse(proto.driver_rate) : null,
      proto.driver_rate_value ? Number(proto.driver_rate_value) : null,
      proto.driver_rate_type,
      Number(proto.delivered_quantity),
      proto.loads_count,
      proto.completed_loads_count,
    );
  }

  public static deparseApproveUpdate(job: JobApprovalSchemaInterface): Job_Update {
    const res: any = {
      id: job.id,
      quantity: Number(job.quantity),
      billable_hours: Number(job.billableHours),
      unit_of_measure: job?.unitOfMeasure?.id || undefined,
      customer_rate_id: job?.customerRate?.id || undefined,
      vendor_rate_id: job?.vendorRate?.id,
    };

    return res as Job_Update;
  }

  public static deparseUpdate(job: JobFormPropsWithRates): Job_Update {
    const { formenContact, salesContact, collaboratorContact, supervisorContact } =
      this.getJobContacts(job);

    const customerRate = deparseUpdateRateDetailsDTO('customer', {
      targetRate: job.customerRate,
      targetRateType: job.customerRateType,
      targetRateValue: job.customerRateValue,
    });
    const vendorOrDriverRate = job.assigneeRate
      ? deparseUpdateRateDetailsDTO(job.assigneeRate.target, {
          targetRate: job.assigneeRate.rate,
          targetRateType: job.assigneeRate.rateType,
          targetRateValue: job.assigneeRate.rateValue,
        })
      : {};

    const res: Job_Update & { id: string } = {
      id: job.id,
      job_start_at: job.jobStartAt ? dayjs(job.jobStartAt)?.toISOString() : undefined,
      notes: job.jobNotes,
      quantity: job.quantity,
      unit_of_measure: job.unitOfMeasure?.id as OrderUnitOfMeasure,
      equipment_id: job.equipment?.id,
      additional_equipment_ids: job.additionalEquipment
        ? [job.additionalEquipment.id]
        : [],
      material_id: job.material?.id,
      waypoints: [
        WayPoint.deparse(job.dropOffWayPoint as WayPointFormProps),
        WayPoint.deparse(job.pickUpWayPoint as WayPointFormProps),
      ],
      priority: job.priority || 0,
      loads: job.loads.map(JobLoad.deparseUpdate),
      foreman_ids: (job.foremen || []).map((foreman) => foreman.id) || [],
      sales_contact: salesContact ? (salesContact as Contact_Update) : undefined,
      foreman_contact: formenContact ? formenContact : undefined,
      collaborator_contact: collaboratorContact
        ? (collaboratorContact as Contact_Update)
        : undefined,
      supervisor_contact: supervisorContact
        ? (supervisorContact as Contact_Update)
        : undefined,
      ...customerRate,
      ...vendorOrDriverRate,
    };

    return res;
  }

  // The job parameter should use some type reference so that we know what properties are available (see: TRE-2938)
  public static deparseUpdateFromInlineMode(job: any): Job_Update {
    // Extract the date part from the `scheduled_date` or the existing `job_start_at` if `scheduled_date` is not provided.
    const scheduledDate = job.scheduled_date
      ? dayjs(job.scheduled_date)
      : job.jobStartAt
        ? dayjs(job.jobStartAt)
        : dayjs(job._job_start_at);
    // Extract the time part from the `job_start_at`
    const scheduledTime = job.jobStartAt
      ? dayjs(job.jobStartAt)
      : dayjs(job._job_start_at);
    // Combine the date part and the time part into a new Dayjs object
    const combinedDateTime = scheduledDate
      .hour(scheduledTime.hour())
      .minute(scheduledTime.minute())
      .second(scheduledTime.second());

    const res = {
      id: job._id,
      job_start_at: combinedDateTime.isValid()
        ? combinedDateTime.toISOString()
        : undefined,
      priority: job.priority || job._priority,
      quantity: job.quantity || job._quantity,
      unit_of_measure: job._unit_of_measure?._id,
      equipment_id: job._equipment?.id || null,
      additional_equipment_ids: job._additional_equipment?.id
        ? [job._additional_equipment.id]
        : [],
      material_id: (job.material || job._material)?._id,
      driver_id: job.driver_id || (job.driver || job._driver)?.id,
    };

    return res;
  }

  // This method is the same in order/projects and should be refactored to a common place
  public static getJobContacts(job: Job | any) {
    const contacts: Record<string, any> = {};
    Object.keys(contactTypeMap).forEach((key) => {
      const contactType = contactTypeMap[key];
      const contact = filterContact(job, contactType);
      contacts[key] = transformContact(contact);
    });
    return contacts;
  }

  public get id(): string {
    return this._id;
  }

  public get editable(): boolean {
    return this._editable;
  }

  public get status(): JobState {
    return this._state;
  }

  public get statusChangedAt(): dayjs.Dayjs | null {
    return this._state_changed_at;
  }
  public get isActive(): boolean {
    return ACTIVE_JOB_STATES.includes(this._state);
  }
  public get isFlagged(): boolean {
    return this._state === JobState.FLAGGED;
  }
  public get isCompleted(): boolean {
    return this._state === JobState.COMPLETED;
  }
  public get isPayed(): boolean {
    return this._state === JobState.PAID;
  }
  public get isSignedOff(): boolean {
    return this._state === JobState.SIGNED_OFF;
  }
  public get jobStartAt(): Nullable<Dayjs> {
    return this._job_start_at;
  }

  public get notes(): string | null {
    return this._notes;
  }

  public get quantity(): string {
    return this._quantity;
  }

  public get unitOfMeasure(): Nullable<ItemNameAndId> {
    return this._unit_of_measure;
  }

  public get driverFullName(): string {
    return [this._driver?.firstName, this._driver?.lastName]
      .filter((item) => !!item)
      .join(' ');
  }

  public get jobId(): string {
    return this._job_id;
  }

  public get equipment(): Nullable<BasicEquipment> {
    return this._equipment;
  }
  public get additionalEquipment(): Nullable<BasicEquipment> {
    return this._additional_equipment;
  }
  public get material(): Nullable<BasicMaterial> {
    return this._material;
  }
  public get isMissingInfo(): boolean {
    return this._isMissingInfo || false;
  }
  public get service(): Nullable<Service> {
    return this._service;
  }

  public get waypoints(): WayPoint[] {
    return this._waypoints;
  }

  public get pickUpWayPoints(): WayPoint[] {
    return this._pickUpWayPoints;
  }
  public get dropOffWayPoints(): WayPoint[] {
    return this._dropOffWayPoints;
  }

  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }

  public get project(): Nullable<ProjectBasic> {
    return this._project;
  }
  public get projectName(): string {
    // Fake
    return this._project?.name || '';
  }

  public get order(): Nullable<Order> {
    return this._order;
  }

  public get orderId(): string {
    return this._order?.orderId || '';
  }

  public get isSalesContact(): boolean {
    return Boolean(this._sales_contact?.id.length);
  }

  public get isSupervisorContact(): boolean {
    return Boolean(this._supervisor_contact?.id.length);
  }

  public get isForemanContact(): boolean {
    return Boolean(this._foreman_contact?.id.length);
  }

  public get isCollaboratorContact(): boolean {
    return Boolean(this._collaborator_contact?.id.length);
  }

  public get foremanContact(): Nullable<ExtendedContactItem> {
    return this._foreman_contact;
  }

  public get salesContact(): Nullable<ExtendedContactItem> {
    return this._sales_contact;
  }

  public get supervisorContact(): Nullable<ExtendedContactItem> {
    return this._supervisor_contact;
  }

  public get collaboratorContact(): Nullable<ExtendedContactItem> {
    return this._collaborator_contact;
  }

  public get allContacts(): ExtendedContactItem[] {
    const allContacts = [];
    if (this._sales_contact) {
      allContacts.push(this._sales_contact);
    }
    if (this._foreman_contact) {
      allContacts.push(this._foreman_contact);
    }
    if (this._collaborator_contact) {
      allContacts.push(this._collaborator_contact);
    }
    if (this._supervisor_contact) {
      allContacts.push(this._supervisor_contact);
    }
    return allContacts;
  }

  public get createdAt(): Dayjs {
    return this._createdAt || dayjs();
  }
  public get updatedAt(): Dayjs {
    return this._updatedAt || dayjs();
  }
  public get driver(): Nullable<DriverBasic> {
    return this._driver;
  }

  public get priority(): number {
    return this._priority || 0;
  }

  public get tickets(): Array<Ticket> {
    return this._tickets || [];
  }
  public set tickets(value: Ticket[]) {
    this._tickets = value || [];
  }
  public get billableHours(): string {
    return this._billable_hours || '';
  }
  public get loads(): Array<JobLoad> {
    return this._loads || [];
  }

  public get loadCycleAvg(): number {
    return this._load_cycle_avg || 0;
  }

  public get customerJobAssignment(): Nullable<JobAssignmentNested> {
    return this._customer_job_assignment;
  }

  public get vendorJobAssignment(): Nullable<JobAssignmentNested> {
    return this._vendor_job_assignment;
  }

  public get stateEvents(): string[] {
    return this._state_events;
  }

  public get foremen(): Array<User> {
    return this._foremen || [];
  }

  public get haulerRateType(): Nullable<RateTypes> {
    return this._hauler_rate_type;
  }
  public get jobNotes(): Nullable<string> {
    return this.notes;
  }

  public get driverRate() {
    return this._driver_rate;
  }

  public get driverRateValue() {
    return this._driver_rate_value;
  }

  public get driverRateType() {
    return this._driver_rate_type;
  }

  public get deliveredQuantity() {
    return this._delivered_quantity;
  }

  public get loadsCount() {
    return this._loads_count;
  }

  public get completedLoadsCount() {
    return this._completed_loads_count;
  }

  constructor(
    private _id: string,
    private _editable: boolean,
    private _state: JobState,
    private _state_changed_at: dayjs.Dayjs | null,
    private _job_start_at: Nullable<dayjs.Dayjs> | null,
    private _notes: string | null,
    private _quantity: string,
    private _unit_of_measure: Nullable<ItemNameAndId>,
    private _job_id: string,
    private _order: Nullable<any>, // Todo: replace any with model definition
    private _project: Nullable<ProjectBasic>,
    private _equipment: Nullable<BasicEquipment>,
    private _additional_equipment: Nullable<BasicEquipment>,
    private _material: Nullable<BasicMaterial>,
    private _service: Nullable<Service>,
    private _waypoints: WayPoint[],
    private _pickUpWayPoints: WayPoint[],
    private _dropOffWayPoints: WayPoint[],
    private _company: Nullable<CompanyBasic>,
    private _state_events: string[],
    private _foreman_contact?: Nullable<ExtendedContactItem>,
    private _sales_contact?: Nullable<ExtendedContactItem>,
    private _supervisor_contact?: Nullable<ExtendedContactItem>,
    private _collaborator_contact?: Nullable<ExtendedContactItem>,
    private _driver?: Nullable<DriverBasic>,
    private _priority?: number,
    private _tickets?: Ticket[],
    private _billable_hours?: string,
    private _isMissingInfo?: boolean,
    private _createdAt?: dayjs.Dayjs,
    private _updatedAt?: dayjs.Dayjs,
    private _loads?: JobLoad[],
    private _load_cycle_avg?: number,
    private _customer_job_assignment?: Nullable<JobAssignmentNested>,
    private _vendor_job_assignment?: Nullable<JobAssignmentNested>,
    private _foremen?: Array<User> | null,
    private _hauler_rate_type?: Nullable<RateTypes>,
    private _driver_rate?: Nullable<RateBasicWithValue>,
    private _driver_rate_value?: Nullable<number>,
    private _driver_rate_type?: Nullable<RateType>,
    private _delivered_quantity?: number,
    private _loads_count?: number,
    private _completed_loads_count?: number,
  ) {}
}

export class JobAssignmentNested {
  public static parse(proto: JobAssignment_Read_Nested) {
    return new JobAssignmentNested(
      proto.id ?? '',
      proto.customer_account ? AccountBasic.parse(proto.customer_account) : null,
      proto.vendor_account ? AccountBasic.parse(proto.vendor_account) : null,
      proto.rate ? RateBasicWithValue.parse(proto.rate) : null,
      proto.rate_value ? Number(proto.rate_value) : null,
      proto.rate_type ?? null,
    );
  }

  public get id(): string {
    return this._id;
  }

  public get customerAccount(): Nullable<AccountBasic> {
    return this._customer_account;
  }

  public get vendorAccount(): Nullable<AccountBasic> {
    return this._vendor_account;
  }

  public get rate(): Nullable<RateBasicWithValue> {
    return this._rate;
  }

  public get rateValue() {
    return this._rate_value;
  }

  public get rateType() {
    return this._rate_type;
  }

  constructor(
    private _id: string,
    private _customer_account?: Nullable<AccountBasic>,
    private _vendor_account?: Nullable<AccountBasic>,
    private _rate?: Nullable<RateBasicWithValue>,
    private _rate_value?: Nullable<number>,
    private _rate_type?: Nullable<RateType>,
  ) {}
}
interface JobAssignmentJobNested {
  id: string;
}
export class JobAssignment extends JobAssignmentNested {
  public static parse(proto: JobAssignment_Read) {
    return new JobAssignment(
      proto.id ?? '',
      proto.customer_account ? AccountBasic.parse(proto.customer_account) : null,
      proto.vendor_account ? AccountBasic.parse(proto.vendor_account) : null,
      proto.rate ? RateBasicWithValue.parse(proto.rate) : null,
      proto.job as JobAssignmentJobNested,
      proto.created_at ? dayjs(proto.created_at) : dayjs(),
      proto.updated_at ? dayjs(proto.updated_at) : dayjs(),
    );
  }

  public get jobId(): string {
    return this._job.id;
  }

  public get createdAt(): Dayjs {
    return this._created_at || dayjs();
  }

  public get updatedAt(): Dayjs {
    return this._updated_at || dayjs();
  }

  constructor(
    id: string,
    customerAccount: Nullable<AccountBasic>,
    vendorAccount: Nullable<AccountBasic>,
    rate: Nullable<RateBasicWithValue>,
    private _job: JobAssignmentJobNested,
    private _created_at?: Dayjs,
    private _updated_at?: Dayjs,
  ) {
    super(id, customerAccount, vendorAccount, rate);
  }
}

export class JobBasic {
  constructor(
    private _id: Nullable<string>,
    private _job_id: string,
    private _loads_count: number,
    private _job_start_at: Date,
  ) {}

  public get id() {
    return this._id;
  }

  public get jobId() {
    return this._job_id;
  }

  public get loadsCount() {
    return this._loads_count;
  }

  public get jobStartAt() {
    return this._job_start_at;
  }

  public static parse(proto: Job_Read_Nested) {
    return new JobBasic(
      proto.id,
      proto.job_id,
      proto.loads_count,
      new Date(proto.job_start_at),
    );
  }
}

export class JobSummary {
  constructor(
    private _logging_started_at: Dayjs,
    private _logging_ended_at: Dayjs,
    private _completed_loads_count: number,
    private _break_time_minutes: number,
    private _work_time_minutes: number,
    private _approved_work_time_minutes: Nullable<number>,
    private _first_geofence_entry_at: Nullable<Dayjs>,
    private _last_geofence_exit_at: Nullable<Dayjs>,
  ) {}

  public get loggingStartedAt() {
    return this._logging_started_at;
  }

  public get loggingEndedAt() {
    return this._logging_ended_at;
  }

  public get completedLoadsCount() {
    return this._completed_loads_count;
  }

  public get breakTimeMinutes() {
    return this._break_time_minutes;
  }

  public get workTimeMinutes() {
    return this._work_time_minutes;
  }

  public get approvedWorkTimeMinutes() {
    return this._approved_work_time_minutes;
  }

  public get firstGeofenceEntryAt() {
    return this._first_geofence_entry_at;
  }

  public get lastGeofenceExitAt() {
    return this._last_geofence_exit_at;
  }

  public static parse(proto: JobSummary_Read) {
    return new JobSummary(
      dayjs(proto.logging_started_at),
      dayjs(proto.logging_ended_at),
      proto.completed_loads_count,
      proto.break_time_minutes,
      proto.work_time_minutes,
      proto.approved_work_time_minutes,
      proto.first_geofence_entry_at ? dayjs(proto.first_geofence_entry_at) : null,
      proto.last_geofence_exit_at ? dayjs(proto.last_geofence_exit_at) : null,
    );
  }
}
