import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { t as $t, t } from 'i18next';
import { get, startCase } from 'lodash';
import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField, CompanySelectorFormField } from '~components/FormFields';
import { data as enums } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { Company, CompanyBasic, useCompany } from '~hooks/useCompany';
import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';

import { connectionValidationSchema } from './connectionValidationSchema';

interface AddConnectionFormProps {
  senderCompanyId: string;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
}

interface ReceiverCompanyVerificationInfoTableProps {
  companyData: Company;
}

interface DefaultValues {
  senderCompanyId: string;
  receiverCompanyId: Nullable<string>;
  accountTypes: Nullable<string[]>;
  receiverCompany: Nullable<CompanyBasic>;
}

interface AddConnectionFormHandler {
  submit: (callBack: () => void) => void;
  resetForm: (callBack?: () => void) => void;
}

const getFullAddressDisplay = (address: Company['address']) => {
  if (address) {
    const { streetAddress, premise, country, administrativeArea } = address;

    return `${streetAddress} ${premise}, ${administrativeArea}${country?.name ? `, ${country?.name}` : ''}`;
  }

  return '';
};

const DividerWithYMargin = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FormWrapper = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
});

const TableHeading = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  padding: '8px',
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.grey[50],
  fontSize: '12px',
  whiteSpace: 'nowrap',
}));

const TableValue = styled(TableCell)({
  whiteSpace: 'nowrap',
});

const CompanyInfoVerificationContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

// Typescript limitation where MUI component prop types
// Get lost when using styled components.
// This is a workaround to fix the issue as documented here:
// https://github.com/mui/material-ui/issues/15695#issuecomment-1026602904
const CompanyInfoVerificationHeading = styled(Typography)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const ReceiverCompanyVerificationInfoTable = ({
  companyData,
}: ReceiverCompanyVerificationInfoTableProps) => {
  const addressDisplay = getFullAddressDisplay(companyData.address);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeading component="th">{t('common.tread_id')}</TableHeading>
            <TableHeading component="th">{t('common.name')}</TableHeading>
            <TableHeading component="th">{t('common.company_type')}</TableHeading>
            <TableHeading component="th">{t('common.usage_status')}</TableHeading>
            <TableHeading component="th">{t('common.billing_status')}</TableHeading>
            <TableHeading component="th">{t('common.plan')}</TableHeading>
            <TableHeading component="th">{t('common.address')}</TableHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={companyData.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableValue scope="row">{companyData.treadId}</TableValue>
            <TableValue>
              <Typography component="span">{companyData.legalName}</Typography>
            </TableValue>
            <TableValue>
              <Typography component="span">
                {startCase(get(companyData, 'companyType') as string)}
              </Typography>
            </TableValue>
            <TableValue>
              <Typography component="span">
                {startCase(get(companyData, 'usageStatus', '') as string)}
              </Typography>
            </TableValue>
            <TableValue>
              <Typography component="span">
                {startCase(get(companyData, 'billingStatus') as string)}
              </Typography>
            </TableValue>
            <TableValue>
              <Typography component="span">
                {startCase(get(companyData, 'saasBillingPlan') as string)}
              </Typography>
            </TableValue>
            <TableValue>
              <Typography component="span">{addressDisplay}</Typography>
            </TableValue>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AddConnectionForm = forwardRef(function AddConnectionForm(
  { senderCompanyId, onFormStateChange }: AddConnectionFormProps,
  ref: Ref<AddConnectionFormHandler>,
) {
  const { getCompanyById } = useCompany();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm<DefaultValues>({
    resolver: yupResolver(connectionValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      senderCompanyId: senderCompanyId || '',
      receiverCompanyId: null,
      accountTypes: [],
      receiverCompany: null,
    },
  });
  const selectedReceiverCompany: Nullable<CompanyBasic> = watch('receiverCompany');
  const previousSelectedReceiverCompany = usePrevious<Nullable<CompanyBasic>>(
    selectedReceiverCompany,
  );

  const [selectedReceiverCompanyVerificationInfo, setReceiverCompanyVerificationInfo] =
    useState<Nullable<Company>>(null);

  // Allows for ref to be used to call form methods
  // And submit form from parent component
  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  useEffect(() => {
    if (
      selectedReceiverCompany &&
      previousSelectedReceiverCompany?.id !== selectedReceiverCompany?.id
    ) {
      setValue('receiverCompanyId', selectedReceiverCompany.id);

      const getReceiverCompanyData = async (receiverCompanyId: string) => {
        await getCompanyById({
          id: receiverCompanyId,
          callBack: setReceiverCompanyVerificationInfo,
        });
      };

      getReceiverCompanyData(selectedReceiverCompany.id);
    }
  }, [previousSelectedReceiverCompany, selectedReceiverCompany?.id]);

  return (
    <FormWrapper component="form" data-test-id="company-form">
      <Box sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          multiple={true}
          name="accountTypes"
          errors={errors}
          list={enums.account_type.values}
          placeholder={`${$t('account.form.account_type')}`}
          isRequired={true}
          getValue={(item) => item}
          getLabel={(item) => startCase(item)}
          defaultValue={[]}
        />
      </Box>

      <Box sx={{ mb: 1 }}>
        <CompanySelectorFormField
          name="receiverCompany"
          errors={errors}
          control={control}
          hideLabel={true}
          isRequired={true}
          inputProps={{
            placeholder: `${$t('form_fields.company_selector_hint')}`,
          }}
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        />
      </Box>

      {selectedReceiverCompanyVerificationInfo && (
        <>
          <DividerWithYMargin />

          <CompanyInfoVerificationContainer>
            <CompanyInfoVerificationHeading component="p">
              {'Company Info (for verification purposes)'}
            </CompanyInfoVerificationHeading>

            <ReceiverCompanyVerificationInfoTable
              companyData={selectedReceiverCompanyVerificationInfo as Company}
            />
          </CompanyInfoVerificationContainer>
        </>
      )}

      {/*Submit buttons are moved to the parent component*/}
    </FormWrapper>
  );
});
export { AddConnectionForm };
