const generateHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? 'AM' : 'PM';
    hours.push(`${hour} ${period}`);
  }
  return hours;
};
export const HOURS = generateHours();
export const FULL_HOUR_WIDTH = 114;

export const INCREMENTS_PER_HOUR = 4;
export const MINUTES_PER_INCREMENT = 60 / INCREMENTS_PER_HOUR;
export const GRID_ITEM_WIDTH = FULL_HOUR_WIDTH / INCREMENTS_PER_HOUR;
export const HEADER_HEIGHT = 110;
export const FOOTER_HEIGHT = 40;
export const ROW_HEIGHT = 125;
export const JOB_COLUMN_WIDTH = 250;
export const JOB_WIDTH = 224;
export const STICKY_HEADER_HEIGHT = 81;
export const STICKY_HEADER_HEIGHT_BUFFER = 15;
export const JOB_CARD_OFFSET = 230;
