import Box from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { styled, SxProps } from '@mui/system';
import { CalendarIcon } from '@mui/x-date-pickers/icons';
import { t as $t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  CustomerSelector,
  DateRangeSelector,
  DispatchSearchParam,
  DriverSelector,
  DropoffSiteSelector,
  PickupSiteSelector,
  ProjectSelector,
  VendorSelector,
} from '~components/Order/Selectors';
import { useStores } from '~store';

import { DispatchNumberSelector } from './Selectors/DispatchNumberSelector';
import OrderJobStatesSelector from './Selectors/OrderJobStatesSelector';
import { ProjectExtIDSelector } from './Selectors/ProjectExtIDSelector';

interface Props {
  isJobsGrid?: boolean;
  sx?: SxProps<Theme>; // Style
}

// The filters that will be used to filter the dispatch page
export const DISPATCH_FILTERS = [
  DispatchSearchParam.PROJECT,
  DispatchSearchParam.EXTERNAL_ID,
  DispatchSearchParam.CUSTOMER_ACCOUNT,
  DispatchSearchParam.DISPATCH_NUMBER,
  DispatchSearchParam.VENDOR_ACCOUNT,
  DispatchSearchParam.DRIVER,
  DispatchSearchParam.MATERIAL,
  DispatchSearchParam.EQUIPMENT_TYPE,
  DispatchSearchParam.SERVICE,
  DispatchSearchParam.PICKUP_SITE,
  DispatchSearchParam.DROPOFF_SITE,
  DispatchSearchParam.STATE,
  DispatchSearchParam.STATUS,
];

const SelectDateButton = styled(IconButton)<IconButtonProps>(() => ({
  padding: '4px 16px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 500,
  border: '1px solid #D0D5DD',
  borderRadius: '4px',
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
    borderColor: '#000',
  },
}));

/*
 * OrderFilter component which will manage all the filters for the dispatch page (orders/jobs)
 */
const OrderFilter = ({ isJobsGrid, sx = {} }: Props) => {
  const { userStore } = useStores();
  const [showCustomDateRange, setShowCustomDateRange] = React.useState(false);
  const [searchParams] = useSearchParams();

  const companyId = useMemo(
    () => userStore.user.company?.id || '',
    [userStore.user.company?.id],
  );

  const allSearchParams = useMemo(() => {
    const res: Record<string, any> = {};

    for (const key of searchParams.keys()) {
      res[`${key}`] = searchParams.get(key);
    }

    return res;
  }, [searchParams]);

  useEffect(() => {
    const hasCustomDateRange = searchParams.has('date_range');

    setShowCustomDateRange(hasCustomDateRange);
  }, [allSearchParams]);

  return (
    <Box
      component={'form'}
      display={'flex'}
      sx={{
        flexDirection: 'row',
        overflow: 'visible',
        ...sx,
      }}
      flexWrap={'wrap'}
    >
      <ProjectSelector allSearchParams={allSearchParams} />
      <CustomerSelector allSearchParams={allSearchParams} />
      <VendorSelector allSearchParams={allSearchParams} />
      {!!companyId && (
        <DriverSelector allSearchParams={allSearchParams} companyId={companyId} />
      )}
      <ProjectExtIDSelector allSearchParams={allSearchParams} />
      <DispatchNumberSelector allSearchParams={allSearchParams} />
      <PickupSiteSelector allSearchParams={allSearchParams} />
      <DropoffSiteSelector allSearchParams={allSearchParams} />
      {!isJobsGrid && <OrderJobStatesSelector allSearchParams={allSearchParams} />}
      {showCustomDateRange ? (
        <Box sx={{ minWidth: '210px' }}>
          <DateRangeSelector />
        </Box>
      ) : (
        <SelectDateButton
          onClick={() => setShowCustomDateRange(true)}
          sx={{ height: '41px', mt: '1px' }}
        >
          <CalendarIcon sx={{ mr: 1 }} />
          {$t('form_fields.date_select')}
        </SelectDateButton>
      )}
    </Box>
  );
};

export default OrderFilter;
