import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { GenericSelector } from '~components/Order/Selectors/GenericSelector';
import { useMaterials } from '~hooks/useMaterials';
import { useStores } from '~store';

import DispatchSearchParam from './DispatchSearchParam';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const MaterialSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { companyAssetsStore } = useStores();
  const { isLoading, getAllMaterials } = useMaterials();

  // Loads the list of materials for this company if there is a material in the URL search params
  // Ideally we should only fetch what is found in the search params, but for now we will fetch all
  const prefetchMaterials = () => {
    if (companyAssetsStore.materials.length === 0) {
      getAllMaterials();
    }
  };
  useEffect(() => {
    if (allSearchParams[DispatchSearchParam.MATERIAL]) {
      prefetchMaterials();
    }
  }, [allSearchParams[DispatchSearchParam.MATERIAL]]);

  return (
    <>
      <Box sx={{ ...sx }}>
        <GenericSelector
          fieldName={DispatchSearchParam.MATERIAL}
          options={companyAssetsStore.materials}
          label={$t('form_fields.material')}
          allSearchParams={allSearchParams}
          sx={{ ...sx }}
          getOptionLabel={(item) => item.name || ''}
          loadData={prefetchMaterials}
          isDataLoading={isLoading}
        />
      </Box>
    </>
  );
});

export { MaterialSelector };
