import './translations/i18n';

import { datadogRum } from '@datadog/browser-rum';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { Router } from '~router/Router';
import theme from '~theme/AppTheme';

import { ErrorGeneralView } from './pages/Static/ErrorGeneralView';
declare global {
  interface Window {
    analytics: any;
  }
}

// Avoid errors with the following HTTP status codes from flowing to Sentry
const sentryOmittedHttpStatusCodes = [401];

if (['production', 'staging', 'sandbox'].includes(import.meta.env.TREAD__ENV)) {
  datadogRum.init({
    applicationId: '14dbc616-bd34-4369-90c4-786e33c58db4',
    clientToken: import.meta.env.TREAD__DATA_DOG_TOKEN!,
    site: 'datadoghq.com',
    service: 'horizon-fe',
    env: import.meta.env.TREAD__ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.TREAD__GIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://api.horizon-sandbox.com',
      'https://api.horizon-staging.com',
      'https://api.tread-horizon.com',
    ],
  });

  datadogRum.startSessionReplayRecording();

  Sentry.init({
    dsn: 'https://97b6c9728e794b3686f05c61d768f639@o76845.ingest.sentry.io/256719',
    release: import.meta.env.TREAD__GIT_SHA,
    environment: import.meta.env.TREAD__ENV,
    // Disable tracing
    enableTracing: false,
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    // Captures all sessions that contain errors: https://docs.sentry.io/platforms/javascript/session-replay/configuration/
    replaysOnErrorSampleRate: 1,
    integrations: [
      // Sentry session replay documentation: https://docs.sentry.io/platforms/javascript/session-replay/
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      Sentry.httpClientIntegration({
        // Configure sentry to ignore the following response statuses:
        //
        // 401 - Unauthorized
        // 403 - Forbidden
        // 422 - Unprocessable Entity
        //
        // Anything else in the 400-499 range and 500-599 range will be reported
        failedRequestStatusCodes: [400, 402, [404, 421], [423, 499], [500, 599]],
      }),
    ],
    beforeSend(event) {
      const statusCode = Number(event.extra?.error_status);
      const shouldOmitSentryLogging = sentryOmittedHttpStatusCodes.includes(statusCode);

      if (shouldOmitSentryLogging) {
        return null;
      }

      return event;
    },
  });
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

/**
 * Embeds a script by creating a script element and appending it to the document head.
 * @param publicPath - The public folder path of the script.
 */
const embedScript = (publicPath: string) => {
  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = publicPath;
  document.head.appendChild(scriptEl);
};

// Only include following 3rd party scripts on production environment
if (['production'].includes(import.meta.env.TREAD__ENV)) {
  // Include Segment Analytic on the specific environments
  embedScript('/segment.js');
  // This code block initializes and configures the Intercom chat widget on the webpage.
  embedScript('/intercom.js');
}

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorGeneralView
          error={error}
          resetError={resetError}
          componentStack={componentStack}
        ></ErrorGeneralView>
      )}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router />
        </LocalizationProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </>,
);
