import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';

import { DataValueItem, GroupTitle } from '~components/Common';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Job, JobAssignment, useJob } from '~hooks/useJob';
import { splitStringBySentenceChange } from '~utils/utilFunctions';

interface Props {
  details: Job;
  sx?: SxProps<Theme>; // Style
}
const BillingInfo = ({ details, sx = {} }: Props) => {
  const theme = useTheme();
  const { currencyFormatter } = useCompanyCurrency();
  const { getJobAssignments } = useJob();
  const [jobAssignments, setJobAssignments] = useState<Array<JobAssignment>>([]);

  useEffect(() => {
    getJobAssignments(details?.id).then((response) => {
      setJobAssignments(response);
    });
  }, []);

  const billingInfo = useMemo(() => {
    const customerBillingInfo = jobAssignments?.find(
      (jobAssignment) =>
        jobAssignment?.customerAccount?.id ===
        details?.customerJobAssignment?.customerAccount?.id,
    );

    const vendorBillingInfo = jobAssignments?.find(
      (jobAssignment) =>
        jobAssignment?.vendorAccount?.id ===
        details?.vendorJobAssignment?.vendorAccount?.id,
    );

    return {
      customerBillingInfo,
      vendorBillingInfo,
    };
  }, [jobAssignments]);

  const normalizedRate = (name: string): string => {
    return splitStringBySentenceChange(name.replace(/^Rate/, '')) || '';
  };

  const vendorRateName = useMemo(() => {
    if (billingInfo?.vendorBillingInfo?.rate?.type) {
      return normalizedRate(billingInfo?.vendorBillingInfo?.rate?.type);
    }

    return '-';
  }, [billingInfo?.vendorBillingInfo?.rate?.type, normalizedRate]);

  const customerRateName = useMemo(() => {
    if (billingInfo?.customerBillingInfo?.rate?.type) {
      return normalizedRate(billingInfo?.customerBillingInfo?.rate?.type);
    }

    return '-';
  }, [billingInfo?.customerBillingInfo?.rate?.type, normalizedRate]);

  return (
    <Box sx={{ ...sx }}>
      <GroupTitle>
        <>{$t('form_fields.billing_information')}</>
      </GroupTitle>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DataValueItem
            label={$t('approvals.vendor_rate_card')}
            value={
              billingInfo.vendorBillingInfo?.rate?.rate &&
              currencyFormatter(billingInfo.vendorBillingInfo?.rate?.rate)
            }
            defaultValue={'N/A'}
          />
        </Grid>
        <Grid item xs={4}>
          <DataValueItem label={$t('form_fields.unit')} value={vendorRateName} />
        </Grid>
        <Grid item xs={4}>
          {/* This will be - for now, as total values have been deprecated */}
          <DataValueItem label={$t('common.total')} value={'-'} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: -1 }}>
        <Grid item xs={4}>
          <DataValueItem
            label={$t('approvals.customer_rate_card')}
            value={
              billingInfo.customerBillingInfo?.rate?.rate &&
              currencyFormatter(billingInfo.customerBillingInfo?.rate?.rate)
            }
            defaultValue={'N/A'}
          />
        </Grid>
        <Grid item xs={4}>
          <DataValueItem label={$t('form_fields.unit')} value={customerRateName} />
        </Grid>
        <Grid item xs={4}>
          {/* This will be - for now, as total values have been deprecated */}
          <DataValueItem label={$t('common.total')} value={'-'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export { BillingInfo };
