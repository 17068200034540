import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';

interface TabProp {
  label: string;
  id: string;
  content: React.ReactNode;
  headerClassName?: string;
}
interface Props {
  tabs: Array<TabProp>;
  selectedTab?: string;
  sx?: SxProps<Theme>; // Style
  /**
   * Height of the panel in pixels.
   * @example 300 // 300px
   */
  panelHeight?: number;
}
const SmallTabs = ({ tabs, selectedTab, panelHeight, sx = {} }: Props) => {
  const theme = useTheme();
  const [tab, setTab] = useState<string>('');

  useEffect(() => {
    setTab(selectedTab || tabs[0].id);
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const tabHeight = '36px';

  const styles = {
    minHeight: tabHeight,
    height: tabHeight,
    root: {
      '& .MuiTab-root': {
        minWidth: 'unset',
        width: '50%',
      },
    },

    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.divider}`,
    mr: 1,
    py: 0.5,

    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      background: '#fff',
      borderBottomColor: 'transparent',
      '&.MuiTabs-indicator': {
        display: 'none',
      },
    },
  };

  return (
    <Box sx={{ ...sx }} className={'MuiTabs__small'}>
      <TabContext value={tab}>
        <TabList onChange={handleChange} sx={{ minHeight: tabHeight, height: tabHeight }}>
          {tabs.map((tab) => (
            <Tab
              key={`header_${tab.id}`}
              label={tab.label}
              value={tab.id}
              className={tab.headerClassName}
              sx={{ ...styles }}
            />
          ))}
        </TabList>
        <Box sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          {tabs.map((tab) => (
            <TabPanel
              key={tab.id}
              value={tab.id}
              sx={{
                p: 2,
                border: `1px solid ${theme.palette.divider}`,
                borderTop: 'none',
                borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
                overflow: panelHeight ? 'auto' : 'hidden',
                backgroundColor: '#fff',
                height: `${panelHeight}px` || 'auto',
              }}
            >
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export { SmallTabs };
