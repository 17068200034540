import { JobState, UserBulkSendJobsChannel_Read } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _, { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~pages/Dispatch/components/OrdersDispatchFiltersBar';
import { useDriverSchedulerFetch } from '~pages/Dispatch/hooks/useDriverSchedulerFetch';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { usePrevious } from '~utils/hooks/usePrevious';

const DriversViewEffects = () => {
  const {
    fetchUnassignedJobs,
    fetchDrivers,
    fetchAssignedJobs,
    subscribeToJobsRTU,
    subscribeToBulkSendJobsRTU,
  } = useDriverSchedulerFetch();
  const { userStore, driverSchedulerStore, toasterStore } = useStores();
  const companyId = userStore.userCompany?.id;
  const [unassignedJobsInput] = useState('');
  const previousDriversFilters = usePrevious(driverSchedulerStore.driversFilters);

  const applyUnassignedJobFilter = _.debounce(() => {
    driverSchedulerStore.setUnassignedJobsFilters({ search: unassignedJobsInput }, true);
  }, ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    applyUnassignedJobFilter();

    return () => {
      applyUnassignedJobFilter.cancel();
    };
  }, [unassignedJobsInput]);

  const handleBulkSendJobsUpdateReceived = useCallback(
    (data: UserBulkSendJobsChannel_Read) => {
      toasterStore.push(
        alert(
          t('dispatch.order.bulk_jobs_sent', {
            success_num: data.sent_job_friendly_ids?.length,
            failed_num: data.failed_job_friendly_ids?.length,
            failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
          }),
          data.failed_job_friendly_ids?.length || 0 > 0
            ? AlertTypes.error
            : AlertTypes.success,
        ),
        false,
        true,
      );
    },
    [],
  );

  useEffect(() => {
    let jobsRTUSubscription: ReturnType<typeof subscribeToJobsRTU> | null = null;
    let bulkSendJobsRTUSubscription: ReturnType<
      typeof subscribeToBulkSendJobsRTU
    > | null = null;

    if (companyId) {
      jobsRTUSubscription = subscribeToJobsRTU(companyId);
      bulkSendJobsRTUSubscription = subscribeToBulkSendJobsRTU(
        companyId,
        handleBulkSendJobsUpdateReceived,
      );
    }

    return () => {
      jobsRTUSubscription?.unsubscribe?.();
      bulkSendJobsRTUSubscription?.unsubscribe?.();
    };
  }, [companyId]);

  useEffect(() => {
    const debouncedFetchUnassignedJobs = debounce(() => {
      if (!driverSchedulerStore.dateFilters.startDate) return;
      driverSchedulerStore.clearUnassignedJobs();
      fetchUnassignedJobs({
        states: [JobState.CREATED, JobState.REJECTED, JobState.REASSIGN],
        companyId,
      });
    }, 300);

    debouncedFetchUnassignedJobs();

    // Cancel the debounce if the component unmounts or dependencies change
    return () => {
      debouncedFetchUnassignedJobs.cancel();
    };
  }, [
    driverSchedulerStore.unassignedJobsFilters,
    driverSchedulerStore.dateFilters.startDate,
  ]);

  useEffect(() => {
    if (!userStore.user.company) return;
    // Only fetch if the filters actually changed
    const filtersChanged =
      JSON.stringify(previousDriversFilters) !==
      JSON.stringify(driverSchedulerStore.driversFilters);

    if (filtersChanged) {
      driverSchedulerStore.clearDrivers();
      fetchDrivers({
        companyId: userStore.user.company.id,
      });
    }
  }, [userStore.user.company, driverSchedulerStore.driversFilters]);

  const currentAssignedJobsFilters = JSON.stringify(
    driverSchedulerStore.assignedJobsFilters,
  );
  const previousAssignedJobsFilters = usePrevious(currentAssignedJobsFilters);
  const assignedJobFiltersDidChange =
    previousAssignedJobsFilters !== currentAssignedJobsFilters;

  const currentStartDateFilter = driverSchedulerStore.dateFilters.startDate;
  const previousStartDateFilter = usePrevious(driverSchedulerStore.dateFilters.startDate);
  const startDateFilterDidChange = previousStartDateFilter !== currentStartDateFilter;

  useEffect(() => {
    const debouncedFetchAssignedJobs = debounce(() => {
      if (!driverSchedulerStore.dateFilters.startDate) {
        return;
      }

      fetchAssignedJobs({
        driverIds: driverSchedulerStore.drivers.map((driver) => driver.id),
      });
    }, 300);

    debouncedFetchAssignedJobs();

    return () => {
      debouncedFetchAssignedJobs.cancel();
    };
  }, [
    assignedJobFiltersDidChange,
    driverSchedulerStore.drivers, // Don't clear data on driver change, just refetch
    startDateFilterDidChange,
    driverSchedulerStore.dateFilters.startDate,
    driverSchedulerStore.assignedJobsFilters,
  ]);

  return null;
};

export default observer(DriversViewEffects);
