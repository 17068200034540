import { convertLength } from '@turf/helpers';
import _ from 'lodash';

import {
  AddressOption,
  AddressOptionForNewSite,
} from '~components/Order/SiteSelection/types';
import { AddressItem } from '~hooks/useAddress';
import { SiteBasic } from '~hooks/useSites';

export type AddressOptionFormValue = Omit<AddressOption, 'type'>;

export const transformSiteToAddressOption = (site: SiteBasic): AddressOptionFormValue => {
  return {
    name: site.name,
    lat: site.lat || '',
    lng: site.lon || '',
    id: site.id,
    siteId: site.id,
  };
};

export const formatAddressOptionForSiteForm = ({
  addressOption,
}: {
  addressOption: AddressOption;
}): AddressOptionForNewSite => {
  const { lat, lng } = addressOption;

  const mappedSite = {
    address: addressOption.address as AddressItem,
    name: '',
    lat: Number(lat),
    lng: Number(lng),
    latLon: `${lat}, ${lng}`,
    placeId: addressOption.placeId,
  };

  return mappedSite;
};

export const getFormattedLatLng = (lat: string | undefined, lng: string | undefined) => {
  const numLat = lat ? Number.parseFloat(lat).toFixed(6) : '';
  const numLng = lng ? Number.parseFloat(lng).toFixed(6) : '';
  return { lat: numLat, lng: numLng };
};

export const radiusInProperUnits = (isFeet: boolean, radius: number | null) => {
  if (_.isNil(radius)) {
    return null;
  }
  return isFeet ? Math.round(Number(convertLength(radius, 'meters', 'feet'))) : radius;
};
