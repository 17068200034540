import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { CompanyShareableType } from '@treadinc/horizon-api-spec/types/models/CompanyShareableType';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { HeaderNavigation } from '~components/DataGrid';
import DataGrid from '~components/DataGrid/DataGrid';
import { DialogHeader, ModalDialog } from '~components/Dialog';
import { CompanySelectorFormField } from '~components/FormFields';
import { SimpleMenu } from '~components/Menu';
import { BasicTooltip } from '~components/Tooltip';
import { AccountType } from '~constants/enums';
import { Account, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { usePermissions } from '~hooks/usePermissions';
import { PaginationLink } from '~interfaces';
import { AccountFormStateChangeProps } from '~pages/Settings/Accounts';
import { ConnectedAccountForm as AccountForm } from '~pages/Settings/Accounts/ConnectedAccountForm';
import { useCompanySharesDetails } from '~pages/Settings/Accounts/useCompanyShareDetails';
import { ErrorResponse, FieldError } from '~src/services/connectionModule';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { usePrevious } from '~utils/hooks/usePrevious';
import { isActionClicked } from '~utils/utilFunctions';

const SelectorConnectionCTAWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  width: '540px',
  marginBottom: '24px',
}));

const AdminAccountsDataGrid = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm();
  const {
    getAccountsByCompanyIdPaginated,
    isLoading: isAccountLoading,
    deleteAccount,
    updateAccount,
  } = useAccount();
  const theme = useTheme();
  const { userStore, companyAdminStore, toasterStore } = useStores();
  const selectedCompany: Nullable<CompanyBasic> = watch('connections-companies-selector');
  const previousSelectedCompany = usePrevious<Nullable<CompanyBasic>>(selectedCompany);
  const { isLoading: isPermsLoading } = usePermissions();
  const isLoading = isAccountLoading || isPermsLoading;
  const { searchValue } = useDataGridSearch();
  const [accountsByCompanyId, setAccountsByCompanyId] = useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
  const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
  const accountActionsRef = useRef<any>(null);
  const modalDialogRef = useRef<any>(null);
  const [currentPagination, setCurrentPagination] = useState<PaginationLink>(
    {} as PaginationLink,
  );
  const { createCompanyShareByCompanyId, deleteCompanyShare } = useCompanyShares();
  const [asyncErrors, setAsyncErrors] = useState<FieldError[]>([]);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const rows = useMemo(() => {
    return accountsByCompanyId || [];
  }, [accountsByCompanyId]);
  const selectedAccount = rows.find((row) => row.id === selectedAccountId) || undefined;

  const {
    drivers,
    equipment,
    sharedDriversCompanyShares,
    sharedEquipmentCompanyShares,
    setEquipment,
    setDrivers,
    setSharedDriversCompanyShares,
    setSharedEquipmentCompanyShares,
  } = useCompanySharesDetails({
    isAccountDialogOpen,
    selectedAccountId,
    selectedAccount,
  });

  const onFormStateChange = ({ isDirty }: AccountFormStateChangeProps) => {
    setCurrentFormDirty(isDirty);
  };

  const deleteRow = (id: string) => {
    setSelectedAccountId(id);
    modalDialogRef.current?.open();
  };

  const editRow = (id: string) => {
    setSelectedAccountId(id);
    setIsAccountDialogOpen(true);
  };

  const deleteAccountCallBack = () => {
    if (selectedAccountId) {
      deleteAccount({
        id: selectedAccountId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert($t('account.account_removed'), AlertTypes.success));
        },
      }).then(() =>
        fetchAccountsByCompanyId(
          selectedCompany?.id || '',
          currentPagination || ({} as PaginationLink),
        ),
      );
    }
  };

  const handleClose = () => {
    setIsAccountDialogOpen(false);
    setEquipment([]);
    setDrivers([]);
    setSharedDriversCompanyShares([]);
    setSharedEquipmentCompanyShares([]);
  };

  const onSuccess = (account?: Account) => {
    setSelectedAccountId(null);

    handleClose();
    toasterStore.push(
      alert(
        selectedAccountId
          ? $t('account.account_updated', { name: account?.name })
          : $t('account.account_created', { name: account?.name }),
        AlertTypes.success,
      ),
    );
    fetchAccountsByCompanyId(
      selectedCompany?.id || '',
      currentPagination || ({} as PaginationLink),
    );
  };

  const onSubmitAccountFormCallBack = async (data: Account | any) => {
    try {
      if (selectedAccountId) {
        const driversPresentOnModalOpen = drivers;
        const driversPresentOnSubmit = data.drivers;

        const equipmentPresentOnModalOpen = equipment;
        const equipmentPresentOnSubmit = data.equipment;

        const updatedAccount = await updateAccount({
          account: data,
        });

        const driversToCreate = driversPresentOnSubmit?.filter(
          (submittedDriver: { id: string }) =>
            !driversPresentOnModalOpen.some(
              (existingDriver) => existingDriver.id === submittedDriver.id,
            ),
        );

        const driversToDelete = driversPresentOnModalOpen.filter(
          (existingDriver) =>
            driversPresentOnSubmit &&
            !driversPresentOnSubmit.some(
              (submittedDriver: { id: string }) =>
                existingDriver.id === submittedDriver.id,
            ),
        );

        // Get the company shares for the drivers that we need to remove
        const driverCompanySharesToDelete = sharedDriversCompanyShares.filter(
          (companyShare) =>
            driversToDelete.some(
              (driver) => driver.id === companyShare.companyShareable.id,
            ),
        );

        const equipmentToCreate = equipmentPresentOnSubmit?.filter(
          (submittedEquipment: { id: string }) =>
            !equipmentPresentOnModalOpen.some(
              (existingEquipment) => existingEquipment.id === submittedEquipment.id,
            ),
        );

        const equipmentToDelete = equipmentPresentOnModalOpen.filter(
          (existingEquipment) =>
            equipmentPresentOnSubmit &&
            !equipmentPresentOnSubmit.some(
              (submittedEquipment: { id: string }) =>
                existingEquipment.id === submittedEquipment.id,
            ),
        );

        // Get the company shares for the equipment that we need to remove
        const equipmentCompanySharesToDelete = sharedEquipmentCompanyShares.filter(
          (companyShare) =>
            equipmentToDelete.some(
              (equip) => equip.id === companyShare.companyShareable.id,
            ),
        );

        const companySharePromises: Promise<void>[] = [];
        if (updatedAccount) {
          if (
            driversToCreate?.length &&
            updatedAccount?.company?.id &&
            updatedAccount?.connectedCompany?.id
          ) {
            driversToCreate.forEach((driver: DriverBasic) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: updatedAccount.company?.id || '',
                  receiver_company_id: updatedAccount.connectedCompany?.id || '',
                  company_shareable_id: driver.id,
                  company_shareable_type: 'User' as CompanyShareableType,
                }),
              );
            });
          }

          if (driverCompanySharesToDelete?.length) {
            driverCompanySharesToDelete.forEach((driverCompanyShare) => {
              companySharePromises.push(
                deleteCompanyShare(driverCompanyShare.id as string),
              );
            });
          }

          if (
            equipmentToCreate?.length &&
            updatedAccount?.company?.id &&
            updatedAccount?.connectedCompany?.id
          ) {
            equipmentToCreate.forEach((equip: BasicEquipment) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: updatedAccount.company?.id || '',
                  receiver_company_id: updatedAccount.connectedCompany?.id || '',
                  company_shareable_id: equip.id,
                  company_shareable_type: 'Equipment' as CompanyShareableType,
                }),
              );
            });
          }

          if (equipmentCompanySharesToDelete?.length) {
            equipmentCompanySharesToDelete.forEach((equipCompanyShare) => {
              companySharePromises.push(
                deleteCompanyShare(equipCompanyShare.id as string),
              );
            });
          }
        }

        await Promise.all(companySharePromises);
        onSuccess(data);
      }
    } catch (error: AxiosError<ErrorResponse> | any) {
      setAsyncErrors(error.response?.data.error.errors || []);
    }
  };

  const onSubmitAccountForm = () => {
    accountActionsRef.current?.submit(onSubmitAccountFormCallBack);
  };

  const userPermissions = userStore.getPermissions();

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.account_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip title={params.row.dbaName}>
                <Typography component="span">{params.value}</Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip title={params.row.dbaName}>
                <Typography component="span">{params.value}</Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'company.connected',
          headerName: $t('form_fields.connected'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const { connectedCompany, id } = params.row;
            const isConnectedLabel = connectedCompany ? 'Yes' : 'No';

            return (
              <BasicTooltip
                title={isConnectedLabel}
                key={`tooltip-account-type-key-${id}`}
              >
                <Chip
                  key={id}
                  label={isConnectedLabel}
                  color={connectedCompany ? 'success' : 'info'}
                  variant={'outlined'}
                />
              </BasicTooltip>
            );
          },
        },
        {
          field: 'accountTypes',
          headerName: $t('form_fields.type'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                {params.value?.sort().map((type: string, index: number) => (
                  <BasicTooltip title={type} key={`tooltip-account-type-key-${type}`}>
                    <Chip
                      sx={{ ml: index !== 0 ? 1 : 0 }}
                      key={type}
                      label={type}
                      color={type === AccountType.VENDOR ? 'info' : 'success'}
                      variant={'outlined'}
                    />
                  </BasicTooltip>
                ))}
              </>
            );
          },
        },
        {
          field: 'address.fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.address) return '';
            const { streetAddress, premise, country, administrativeArea } =
              params.row.address;

            return `${streetAddress} ${premise}, ${administrativeArea}, ${country.name}`;
          },
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'another.action.navigation',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAdminStore.accounts.length}
              loading={loadingRef.current}
              pagination={companyAdminStore.accountsPagination}
              callback={(link) => {
                setCurrentPagination(link);
                fetchAccountsByCompanyId(selectedCompany?.id || '', link);
              }}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            return (
              <>
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu
                  options={[
                    {
                      title: t('actions.delete'),
                      icon: <Delete />,
                      callBack: () => deleteRow(params.row.id),
                    },
                  ]}
                >
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [
      rows,
      userPermissions,
      companyAdminStore.accounts,
      isLoading,
      searchValue,
      selectedCompany?.id,
    ],
  );

  const fetchAccountsByCompanyId = async (companyId: string, link: PaginationLink) => {
    return await getAccountsByCompanyIdPaginated({
      companyId,
      callBack: setAccountsByCompanyId,
      link,
    });
  };

  useEffect(() => {
    if (selectedCompany && previousSelectedCompany?.id !== selectedCompany?.id) {
      fetchAccountsByCompanyId(selectedCompany?.id, {} as PaginationLink);
    } else {
      setAccountsByCompanyId([]);
    }
  }, [previousSelectedCompany?.id, selectedCompany?.id]);

  return (
    <Box>
      <SelectorConnectionCTAWrapper>
        <CompanySelectorFormField
          parentCompanyId={selectedCompany?.id}
          name="connections-companies-selector"
          errors={errors}
          control={control}
          hideLabel={true}
          inputProps={{
            placeholder: `${$t('form_fields.company_selector_hint')}`,
          }}
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
          clearable
        />
      </SelectorConnectionCTAWrapper>

      <DataGrid
        id="admin-accounts-grid"
        columns={accountsColumns}
        rows={rows as unknown as Record<string, any>[]}
        loading={isLoading}
      />
      {/* Update Modal */}
      <Dialog
        open={isAccountDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
        maxWidth={'lg'}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`account.${selectedAccountId ? 'update_account' : 'create_account'}`)}
        />
        <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
          <AccountForm
            ref={accountActionsRef}
            onFormStateChange={onFormStateChange}
            defaultAccount={selectedAccount}
            sharedDrivers={drivers}
            sharedEquipment={equipment}
            sharedDriversCompanyShares={sharedDriversCompanyShares}
            sharedEquipmentCompanyShares={sharedEquipmentCompanyShares}
            errors={asyncErrors}
          ></AccountForm>
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ mr: 1, px: 2 }}
            disabled={isLoading}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>

          <LoadingButton
            disabled={isLoading || !currentFormDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitAccountForm}
            data-test-id={'create-account-btn'}
            type="button"
            variant="contained"
            color="primary"
            sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedAccountId ? 'submit' : 'create'}`)}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Delete Modal */}
      <ModalDialog
        ref={modalDialogRef}
        loading={isLoading}
        title={$t('account.delete_account_title')}
        content={$t('account.delete_account_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteAccountCallBack}
      />
    </Box>
  );
};

export default observer(AdminAccountsDataGrid);
