import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { OmniReport, useOmniReports } from '~hooks/useOmniReports';
import { LoadingSpinner } from '~pages/Dispatch/components/ordersDispatchStyledComponents';

export const OmniReports = () => {
  const { isLoadingAllReports, getOmniReports, isLoadingReport, getOmniReportById } =
    useOmniReports();
  const [reports, setReports] = useState<OmniReport[]>([]);
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState<boolean>(false);
  // MUI Tabs value use false to indicate no report is selected
  const [selectedReport, setSelectedReport] = useState<string | boolean>(false);

  // Loads all available reports
  useEffect(() => {
    getOmniReports().then(setReports);
  }, []);

  // When reports are loaded, select the first one
  useEffect(() => {
    if (reports.length > 0) {
      setSelectedReport(reports[0].id);
      handleReportClick(reports[0].id);
    }
  }, [reports]);

  // If the iframeUrl changes, set iframeLoading to true since we are loading a new report
  useEffect(() => {
    if (iframeUrl) {
      setIframeLoading(true);
    }
  }, [iframeUrl]);

  const handleReportClick = async (id: string) => {
    const url = await getOmniReportById(id);
    setIframeUrl(url);
  };

  const handleChange = (_event: React.SyntheticEvent, reportId: string) => {
    setSelectedReport(reportId);
    handleReportClick(reportId);
  };

  const loadingSingleReport = isLoadingReport || iframeLoading;

  return (
    <Box display={'flex'} flexDirection={'row'} height="100%" mx={-5}>
      <Box sx={{ width: '240px' }} pt={2} mr={2}>
        {isLoadingAllReports ? (
          <Box display={'flex'} height={'100%'}>
            <LoadingSpinner isVisible />
          </Box>
        ) : (
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedReport}
            onChange={handleChange}
            sx={{
              borderLeft: 1,
              borderColor: 'divider',
              '& .MuiTabs-indicator': {
                left: 0,
                width: 4,
                borderRadius: 0,
              },
            }}
          >
            {reports.map((report) => (
              <Tab
                key={report.id}
                label={report.name}
                value={report.id}
                sx={{ alignItems: 'flex-start' }}
              />
            ))}
          </Tabs>
        )}
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}
        border={`solid 1px black`}
      >
        {loadingSingleReport && <LoadingSpinner isVisible />}
        {iframeUrl ? (
          <iframe
            width="100%"
            height="100%"
            title={'Omni Report'}
            src={iframeUrl}
            data-test-id="report-iframe"
            onLoad={() => setIframeLoading(false)}
            hidden={loadingSingleReport}
          />
        ) : (
          <Box display={'flex'}>
            <Typography>{t('omni.no_reports')}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
