import Check from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React from 'react';

import { SelectOption } from '~components/FormFields/Parts/SelectOption';

interface Props {
  id: string;
  value: any;
  name: string;
  field: string;
  optionsList: Array<any>;
  multiple?: boolean;
  api: any; // Grid api
  disabled?: boolean;
}

const SelectEditCell = ({
  id,
  value,
  field,
  optionsList,
  multiple = false,
  api,
  disabled = false,
}: Props) => {
  const saveChanges = () => api.stopCellEditMode({ id, field });
  const onChange = (values: any) => {
    api.setEditCellValue({
      id,
      field,
      value: values,
    });
  };

  const getValue = (option: any) => {
    return option?.id || null;
  };

  const getLabel = (option: any) => {
    return option?.name || '';
  };

  return (
    <ClickAwayListener onClickAway={saveChanges}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 0.25, width: '100%' }}>
        <Autocomplete
          disableClearable={true}
          options={optionsList}
          fullWidth
          multiple={multiple}
          getOptionLabel={(item) => getLabel(item)}
          filterSelectedOptions={false}
          isOptionEqualToValue={(option, value) => {
            return getValue(option) === getValue(value);
          }}
          disabled={disabled}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                size={'small'}
                margin={'dense'}
                variant="outlined"
                disabled={disabled}
                sx={{
                  '& .MuiInputBase-root': { px: 0 },
                  '& .MuiInputBase-input': { px: 0.25 },
                }}
              />
            );
          }}
          renderOption={(props, option, { inputValue, selected }) => (
            <SelectOption
              key={`select-option-${option.id || option.name}`}
              isCheckEnabled={!!multiple}
              selected={selected}
              option={option}
              getLabel={getLabel}
              inputValue={inputValue}
              props={props}
            />
          )}
          onChange={(event, values, reason) => {
            onChange(values);
          }}
          value={value}
        />

        <IconButton onClick={saveChanges} sx={{ ml: 0.25 }} disabled={disabled}>
          <Check sx={{ height: 18, width: 18 }} />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
};

export { SelectEditCell };
