import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { GenericSelector } from '~components/Order/Selectors/GenericSelector';
import { useServices } from '~hooks/useServices';
import { useStores } from '~store';

import DispatchSearchParam from './DispatchSearchParam';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const ServiceSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { companyAssetsStore } = useStores();
  const { isLoading, getAllServices } = useServices();

  // Loads the list of service for this company if there is a service in the URL search params
  // Ideally we should only fetch what is found in the search params, but for now we will fetch all
  const prefetchServices = () => {
    if (companyAssetsStore.services.length === 0) {
      getAllServices();
    }
  };
  useEffect(() => {
    if (allSearchParams[DispatchSearchParam.SERVICE]) {
      prefetchServices();
    }
  }, [allSearchParams[DispatchSearchParam.SERVICE]]);

  return (
    <>
      <Box sx={{ ...sx }}>
        <GenericSelector
          fieldName={DispatchSearchParam.SERVICE}
          options={companyAssetsStore.services}
          label={$t('form_fields.service')}
          allSearchParams={allSearchParams}
          sx={{ ...sx }}
          getOptionLabel={(item) => item.name || ''}
          loadData={prefetchServices}
          isDataLoading={isLoading}
        />
      </Box>
    </>
  );
});

export { ServiceSelector };
