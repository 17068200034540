import Box from '@mui/material/Box';
import Chip, { ChipOwnProps, ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/system';
import { t } from 'i18next';

import { Order } from '~src/hooks/useOrders';

import { BasicTooltip } from '../Tooltip';

interface TruckStatusProps {
  order: Order;
}

const TruckStatusChip = styled(Chip)<ChipProps>(() => {
  return {
    '&.MuiChip': {
      '&-root': {
        height: '20px',
        borderStyle: 'solid !important',

        '&.MuiChip-colorPrimary': {
          color: '#BD9000',
          borderColor: '#BD9000',
        },

        '.MuiChip-label': {
          fontSize: '12px',
          paddingLeft: '6px',
          paddingRight: '6px',
        },
      },
    },
  };
});

interface ChipWithTooltipProps {
  chipColor: ChipOwnProps['color'];
  chipLabel: number;
  tooltipTitle: string;
}

const ChipWithTooltip = ({
  chipColor,
  chipLabel,
  tooltipTitle,
}: ChipWithTooltipProps) => (
  <BasicTooltip title={tooltipTitle}>
    <TruckStatusChip
      label={chipLabel}
      color={chipColor}
      variant="outlined"
      size="small"
    />
  </BasicTooltip>
);

const TruckStatus = ({ order }: TruckStatusProps) => {
  return (
    <Box display="flex" gap={0.5}>
      <ChipWithTooltip
        chipColor="primary"
        chipLabel={order.pendingJobsCount}
        tooltipTitle={t('status.pending')}
      />

      <ChipWithTooltip
        chipColor="info"
        chipLabel={order.activeJobsCount}
        tooltipTitle={t('status.active')}
      />
      <ChipWithTooltip
        chipColor="default"
        chipLabel={order.acceptedJobsCount}
        tooltipTitle={t('status.accepted')}
      />
      <ChipWithTooltip
        chipColor="success"
        chipLabel={order.completedJobsCount}
        tooltipTitle={t('status.completed')}
      />

      <ChipWithTooltip
        chipColor="secondary"
        chipLabel={order.jobsCount}
        tooltipTitle={t('status.total')}
      />
    </Box>
  );
};

export { TruckStatus };
