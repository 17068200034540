import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { t } from 'i18next';
import React from 'react';

import { TreadLogoWithText } from '~icons/TreadLogoWithText';
import AppleBadge from '~images/apple-badge.svg?react';
import googlePlayBadgeSrc from '~images/google-play-badge.png';
import PageWrapWithBackGround from '~pages/Auth/PageWrapWithBackGround';
import { routes } from '~router';

const img_container_sx_props = {
  height: '60px',
  width: '50%',
  alignItems: 'center',
  justifyContent: 'center',
};
const playStoreLink =
  'https://play.google.com/store/apps/details?id=io.tread.horizon.android';
const appleStoreLink = 'https://apps.apple.com/us/app/tread-horizon/id6449744081';
export const FinalConfirmationScreen = () => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <PageWrapWithBackGround
      backgroundImageUrl={'../../src/assets/images/trucks-image-grey.jpg'}
    >
      <Box
        display={'flex'}
        sx={{
          flexDirection: 'column',
          width: '100%',
          maxWidth: '420px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box display={'flex'} sx={{ mb: 1 }}>
          <TreadLogoWithText textFill={theme.palette.common.white}></TreadLogoWithText>
        </Box>
        <Typography
          sx={{ color: theme.palette.common.white }}
          variant={matchesMobile ? 'h4' : 'h3'}
        >
          {t('page_headings.better_app_driver')}{' '}
        </Typography>
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-around'}
          sx={{ mt: 6 }}
        >
          <Box display={'flex'} sx={img_container_sx_props}>
            <a target={'_blank'} href={playStoreLink} rel="noreferrer">
              <img
                // Keep hardcode sizes for better images alignment
                style={{ height: '88px', width: '204px' }}
                src={googlePlayBadgeSrc}
                alt={'App is available on Google Play.'}
              />
            </a>
          </Box>
          <Box display={'flex'} sx={img_container_sx_props}>
            <a target={'_blank'} href={appleStoreLink} rel="noreferrer">
              <AppleBadge></AppleBadge>
            </a>
          </Box>
        </Box>
        <Link href={routes.admin.base} sx={{ color: theme.palette.common.white, mt: 5 }}>
          {t('redirect.proceed_to_web_app')}
        </Link>
        {/*<Typography*/}
        {/*  Component={<Link></Link>}*/}
        {/*  Sx={{ color: theme.palette.common.white, mt: 2 }}*/}
        {/*  Variant={'body2'}*/}
        {/*>*/}
        {/*  {t('redirect.proceed_to_web_app')}{' '}*/}
        {/*</Typography>*/}
      </Box>
    </PageWrapWithBackGround>
  );
};
