import Box from '@mui/material/Box';
import MuiChip, { ChipOwnProps } from '@mui/material/Chip';
import { styled } from '@mui/system';

export interface ChipsGroupProps {
  chips: Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
  value: string;
}

const ChipsGroup = ({ chips, onChange, value }: ChipsGroupProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {chips.map((chip) => (
        <ChipsGroupItem
          key={chip.value}
          isActive={value === chip.value}
          label={chip.label}
          onClick={() => onChange(chip.value)}
        />
      ))}
    </Box>
  );
};

interface ChipsGroupItemProps {
  isActive?: boolean;
  label: string;
  onClick: () => void;
}

const ChipsGroupItem = ({ isActive, label, onClick }: ChipsGroupItemProps) => {
  return (
    <Chip isActive={isActive} label={label} onClick={isActive ? undefined : onClick} />
  );
};

interface ChipProps extends ChipOwnProps {
  isActive?: boolean;
}

const Chip = styled(MuiChip, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<ChipProps>(({ theme, isActive }) => ({
  '&.MuiChip-root': {
    backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.grey[200],
    color: isActive
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.contrastText,
    cursor: isActive ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.grey[300],
    },
  },
}));

export default ChipsGroup;
