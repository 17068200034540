import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';

import { Chip } from '~components/Chip/Chip';
import { Order } from '~hooks/useOrders';
import { OverflowAwareText } from '~pages/Dispatch/components/ordersDispatchStyledComponents';

import { OrderStateLabelMapping } from './constants';
import { MoreOrdersMenu } from './MoreOrdersMenu';

interface ProjectOrderCardProps {
  orders: Order[];
}

export const ProjectOrderCard: React.FC<ProjectOrderCardProps> = (props) => {
  const theme = useTheme();
  const orders = props.orders;
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(
    props.orders[0] || null,
  );

  if (!selectedOrder) {
    return null;
  }

  return (
    <Box
      sx={{
        width: 245,
        margin: '16px',
        borderColor: `${theme.brandV2.colors.treadGray7}`,
        borderWidth: '1px',
        borderRadius: '6px',
        overflow: 'hidden',
        backgroundColor: `#FFFFFF`,
      }}
    >
      <CardContent
        sx={{
          borderTopColor: `${theme.brandV2.colors.treadBlue}`,
          borderTopWidth: '4px',
          padding: '0px',
        }}
      >
        {selectedOrder.projectName && (
          <Box>
            <Box sx={{ padding: '12px' }}>
              <Typography variant="subtitle1SemiBold">
                {selectedOrder.projectName}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
              >{`${props.orders.length} orders`}</Typography>
            </Box>
            <Divider
              sx={{
                marginY: '0px',
                borderWidth: '1px',
                borderColor: `${theme.brandV2.colors.treadGray7}`,
              }}
            />
          </Box>
        )}
        <Box sx={{ padding: '12px' }}>
          <Box
            display={'flex'}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Chip
              color={OrderStateLabelMapping[selectedOrder.state]?.color}
              label={OrderStateLabelMapping[selectedOrder.state]?.label}
              hideDropdownArrow={true}
            />
            <MoreOrdersMenu
              orders={orders}
              selectedOrder={selectedOrder}
              onChange={(newOrder) => {
                setSelectedOrder(newOrder);
              }}
            />
          </Box>
          <Box sx={{ paddingTop: '4px' }}>
            <Typography variant="subtitle2">{selectedOrder.orderId}</Typography>
            <Typography variant="subtitle2" color="text.secondary"></Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={1}
            ></Box>
          </Box>
          <Box mt={2}>
            <OrderItem
              text={t('dispatch.dispatch_v2.calendar.trucks')}
              value={selectedOrder.truckCount}
            />
            <OrderItem
              text={t('dispatch.dispatch_v2.calendar.tons')}
              value={selectedOrder.quantity}
            />
            <OrderItem
              text={t('dispatch.dispatch_v2.calendar.material')}
              value={selectedOrder.material?.name}
            />
          </Box>
          <Divider
            sx={{ marginY: 1, borderColor: `${theme.brandV2.colors.treadGray7}` }}
          />
          <Box>
            <OrderItem
              text={t('dispatch.dispatch_v2.calendar.start_date')}
              value={dayjs.tz(selectedOrder.jobStartAt).format('lll')}
            />
            {selectedOrder.pickUpWayPoints &&
              selectedOrder.pickUpWayPoints.length > 1 && (
                <OrderItem
                  text={t('dispatch.dispatch_v2.calendar.pick_up')}
                  value={selectedOrder.pickUpWayPoints[0].site?.name}
                />
              )}
            {selectedOrder.dropOffWayPoints &&
              selectedOrder.dropOffWayPoints.length > 1 && (
                <OrderItem
                  text={t('dispatch.dispatch_v2.calendar.drop_off')}
                  value={selectedOrder.dropOffWayPoints[0].site?.name}
                />
              )}
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};

interface OrderItemProps {
  text?: string | null;
  value?: string | number;
}

const OrderItem = ({ text, value }: OrderItemProps) => {
  return (
    <Box display="flex">
      <Box
        display={'flex'}
        width={85}
        mr={2}
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="subtitle2">{text}</Typography>
      </Box>

      <OverflowAwareText>{value}</OverflowAwareText>
    </Box>
  );
};
