import '~styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { FullStory, init as initFullStory } from '@fullstory/browser';
import Box from '@mui/material/Box';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { MessagePayload } from 'firebase/messaging';
import { onMessage } from 'firebase/messaging';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import CustomToastContent from '~components/CustomToast/CustomToastContent';
import Toasters from '~components/Toasters';
import { useSegmentAnalytics } from '~hooks/useAnalytics/SegmentAnalytics';
import { useUsers } from '~hooks/useUsers';
import { generateToken, messaging } from '~notifications/firebase';
import notificationSound from '~sounds/notification.wav';
import { useStores } from '~store';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

const key = import.meta.env.TREAD__MUI_LICENCE_KEY;
LicenseInfo.setLicenseKey(key);
const Layout = () => {
  const { userStore } = useStores();
  const { addUserDeviceToken } = useUsers();
  const notificationAlertSound = new Audio(notificationSound);

  useSegmentAnalytics();

  // User timezone configuration
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    // Get the timezone from the user's model
    if (userStore?.user?.timeZone) {
      dayjs.tz.setDefault(userStore.user.timeZone);
    }
  }, [userStore.user?.timeZone]);

  useEffect(() => {
    if (userStore?.user) {
      const currentUser = userStore.user;

      if (['production'].includes(import.meta.env.TREAD__ENV)) {
        // Intialize FullStory identity for the current user
        initFullStory({ orgId: 'o-1S518Y-na1' }, () => {
          FullStory('setIdentity', {
            uid: currentUser.id,
            properties: {
              displayName: `${currentUser.firstName} ${currentUser.lastName}`,
              email: currentUser.email,
            },
          });
        });

        // Append intercom identity for the current user into intercomSettings
        (window as any).intercomSettings = {
          ...(window as any).intercomSettings,
          user_id: currentUser.id,
          user_hash: currentUser.intercomHash,
        };
      }

      // Sentry user tracking
      Sentry.setUser({
        id: currentUser.id,
        ...(currentUser.email ? { email: currentUser.email } : {}),
        company_id: currentUser?.company?.id,
        company_name: currentUser?.company?.legalName,
        ip_address: '{{auto}}',
      });
    } else {
      Sentry.setUser({
        ip_address: '{{auto}}',
      });
    }
  }, [userStore?.user]);

  const checkBrowserAndGenerateToken = async () => {
    if (
      navigator.userAgent.indexOf('Firefox') !== -1 ||
      (navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1)
    ) {
      document.addEventListener(
        'click',
        async () => {
          const token = await generateToken();
          if (token) {
            await addUserDeviceToken(token);
          }
        },
        { once: true },
      );
    } else {
      const token = await generateToken();
      if (token) {
        await addUserDeviceToken(token);
      }
    }
  };
  useEffect(() => {
    const fetchUserAndGenerateToken = async () => {
      try {
        if (userStore?.user) {
          await checkBrowserAndGenerateToken();
        }
      } catch (error) {
        console.error(
          'An error occurred during user fetching or token generation:',
          error,
        );
      }
    };

    // Call this function only if the user is defined in the userStore.
    if (userStore?.user) {
      fetchUserAndGenerateToken();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: MessagePayload) => {
      if (payload.notification) {
        toast(
          <CustomToastContent
            title={payload.notification.title ?? ''}
            body={payload.notification.body ?? ''}
          />,
          {
            position: 'top-right',
            autoClose: 60000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        notificationAlertSound.play().catch(console.error);
      }
    });

    return () => unsubscribe();
  }, [notificationAlertSound]);

  return (
    <>
      <div className="wrapper">
        <AppHeader />
        <Box component="main" sx={{ px: 5 }} className={classNames({ loading: false })}>
          <Outlet />
        </Box>
        <AppFooter />
      </div>
      <Toasters />
      <ToastContainer newestOnTop={true} />
    </>
  );
};

export default observer(Layout);
