import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextFormField } from '~components/FormFields';
import { useLogin } from '~hooks/useLogin/useLogin';
import { inputTypes } from '~pages/Auth/types';
import { sendMagicLinkValidationSchema } from '~pages/Auth/validationSchemas';

interface SignInWithMagicLinkFormProps {
  email: string;
  onMagicLinkSent: () => void;
}

interface SendMagicLinkData extends yup.InferType<typeof sendMagicLinkValidationSchema> {}

const SignInWithMagicLinkForm = ({
  email,
  onMagicLinkSent,
}: SignInWithMagicLinkFormProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { sendMagicLink, isLoading } = useLogin();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SendMagicLinkData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(sendMagicLinkValidationSchema),
    defaultValues: { email },
  });

  const handleToggleInputFocus = useCallback(() => {
    setIsInputFocused((isFocused) => !isFocused);
  }, []);

  const onSubmit = (data: SendMagicLinkData) => {
    sendMagicLink({ email: data.email }).then(() => {
      onMagicLinkSent();
    });
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      gap={1}
    >
      <TextFormField
        control={control}
        errors={errors}
        name={inputTypes.email}
        type={inputTypes.email}
        label={`${t('form_fields.email')}`}
        inputProps={{
          'data-test-id': inputTypes.email,
          readOnly: true,
        }}
        onFocus={handleToggleInputFocus}
        onBlur={handleToggleInputFocus}
      />

      <Box width="100%">
        <LoadingButton
          fullWidth
          color={isInputFocused ? 'primary' : 'inherit'}
          sx={isInputFocused ? undefined : { backgroundColor: '#E1E1E1' }}
          variant="contained"
          type="submit"
          loading={isLoading}
        >
          {t('actions.send_magic_link')}
        </LoadingButton>

        <Typography variant="body2" textAlign="center" mt={1}>
          {t('actions.we_will_email_you_a_magic_link')}
        </Typography>
      </Box>
    </Box>
  );
};

export default SignInWithMagicLinkForm;
