import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { AutocompleteFormField } from '~components/FormFields';

import DispatchSearchParam from './DispatchSearchParam';

interface OrderJobStatesSelectorProps {
  allSearchParams: Record<string, string>;
}

const jobStatesSchema = yup.object().shape({
  jobStates: yup.array(yup.mixed<JobState>().oneOf(Object.values(JobState))),
});

type JobStatesDTO = yup.InferType<typeof jobStatesSchema>;

export default function OrderJobStatesSelector({
  allSearchParams,
}: OrderJobStatesSelectorProps) {
  const form = useForm<JobStatesDTO>({
    resolver: yupResolver(jobStatesSchema),
    defaultValues: { jobStates: [] },
  });
  const selectedValues = form.watch('jobStates');

  const [, setSearchParams] = useSearchParams();

  const availableJobStatesList = useMemo(() => {
    return Object.values(JobState).filter((state) => {
      return !(selectedValues ?? []).includes(state);
    });
  }, [selectedValues]);

  useEffect(() => {
    const jobStates = (allSearchParams[DispatchSearchParam.STATE] ?? '')
      .split('|')
      .filter((i) => i) as JobState[];

    if (jobStates.length) {
      form.reset({ jobStates });
    }
  }, [JSON.stringify(allSearchParams)]);

  useEffect(() => {
    if (selectedValues?.length) {
      setSearchParams((params) => {
        params.set(DispatchSearchParam.STATE, selectedValues.join('|'));

        return params;
      });
    } else {
      setSearchParams((params) => {
        params.delete(DispatchSearchParam.STATE);

        return params;
      });
    }
  }, [selectedValues, setSearchParams]);

  return (
    <>
      <Box>
        <AutocompleteFormField
          blurOnSelect
          clearOnBlur
          control={form.control}
          errors={form.formState.errors}
          getLabel={(item) => t(`status.${item}`)}
          getValue={(item) => item}
          limitTags={1}
          list={availableJobStatesList}
          multiple
          name="jobStates"
          placeholder={
            selectedValues?.length ? undefined : `${t('dispatch.job.job_status')}`
          }
          sx={{
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
              '& .MuiAutocomplete-inputRoot > .MuiBox-root': {
                maxWidth: '80%',
              },
            },
          }}
        />
      </Box>
    </>
  );
}
