import Add from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import DataGrid from '~components/DataGrid/DataGrid';
import { DialogActionsWrapper, DialogHeader } from '~components/Dialog';
import { CompanySelectorFormField } from '~components/FormFields';
import { BasicTooltip } from '~components/Tooltip';
import { AccountType } from '~constants/enums';
import { Account, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { usePermissions } from '~hooks/usePermissions';
import { useAccountsAdmin } from '~hooksAdmin/useAccountsAdmin';
import { CreateConnectedAccountsByCompanyIdsParams } from '~hooksAdmin/useAccountsAdmin/useAccountsAdmin';
import { FormStateChangeProps } from '~src/formsShared';
import { useStores } from '~store';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { usePrevious } from '~utils/hooks/usePrevious';
import { isActionClicked } from '~utils/utilFunctions';

import { AddConnectionForm } from './AddConnectionForm';

const SelectorConnectionCTAWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  width: '540px',
  marginBottom: '24px',
}));

interface AddConnectionCTADialogProps {
  selectedCompany: Nullable<CompanyBasic>;
  onSuccessCallback?: (selectedCompanyId: string) => void;
}

const AddConnectionCTADialog = ({
  selectedCompany,
  onSuccessCallback,
}: AddConnectionCTADialogProps) => {
  const { isUpdating, createConnectedAccountByCompanyIds } = useAccountsAdmin();
  const addConnectionFormRef = useRef<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const openAddConnectionDialog = () => setIsDialogOpen(true);
  const closeAddConnectionDialog = () => setIsDialogOpen(false);

  const onValidateCompanyForm = ({ isDirty, isValid = false }: FormStateChangeProps) => {
    setIsDirty(isDirty);
    setIsValid(isValid);
  };

  const onSuccess = () => {
    // Close Modal
    closeAddConnectionDialog();
    // Update Accounts table on main page
    selectedCompany && onSuccessCallback?.(selectedCompany.id);
  };

  const onSubmitAction = (data: CreateConnectedAccountsByCompanyIdsParams) => {
    createConnectedAccountByCompanyIds({ ...data, callBack: onSuccess });
  };

  const onSubmitCompanyForm = () => {
    addConnectionFormRef.current?.submit(onSubmitAction);
  };

  return (
    <>
      <Button
        data-test-id={'create-company-btn'}
        color={'primary'}
        onClick={openAddConnectionDialog}
        startIcon={<Add />}
        disabled={!selectedCompany?.id}
      >
        {t('company.add_connected_account')}
      </Button>

      {selectedCompany && (
        <Dialog
          open={isDialogOpen}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            isActionClicked(reason) && closeAddConnectionDialog();
          }}
        >
          <DialogHeader
            closeCallBack={closeAddConnectionDialog}
            title={`${t('account.add_connection_heading')} ${selectedCompany.legalName}`}
          />
          <DialogContent>
            <AddConnectionForm
              ref={addConnectionFormRef}
              senderCompanyId={selectedCompany.id}
              onFormStateChange={onValidateCompanyForm}
            />
          </DialogContent>
          <DialogActionsWrapper>
            <Button
              onClick={closeAddConnectionDialog}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
            <LoadingButton
              disabled={!isDirty || !isValid || isUpdating}
              loading={isUpdating}
              loadingPosition="start"
              onClick={onSubmitCompanyForm}
              data-test-id={'connect-companies-btn'}
              type="button"
              variant="contained"
              color="primary"
            >
              {$t('actions.connect')}
            </LoadingButton>
          </DialogActionsWrapper>
        </Dialog>
      )}
    </>
  );
};

const AdminConnectionsDataGrid = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm();
  const { getAccountsByCompanyId, isLoading: isAccountLoading } = useAccount();
  const { userStore } = useStores();
  const selectedCompany: Nullable<CompanyBasic> = watch('connections-companies-selector');
  const previousSelectedCompany = usePrevious<Nullable<CompanyBasic>>(selectedCompany);
  const [accountsByCompanyId, setAccountsByCompanyId] = useState<Account[]>([]);
  const { isLoading: isPermsLoading } = usePermissions();
  const isLoading = isAccountLoading || isPermsLoading;

  const rows = useMemo(() => {
    return accountsByCompanyId || [];
  }, [accountsByCompanyId]);

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.account_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip title={params.row.dbaName}>
                <Typography component="span">{params.value}</Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'company.connected',
          headerName: $t('form_fields.connected'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const { connectedCompany, id } = params.row;
            const isConnectedLabel = connectedCompany ? 'Yes' : 'No';

            return (
              <BasicTooltip
                title={isConnectedLabel}
                key={`tooltip-account-type-key-${id}`}
              >
                <Chip
                  key={id}
                  label={isConnectedLabel}
                  color={connectedCompany ? 'success' : 'info'}
                  variant={'outlined'}
                />
              </BasicTooltip>
            );
          },
        },
        {
          field: 'accountTypes',
          headerName: $t('form_fields.type'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                {params.value?.sort().map((type: string, index: number) => (
                  <BasicTooltip title={type} key={`tooltip-account-type-key-${type}`}>
                    <Chip
                      sx={{ ml: index !== 0 ? 1 : 0 }}
                      key={type}
                      label={type}
                      color={type === AccountType.VENDOR ? 'info' : 'success'}
                      variant={'outlined'}
                    />
                  </BasicTooltip>
                ))}
              </>
            );
          },
        },
        {
          field: 'address.fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.address) return '';
            const { streetAddress, premise, country, administrativeArea } =
              params.row.address;

            return `${streetAddress} ${premise}, ${administrativeArea}, ${country.name}`;
          },
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const fetchAccountsByCompanyId = async (companyId: string) => {
    return await getAccountsByCompanyId({
      companyId,
      callBack: setAccountsByCompanyId,
    });
  };

  useEffect(() => {
    if (selectedCompany && previousSelectedCompany?.id !== selectedCompany?.id) {
      fetchAccountsByCompanyId(selectedCompany?.id);
    } else {
      setAccountsByCompanyId([]);
    }
  }, [previousSelectedCompany?.id, selectedCompany?.id]);

  return (
    <Box>
      <SelectorConnectionCTAWrapper>
        <CompanySelectorFormField
          parentCompanyId={selectedCompany?.id}
          name="connections-companies-selector"
          errors={errors}
          control={control}
          hideLabel={true}
          inputProps={{
            placeholder: `${$t('form_fields.company_selector_hint')}`,
          }}
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            maxWidth: '300px',
          }}
          clearable
        />

        <AddConnectionCTADialog
          selectedCompany={selectedCompany}
          onSuccessCallback={fetchAccountsByCompanyId}
        />
      </SelectorConnectionCTAWrapper>

      <DataGrid
        id="admin-accounts-grid"
        columns={accountsColumns}
        rows={rows as unknown as Record<string, any>[]}
        loading={isLoading}
      />
    </Box>
  );
};

export default observer(AdminConnectionsDataGrid);
