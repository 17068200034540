import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ApartmentOutlined from '@mui/icons-material/ApartmentOutlined';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { t } from 'i18next';
import { useEffect } from 'react';

import { FeatureFlags } from '~constants/featureFlags';
import { useAccess } from '~hooks/useAccess';
import { userHasAccessToConfigurationManagement } from '~pages/Settings/Configuration';
import { routes } from '~router';
import { useStores } from '~store';

interface NavItem {
  title: string;
  icon: JSX.Element;
  items: SubnavItem[];
}

interface SubnavItem {
  title: string;
  link: string;
  items?: SubnavItem[];
}

const conditionalSubnavItem = (access: boolean, navItem: SubnavItem) => {
  if (access) {
    return [navItem];
  }

  return [];
};

// NavItems is used to generate the navItems list for the Settings page
// Based on the user's access level
export const getNavItems = (): NavItem[] => {
  const { userStore } = useStores();
  const { getAccess } = useAccess();
  const { treatments, isReady } = useSplitTreatments({
    names: [
      FeatureFlags.manageEquipmentTypes,
      FeatureFlags.driverPay,
      FeatureFlags.manageTelematicsIntegrations,
    ],
  });
  const equipmentTypeFeatureFlag = treatments[FeatureFlags.manageEquipmentTypes];
  const driverPayFeatureFlag = treatments[FeatureFlags.driverPay];
  const integrationsFeatureFlag = treatments[FeatureFlags.manageTelematicsIntegrations];

  useEffect(() => {
    getAccess();
  }, []);

  const userAccess = userStore.getAccess();

  return [
    {
      title: t('page_headings.user'),
      icon: <AccountCircleOutlined sx={{ width: 20, height: 20 }} />,
      items: [
        {
          title: t('page_headings.user_profile'),
          link: routes.settings.userProfile,
        },
      ],
    },
    {
      title: t('page_headings.administration'),
      icon: <ApartmentOutlined sx={{ width: 20, height: 20 }} />,
      items: [
        ...conditionalSubnavItem(userAccess.manageCompanies, {
          title: t('page_headings.general_settings'),
          link: routes.settings.generalSettings,
        }),
        ...conditionalSubnavItem(userAccess.manageUser, {
          title: t('page_headings.users_management'),
          link: routes.settings.userManagement,
        }),
        ...conditionalSubnavItem(userAccess.manageAccounts, {
          title: t('page_headings.account_management'),
          link: routes.settings.accounts,
        }),
        ...(isReady && equipmentTypeFeatureFlag.treatment === 'on'
          ? conditionalSubnavItem(userAccess.manageEquipment, {
              title: t('administration.equipment_type.title'),
              link: routes.settings.equipmentTypeManagement,
            })
          : []),
        ...conditionalSubnavItem(userAccess.manageEquipment, {
          title: t('administration.equipment.title'),
          link: routes.settings.equipmentManagement,
        }),
        ...conditionalSubnavItem(userAccess.manageMaterials, {
          title: t('administration.material.title'),
          link: routes.settings.materialsManagement,
        }),
        ...conditionalSubnavItem(userAccess.manageRates, {
          title: t('administration.rates.title'),
          link: routes.settings.ratesManagement,
          items: conditionalSubnavItem(
            isReady && driverPayFeatureFlag.treatment === 'on',
            {
              title: t('administration.rates.add_ons.add_on_charges'),
              link: routes.settings.addOnCharges,
            },
          ),
        }),
        ...conditionalSubnavItem(userAccess.manageSites, {
          title: t('administration.site.title'),
          link: routes.settings.sitesManagement,
        }),
        ...conditionalSubnavItem(userAccess.manageDepartments, {
          title: t('administration.department.title'),
          link: routes.settings.departmentsManagement,
        }),
        ...conditionalSubnavItem(userAccess.manageServiceClasses, {
          title: t('administration.service_class.title'),
          link: routes.settings.serviceClassesManagement,
        }),
        ...conditionalSubnavItem(userHasAccessToConfigurationManagement(userStore), {
          title: t('administration.configuration.title'),
          link: routes.settings.configuration,
        }),
        ...(isReady && integrationsFeatureFlag.treatment === 'on'
          ? conditionalSubnavItem(userAccess.manageTelematics, {
              title: t('administration.integration.title'),
              link: routes.settings.integrations.base,
            })
          : []),
      ],
    },
  ];
};
