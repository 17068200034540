import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  AutocompleteAsyncFormField,
  AutocompleteFormField,
  TextFormField,
} from '~components/FormFields';
import { unitOfMeasureOptions } from '~constants/enums';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { Job } from '~hooks/useJob';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Order } from '~hooks/useOrders';
import { Project } from '~hooks/useProjects';
import { useServiceClass } from '~hooks/useServiceClass';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface OrderDetailsProps {
  parentData?: Project | Order | null;
  defaultData?: Project | Order | Job | null;
  isProjectView?: boolean;
}

export const OrderDetails = ({
  parentData,
  defaultData,
  isProjectView = false,
}: OrderDetailsProps) => {
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();
  const {
    createMaterialByCompanyId,
    isLoading: isMaterialLoading,
    getMaterialsTypeahead,
    getMaterialsByCompanyId,
  } = useMaterials();
  const {
    getEquipmentTypesByCompanyIdTypeahead,
    createEquipmentTypeByCompanyId,
    getEquipmentTypeById,
    isLoading: isEquipmentTypeLoading,
  } = useEquipment();
  const { getServiceClassesByCompanyId } = useServiceClass();
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const servicesOptions = companyAssetsStore.services;
  const serviceClassOptions = companyAssetsStore.serviceClasses;
  const [tmpEquipmentType, setTmpEquipmentType] = useState<string>('');

  const company = watch('company');
  const grossCapacity = watch('grossCapacity');
  const equipmentType = watch('equipmentType');

  //Will request on create order all the info based on selected company
  useEffect(() => {
    if (company?.id) {
      getServiceClassesByCompanyId(company?.id);
      getMaterialsByCompanyId(company?.id);
    }
  }, [company?.id]);

  // Load in default data if it exists on the parent object for create forms
  useEffect(() => {
    if (!defaultData?.id && parentData) {
      setValue('service', parentData.service);
      setValue('serviceClass', parentData.serviceClass);
      setValue('jobTime', parentData.jobTime);
      setValue('quantity', parentData.quantity);
      setValue('equipmentType', parentData.equipmentType);
      setValue('loadsPerTruck', parentData.loadsPerTruck);
      setValue('truckCount', parentData.truckCount);
      setValue('unitsPerHour', parentData.unitsPerHour);
      setValue('jobQuantity', parentData.jobQuantity);
    }
  }, [defaultData?.id, parentData]);

  const fetchEquipmentTypesByCompanyId = (additionalProps = {}) => {
    const companyId = company?.id || parentData?.company?.id || defaultData?.company?.id;
    return companyId
      ? getEquipmentTypesByCompanyIdTypeahead({
          companyId,
          ...additionalProps,
        })
      : [];
  };

  // Load in projects list of materials if parent is a project object
  const projectMaterialTotals = useMemo(() => {
    if (parentData && 'projectMaterialTotals' in parentData) {
      return parentData.projectMaterialTotals || [];
    }
    return [];
  }, [parentData]);

  const projectMaterials = useMemo(() => {
    return projectMaterialTotals.map((material) => material.material);
  }, [projectMaterialTotals]);

  const [tmpMaterial, setTmpMaterial] = useState<string>('');
  const onInputMaterial = (event: React.SyntheticEvent, value: string) => {
    if (parentData instanceof Project && parentData?.projectMaterialTotals) {
      const material = parentData?.projectMaterialTotals.find(
        (material) => material?.material?.name === value,
      );
      if (material) {
        setValue('unitOfMeasure', material.unitOfMeasure, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      return;
    }
    setTmpMaterial(value);
  };

  const createMaterial = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createMaterialByCompanyId({
      companyId: userStore.userCompany?.id || '',
      material: {
        name: tmpMaterial,
      } as unknown as Material,
    }).then((material: Material) => {
      toasterStore.push(
        alert(
          t('administration.material.material_created', {
            name: material.name,
          }),
          AlertTypes.success,
        ),
      );
      setValue('material', material, { shouldValidate: true, shouldDirty: true });
    });
  };

  // Load in default data if it exists on the parent object for create forms
  useEffect(() => {
    if (!defaultData?.id && parentData) {
      setValue('service', parentData.service);
      setValue('serviceClass', parentData.serviceClass);
      setValue('jobTime', parentData.jobTime);
      setValue('quantity', parentData.quantity);
      setValue('equipmentType', parentData.equipmentType);
      setValue('loadsPerTruck', parentData.loadsPerTruck);
      setValue('truckCount', parentData.truckCount);
      setValue('unitsPerHour', parentData.unitsPerHour);
      setValue('jobQuantity', parentData.jobQuantity);
    }
  }, [defaultData?.id, parentData]);

  const onInputEquipmentType = (event: React.SyntheticEvent, value: string) => {
    setTmpEquipmentType(value);
  };

  const createEquipmentType = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createEquipmentTypeByCompanyId({
      companyId: userStore.userCompany?.id || '',
      equipmentType: {
        name: tmpEquipmentType,
      } as unknown as EquipmentTypeItem,
    }).then((equipmentType) => {
      fetchEquipmentTypesByCompanyId();
      setValue('equipmentType', equipmentType);
      trigger('equipmentType');
      toasterStore.push(
        alert(
          t('administration.equipment.equipment_created', {
            name: equipmentType.name,
          }),
          AlertTypes.success,
        ),
      );
    });
  };

  const fetchEquipmentType = async (equipmentTypeId: string) => {
    const fetchedEquipmentType = await getEquipmentTypeById(equipmentTypeId);

    setValue('equipmentType', fetchedEquipmentType);
    trigger('equipmentType');
  };

  // Need to fetch the full equipment type to get the gross capacity when the equipment type is selected via typeahead
  const onEquipmentTypeSelect = (equipmentType: EquipmentTypeItem) => {
    if (equipmentType) {
      fetchEquipmentType(equipmentType.id);
    }
  };

  // Set gross capacity value for equipment type when component is mounted
  useEffect(() => {
    const equipmentTypeId = _.get(defaultData, 'equipmentType.id');
    if (equipmentTypeId) {
      fetchEquipmentType(equipmentTypeId);
    }
  }, [_.get(defaultData, 'equipmentType.id')]);

  useEffect(() => {
    const grossCapacity =
      equipmentType?.grossCapacity && equipmentType?.grossCapacityUnitOfMeasure
        ? `${equipmentType?.grossCapacity} ${equipmentType?.grossCapacityUnitOfMeasure?.name}`
        : '';

    setValue('grossCapacity', grossCapacity);
  }, [equipmentType]);

  return (
    <Grid container spacing={2}>
      {/* Row one */}
      <Grid item xs={4.5}>
        <AutocompleteFormField
          control={control}
          name="service"
          errors={errors}
          list={servicesOptions}
          label={`${t('form_fields.service')}`}
          isRequired={!isProjectView}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          clearOnBlur={true}
          blurOnSelect={true}
        />
      </Grid>
      <Grid item xs={4.5}>
        {!isProjectView && (
          <AutocompleteAsyncFormField
            control={control}
            errors={errors}
            filterSelectedOptions={false}
            name="material"
            getValue={(material: Material) => material.id}
            getLabel={(material: Material) =>
              `${material?.externalId ? `${material.externalId} - ` : ''}${material.name}`
            }
            label={`${t('form_fields.material')}`}
            defaultSuggestions={projectMaterials as Record<string, any>[]}
            asyncCallback={getMaterialsTypeahead}
            onInput={onInputMaterial}
            isRequired={true}
            noOptionsText={
              tmpMaterial ? (
                <Chip
                  size={'small'}
                  variant={'filled'}
                  color={'primary'}
                  onClick={createMaterial}
                  disabled={isMaterialLoading}
                  sx={{ cursor: 'pointer' }}
                  label={t('common.add_new_name', {
                    name: tmpMaterial || '',
                  })}
                />
              ) : (
                t('form_fields.no_options')
              )
            }
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {!isProjectView && (
          <AutocompleteFormField
            control={control}
            name="unitOfMeasure"
            errors={errors}
            list={unitOfMeasureOptions}
            label={`${t('form_fields.units')}`}
            isRequired={!isProjectView}
            getValue={(item) => item.id}
            getLabel={(item) => item.name || ''}
            clearOnBlur={true}
            blurOnSelect={true}
          />
        )}
      </Grid>
      {/* Row two */}
      <Grid item xs={4.5}>
        <AutocompleteFormField
          control={control}
          name="serviceClass"
          errors={errors}
          list={serviceClassOptions}
          label={`${t('form_fields.service_class')}`}
          isRequired={false}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          clearOnBlur={true}
          blurOnSelect={true}
          clearable
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextFormField
          control={control}
          errors={errors}
          name="unitsPerHour"
          label={`${t('form_fields.units_per_hour')}`}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextFormField
          control={control}
          errors={errors}
          name="jobTime"
          label={`${t('form_fields.job_time')}`}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextFormField
          control={control}
          errors={errors}
          name={'quantity'}
          label={`${t('form_fields.order_quantity')}`}
          isRequired={!isProjectView}
        />
      </Grid>
      {/* Row three */}
      <Grid item xs={4.5}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          name={'equipmentType'}
          label={`${t('form_fields.equipment_type')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          asyncCallback={fetchEquipmentTypesByCompanyId}
          onInput={onInputEquipmentType}
          onSelect={onEquipmentTypeSelect}
          noOptionsText={
            tmpEquipmentType ? (
              <Chip
                size={'small'}
                variant={'filled'}
                color={'primary'}
                onClick={createEquipmentType}
                disabled={isEquipmentTypeLoading}
                sx={{ cursor: 'pointer' }}
                label={t('common.add_new_name', {
                  name: tmpEquipmentType || '',
                })}
              />
            ) : (
              t('form_fields.no_options')
            )
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        {/* Separate div required for disabled components. See:
            https://v4.mui.com/components/tooltips/#disabled-elements */}
        <Tooltip
          title={grossCapacity}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
        >
          <span>
            <TextFormField
              control={control}
              errors={errors}
              name="grossCapacity"
              label={`${t('form_fields.gross_capacity')}`}
              disabled
            />
          </span>
        </Tooltip>
      </Grid>

      {!isProjectView && (
        <Grid item xs={2.5}>
          <TextFormField
            control={control}
            errors={errors}
            name="loadsPerTruck"
            label={`${t('form_fields.loads_per_truck')}`}
            isRequired={false}
            disabled={!!defaultData?.id}
          />
        </Grid>
      )}
      <Grid item xs={2.5}>
        <TextFormField
          control={control}
          errors={errors}
          name="jobQuantity"
          label={`${t('form_fields.job_quantity')}`}
          isRequired={!isProjectView}
        />
      </Grid>

      <Grid item xs={2.5}>
        <TextFormField
          control={control}
          errors={errors}
          name="truckCount"
          label={`${t('form_fields.trucks')}`}
          isRequired={!isProjectView}
          disabled={!!defaultData?.id}
        />
      </Grid>
    </Grid>
  );
};
