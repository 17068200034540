import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { useStores } from '~store';

import {
  useGetCycleSegments,
  useIndicatorValues,
  useNoSignalBlocks,
  useTimeNormalization,
  useWholeHours,
} from './hooks';
import { HourMarker } from './HourMarker';
import NoSignal from './NoSegment';
import SpeedSegment from './SpeedSegment';
import TimeIndicator from './TimeIndicator';

interface SpeedTimelineProps {
  pings: NextBillionAssetLocation[];
}

export const SpeedTimeline = ({ pings }: SpeedTimelineProps) => {
  const minTime = pings[0].createdAt.toISOString();
  const maxTime = pings[pings.length - 1].createdAt.toISOString();

  const { wholeHours, determineInterval } = useWholeHours(minTime, maxTime);
  const normalize = useTimeNormalization(minTime, maxTime);

  const maxSpeed = useMemo(
    () => Math.max(...pings.map((p) => Number(p.speed)), 0) || 0,
    [pings.length],
  );

  const lowerMiddleSpeed = 0.33 * maxSpeed;
  const upperMiddleSpeed = 0.66 * maxSpeed;

  const segments = useGetCycleSegments(pings);
  const noSignalBlocks = useNoSignalBlocks(segments);

  const { graphRef, indicatorValues } = useIndicatorValues(
    minTime,
    maxTime,
    noSignalBlocks,
  );

  const interval = determineInterval();

  const filteredHours = wholeHours.filter((hour, index) => index % interval === 0);

  return (
    <Box
      display={'grid'}
      gridTemplateRows={'72px'} // TODO: need to change this to something form our themes
      gridTemplateColumns={'auto 1fr'}
      gap={1}
    >
      {/* Legend */}
      <Box display={'flex'} flexDirection={'column'}>
        <SpeedLegendLabel value={maxSpeed} />
        <SpeedLegendLabel value={upperMiddleSpeed} />
        <SpeedLegendLabel value={lowerMiddleSpeed} />
        <SpeedLegendLabel value={0} />
      </Box>

      {/* Graph */}
      <Box ref={graphRef} position={'relative'} sx={{ backgroundColor: 'white' }}>
        {[0, 33, 66, 100].map((percent) => (
          <Box
            key={percent}
            position={'absolute'}
            height={2}
            width={'100%'}
            top={`${percent}%`}
          />
        ))}

        <TimeIndicator
          visible={indicatorValues.indicatorVisible}
          position={indicatorValues.indicatorPosition}
          time={indicatorValues.indicatorTime}
        />

        {filteredHours.map((hour) => (
          <HourMarker key={hour} position={normalize(hour)} time={hour} />
        ))}

        {pings
          .filter((p) => p.speed !== null)
          .map((p, index) => (
            <SpeedSegment
              key={`speed_${index}`}
              position={normalize(p.createdAt.toISOString())}
              speed={parseFloat(p.speed || '0')}
              maxSpeed={maxSpeed}
            />
          ))}

        {noSignalBlocks.map(([start, end]) => (
          <NoSignal
            key={`nosignal_${start}`}
            startPosition={normalize(start.toISOString())}
            endPosition={normalize(end.toISOString(), true)}
          />
        ))}
      </Box>
    </Box>
  );
};

interface SpeedLegendLabelProps {
  value: number; // The speed in m/s
}

const SpeedLegendLabel = ({ value }: SpeedLegendLabelProps) => {
  const { userStore } = useStores();
  const isMetric = userStore?.userCompany.isFeet;

  // Convert speed from m/s to km/h
  const convertMStoKMPH = (speed: number) => speed * 3.6;

  // Convert speed from m/s to mi/h
  const convertMStoMPH = (speed: number) => speed * 2.23694;

  const speed = isMetric ? convertMStoKMPH(value) : convertMStoMPH(value);

  return (
    <Box textAlign={'right'} sx={{ transform: 'translateY(-50%)' }} pb={0.5}>
      <Typography variant={'body2'}>
        {speed.toFixed(2)}
        &nbsp;
        {isMetric ? 'km/h' : 'mi/h'}
      </Typography>
    </Box>
  );
};
