import { Theme, useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';

import { BasicTooltip } from '~components/Tooltip';

interface Props {
  label: string;
  value?: string | number | ReactNode;
  hint?: string;
  sx?: SxProps<Theme>; // Style
}
const RowItemLabelValue = ({ label, value, hint, sx = {} }: Props) => {
  const theme = useTheme();

  const borderBottom = `1px solid ${theme.palette.divider}`;

  return (
    <TableRow>
      <TableCell sx={{ py: 1, pl: 0, borderBottom, ...sx }}>
        <Typography
          variant={'body1'}
          sx={{ pr: 1, color: theme.palette.text.secondary, fontWeight: 'bold' }}
        >
          {label}
        </Typography>
      </TableCell>
      <TableCell sx={{ py: 1, pr: 0, textAlign: 'right', borderBottom, ...sx }}>
        {hint && value ? (
          <BasicTooltip title={hint}>
            <Typography variant={'body1'}>{value}</Typography>
          </BasicTooltip>
        ) : (
          <Typography variant={'body1'}>{value}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
export { RowItemLabelValue };
