import History from '@mui/icons-material/History';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { createdBy } from '~components/Job/AuditDetails/utils';
import { JobTimeline as JobTimelineModel, useJob } from '~hooks/useJob';
import { LoadingSpinner } from '~pages/Dispatch/components/ordersDispatchStyledComponents';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';
import { snakeCaseToHuman } from '~utils/utilFunctions';

interface Props {
  id: string;
  sx?: SxProps<Theme>;
}

const JobLoadTimeline = observer(({ id, sx = {} }: Props) => {
  const theme = useTheme();
  const { jobStore } = useStores();
  const { getJobTimeline, isLoading } = useJob();

  useEffect(() => {
    getJobTimeline(id);
  }, [id]);

  const timeline = useMemo(() => {
    return jobStore.timeline[id] || [];
  }, [id, jobStore.timeline[id]]);

  const getText = (entry: JobTimelineModel) => {
    const changes = Object.keys(entry.changes);
    const stateMap: Partial<Record<JobState, string>> = {
      [JobState.TO_PICKUP]: $t('job_status.to_pickup') as string,
      [JobState.ARRIVED_PICKUP]: $t('job_status.arrived_pickup') as string,
      [JobState.LOADED]: $t('job_status.loaded') as string,
      [JobState.TO_DROPOFF]: $t('job_status.to_dropoff') as string,
      [JobState.ARRIVED_DROPOFF]: $t('job_status.arrived_dropoff') as string,
      [JobState.UNLOADED]: $t('job_status.unloaded') as string,
      [JobState.COMPLETED]: $t('job_status.completed') as string,
    };

    if (changes[0] === 'id') {
      return t('dispatch.timeline.job_created');
    } else if (changes[0] === 'state') {
      return (
        stateMap[entry.changes.state[1] as JobState] ||
        `${snakeCaseToHuman(entry.changes.state[1])}`
      );
    } else if (changes[0] === 'driver_id') {
      if (entry.changes.driver_id[0] && entry.changes.driver_id[1]) {
        return t('dispatch.timeline.driver_changed');
      } else if (entry.changes.driver_id[0]) {
        return t('dispatch.timeline.unassign_driver');
      } else {
        return t('dispatch.timeline.assign_driver');
      }
    } else {
      return t('dispatch.timeline.job_updated');
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ ...sx }}>
        <LoadingSpinner isVisible />
      </Box>
    );
  }

  if (!timeline.length) {
    return (
      <Box
        sx={{ ...sx, height: '100%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <History
          style={{
            color: theme.brandV2.colors.treadGray3,
          }}
        />
        <Typography
          variant={'body1'}
          sx={{ color: theme.brandV2.colors.treadGray3, ml: '6px' }}
        >
          {t('dispatch.timeline.no_history')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
        px: 2,
        mb: -4,
        pb: 2,
        ...sx,
      }}
    >
      <Timeline
        sx={{
          p: 0,
          pt: 1,
          px: 0,
          my: 0,
          ...sx,
          [`& .MuiTimelineOppositeContent-root`]: {
            flex: 0.5,
            display: timeline.length > 1 ? 'flex' : 'none',
          },
          [`& .MuiTimelineItem-root`]: {
            minHeight: theme.spacing(4),
            pl: 2,
          },
          [`& .MuiTimelineItem-positionRight:before`]: {
            flex: 0,
            p: 0,
          },
          [`& .MuiTimelineConnector-root`]: {
            my: -1.5,
            width: '4px',
            height: '48px',
            backgroundColor: theme.brandV2.colors.treadYellow,
          },
          [`& .MuiTimelineDot-root`]: {
            backgroundColor: theme.brandV2.colors.treadYellow,
            border: `2px solid ${theme.brandV2.colors.treadYellowDark}`,
            boxShadow: 'none',
            width: '16px',
            height: '16px',
          },
        }}
      >
        {timeline.map((item, index) => {
          return (
            <TimelineItem key={`${id}_${index}_${item.createdAt.toISOString()}`}>
              <TimelineSeparator>
                <TimelineDot />
                {index < timeline.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent display={'flex'} gap={4}>
                <Box
                  sx={{
                    minWidth: '60px',
                  }}
                >
                  <Typography
                    variant="body1"
                    mb={0.5}
                    sx={{
                      color: theme.brandV2.colors.treadBlack,
                      fontWeight: 600,
                    }}
                  >
                    {dateFormat(item.createdAt, 'h:mm A')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{
                      color: theme.brandV2.colors.treadGray2,
                    }}
                  >
                    {dateFormat(item.createdAt, "MMM D, 'YY")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    mb={0.5}
                    sx={{
                      color: theme.brandV2.colors.treadBlack,
                      fontWeight: 600,
                    }}
                  >
                    {getText(item)}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{
                      color: theme.brandV2.colors.treadGray2,
                    }}
                  >
                    {createdBy(item.createdBy)}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
});

export { JobLoadTimeline };
