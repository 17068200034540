import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { useOrders } from '~hooks/useOrders';
import { useStores } from '~store';

import DispatchSearchParam from './DispatchSearchParam';
import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, string>;
  sx?: SxProps<Theme>; // Style
}

const DispatchNumberSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { userStore } = useStores();
  const { getOrderDispatchNumbersTypeahead } = useOrders();

  const fetchOrdersByCompanyId = async (additionalProps = {}) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const orders = await getOrderDispatchNumbersTypeahead({
      companyId: company.id,
      ...additionalProps,
    });

    return orders;
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncCallback={fetchOrdersByCompanyId}
          fieldName={DispatchSearchParam.DISPATCH_NUMBER}
          label={$t('order.form.dispatch_number')}
          defaultValues={[]}
          allSearchParams={allSearchParams}
          sx={{
            ...sx,
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.dispatchNumber || ''}
          getOptionValue={(item) => item.dispatchNumber || ''}
        />
      </Box>
    </>
  );
});

export { DispatchNumberSelector };
