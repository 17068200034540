import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TelematicsProviderSource } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import DataGrid, { HeaderNavigation } from '~components/DataGrid';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { useTelematicsFleets } from '~hooks/useTelematicsFleets';
import { useTelematicsVehicles } from '~hooks/useTelematicsVehicles';
import { PaginationLink } from '~interfaces';
import { remoteVehicleDataGridColumnsDefinition as colsDef } from '~pages/Settings/Integrations';
import { routes } from '~router';
import { useStores } from '~store';

import { RemoteVehicleRowDef } from './remoteVehicleDataGridColumnsDefinition';

interface FleetManagementProps {
  providerSource: TelematicsProviderSource;
}

export const FleetManagement = observer(({ providerSource }: FleetManagementProps) => {
  const { telematicsStore, userStore } = useStores();

  const { isLoading: isLoadingTelematicsVehicles, getTelematicsVehicleByFleetId } =
    useTelematicsVehicles();
  const { isLoading: isLoadingTelematicsFleets, getTelematicsFleets } =
    useTelematicsFleets();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();

  const fleetIdRef = useRef('');
  const fetchRemoteVehiclesCallback = (link?: PaginationLink) => {
    getTelematicsVehicleByFleetId(
      fleetIdRef.current,
      link || ({} as PaginationLink),
      searchValue,
    );
  };

  const paginationDef: GridColDef = {
    flex: 1,
    field: 'pagination',
    headerName: '',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    renderHeader: () => (
      <HeaderNavigation
        count={telematicsStore.remoteVehicles?.length || 0}
        loading={loadingRef.current}
        pagination={telematicsStore.remoteVehiclesPagination}
        callback={fetchRemoteVehiclesCallback}
      />
    ),
  };

  const columns = useMemo(() => {
    return [
      colsDef.equipmentIDDef,
      colsDef.equipmentNameDef,
      colsDef.equipmentMappingDef,
      paginationDef,
    ];
  }, []);

  const rows = useMemo(
    () =>
      telematicsStore.remoteVehicles.map(
        (vehicle) =>
          ({
            id: vehicle.id,
            remoteVehicle: vehicle,
          }) as RemoteVehicleRowDef['row'],
      ),
    [telematicsStore.remoteVehicles],
  );

  // Fetch the fleet and then its associated vehicles
  useEffect(() => {
    if (!userStore.userCompany?.id) {
      return;
    }

    getTelematicsFleets().then((fleets) => {
      const fleet = fleets.find(
        (f) =>
          f.providerSource === providerSource &&
          f.companyId === userStore.userCompany?.id,
      );

      if (fleet) {
        fleetIdRef.current = fleet.id;
        getTelematicsVehicleByFleetId(fleet.id);
      }
    });
  }, [userStore.userCompany?.id, providerSource]);

  const isLoading =
    isLoadingTelematicsVehicles ||
    isLoadingTelematicsFleets ||
    !userStore.userCompany?.id;

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const onChangeFilter = (searchValue: string) => {
    setSearchQueryValue(searchValue);
  };

  useEffect(() => {
    if (fleetIdRef.current) {
      getTelematicsVehicleByFleetId(
        fleetIdRef.current,
        {} as PaginationLink,
        searchValue,
      );
    }
  }, [searchValue, fleetIdRef.current]);

  return (
    <Box maxWidth={'1000px'}>
      <Button
        variant="text"
        color="secondary"
        component={Link}
        to={`/${routes.settings.base}/${routes.settings.integrations.base}`}
        startIcon={<ArrowBackIosNew fontSize="small" />}
      >
        {t('administration.integration.title')}
      </Button>

      <CardContent>
        <Box display={'flex'} flexDirection={'row'}>
          <Typography variant="h5">
            {t(`administration.integration.${providerSource}.name`)}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {t(`administration.integration.${providerSource}.description`)}
        </Typography>
      </CardContent>

      <DataGrid
        id={`${providerSource}-management-grid`}
        columns={columns}
        rows={rows}
        disableColumnFilter
        loading={isLoading}
        onChangeFilter={onChangeFilter}
      />
    </Box>
  );
});
