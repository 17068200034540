import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React from 'react';

interface Props {
  id: string;
  value: number;
  name: string;
  type: 'text' | 'number' | 'tel' | 'url';
  field: string;
  api: any; // Grid api
  disabled?: boolean;
}

const TextEditCell = ({ id, name, type, value, field, api, disabled = false }: Props) => {
  const saveChanges = () => api.stopCellEditMode({ id, field });

  return (
    <ClickAwayListener onClickAway={saveChanges}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 0.25, width: '100%' }}>
        <TextField
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(event) => {
            api.setEditCellValue({
              id,
              field,
              value: event.target.value,
            });
          }}
          sx={{ width: '100%', px: 0, m: 0, '& .MuiInputBase-input': { px: 0.25 } }}
          no-border
          no-padding
          no-margin
          hide-label
        />
        <IconButton onClick={saveChanges} sx={{ ml: 0.25 }} disabled={disabled}>
          <Check sx={{ height: 18, width: 18 }} />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
};

export { TextEditCell };
