import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { AutocompleteFormField } from '~components/FormFields';
import { SearchParams } from '~constants/enums';

import { payableInvoiceCategories } from '../DriverPay';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>;
}

const payableJobsOptions: { value: InvoiceCategoryFilter; label: string }[] = [
  { value: InvoiceCategoryFilter.PAYABLES, label: t('common.all') },
  { value: InvoiceCategoryFilter.VENDOR_PAYABLES, label: t('common.vendor') },
  { value: InvoiceCategoryFilter.OWNER_OPERATOR_PAYABLES, label: t('common.o_and_o') },
  { value: InvoiceCategoryFilter.INTERNAL_PAYABLES, label: t('common.internal') },
];

const receivableJobsOptions: { value: InvoiceCategoryFilter; label: string }[] = [
  { value: InvoiceCategoryFilter.RECEIVABLES, label: t('common.all') },
  {
    value: InvoiceCategoryFilter.CONNECTION_RECEIVABLES,
    label: t('common.connection'),
  },
  { value: InvoiceCategoryFilter.ACCOUNT_RECEIVABLES, label: t('common.account') },
];

const DriverTypeSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const showPayables = Boolean(
    allSearchParams.category &&
      payableInvoiceCategories.includes(allSearchParams.category),
  );

  const options = useMemo(() => {
    return showPayables ? payableJobsOptions : receivableJobsOptions;
  }, [showPayables]);

  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [SearchParams.INVOICE_CATEGORY]: options[0],
    },
  });

  const value = watch(SearchParams.INVOICE_CATEGORY);

  useEffect(() => {
    const searchParamIds = allSearchParams[SearchParams.INVOICE_CATEGORY]
      ? allSearchParams[SearchParams.INVOICE_CATEGORY].split('|')
      : [];
    const value = options.filter((item) => searchParamIds.includes(item.value))[0];

    if (options.length && value) {
      setValue(SearchParams.INVOICE_CATEGORY, value);
      trigger(SearchParams.INVOICE_CATEGORY);
    } else {
      reset({ [SearchParams.INVOICE_CATEGORY]: options[0] });
    }
  }, [options, allSearchParams[SearchParams.INVOICE_CATEGORY]]);

  useEffect(() => {
    if (options.length) {
      const params = new URLSearchParams(allSearchParams);
      if (value) {
        params.set(SearchParams.INVOICE_CATEGORY, value.value);
      } else {
        params.set(SearchParams.INVOICE_CATEGORY, options[0].value);
      }

      // Only update the URL if the value has changed
      if (
        params.get(SearchParams.INVOICE_CATEGORY) !==
        currentSearchParams.get(SearchParams.INVOICE_CATEGORY)
      ) {
        setSearchParams(params);
      }
    }
  }, [JSON.stringify(value), options]);

  return (
    <AutocompleteFormField
      control={control}
      name={SearchParams.INVOICE_CATEGORY}
      errors={errors}
      list={options}
      label={t('form_fields.driver_type') as string}
      isRequired={false}
      getValue={(item) => item.value}
      getLabel={(item) => item.label}
      clearOnBlur={true}
      blurOnSelect={true}
      clearDefaultValue={[]}
      onInput={() => {}}
      sx={{
        ...sx,
        ...{
          width: '150px',
          fontSize: '12px',
          mr: 0.25,
          '& label:not(.Mui-focused)': {
            color: '#949494',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
      }}
      isShrinkingLabel
    />
  );
});

export default DriverTypeSelector;
