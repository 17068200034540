import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useCallback } from 'react';

import theme from '~theme/AppTheme';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../NewDispatch';

export interface TabProps<T> {
  label: string;
  numberAdornment?: number;
  value: T;
}

interface TabsProps<T> {
  onChange: (selected: T) => void;
  selected: T;
  tabs: TabProps<T>[];
  sx?: SxProps;
}

export default function Tabs<T>({ onChange, selected, tabs, sx }: TabsProps<T>) {
  const handleChange = useCallback(
    (_: React.SyntheticEvent, value: T) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Box sx={sx}>
      <MuiTabs
        value={selected}
        onChange={handleChange}
        sx={{
          '&.MuiTabs-root': {
            minHeight: 0,
            '.MuiTabs-indicator': {
              display: 'none',
            },
            '.MuiTabs-flexContainer': {
              alignItems: 'center',
              position: 'relative',
              '&:before': {
                backgroundColor: theme.brandV2.colors.treadGray8,
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: theme.brandV2.borderRadius,
                content: "''",
                display: 'block',
                inset: 1,
                position: 'absolute',
              },
            },
          },
        }}
      >
        {tabs.map((tab) => (
          <MuiTab
            key={String(tab.value)}
            label={tab.label}
            value={tab.value}
            {...(_.isUndefined(tab.numberAdornment)
              ? undefined
              : {
                  iconPosition: 'end',
                  icon: (
                    <Typography fontSize="12px" color={theme.brandV2.colors.treadGray4}>
                      {tab.numberAdornment}
                    </Typography>
                  ),
                })}
            sx={{
              flex: 1,
              '&.MuiTab-root': {
                borderLeft: 'solid 1px transparent',
                borderRight: 'solid 1px transparent',
                color: theme.brandV2.colors.treadBlack,
                fontSize: '12px',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX - 2,
                minHeight: 0,
                opacity: 1,
                p: theme.spacing(),
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                  borderRadius: theme.brandV2.borderRadius,
                  height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                },
              },
            }}
          />
        ))}
      </MuiTabs>
    </Box>
  );
}
