import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Consumer } from '@rails/actioncable';
import { createConsumer } from '@rails/actioncable';
import * as Sentry from '@sentry/react';
import { OpenAPI } from '@treadinc/horizon-api-spec';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import { runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';

import { getTokenProp } from '~constants/consts';
import { STATUS_MESSAGES } from '~constants/errorMessagesConsts';
import { ERROR_TYPES } from '~constants/errorTypes';
import { Pagination } from '~interfaces/pagination';
import { routes } from '~router';
import { rootStore } from '~store';
import { alert } from '~types/AlertTypes';

export interface FieldError {
  model: string;
  message: string;
  field: string;
}

export interface ErrorResponse {
  error: {
    code: string;
    errors: FieldError[];
  };
}

const emitError = (
  message: string | React.ReactNode,
  includeTreadAlertedText = false,
) => {
  runInAction(() => {
    const errorMessage = includeTreadAlertedText ? (
      <Box>
        <Typography variant="body1">{message}</Typography>
        <Typography variant="body2">{$t('error_messages.tread_alerted')}</Typography>
      </Box>
    ) : (
      message
    );

    rootStore.toasterStore.push(alert(errorMessage, 'error'), true);
  });
};
const REAL_TIME_URL = import.meta.env.TREAD__RTU_URL || 'ws://localhost:8080';
class Connection {
  private axiosInstance: AxiosInstance;
  private realTimeConnectionInstance!: Consumer;
  constructor(baseURL: string, REAL_TIME_URL_Value: string) {
    this.initializeRealTimeConnection(REAL_TIME_URL_Value);
    this.axiosInstance = axios.create({ baseURL });
    this.initializeInterceptors();
  }
  private initializeRealTimeConnection(REAL_TIME_URL_Value: string): void {
    if (!this.realTimeConnectionInstance) {
      const token = localStorage.getItem(getTokenProp) || '';
      if (token?.length) {
        const consumer = createConsumer(REAL_TIME_URL_Value);
        consumer.addSubProtocol(token);
        this.realTimeConnectionInstance = consumer;
      }
    }
  }
  public get realTimeConnection(): Consumer {
    return this.realTimeConnectionInstance;
  }
  private initializeInterceptors(): void {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(getTokenProp);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        // Config.headers['Content-Type'] = 'application/json';
        config.headers.Accept = 'application/json';
        config.headers['X-Request-Id'] = UUID();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Ensure that the handler has access to object methods and context
    const boundHandler = this.handleSuccessResponse.bind(this);
    this.axiosInstance.interceptors.response.use(boundHandler);
  }
  private handleSuccessResponse(response: AxiosResponse): AxiosResponse {
    const token = response?.headers?.['x-horizon-api-session-jwt'] ?? '';
    if (!!token && token !== localStorage.getItem(getTokenProp)) {
      localStorage.setItem(getTokenProp, token);
      if (this.realTimeConnectionInstance) {
        this.realTimeConnectionInstance.disconnect();
        this.realTimeConnectionInstance.subprotocols = [];
        this.realTimeConnectionInstance.addSubProtocol(token);
        this.realTimeConnectionInstance.connect();
      }
    }
    return response;
  }

  private handleRequestError(
    e: unknown,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): never {
    const error = customErrorMessage
      ? new AxiosError(
          `${(e as AxiosError)?.response?.status} error: ${customErrorMessage}`,
          (e as AxiosError).code,
          (e as AxiosError).config,
          (e as AxiosError).request,
          (e as AxiosError).response,
        )
      : e;

    const errorMessage = (error as Error)?.message || 'Unknown error';
    let errorRequest = 'No request data';
    let errorResponse = 'No response data';
    let statusCode: number | undefined;

    if (axios.isAxiosError(error)) {
      if (error.request) {
        errorRequest = JSON.stringify(error.request);
      }

      if (error.response) {
        errorResponse =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
        statusCode = Number(error.response.status);
      }
    }

    const overrideError = shouldOverrideError(statusCode, overrideErrorCodes);
    if (!overrideError) {
      Sentry.withScope((scope) => {
        scope.setTag('axios_error', axios.isAxiosError(error));
        scope.setExtra('error_message', errorMessage);
        scope.setExtra('error_request', errorRequest);
        scope.setExtra('error_response', errorResponse);
        if (statusCode !== undefined) {
          scope.setExtra('error_status', statusCode);
        }
        // Set the fingerprint to group by error message
        scope.setFingerprint([errorMessage]);
        Sentry.captureException(error);
      });
    }

    // Handling the error response here allows us to display the custom error message in the error toast
    this.handleErrorResponse(
      error as AxiosError,
      !!customErrorMessage,
      overrideErrorCodes,
    );

    throw error;
  }

  // @ts-ignore
  private handleErrorResponse(
    error: AxiosError,
    includeTreadAlertedText: boolean,
    overrideErrorCodes?: number[],
  ): Promise<never> {
    if (error) {
      const code = String(error.code || '').toUpperCase();
      const status = Number(get(error, 'response.status', 0));
      const message =
        error.message ||
        get(error, 'response.error.message') ||
        get(error, 'response.data.message') ||
        STATUS_MESSAGES[500];
      const overrideError = shouldOverrideError(status, overrideErrorCodes);
      if (overrideError) {
        return Promise.reject(error);
      }

      const errorType = String(get(error, 'response.data.error.error_type', ''));

      if ([400, 422, 404].includes(status)) {
        const errorList = get(error, 'response.data.error.errors', [])
          // @ts-ignore
          .map((err: { message: string }) => err.message)
          .join(', ');

        // @ts-ignore
        emitError(
          errorList || message || STATUS_MESSAGES[status as keyof typeof STATUS_MESSAGES],
          includeTreadAlertedText,
        );
      } else if (status === 401) {
        // Auth required
        // RedirectToLogin
        switch (errorType) {
          case 'member_reset_password':
            emitError(ERROR_TYPES.member_reset_password);
            break;
          case 'breached_password':
            emitError(ERROR_TYPES.breached_password);
            break;
          case 'unable_to_auth_magic_link':
            emitError(ERROR_TYPES.unable_to_auth_magic_link);
            break;
          case 'member_password_not_found':
            emitError(ERROR_TYPES.member_password_not_found);
            break;
          case 'weak_password':
            emitError(ERROR_TYPES.weak_password);
            break;
          default:
            emitError(STATUS_MESSAGES[401]);
            localStorage.removeItem(getTokenProp);
            // //keep here, do no reload of current login page if wrong credentials
            if (window.location.pathname !== `/${routes.signIn}`) {
              window.location.replace(`/${routes.signIn}`);
            }
            break;
        }
      } else if (status === 403) {
        // Unauthorized
        emitError(message || STATUS_MESSAGES[403], includeTreadAlertedText);
      } else if (Math.floor(status / 100) === 5) {
        // Any 500 error
        emitError(message || STATUS_MESSAGES[500], includeTreadAlertedText);
      } else if (!status && !code.includes('ERR_CANCEL')) {
        // Any not-recognized except CANCEL
        emitError(message);
      } else if (message || STATUS_MESSAGES[409]) {
        emitError(message || STATUS_MESSAGES[401], includeTreadAlertedText);
      }
      console.error(message || status || 'Unknown error');
      return Promise.reject(error);
    }
    return Promise.reject('Unknown error');
  }
  public async get<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.get<T>(url, config);

      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async getPaginated<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<{ pagination: Pagination; data: Array<T> }> {
    try {
      const response = await this.axiosInstance.get(url, config);
      const { headers } = response;

      const links = headers.link || '';
      const refs = links.match(/\[(after|before)\]=\S+/gi);
      const pagination = {} as Pagination;

      // Parse pagination links to store them in pagination object
      refs?.forEach((ref: string) => {
        // Split by first '=', because the link can contain '=' in the url
        const [key, link] = ref.replace(/=/, '****').split('****');

        // @ts-ignore
        pagination[key.replace(/\[|\]/g, '')] = link.replace(/>\S+/g, '');
      });
      return {
        data: response.data.data,
        pagination,
      };
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async post<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.post<T>(url, data, config);

      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage, overrideErrorCodes);
    }
  }

  public async put<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.put<T>(url, data, config);

      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async patch<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.patch<T>(url, data, config);
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage, overrideErrorCodes);
    }
  }

  public async delete<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.delete<T>(url, config);
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }
}

/**
 * Determines whether the given error code should be overridden based on the provided error codes.
 *
 * @param {number | undefined} errorCode - The error code to check.
 * @param {number[] | undefined} overrideErrorCodes - The list of error codes to compare against.
 * @return {boolean} Returns true if the error code should be overridden, false otherwise.
 */
const shouldOverrideError = (
  errorCode: number | undefined,
  overrideErrorCodes: number[] | undefined,
) => {
  if (!overrideErrorCodes || !errorCode) {
    return false;
  }

  const staticSet = new Set([400, 422, 404, 401, 403]);
  if (overrideErrorCodes.some((code) => staticSet.has(code))) {
    return overrideErrorCodes.some((code) => code === errorCode);
  }

  // check if the error code starts with 5
  if (overrideErrorCodes.some((code) => code.toString().startsWith('5'))) {
    return errorCode.toString().startsWith('5');
  }

  return false;
};

// Use TREAD__BASE_URL if it's specified in our env
// TODO: is this the right place for this?
if (import.meta.env.TREAD__BASE_URL) OpenAPI.BASE = import.meta.env.TREAD__BASE_URL;

const connection = new Connection(OpenAPI.BASE, REAL_TIME_URL);
export default connection;
