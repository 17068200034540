import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TelematicsProviderSource } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { PageTitle } from '~components/typographyTitles';
import { TelematicsFleet } from '~hooks/useTelematicsFleets/models';
import { useTelematicsFleets } from '~hooks/useTelematicsFleets/useTelematicsFleets';
import { routes } from '~router';
import { useStores } from '~store';

export const IntegrationManagement = observer(() => {
  const theme = useTheme();
  const { isLoading, getTelematicsFleets } = useTelematicsFleets();
  const [fleets, setFleets] = useState<TelematicsFleet[]>([]);
  const { userStore } = useStores();

  useEffect(() => {
    getTelematicsFleets().then(setFleets);
  }, []);

  const isConnected = useMemo(
    () => (source: TelematicsProviderSource) =>
      fleets.some(
        (f) => f.providerSource === source && f.companyId === userStore.userCompany?.id,
      ),
    [fleets, userStore.userCompany?.id],
  );

  const isAxleConnected = useMemo(
    () => isConnected(TelematicsProviderSource.AXLE),
    [isConnected],
  );

  const isPaverTrackerConnected = useMemo(
    () => isConnected(TelematicsProviderSource.PAVER_TRACKER),
    [isConnected],
  );

  const integrationCards = [
    {
      name: t('administration.integration.axle.name'),
      description: t('administration.integration.axle.description'),
      connected: isAxleConnected,
      link: routes.settings.integrations.axle,
    },
    {
      name: t('administration.integration.paver_tracker.name'),
      description: t('administration.integration.paver_tracker.description'),
      connected: isPaverTrackerConnected,
      link: routes.settings.integrations.paverTracker,
    },
  ];

  return (
    <Box maxWidth={'800px'}>
      <PageTitle text={`${t('administration.integration.title')}`} sx={{ mb: 2 }} />

      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        integrationCards.map((card) => (
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              mb: 2,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            key={card.name}
          >
            <CardContent>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography variant="h5">{card.name}</Typography>
                {card.connected && (
                  <Chip
                    label={t('administration.integration.connected')}
                    style={{
                      backgroundColor: '#2E7D321A',
                      color: theme.palette.success.main,
                      marginLeft: 10,
                    }}
                  />
                )}
              </Box>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {card.description}
              </Typography>
            </CardContent>
            <CardActions>
              <List>
                <Button component={Link} to={card.link} disabled={!card.connected}>
                  {t('administration.integration.manage')}
                </Button>
              </List>
            </CardActions>
          </Card>
        ))
      )}
    </Box>
  );
});
