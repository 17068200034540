import { CSSProperties, ReactNode } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { LEFT_COLUMN_WIDTH_IN_PX } from '~pages/Dispatch/components/OrdersView';

interface InfiniteListProps<T> {
  items: T[];
  itemSize: number;
  loadMoreItems: () => void;
  hasNextPage: boolean;
  renderItem: (index: number, style: CSSProperties) => ReactNode;
  style?: CSSProperties;
}

const InfiniteList = <T,>({
  items,
  itemSize,
  loadMoreItems,
  hasNextPage,
  renderItem,
  style,
}: InfiniteListProps<T>) => {
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadNextPage = hasNextPage
    ? () => {
        loadMoreItems();
      }
    : () => {};

  return (
    <AutoSizer>
      {({ height }) => (
        <InfiniteLoader
          isItemLoaded={(index) => !hasNextPage || index < items.length + 1}
          loadMoreItems={loadNextPage}
          itemCount={10000}
          threshold={15}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={height || 0}
              itemCount={items.length}
              itemSize={itemSize}
              width={LEFT_COLUMN_WIDTH_IN_PX}
              onItemsRendered={onItemsRendered}
              style={style}
              ref={ref}
            >
              {({ index, style }) => renderItem(index, style)}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default InfiniteList;
