import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { TicketState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Header } from '~components/Drawer';
import { AutocompleteFormField, TextFormField } from '~components/FormFields';
import { unitOfMeasureOptions } from '~constants/enums';
import { useMaterials } from '~hooks/useMaterials';
import { Ticket } from '~hooks/useTickets';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';
import { canEditTicket } from '~utils/tickets/ticket-utils';

import {
  setDefaultTicketValues,
  TicketFormDTO,
  ticketFormSchema,
} from '../ApprovalsComponents/ticketFormSchema';
import { Field } from './Field';

interface TicketDetailFormProps {
  jobId: string;
  onChange: (ticket: TicketFormDTO) => Promise<Ticket>;
  onEditModeChange?: (isEditing: boolean) => void;
  ticket?: Ticket;
}
export const TicketDetailForm = ({
  jobId,
  onChange,
  onEditModeChange,
  ticket,
}: TicketDetailFormProps) => {
  const { companyAssetsStore } = useStores();
  const { getAllMaterials } = useMaterials();

  const [isEditing, setIsEditing] = useState(!ticket);
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TicketFormDTO>({
    resolver: yupResolver(ticketFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: setDefaultTicketValues(ticket, jobId),
  });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      setIsSaving(true);

      onChange(data)
        .then((ticket) => {
          reset(setDefaultTicketValues(ticket, jobId));
        })
        .finally(() => {
          setIsSaving(false);
          setIsEditing(false);
        });
    })();
  }, [handleSubmit, reset, jobId]);

  useEffect(() => {
    if (!companyAssetsStore.materials.length) {
      getAllMaterials();
    }
  }, [companyAssetsStore.materials.length]);

  useEffect(() => {
    if (!isEditing) {
      reset(setDefaultTicketValues(ticket, jobId));
    }
  }, [isEditing, ticket, jobId, reset]);

  useEffect(() => {
    onEditModeChange?.(isEditing);
  }, [onEditModeChange, isEditing]);

  return (
    <div>
      <Header
        title={
          <span className="text-sm font-bold">
            {$t('approvals.ticket_details.ticket_details')}
          </span>
        }
        actions={
          <>
            {!isEditing && ticket && canEditTicket(ticket.state as TicketState) && (
              <Button
                color="inherit"
                disabled={isSaving}
                onClick={() => setIsEditing(true)}
                startIcon={<Edit />}
                variant="outlined"
              >
                {$t('approvals.ticket_details.edit')}
              </Button>
            )}

            {isEditing && (
              <div className="flex space-x-2">
                {ticket && (
                  <Button
                    color="inherit"
                    disabled={isSaving}
                    onClick={() => setIsEditing(false)}
                    startIcon={<Close />}
                    variant="outlined"
                  >
                    {$t('actions.cancel')}
                  </Button>
                )}

                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isSaving}
                  startIcon={<Check />}
                  onClick={onSubmit}
                >
                  {$t('actions.save')}
                </LoadingButton>
              </div>
            )}
          </>
        }
      />

      <Field
        label={$t('approvals.ticket_details.ticket_number')}
        value={ticket?.ticketNumber || ''}
        icon={ticket?.ticketNumber ? null : <AlertIcon />}
        editing={isEditing}
        editField={
          <TextFormField
            control={control}
            errors={errors}
            disabled={false}
            hint=""
            name="ticketNumber"
            isRequired={false}
            margin="none"
          />
        }
      />

      <Field
        label={$t('approvals.ticket_details.material')}
        value={ticket?.material?.name || ''}
        icon={ticket?.material?.name ? null : <AlertIcon />}
        editing={isEditing}
        editField={
          <AutocompleteFormField
            sx={{ width: '100%' }}
            list={companyAssetsStore.allMaterials}
            getLabel={(option) => option.name}
            getValue={(option) => option.id}
            control={control}
            errors={errors}
            name={`material`}
            isDenseText={false}
          />
        }
      />

      <Field
        label={$t('approvals.ticket_details.quantity')}
        value={ticket?.quantity || ''}
        icon={ticket?.quantity ? null : <AlertIcon />}
        editing={isEditing}
        editField={
          <TextFormField
            control={control}
            errors={errors}
            disabled={false}
            hint=""
            name="quantity"
            isRequired={false}
            margin="none"
          />
        }
      />

      <Field
        label={$t('approvals.ticket_details.unit')}
        value={ticket?.unitOfMeasure?.name || ''}
        icon={ticket?.unitOfMeasure?.name ? null : <AlertIcon />}
        editing={isEditing}
        editField={
          <AutocompleteFormField
            key={'ticket-material-key'}
            control={control}
            name="unitOfMeasure"
            isDenseText={false}
            errors={errors}
            list={unitOfMeasureOptions}
            isRequired={true}
            getValue={(item) => item.id}
            getLabel={(item) => item.name || ''}
            clearOnBlur={true}
            blurOnSelect={true}
          />
        }
      />

      <Field
        label={$t('approvals.ticket_details.ticket_date')}
        value={
          ticket &&
          `${dateFormat(new Date(ticket.createdAt))} ${dateFormat(new Date(ticket.createdAt), 'hh:mm A')}`
        }
      />

      <Field
        label={$t('approvals.ticket_details.image_url')}
        value={
          <a
            href={ticket?.imageUrl}
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            {ticket?.imageUrl ? $t('approvals.ticket_details.view_image') : ''}
          </a>
        }
        icon={ticket?.imageUrl ? null : <AlertIcon />}
      />
    </div>
  );
};

export const AlertIcon = () => {
  return <WarningAmber color="primary" />;
};
