import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React, { ReactNode } from 'react';

import { BasicTooltip } from '~components/Tooltip';

interface Props {
  label: string;
  value?: string | number | ReactNode;
  hint?: string;
  defaultValue?: string | number | ReactNode;
  sx?: SxProps<Theme>; // Style
}

const DataValueItem = ({ label, value, hint, defaultValue = '', sx = {} }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...sx }}>
      <Typography
        variant={'body1'}
        component={'div'}
        sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
      >
        {label}
      </Typography>
      {hint ? (
        <BasicTooltip title={hint}>
          <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
            {value}
          </Typography>
        </BasicTooltip>
      ) : (
        <>
          {value ? (
            <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
              {value}
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ fontStyle: 'italic', ...sx }}>
              {defaultValue}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export { DataValueItem };
