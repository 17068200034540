import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SingleInputDateRangePicker } from '~components/FormFields';
import theme from '~theme/AppTheme';

import { CONTROLS_HEIGHT_IN_PX } from '../CopyVendorAssignmentsForm';

interface DateRangeSelectorProps {
  onChange: (startDate?: string, endDate?: string) => void;
}

export default function DateRangeSelector({ onChange }: DateRangeSelectorProps) {
  const [showDateRateSelector, setShowDateRateSelector] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { dateRange: [null, null] },
  });

  const [selectedStartDate, selectedEndDate] = watch('dateRange') as [
    Dayjs | null,
    Dayjs | null,
  ];

  useEffect(() => {
    if (!showDateRateSelector) {
      return;
    }

    const startDate =
      selectedStartDate && selectedStartDate.isValid()
        ? selectedStartDate.format('YYYY-MM-DD')
        : undefined;
    const endDate =
      selectedEndDate && selectedEndDate.isValid()
        ? selectedEndDate.format('YYYY-MM-DD')
        : undefined;

    onChange(startDate, endDate);
  }, [onChange, showDateRateSelector, selectedStartDate, selectedEndDate]);

  return (
    <Box flex={0} minWidth={showDateRateSelector ? '200px' : undefined}>
      {showDateRateSelector ? (
        <SingleInputDateRangePicker
          InputProps={{
            sx: {
              py: 0,
              '& .MuiInputBase-input': { py: 0, height: CONTROLS_HEIGHT_IN_PX },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.brandV2.colors.treadGray7,
              },
            },
          }}
          control={control}
          defaultOpen
          errors={errors}
          name="dateRange"
          sx={{ width: '100%', mt: 0 }}
        />
      ) : (
        <Button
          onClick={() => setShowDateRateSelector(true)}
          startIcon={<CalendarMonth />}
          sx={{
            '&.MuiButton-root': {
              backgroundColor: 'white',
              border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              borderRadius: theme.brandV2.borderRadius,
              boxShadow: 'none',
            },
          }}
        >
          {t('common.date')}
        </Button>
      )}
    </Box>
  );
}
