import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { DefaultTFuncReturn, t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { GroupTitle } from '~components/Common';
import { PseudoLink } from '~components/Helpers';
import { FileAttachment, useFileAttachment } from '~hooks/useFileAttachment';
import { FileAttachableType } from '~hooks/useFileAttachment/useFileAttachment';

const FileAttachmentsReadOnly = ({
  label,
  fileAttachableId,
  fileAttachableType,
}: {
  label?: DefaultTFuncReturn;
  fileAttachableId: string;
  fileAttachableType: FileAttachableType;
}) => {
  const { getAllFileAttachmentsById } = useFileAttachment();
  const [attachments, setAttachments] = useState<FileAttachment[]>([]);

  useEffect(() => {
    if (!fileAttachableType || !fileAttachableId) return;

    getAllFileAttachmentsById(fileAttachableId, fileAttachableType).then((attachments) =>
      setAttachments(attachments),
    );
  }, [fileAttachableId, fileAttachableType]);
  return (
    <Box mt={2}>
      <GroupTitle>{label ?? t('form_fields.attachments')}</GroupTitle>
      {attachments.length
        ? attachments.map((attachment) => (
            <Box
              key={attachment.id}
              display="flex"
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box>
                <PseudoLink
                  title={attachment.fileName}
                  action={() => window.open(attachment.fileUrl)}
                />
                <Typography variant="caption">{attachment.description}</Typography>
              </Box>
              <Chip label={attachment.category} size="small" color="default" />
            </Box>
          ))
        : t('file_attachments.no_attachments')}
    </Box>
  );
};

export default FileAttachmentsReadOnly;
