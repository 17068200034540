import '@nbai/nbmap-gl/dist/nextbillion.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import nextbillion, {
  IControl,
  Listener,
  LngLatBounds,
  LngLatLike,
  MapLayerMouseEvent,
  NBMap,
  Offset,
  PointLike,
} from '@nbai/nbmap-gl';
import { Polygon } from '@turf/helpers';
import {
  GeolocateControl,
  LayerSpecification,
  Marker,
  NavigationControl,
} from 'maplibre-gl';
import React, { useEffect, useMemo, useState } from 'react';

import MapStylesControl from '~components/Maps/Controls/MapStylesControl';
import { MapType, MapTypes } from '~components/Maps/Controls/MapTypeToggle';
import {
  ControlKey,
  ControlsConstructDictionary,
  MarkerProps,
  SourceItem,
} from '~components/Maps/interfaces';
import {
  DEFAULT_BEARING,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_PITCH,
  INITIAL_ZOOM_LEVEL,
} from '~hooks/useLiveMap/constants';
import { Nullable } from '~types/Nullable';
import { validateLatLon } from '~utils/utilFunctions';

interface BaseMapProps {
  isPopUpCustom?: boolean;
  containerId: string;
  center: LngLatLike;
  bounds?: Nullable<LngLatBounds>;
  zoom?: number;
  layers: LayerSpecification[];
  clickable?: string[]; // Array of IDs of clickable layers
  sources: SourceItem[];
  bearing?: number;
  pitch?: number; // The angle of the camera from the horizontal plane
  controlsToAdd: ControlKey[];
  markers?: MarkerProps[];
  sx?: SxProps<Theme>;
  overlay?: React.ReactNode;
  isDrawAllowed?: boolean;
  drawOptions?: any;
  defaultGeoFences?: Nullable<Polygon[]>;
  onDrawCreateCallBack?: (e: DrawCreateEvent) => void;
  onDrawUpdateCallBack?: (e: DrawUpdateEvent) => void;
  onDrawDeleteCallBack?: (e: DrawDeleteEvent) => void;
  isDrawPolygonControlEnabled?: boolean;
  isTrashDrawControlEnabled?: boolean;
  imagesToAdd?: { name: string; path: string }[];
  mapLoadCallBack?: (map: NBMap) => void;
  maxZoom?: number;
  minZoom?: number;
  PopUpComponent?: React.ReactNode | React.FC<any>;
}

const controls: ControlsConstructDictionary = {
  navigation: () => new NavigationControl({}),
  geolocate: () => new GeolocateControl({}),
  fullscreen: () =>
    new nextbillion.maps.FullscreenControl({
      container: document.querySelector('body') || undefined,
    }),
};

const key = import.meta.env.TREAD__NEXTBILLION_KEY;
nextbillion.setApiKey(key);

const BaseMap = ({
  isPopUpCustom = false,
  containerId,
  center,
  bounds = undefined,
  zoom = INITIAL_ZOOM_LEVEL,
  layers,
  clickable = [],
  sources,
  bearing = DEFAULT_BEARING,
  pitch = DEFAULT_PITCH,
  controlsToAdd,
  markers = [] as MarkerProps[],
  sx,
  overlay,
  isDrawAllowed = false,
  defaultGeoFences = [],
  onDrawCreateCallBack,
  onDrawDeleteCallBack,
  onDrawUpdateCallBack,
  isDrawPolygonControlEnabled = true,
  isTrashDrawControlEnabled = true,
  imagesToAdd,
  mapLoadCallBack,
  maxZoom = DEFAULT_MAX_ZOOM,
  minZoom = DEFAULT_MIN_ZOOM,
  PopUpComponent,
}: BaseMapProps) => {
  const geofences = useMemo(() => {
    return defaultGeoFences || [];
  }, [defaultGeoFences]);
  const [drawInstance, setDrawInstance] = useState<MapboxDraw>();
  const [popupLngLat, setPopupLngLat] = useState<LngLatLike>();
  const [selectedFeature, setSelectedFeature] = useState<GeoJSON.Feature>();
  const [NBmap, setNBMap] = useState<maplibregl.Map>();
  const [mapStyle, setMapStyle] = useState<MapType>(MapTypes.STREET);
  const [styleChanged, setStyleChanged] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const memoizedContainerId = useMemo(() => containerId, [containerId]);
  const containerRef = React.useRef<HTMLDivElement>(null);
  let nbmap: NBMap;

  // Load any new sources that have been added to the map
  useEffect(() => {
    if (!sources || !NBmap) return;

    addSources(NBmap);
  }, [sources, NBmap]);

  useEffect(() => {
    if (!nbmap || !nbmap.map) return;

    nbmap.map.setCenter(center);
  }, [sources]);

  useEffect(() => {
    if (!isDrawAllowed) return;

    togglePolygonDrawControlVisibility(isDrawPolygonControlEnabled);
  }, [isDrawPolygonControlEnabled, isTrashDrawControlEnabled, drawInstance]);

  // When the map style changes, and the map is fully loaded, all of the sources and layers gets deleted
  // So after that style load has happened we need to reapply all of the sources and layers again
  useEffect(() => {
    if (NBmap && styleChanged && mapLoaded) {
      addImages(NBmap);
      addSources(NBmap);
      drawLayers(NBmap);
      setMapLoaded(false);
    }
    setStyleChanged(false);
  }, [styleChanged, mapLoaded]);

  /**
   * This effect will run once when the component mounts, and will create the map instance.
   * It will also add the sources and layers to the map instance.
   */
  useEffect(() => {
    if (containerRef?.current) {
      // Clear the container before creating a new map instance
      containerRef.current.innerHTML = '';
    }

    nbmap = new NBMap({
      container: memoizedContainerId,
      style: mapStyle.url,
      zoom: zoom,
      center: center || null,
      pitch: pitch,
      pitchWithRotate: false, // Prevent the map from tilting when rotating
      bearing: bearing,
      minZoom: minZoom,
      maxZoom: maxZoom,
      trackResize: true,
    });

    let Draw: MapboxDraw;
    if (isDrawAllowed) {
      Draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: isDrawAllowed,
          trash: isTrashDrawControlEnabled,
        },
      });
    }
    //Fired after the last frame rendered before the map enters an "idle" state
    nbmap.map.on('idle', function () {
      setMapLoaded(true);
      mapLoadCallBack?.(nbmap);
    });

    nbmap.map.on('load', function () {
      addImages();
      addControls({
        controlsToAdd,
        mapInstance: nbmap,
      });
      addSources();
      addMarkers();
      drawLayers();
      fitToBounds();
      addInteractions();

      if (isDrawAllowed) {
        setDrawInstance(() => Draw);
        nbmap.map.addControl(Draw as unknown as IControl, 'top-left');
        nbmap.map.on('draw.create', onDrawCreateCallBack as Listener);
        nbmap.map.on('draw.delete', onDrawDeleteCallBack as Listener);
        nbmap.map.on('draw.update', onDrawUpdateCallBack as Listener);
        if (geofences) {
          addGeoFencesFences(geofences, Draw as MapboxDraw);
        }
      }
      setNBMap(nbmap.map);
      mapLoadCallBack?.(nbmap);
    });

    // This will be called when the map style changes, and will alert the useEffect bound
    // To styleChanged to reapply the sources and layers to the map instance
    nbmap.map.on('style.load', async () => {
      // The mapbox style.load event only fires once the first source has started to render from the style
      setStyleChanged(true);
    });
    // Todo: optimize rendering flow to prevent re-rendering
    // Todo: make maker as a layer/source and make that draggable:
    //  https://docs.mapbox.com/mapbox-gl-js/example/drag-a-point/
  }, [memoizedContainerId]);

  //The only way do control draw controls visibility
  const togglePolygonDrawControlVisibility = (isVisible: boolean) => {
    const container = containerRef?.current;
    if (!container) return;

    const polygonButton: HTMLElement | null = container.querySelector(
      '.mapbox-gl-draw_polygon',
    );
    if (!polygonButton) return;

    polygonButton.style.display = isVisible ? 'block' : 'none';
  };

  const addControls = ({
    controlsToAdd,
    mapInstance,
  }: {
    controlsToAdd: ControlKey[];
    mapInstance: NBMap;
  }) => {
    controlsToAdd.forEach((controlKey) => {
      const controlFactory = controls[controlKey as ControlKey];
      if (controlFactory) {
        const control = controlFactory();
        mapInstance.map.addControl(control, 'top-right');
      }
    });

    // Add custom controls
    const mapStylesControl = new MapStylesControl(setMapStyle);
    mapInstance.map.addControl(mapStylesControl, 'bottom-left');
  };

  const addMarkers = () => {
    const validMarkers = markers.filter(
      (item) =>
        item &&
        Object.hasOwn(item, 'lat') &&
        Object.hasOwn(item, 'lng') &&
        validateLatLon({ latitude: item.lat, longitude: item.lng }),
    );

    validMarkers.forEach((item) => {
      let zoomLevel = nbmap.map.getZoom();

      // Warning: USE THIS APPROACH FOR SMALL AMOUNT OF MARKERS due to performance issues
      const marker = new Marker({
        draggable: item.draggable,
      })
        .setLngLat({ lat: item.lat, lng: item.lng })
        .addTo(nbmap.map);

      if (item.draggable && item?.onDragMarker) {
        marker.on('dragstart', () => {
          zoomLevel = nbmap.map.getZoom();
        });
        marker.on('dragend', () => {
          item?.onDragMarker && item.onDragMarker(marker.getLngLat());
          setTimeout(() => {
            nbmap.map.setZoom(zoomLevel);
          }, 1000);
        });
      }

      // More performant approach:
      // New DraggableMarker(item.id, item.lat, item.lng, nbmap.map, item.onDragMarker);
    });
  };

  const addGeoFencesFences = (geofenceItems: Polygon[], Draw: MapboxDraw) => {
    if (!geofenceItems?.length && !drawInstance) return;

    geofenceItems?.map?.((geofenceItem) => {
      Draw && Draw.add(geofenceItem);
    });
  };

  const addInteractions = () => {
    const popupOffsets = {
      top: [0, 0] as PointLike,
      'top-left': [0, 0] as PointLike,
    } as Offset;

    const popUp = new nextbillion.maps.Popup({ offset: popupOffsets });

    clickable.forEach((layerId) => {
      nbmap.map.on('click', layerId, (mouseClickEvent: MapLayerMouseEvent) => {
        if (!mouseClickEvent.features) return;
        const coordinates = mouseClickEvent.lngLat.wrap();
        const description = mouseClickEvent.features?.[0]?.properties?.description || '';

        setPopupLngLat(coordinates);
        setSelectedFeature(mouseClickEvent.features[0]);

        !isPopUpCustom &&
          popUp.setLngLat(coordinates).setHTML(description).addTo(nbmap.map);
      });
    });
  };

  /**
   * Adds source data to the map instance, which defines the data for the layer will be.
   *
   * @param mapInstance - The map instance to add sources to.
   */
  const addSources = (mapInstance = nbmap.map) => {
    sources.map((singleSource) => {
      if (!mapInstance.getSource(singleSource?.id)) {
        mapInstance.addSource(singleSource.id, singleSource.sourceData);
      } else {
        // @ts-ignore, source can be various types, but we only use the types that have setData method
        mapInstance.getSource(singleSource.id).setData(singleSource.sourceData.data);
      }
    });
  };

  const addImages = (mapInstance = nbmap.map) => {
    if (imagesToAdd?.length) {
      imagesToAdd.forEach((imageOne) => {
        if (!mapInstance.hasImage(imageOne.name)) {
          mapInstance.loadImage(imageOne.path, (error: any, loadedImage: any) => {
            if (error) throw error;
            // Add the image to the map style.
            mapInstance.addImage(
              imageOne.name,
              loadedImage,
              // { sdf: true } //SDF format icons, uncomment i f will be required
            );
          });
        }
      });
    }
  };

  /**
   * Sets the layer data on the map instance, which defines how the items will be drawn.
   *
   * @param {maplibregl.Map} mapInstance - The map instance on which the layers will be drawn.
   */
  const drawLayers = (mapInstance = nbmap.map) => {
    layers.map((singleLayer) => {
      if (!mapInstance.getLayer(singleLayer.id)) {
        mapInstance.addLayer(singleLayer);
      }
    });
  };

  const fitToBounds = () => {
    // Check that bounds are defined and not empty before fitting the map
    if (bounds && Object.keys(bounds).length > 0) {
      nbmap.map.fitBounds(bounds, {
        padding: 25,
        duration: 0,
      });
    }
  };

  const onPopupClose = () => {
    setPopupLngLat(undefined);
    setSelectedFeature(undefined as unknown as GeoJSON.Feature);
  };

  return (
    <Box sx={{ ...sx, position: 'relative' }}>
      {isPopUpCustom && popupLngLat && selectedFeature && NBmap && (
        // @ts-ignore
        <PopUpComponent
          map={NBmap}
          lngLat={popupLngLat}
          feature={selectedFeature}
          onClose={onPopupClose}
        ></PopUpComponent>
      )}
      <Box
        sx={{ width: '100%', height: '100%', ...sx }}
        id={memoizedContainerId}
        ref={containerRef}
      />
      {overlay && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255,255,255,0.6)',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {overlay}
        </Box>
      )}
    </Box>
  );
};

export { BaseMap };
