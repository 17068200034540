import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { SearchParams } from '~constants/enums';
import { useEquipment } from '~hooks/useEquipment';
import { useStores } from '~store';

import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const TruckSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { getEquipmentById, getEquipmentByCompanyIdTypeahead } = useEquipment();
  const { userStore } = useStores();

  const fetchEquipmentByCompanyId = async (additionalProps = { shared: true }) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const equipment = await getEquipmentByCompanyIdTypeahead({
      companyId: company.id,
      ...additionalProps,
    });

    return equipment;
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncGet={getEquipmentById}
          asyncCallback={fetchEquipmentByCompanyId}
          fieldName={SearchParams.TRUCK}
          label={$t('form_fields.truck')}
          defaultValues={[]}
          allSearchParams={allSearchParams}
          sx={{
            ...sx,
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.name || ''}
        />
      </Box>
    </>
  );
});

export { TruckSelector };
