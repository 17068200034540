import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { useStores } from '~store';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../../NewDispatch';
import DateFilter from '../filters/CalendarDateFilter';
import CalendarFilters from '../filters/CalendarFilters';
import { SmallButton } from './../ordersDispatchStyledComponents';
import { SearchBar } from './SearchBar';

export const ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS = 500;

interface OrdersDispatchFiltersBarProps {
  onNewOrderClick: () => void;
  sx?: SxProps;
}

const CalendarDispatchFiltersBar = observer(
  ({ onNewOrderClick }: OrdersDispatchFiltersBarProps) => {
    const { calendarDispatchStore } = useStores();

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
        {!calendarDispatchStore.filters.searchOrder && (
          <Box display="flex" gap={1} alignItems="center">
            <DateFilter />
            <CalendarFilters />
          </Box>
        )}

        <Box display="flex" gap={1} ml="auto">
          <SearchBar
            placeHolder="Search orders"
            setFilter={(value) => {
              calendarDispatchStore.setFilters({ searchOrder: value }, true);
            }}
          />
          <CreateOrderButton onClick={onNewOrderClick} />
        </Box>
      </Box>
    );
  },
);

interface CreateOrderButtonProps {
  onClick: () => void;
}

function CreateOrderButton({ onClick }: CreateOrderButtonProps) {
  return (
    <Box>
      <SmallButton
        color="brandV2Yellow"
        onClick={onClick}
        sx={{
          '&.MuiButtonBase-root': {
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          },
        }}
      >
        {t('dispatch.dispatch_v2.new_order')}
      </SmallButton>
    </Box>
  );
}

export default CalendarDispatchFiltersBar;
