import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';

import { Company } from '~hooks/useCompany';

export interface FooterProps {
  company: Company;
}

export default function Footer({ company }: FooterProps) {
  const styles = StyleSheet.create({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tocs: {
      borderColor: '#E0E0E0',
      borderStyle: 'solid',
      borderWidth: 2,
      padding: 5,
    },
    tocsText: {
      fontSize: 7,
    },
    bottomLine: {
      marginTop: 5,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    bottomLineContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    bottomLineText: {
      fontSize: 7,
    },
  });

  const email = company.primaryContact?.email;
  const phone = company.primaryContact?.phone;

  return (
    <View style={styles.root}>
      {/* FIXME @todo To be added in a follow-up ticket */}
      {/* <View style={styles.tocs}>
        <Text style={styles.tocsText}>TOCs content goes here</Text>
      </View> */}

      <View style={styles.bottomLine}>
        <View style={styles.bottomLineContainer}>
          {Boolean(email || phone) && (
            <>
              {email && (
                <Text style={[styles.bottomLineText, { marginRight: 10 }]}>{email}</Text>
              )}
              {phone && <Text style={[styles.bottomLineText]}>{phone}</Text>}
            </>
          )}
        </View>

        <Text
          style={styles.bottomLineText}
          render={({ pageNumber, totalPages }) =>
            t('approvals.driver_pay.settlements.pdf.page_num_of_total', {
              num: pageNumber,
              total: totalPages,
            })
          }
        />
      </View>
    </View>
  );
}
