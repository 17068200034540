import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { DesktopDateTimePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React from 'react';
const DateEditCell = (props: GridRenderCellParams<any, any>) => {
  const { id, value, field, row } = props;
  const editable = row?._editable;

  const apiRef = useGridApiContext();

  const handleChange = (newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const saveChanges = () => apiRef.current.stopCellEditMode({ id, field });

  return (
    <ClickAwayListener onClickAway={saveChanges}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 0.25, width: '100%' }}>
        <DesktopDateTimePicker
          value={dayjs.tz(value)}
          disabled={!editable}
          onChange={(value) => handleChange(value)}
          onClose={saveChanges}
          sx={{ width: '100%', px: 0, m: 0, '& .MuiInputBase-input': { px: 0.25 } }}
        />
      </Box>
    </ClickAwayListener>
  );
};

const renderDateEditCell: GridColDef['renderCell'] = (params) => {
  return <DateEditCell {...params} />;
};

export { DateEditCell, renderDateEditCell };
