import { t } from 'i18next';
import { t as $t } from 'i18next';
import * as yup from 'yup';

import { emailRegex, personNameSchema, phoneSchema } from '~constants/regexConst';

export const userEmailSchema = yup
  .string()
  .email(`${t('form_validation_errors.required', { field: t('form_fields.email') })}`)
  .matches(emailRegex, `${t('form_validation_errors.invalid_email')}`);

export const userPhoneSchema = phoneSchema.required(
  `${t('form_validation_errors.required', { field: t('form_fields.phone') })}`,
);

const companySharesSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string(),
      legalName: yup.string(),
    }),
  )
  .notRequired();

export const userFormValidationSchema = yup.object().shape({
  firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
  ),
  lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
  ),
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  email: userEmailSchema,
  userRoles: yup
    .array()
    .min(
      1,
      `${t('form_validation_errors.min_count', {
        field: t('form_fields.role'),
        count: 1,
      })}`,
    )
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.role') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.role') })}`,
    ),
  phone: userPhoneSchema,
  companyShares: companySharesSchema,
  textToAcceptFieldEnabled: yup.boolean(),
  textToAcceptFieldValue: yup.boolean(),
});

export const driverFormValidationSchema = yup.object().shape({
  companyShares: companySharesSchema,
});

export const userWithRequiredEmailValidationSchema = userFormValidationSchema
  .clone()
  .shape({
    email: userEmailSchema.required(
      `${$t('form_validation_errors.required', { field: $t('form_fields.email') })}`,
    ),
    phone: userPhoneSchema.notRequired(),
  });

export const userWithRequiredEmailAndOmitedPhoneValidationSchema =
  userWithRequiredEmailValidationSchema.clone().omit(['phone']);

// OTP_SMS enabled -> either email or phone required
export const userWithEmailOrPhoneValidationSchema = userFormValidationSchema
  .clone()
  .shape({
    email: userEmailSchema
      .transform((val) => {
        if (!val?.length) {
          return undefined;
        }

        return val;
      })
      .notRequired()
      .when('phone', {
        is: (phone: string | undefined) => {
          return Boolean(!phone || phone.length === 0);
        },
        then: () => {
          return userEmailSchema.required(
            `${$t('form_validation_errors.either_one_or_other', { one: $t('form_fields.email'), other: $t('form_fields.phone') })}`,
          );
        },
      }),
    phone: userPhoneSchema
      .transform((val) => {
        if (!val?.length) {
          return undefined;
        }

        return val;
      })
      .notRequired(),
  });

export const userWithEmailAndOmitedPhoneValidationSchema =
  userWithEmailOrPhoneValidationSchema.clone().omit(['phone']);
