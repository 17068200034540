import Box from '@mui/material/Box';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { SmallTabs } from '~components/Tabs/SmallTabs';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';

import TreadLiveMap from '../LiveMap/TreadLiveMap';
import { JobsOrderDataGrid as JobsDataGrid } from './JobsOrderDataGrid';
import { OrderDetails } from './OrderDetails';

interface Props {
  order: Order;
  orderSearchValue?: string;
  searchValue: string;
}
const OrderExpandedBlock = observer(({ order, orderSearchValue, searchValue }: Props) => {
  const { jobStore } = useStores();

  const jobs = useMemo(() => {
    if (order?.id) {
      return jobStore.jobs.filter((row) => row?.order?.id === order.id) || [];
    }
  }, [order?.id, jobStore.jobs]);

  const tabs = [
    {
      label: $t('dispatch.jobs'),
      id: 'jobs',
      content: (
        <JobsDataGrid
          orderId={order.id}
          jobs={jobs}
          orderSearchValue={orderSearchValue}
          searchValue={searchValue}
          isNestedFromOrdersGrid
        />
      ),
      headerClassName: 'jobs-tab',
    },
    {
      label: $t('dispatch.order_details'),
      id: 'details',
      content: <OrderDetails order={order} />,
      headerClassName: 'order-tab',
    },
    {
      label: $t('live_map.live_map'),
      id: 'live_map',
      content: (
        <Box
          sx={{
            background: '#fff',
            my: 0,
            height: 400,
            width: 1280,
          }}
        >
          <TreadLiveMap order={order} />
        </Box>
      ),
      headerClassName: 'live-map-tab',
    },
  ];

  return <SmallTabs sx={{ px: 2, py: 1 }} tabs={tabs} />;
});

export { OrderExpandedBlock };
