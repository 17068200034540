import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import Clear from '@mui/icons-material/Clear';
import ControlPointOutlined from '@mui/icons-material/ControlPointOutlined';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { LoadState, TicketState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useCallback } from 'react';

import { LoadStatus } from '~components/Job';
import { BasicTooltip } from '~components/Tooltip';
import { JobLoad } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets/models';
import { TicketEventType, useTickets } from '~hooks/useTickets/useTickets';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { useStores } from '~store/RootStore';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { dateFormat, minutesToHoursMins } from '~utils/dateTime';
import { canEditTicket } from '~utils/tickets/ticket-utils';

interface Props {
  cycleTime: number;
  deletable: boolean;
  index: number;
  load: JobLoad;
  onClick?: () => void;
  onRemoveLoad: (id: string) => void;
  reload: () => void;
  sx?: SxProps<Theme>;
  ticket: Nullable<Ticket>;
}
const LoadCard = ({
  load,
  ticket,
  cycleTime,
  index,
  deletable,
  onRemoveLoad,
  onClick,
  reload,
  sx = {},
}: Props) => {
  const theme = useTheme();

  const { toasterStore } = useStores();
  const { updateState } = useTickets();

  // The load data currently does not display the dropoff and pickup arrival times
  // Once this is added, we can uncomment out the below

  // Const [loadData, setLoadData] = React.useState<JobLoad | null>(null);
  // Const { getLoadByLoadId } = useJob();
  // UseEffect(() => {
  //   If (!load.id) return;
  //
  //   GetLoadByLoadId({ loadId: load.id, callback: setLoadData });
  // }, [load.id]);

  const styles = {
    background: '#fff',
  };

  const gridStyles = {
    display: 'grid',
    flexDirection: 'column',
    alignContent: 'center',
    py: 0.75,
    gridTemplateRows: '1fr 1fr',
  };
  const hasCycleTime = cycleTime > 0;
  const hasTicket = !!ticket;
  const hasAttachment = !!ticket?.imageUrl;
  const ticketIsMissingData =
    !ticket?.ticketNumber ||
    !ticket?.material?.name ||
    !ticket?.quantity ||
    !ticket?.unitOfMeasure?.name ||
    !ticket?.imageUrl;
  const formattedTicketTime = ticket?.createdAt
    ? dateFormat(new Date(ticket.createdAt), 'h:mm A')
    : '-';
  const formattedTicketQuantity =
    ticket?.quantity && ticket?.unitOfMeasure?.name
      ? `${ticket.quantity} ${ticket.unitOfMeasure.name}`
      : '-';
  const ticketIsFlagged = ticket?.flagged || false;
  const ticketIsApproved = ticket?.state === TicketState.APPROVED;
  const ticketIsApprovable = ticket && canEditTicket(ticket.state as TicketState);
  const ticketApprovalAreaRendered = ticketIsApprovable || ticketIsApproved;

  let ticketMetadataText: string;

  if (formattedTicketTime !== '-' && formattedTicketQuantity !== '-') {
    ticketMetadataText = `${formattedTicketQuantity} • ${formattedTicketTime}`;
  } else if (formattedTicketTime !== '-') {
    ticketMetadataText = formattedTicketTime;
  } else if (formattedTicketQuantity !== '-') {
    ticketMetadataText = formattedTicketQuantity;
  } else {
    ticketMetadataText = '-';
  }

  const handleRemoveLoad = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      onRemoveLoad(load.id);
    },
    [onRemoveLoad],
  );

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    reload();
    return res;
  };

  return (
    <>
      <Grid
        aria-label={`load job ${load.id}`}
        container
        columnSpacing={1}
        rowSpacing={0}
        alignItems={'center'}
        sx={{
          flexWrap: 'nowrap',
          cursor: 'pointer',
          borderBottom: `1px solid ${theme.brandV2.colors.treadGray6}`,
          position: 'relative',
          ...styles,
          ...sx,
        }}
        onClick={onClick}
      >
        {/* Load number */}
        <Grid item xs={1} sx={{ ...gridStyles }}>
          <Typography sx={{ pl: 1, gridRow: 'span 2' }}>{index + 1}</Typography>
          {ticketIsFlagged && (
            <Box pl={0.5}>
              <BasicTooltip title={t('loads.ticket_flagged')}>
                <FlagOutlined
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGray2,
                  }}
                  aria-label={t('aria_label.ticket.flagged')}
                />
              </BasicTooltip>
            </Box>
          )}
        </Grid>

        {/* Status and cycle */}
        <Grid item xs={2} sx={{ ...gridStyles }}>
          <Box>
            <LoadStatus status={load.status} />
          </Box>
          <Typography variant={'body2'} mt={0.5}>
            {hasCycleTime ? minutesToHoursMins(cycleTime) : '-'}
          </Typography>
        </Grid>

        {/* Attachments and alerts */}
        <Grid item xs={1} sx={{ ...gridStyles }}>
          <Box>
            {hasAttachment && (
              <BasicTooltip title={t('loads.ticket_attached')}>
                <InsertDriveFileOutlined
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGray2,
                  }}
                  aria-label={t('aria_label.ticket.has_attachment')}
                />
              </BasicTooltip>
            )}
            {ticketIsApproved && (
              <BasicTooltip title={t('loads.ticket_approved')}>
                <CheckCircleOutlineOutlined
                  sx={{
                    display: 'inline-block',
                    ml: 0.5,
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGreen,
                  }}
                  aria-label={t('aria_label.ticket.approved')}
                />
              </BasicTooltip>
            )}

            {hasTicket && ticketIsMissingData && (
              <BasicTooltip title={t('loads.missing_info')}>
                <WarningAmber
                  sx={{
                    display: 'inline-block',
                    ml: 0.5,
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadOrangeDark,
                  }}
                  aria-label={t('aria_label.ticket.missing_info')}
                />
              </BasicTooltip>
            )}
          </Box>
        </Grid>

        {/* Ticket */}
        <Grid item xs={3} sx={{ ...gridStyles, whiteSpace: 'nowrap' }}>
          {ticket ? (
            <Typography variant={'body2'}>{ticket.ticketNumber || '-'} </Typography>
          ) : (
            <Typography
              variant={'body2'}
              sx={{ color: theme.brandV2.colors.treadOrangeDark }}
              display={'flex'}
              alignItems={'center'}
            >
              <ControlPointOutlined sx={{ mr: 0.5, width: '16px', height: '16px;' }} />
              {t('loads.add_ticket')}
            </Typography>
          )}
          <Typography variant={'body2'} mt={0.5}>
            {ticketMetadataText}
          </Typography>
        </Grid>

        {ticketApprovalAreaRendered ? (
          <Grid
            item
            xs={3.5}
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: 0.5,
              marginLeft: 'auto',
              justifyContent: 'end',
              mr: 1,
              color: ticketIsApproved ? theme.brandV2.colors.treadGreen : null,
            }}
          >
            {ticketIsApprovable ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  doAction(ticket.id, 'approve');
                }}
              >
                {t('approvals.approve')}
              </Button>
            ) : (
              <>
                <BasicTooltip title={t('loads.ticket_approved')}>
                  <CheckCircleOutlineOutlined
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                </BasicTooltip>
                <Typography variant="body2">{t('status.approved')}</Typography>
              </>
            )}
          </Grid>
        ) : null}

        {/* Remove row */}
        {deletable && [LoadState.CREATED].includes(load?.status) && (
          <Grid
            item
            xs={0.5}
            sx={{
              display: 'grid',
              ml: ticketApprovalAreaRendered ? 0 : 'auto',
              mr: 3,
            }}
          >
            <RemoveItemButton
              action={handleRemoveLoad}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
              icon={Clear}
              iconStyles={{
                color: theme.brandV2.colors.treadGray3,
                width: '16px',
                height: '16px',
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { LoadCard };
