import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  SettlementLineItemGrouping,
  SettlementLineItemType,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog';
import { CheckBoxFormField, RadioGroupFormField } from '~components/FormFields';
import { Settlement } from '~hooks/useSettlements';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { isActionClicked } from '~utils/utilFunctions';

interface DialogState {
  isBusy: boolean;
  isOpen: boolean;
  settlement?: Settlement;
}

export function useSettlementForm() {
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>({
    isBusy: false,
    isOpen: false,
  });

  const updateSelectedInvoices = useCallback((invoiceIds: string[]) => {
    setSelectedInvoices(invoiceIds);
  }, []);

  const openDialog = useCallback((settlement?: Settlement) => {
    setDialogState({ isOpen: true, isBusy: false, settlement });
  }, []);

  const closeDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, isOpen: false, isBusy: false }));
  }, []);

  const updateBusyState = useCallback((isBusy: boolean) => {
    setDialogState((state) => ({ ...state, isBusy }));
  }, []);

  const dialogHandler = useMemo(() => {
    return { open: openDialog, close: closeDialog, updateBusyState, ...dialogState };
  }, [openDialog, closeDialog, updateBusyState, dialogState.isBusy, dialogState.isOpen]);

  return { selectedInvoices, updateSelectedInvoices, dialogHandler };
}

interface SettlementFormProps {
  invoiceIds?: string[];
  isBusy: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  settlement?: Settlement;
}

export type SettlementFormHandler = {
  onSubmit?: (callback: (args: SettlementDTO) => void) => void;
};

export const SettlementForm = forwardRef<SettlementFormHandler, SettlementFormProps>(
  function SettlementForm(
    { invoiceIds, isBusy, isOpen, onClose, onSubmit, settlement },
    ref,
  ) {
    const isEditing = Boolean(settlement?.id);

    return (
      <Dialog
        open={isOpen}
        onClose={(__: never, reason: DialogCloseReasonType) => {
          if (isActionClicked(reason)) {
            onClose();
          }
        }}
      >
        <DialogHeader
          closeCallBack={onClose}
          title={
            <Typography component="span" variant="h5">
              {isEditing
                ? t('approvals.driver_pay.update_settlement')
                : t('approvals.driver_pay.create_settlement')}
            </Typography>
          }
        />

        <DialogContent>
          <Box sx={{ pt: 2.5 }}>
            <Form ref={ref} invoiceIds={invoiceIds} settlement={settlement} />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            color="primary"
            disabled={isBusy}
            loading={isBusy}
            loadingPosition="start"
            onClick={onSubmit}
            startIcon={<></>}
            sx={isBusy ? { pl: 5, pr: 2 } : { pr: 2 }}
            type="button"
            variant="contained"
          >
            {isEditing ? t('actions.update') : t('actions.create')}
          </LoadingButton>

          <Button
            color="secondary"
            disabled={isBusy}
            onClick={onClose}
            sx={{ mr: 2, px: 2 }}
            variant="outlined"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

// FIXME @todo leaving out "ORDER" line item type until we have fully sorted out
const settlementLineItemTypeList = [
  SettlementLineItemType.JOB,
  SettlementLineItemType.LOAD,
];
// Const settlementLineItemTypeList = Object.values(SettlementLineItemType);
const settlementLineItemGroupingList = Object.values(SettlementLineItemGrouping);
const setGroupingsFormFieldDefaultValue = (list: SettlementLineItemGrouping[] = []) => {
  return settlementLineItemGroupingList.reduce(
    (obj, key) => {
      obj[key] = list.includes(key);

      return obj;
    },
    {} as Record<SettlementLineItemGrouping, boolean>,
  );
};

const settlementSchema = yup.object().shape({
  id: yup.string().trim().uuid().notRequired(),
  settlementLineItemType: yup
    .mixed<SettlementLineItemType>()
    .oneOf(settlementLineItemTypeList)
    .required(`${t('form_validation_errors.required_simple')}`),
  settlementLineItemGroupings: yup
    .object()
    .shape({
      [SettlementLineItemGrouping.EQUIPMENT_TYPE]: yup.boolean(),
      [SettlementLineItemGrouping.MATERIAL]: yup.boolean(),
      [SettlementLineItemGrouping.SERVICE]: yup.boolean(),
    })
    .notRequired(),
  includeScaleTickets: yup.boolean(),
  invoiceIds: yup
    .array()
    .of(yup.string().trim().min(1).required())
    .when('id', {
      is: (id: Nullable<string>) => _.isNil(id),
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const setSettlementSchemaDefaultValues = (
  invoiceIds?: string[],
  settlement?: Settlement,
) => {
  return {
    id: settlement?.id ?? null,
    settlementLineItemType: settlement?.settlementLineItemType ?? null,
    settlementLineItemGroupings: setGroupingsFormFieldDefaultValue(
      settlement?.settlementLineItemGroupings,
    ),
    includeScaleTickets: false,
    invoiceIds: invoiceIds ?? null,
  } as unknown as SettlementDTO;
};

export type SettlementDTO = yup.InferType<typeof settlementSchema>;

type FormProps = Pick<SettlementFormProps, 'invoiceIds' | 'settlement'>;

const Form = forwardRef<SettlementFormHandler, FormProps>(function Form(
  { invoiceIds, settlement },
  ref,
) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SettlementDTO>({
    resolver: yupResolver(settlementSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setSettlementSchemaDefaultValues(invoiceIds, settlement),
  });

  useImperativeHandle(
    ref,
    () => ({
      onSubmit(callback) {
        handleSubmit(callback)();
      },
    }),
    [],
  );

  return (
    <Box flex={1} display="flex" flexDirection="column" gap={3}>
      <Typography variant="h6">
        {t('approvals.driver_pay.settlements.configure_settlement')}
      </Typography>

      <Box>
        <Typography variant="body1" fontWeight={600}>
          {t('approvals.driver_pay.settlements.line_items')}
        </Typography>

        <Typography variant="body1" color="text.secondary" mt={0.5}>
          {t('approvals.driver_pay.settlements.line_items_to_include')}
        </Typography>

        <RadioGroupFormField
          control={control}
          errors={errors}
          list={settlementLineItemTypeList}
          name="settlementLineItemType"
          radioGroupSx={{
            flexDirection: 'column',
            '& .MuiRadio-root': { py: 0.5 },
          }}
          sx={{ mt: 2 }}
        />
      </Box>

      <Divider />

      <Box>
        <Typography variant="body1" fontWeight={600}>
          {t('approvals.driver_pay.settlements.groupings')}
        </Typography>

        <Typography variant="body1" color="text.secondary" mt={0.5}>
          {t('approvals.driver_pay.settlements.group_line_items_by')}
        </Typography>

        <Box display="flex" flexDirection="column" mt={2}>
          {settlementLineItemGroupingList.map((value) => (
            <CheckBoxFormField
              key={value}
              control={control}
              errors={errors}
              label={`${t(`approvals.driver_pay.settlements.grouping.${value}`)}`}
              name={`settlementLineItemGroupings.${value}`}
              value={false}
              checkboxSx={{ '&.MuiCheckbox-root': { py: 0.5 } }}
            />
          ))}
        </Box>
      </Box>

      <Divider />

      <Box>
        <CheckBoxFormField
          checkboxSx={{ '&.MuiCheckbox-root': { py: 0.5 } }}
          control={control}
          errors={errors}
          label={`${t('approvals.driver_pay.settlements.include_scale_tickets')}`}
          name="includeScaleTickets"
          value={false}
        />
      </Box>
    </Box>
  );
});
