import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Mail from '@mui/icons-material/Mail';
import Pause from '@mui/icons-material/Pause';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { JobEventName } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';

import { useJob } from '~hooks/useJob';
import { LoadingSpinner } from '~pages/Dispatch/components/ordersDispatchStyledComponents';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { createdBy } from './utils';

interface JobEventsProps {
  jobId: string;
}

const commonIconStyle = {
  width: 20,
  height: 20,
};

const checkIcon = (
  <Check
    style={{
      ...commonIconStyle,
      color: theme.brandV2.colors.treadGreen,
    }}
  />
);

const closeIcon = (
  <Close
    style={{
      ...commonIconStyle,
      color: theme.palette.error.main,
    }}
  />
);

const pauseIcon = (
  <Pause
    style={{
      ...commonIconStyle,
      color: theme.palette.info.main,
    }}
  />
);

const mailIcon = (
  <Mail
    style={{
      ...commonIconStyle,
      color: theme.palette.primary.main,
    }}
  />
);

const jobEventMap: Partial<Record<JobEventName, { title: string; icon: ReactNode }>> = {
  [JobEventName.JOB_CREATED]: {
    title: $t('job_events.created'),
    icon: checkIcon,
  },
  [JobEventName.JOB_ASSIGNED]: {
    title: $t('job_events.assigned'),
    icon: checkIcon,
  },
  [JobEventName.JOB_UNASSIGNED]: {
    title: $t('job_events.unassigned'),
    icon: closeIcon,
  },
  [JobEventName.JOB_SENT]: {
    title: $t('job_events.sent'),
    icon: checkIcon,
  },
  [JobEventName.JOB_ACCEPTED]: {
    title: $t('job_events.accepted'),
    icon: checkIcon,
  },
  [JobEventName.JOB_DECLINED]: {
    title: $t('job_events.declined'),
    icon: closeIcon,
  },
  [JobEventName.JOB_CANCELED]: {
    title: $t('job_events.canceled'),
    icon: closeIcon,
  },
  [JobEventName.JOB_PAUSED]: {
    title: $t('job_events.paused'),
    icon: pauseIcon,
  },
  [JobEventName.JOB_RESUMED]: {
    title: $t('job_events.resumed'),
    icon: checkIcon,
  },
  [JobEventName.JOB_IN_REVIEW]: {
    title: $t('job_events.in_review'),
    icon: mailIcon,
  },
  [JobEventName.JOB_COMPLETED]: {
    title: $t('job_events.completed'),
    icon: checkIcon,
  },
};

export const JobAuditLog = observer(({ jobId }: JobEventsProps) => {
  const { getJobTripEvents } = useJob();
  const { jobStore } = useStores();
  useEffect(() => {
    if (!jobStore.tripEvents[jobId]) {
      getJobTripEvents({ id: jobId });
    }
  }, [jobStore.tripEvents[jobId], jobId]);

  const tripEvents = jobStore.tripEvents[jobId];

  const allowableJobEventStates = [
    JobEventName.JOB_CREATED,
    JobEventName.JOB_ASSIGNED,
    JobEventName.JOB_UNASSIGNED,
    JobEventName.JOB_SENT,
    JobEventName.JOB_ACCEPTED,
    JobEventName.JOB_DECLINED,
    JobEventName.JOB_CANCELED,
    JobEventName.JOB_PAUSED,
    JobEventName.JOB_RESUMED,
    JobEventName.JOB_IN_REVIEW,
    JobEventName.JOB_COMPLETED,
  ];

  const filteredJobEvents = tripEvents?.filter((event) => {
    return allowableJobEventStates.includes(event.eventName);
  });

  return (
    <>
      {filteredJobEvents ? (
        filteredJobEvents.map((event) => {
          const eventStateKey = event.eventName as JobEventName;
          const mappedState = jobEventMap[eventStateKey];

          return (
            <Box key={event.id} display={'flex'}>
              {mappedState?.icon}
              <Typography variant={'body1'} mb={1.5} ml={1}>
                <Typography
                  component={'span'}
                  sx={{ fontWeight: 600, color: theme.brandV2.colors.treadBlack }}
                >
                  {mappedState?.title || event.eventName}
                </Typography>{' '}
                <Typography
                  component={'span'}
                  variant={'body2'}
                  sx={{ color: theme.brandV2.colors.treadGray3 }}
                >
                  {dayjs(event.createdAt).format(`h:mma MMM D, 'YY`)}
                </Typography>
                <Typography
                  component={'span'}
                  variant={'body2'}
                  ml={0.5}
                  sx={{ color: theme.brandV2.colors.treadGray3 }}
                >
                  {event.createdBy ? `• ${createdBy(event.createdBy)}` : null}
                </Typography>
              </Typography>
            </Box>
          );
        })
      ) : (
        <LoadingSpinner isVisible />
      )}
    </>
  );
});
