import { JobState } from '@treadinc/horizon-api-spec';

export const pendingJobStates = [JobState.CREATED, JobState.REJECTED];

export const inProgressJobStates = [
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.LOAD_COMPLETED,
];

export const assignedJobStates = [JobState.ASSIGNED];

export const sentJobStates = [JobState.SENT];

export const acceptedJobStates = [JobState.ACCEPTED];

export const rejectedJobStates = [JobState.REJECTED];

export const activeJobStates = [
  ...new Set([
    ...pendingJobStates,
    ...inProgressJobStates,
    ...assignedJobStates,
    ...sentJobStates,
    ...acceptedJobStates,
  ]),
];

export const loadingJobStates = [
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
];

export const droppingJobStates = [
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.LOAD_COMPLETED,
];

export const doneJobStates = [
  JobState.COMPLETED,
  JobState.FLAGGED,
  JobState.SIGNED_OFF,
  JobState.PAID,
  JobState.CANCELED,
  JobState.IN_REVIEW,
];

export const editableJobStates = [JobState.CREATED, JobState.ACCEPTED, JobState.REJECTED];
export const nonEditableJobStates = [
  JobState.REASSIGN,
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.COMPLETED,
  JobState.CANCELED,
  JobState.SENT,
  JobState.IN_REVIEW,
  JobState.FLAGGED,
  JobState.SIGNED_OFF,
  JobState.PAID,
  JobState.LOAD_COMPLETED,
];

export const allJobStates = [...editableJobStates, ...nonEditableJobStates];
