interface BaseAlert {
  message: string;
  display: boolean;
}

export interface Success extends BaseAlert {
  kind: 'success';
}

export interface Info extends BaseAlert {
  kind: 'info';
}

export interface Warn extends BaseAlert {
  kind: 'warn';
}

export interface Error extends BaseAlert {
  kind: 'error';
}

export type Alert = Success | Info | Warn | Error;
export const AlertTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
  warn: 'warn',
} as const;
export const alert = (message: string | React.ReactNode, kind: Alert['kind']): Alert =>
  ({
    message: message,
    kind,
    display: true,
  }) as Alert;
