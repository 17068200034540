import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import circle from '@turf/circle';
import { convertLength, Polygon, polygon } from '@turf/helpers';
import { t as $t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { v4 as UUID } from 'uuid';

import { AnimateComponentPresence } from '~components/AnimatePresence';
import { AutocompleteFormField, TextFormField } from '~components/FormFields';
import { PseudoLink } from '~components/Helpers';
import {
  geoFenceCircleOption,
  geofenceEquipmentOption,
  geofenceOptions,
  geofencePolygonOption,
  GeoFenceTypes,
  movingGeoFencesTagsOptions,
} from '~constants/enums';
import { useEquipment } from '~hooks/useEquipment';
import { Geofence, Site, useSites } from '~hooks/useSites';
import EditPolygon from '~icons/EditPolygon';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { geofenceOptionsWithNone } from '~pages/Settings/Administration';
import { useStores } from '~store';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { radiusInProperUnits } from '~utils/sites';

import { options, radius } from './SiteForm';

export interface AdditionalGeoFenceField {
  id: string;
  radius: number;
  tag: ItemNameAndId;
  type: ItemNameAndId;
}

export interface AdditionalGeoFencePolygon extends Polygon {
  id: string;
  circleCenter?: {
    lat: number;
    lon: number;
  };
}
export interface AdditionalGeoFenceDetails
  extends Omit<AdditionalGeoFencePolygon, 'type'> {
  tag: ItemNameAndId;
  type: ItemNameAndId;
  name: string;
  radius: number;
  isFeet: boolean;
}
export interface SiteWithAdditionalGeoFences extends Site {
  additionalGeoFencesDetails?: AdditionalGeoFenceDetails[];
}
export const GeofenceForm = ({
  companyId,
  activePolygonKey,
  setActivePolygonKey,
  deleteGeofence,
  additionalGeoFencesPolygons,
  setAdditionalGeoFencesPolygons,
  defaultSiteId,
  defaultPosition,
  refreshMarker,
}: {
  companyId: string;
  activePolygonKey: string | null;
  setActivePolygonKey: (value: string | null) => void;
  deleteGeofence: (index: string) => void;
  additionalGeoFencesPolygons: AdditionalGeoFencePolygon[];
  setAdditionalGeoFencesPolygons: (value: AdditionalGeoFencePolygon[]) => void;
  defaultSiteId: string | undefined;
  defaultPosition: { lat: number; lng: number };
  refreshMarker: () => void;
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useFormContext();
  const { getAdditionalGeoFencesForSite } = useSites();
  const { getEquipmentByCompanyId, isLoading } = useEquipment();
  const { companyAssetsStore, userStore } = useStores();
  const defaultAdditionalGeoFences = companyAssetsStore.activeSiteAdditionalGeoFences;
  const [showAlert, setShowAlert] = useState(false);
  const [newPrimaryType, setNewPrimaryType] = useState('');
  const [tags, setTags] = useState(movingGeoFencesTagsOptions);
  const watchedGeofenceType = watch('geofenceType');
  const coords = watch('latLon');
  const watchedLat = coords.split(',')?.[0]?.trim() || '';
  const watchedLon = coords?.split(',')?.[1]?.trim() || '';
  const equipmentOptions = companyAssetsStore.equipment;
  const isCircleGeofence = watchedGeofenceType?.id === GeoFenceTypes.CIRCLE;
  const isPolygonGeoFence = watchedGeofenceType?.id === GeoFenceTypes.POLYGON;
  const isMovingGeoFence = watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT;
  const isNone = watchedGeofenceType?.id === 'none';
  const isFeet = userStore?.userCompany.isFeet || false;
  const theme = useTheme();

  const {
    fields: additionalGeoFencesFields,
    append: appendGeofence,
    remove: removeGeofence,
  } = useFieldArray({
    control,
    name: 'additionalGeoFences',
  });
  const watchedAdditionalGeoFences = useWatch({ name: 'additionalGeoFences', control });

  const isAddAdditionalGeoFencesDisabled = useMemo(() => {
    if (isMovingGeoFence) {
      return additionalGeoFencesFields.length === 1 || !coords;
    } else {
      return additionalGeoFencesFields.length === 5 || !coords;
    }
  }, [additionalGeoFencesFields.length, watchedGeofenceType?.id, coords]);

  // Set additional geoFences polygons from the fields
  const setAdditionalGeoFencesPolygonsFromFields = () => {
    const polygons: AdditionalGeoFencePolygon[] = defaultAdditionalGeoFences.map(
      (site) => {
        const isPolygon = site.type === GeoFenceTypes.POLYGON;

        if (isPolygon) {
          return polygon(
            site.geojson?.coordinates ?? [],
            {},

            { id: site.id },
          ) as unknown as AdditionalGeoFencePolygon;
        } else {
          const center = [
            Number(watchedLon) || defaultPosition.lng,
            Number(watchedLat) || defaultPosition.lat,
          ];
          const appliedRadius = isFeet
            ? convertLength(Number(site.circleRadius), 'feet', 'meters')
            : site.circleRadius;

          const geofenceDefault = circle(center, appliedRadius, {
            ...options,
            units: 'meters',
          });
          geofenceDefault.id = site.id;

          return {
            ...(geofenceDefault as unknown as AdditionalGeoFencePolygon),
            circleCenter: {
              lat: center[1],
              lon: center[0],
            },
          };
        }
      },
    );
    setAdditionalGeoFencesPolygons(polygons);
    refreshMarker();
  };

  useEffect(() => {
    if (!isDirty) {
      setAdditionalGeoFencesPolygonsFromFields();
    }
  }, [defaultAdditionalGeoFences]);
  {
    /*
  Filter the additional geoFences to get only the circle geoFences
  check if the circle geoFences have a radius
  if they do, create a circle geofence with the center and radius
  add the circle to the additionalGeoFences array
  */
  }

  const checkForCircleGeoFences = () => {
    const validCircleGeoFences = watchedAdditionalGeoFences.filter(
      (geo: AdditionalGeoFenceField) => {
        return (
          (geo.type.id === geofenceEquipmentOption.id ||
            geo.type.id === geoFenceCircleOption.id) &&
          geo.radius > 0
        );
      },
    );
    const circles: AdditionalGeoFencePolygon[] = [];

    if (validCircleGeoFences.length) {
      validCircleGeoFences.map((geo: any) => {
        const center = [
          Number(watchedLon) || defaultPosition.lng,
          Number(watchedLat) || defaultPosition.lat,
        ];
        const appliedRadius = isFeet
          ? convertLength(Number(geo.radius), 'feet', 'meters')
          : radius;

        const geofenceDefault = circle(center, appliedRadius, {
          ...options,
          units: 'meters',
        });
        geofenceDefault.id = geo.id;

        circles.push({
          ...(geofenceDefault as unknown as AdditionalGeoFencePolygon),
          circleCenter: {
            lat: center[1],
            lon: center[0],
          },
        });
      });
    }
    const polygonsInAdditionalGeoFences = additionalGeoFencesPolygons.filter(
      (geo: any) => !geo.circleCenter,
    );

    setAdditionalGeoFencesPolygons([...polygonsInAdditionalGeoFences, ...circles]);
    refreshMarker();
  };

  {
    /* Check for circle geoFences in the additional geoFences to start drawing them
     only after the additional geoFences have been retrieved */
  }
  useEffect(() => {
    checkForCircleGeoFences();
  }, [watchedAdditionalGeoFences]);

  // Fetch additional geoFences when the default site changes
  useEffect(() => {
    if (defaultSiteId) {
      getAdditionalGeoFencesForSite(defaultSiteId);
    } else {
      setValue('additionalGeoFences', []);
    }
  }, [defaultSiteId]);

  // Fetch equipment when geofence type is equipment
  useEffect(() => {
    if (watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT) {
      getEquipmentByCompanyId({ companyId, shared: false });
    }
  }, [watchedGeofenceType?.id]);

  // Remove tags that are already is use by the additional geoFences
  useEffect(() => {
    const filteredTags = movingGeoFencesTagsOptions.filter((tag) => {
      return !watchedAdditionalGeoFences.some(
        (geoFence: any) => geoFence.tag?.id === tag.id,
      );
    });
    if (filteredTags.length !== tags.length) {
      setTags(filteredTags);
    }
  }, [watchedAdditionalGeoFences]);

  {
    /* Check if the user is changing the primary geofence type from a static type to a moving type
   or vice versa. If so, show a warning alert
   if approved, change the primary geofence type and clear the additional geoFences */
  }
  const onPrimaryTypeChange = (value: any) => {
    if (isNone || additionalGeoFencesFields.length === 0) return true;

    const isChangingToStatic =
      (value?.id === GeoFenceTypes.CIRCLE || value?.id === GeoFenceTypes.POLYGON) &&
      isMovingGeoFence;
    const isChangingToMoving = value?.id === GeoFenceTypes.EQUIPMENT && !isMovingGeoFence;
    if (isChangingToStatic || isChangingToMoving) {
      setNewPrimaryType(value);
      setShowAlert(true);
      return false;
    }
    return true;
  };

  const addNewGeoFence = () => {
    const newGeofence = {
      id: UUID(),
      radius: 0,
      type: geoFenceCircleOption,
      tag: undefined,
    };
    if (isPolygonGeoFence) {
      appendGeofence({
        ...newGeofence,
        type: geofencePolygonOption,
      });
    } else if (isMovingGeoFence) {
      appendGeofence({
        ...newGeofence,
        type: geofenceEquipmentOption,
      });
    } else {
      appendGeofence(newGeofence);
    }
  };

  const ChangeGeoFenceTypeWarningAlert = () => {
    return showAlert ? (
      <Box
        display={'flex'}
        sx={{ backgroundColor: '#FFF4E5', p: 2, my: 2 }}
        justifyContent={'space-between'}
      >
        <Box gap={0.5}>
          <Typography variant={'body1'} fontWeight={'600'} color={'#663C00'}>
            {$t('form_fields.change_geo_fence_type_alert_title')}
          </Typography>
          <Typography variant={'body2'} color={'#663C00'}>
            {$t('form_fields.change_geo_fence_type_alert_text')}
          </Typography>
        </Box>

        <Box display={'flex'} gap={1}>
          <Button
            variant="text"
            sx={{ color: '#663C00', fontWeight: '600' }}
            onClick={() => {
              setShowAlert(false);
            }}
          >
            {$t('form_fields.change_geo_fence_type_alert_cancel')}
          </Button>
          <Button
            variant="text"
            sx={{ color: '#663C00', fontWeight: '600' }}
            onClick={() => {
              setValue('geofenceType', newPrimaryType);
              setValue('additionalGeoFences', []);
              setAdditionalGeoFencesPolygons([]);
              setShowAlert(false);
            }}
          >
            {$t('form_fields.change_geo_fence_type_alert_confirm')}
          </Button>
        </Box>
      </Box>
    ) : null;
  };

  const PrimaryGeoFence = useCallback(() => {
    return (
      <>
        <Typography variant={'body1'} my={2}>
          {$t('form_fields.primary_geo_fence')}
        </Typography>
        <ChangeGeoFenceTypeWarningAlert />
        <Grid
          container
          spacing={2}
          sx={{
            pb: 2,
            borderBottom: additionalGeoFencesFields?.length
              ? `1px solid ${theme.palette.divider}`
              : 'none',
            mb: additionalGeoFencesFields?.length ? 2 : 0,
          }}
        >
          <Grid item xs={6}>
            <AutocompleteFormField
              disabled={!coords || showAlert}
              list={geofenceOptionsWithNone}
              control={control}
              errors={errors}
              name="geofenceType"
              getLabel={(item) => item?.name}
              getValue={(item) => item?.id}
              label={`${$t('form_fields.geo_fence_type')}`}
              clearOnChange={(value) => {
                return onPrimaryTypeChange(value);
              }}
            />
          </Grid>
          {isMovingGeoFence && (
            <Grid item xs={6}>
              <Box display={'flex'}>
                <AutocompleteFormField
                  control={control}
                  errors={errors}
                  name={`equipment`}
                  getValue={(item) => item._id}
                  getLabel={(item) => item._name || ''}
                  label={`${$t('form_fields.available_equipment')}`}
                  loading={isLoading}
                  list={equipmentOptions}
                  clearable={true}
                  noOptionsText={$t('form_fields.no_options')}
                  disabled={showAlert}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={6}>
            <AnimateComponentPresence
              identifier={`geofence-radius`}
              isVisible={Boolean((coords && isCircleGeofence) || isMovingGeoFence)}
              animationDuration={0.3}
            >
              <Box display={'flex'}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="radius"
                  type="number"
                  label={`${$t('form_fields.radius')} (${$t(
                    isFeet ? 'units_of_distance.feet' : 'units_of_distance.meters',
                  )})`}
                  disabled={showAlert}
                />
              </Box>
              <Typography color={'grey'} variant={'body2'}>
                {$t('form_fields.must_be_integer')}
              </Typography>
            </AnimateComponentPresence>
            <Typography variant={'body1'} color={'grey'} mt={1}>
              {watchedGeofenceType?.id === GeoFenceTypes.POLYGON
                ? $t('form_fields.geo_fence_type_hint')
                : ''}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }, [
    control,
    errors,
    showAlert,
    watchedGeofenceType?.id,
    coords,
    isLoading,
    additionalGeoFencesFields.length,
  ]);

  const MovingGeoFenceForm = useCallback(
    ({ index }: { index: number }) => {
      const key = watchedAdditionalGeoFences[index]?.id;
      return (
        <Card
          sx={{
            mb: 2,
            p: 2,
            mt: 3,
            ml: 2,
            overflow: 'visible',
            width: '100%',
            position: 'relative',
          }}
          key={key}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteFormField
                disabled={true}
                list={geofenceOptionsWithNone.filter(
                  (item) => item.id === GeoFenceTypes.EQUIPMENT,
                )}
                control={control}
                errors={errors}
                name="geofenceType"
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${$t('form_fields.geo_fence_type')}`}
              />
            </Grid>

            <Grid item xs={6}>
              <Box display={'flex'}>
                <AutocompleteFormField
                  control={control}
                  errors={errors}
                  name={'equipment'}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  label={`${$t('form_fields.available_equipment')}`}
                  loading={isLoading}
                  list={equipmentOptions}
                  clearable={true}
                  noOptionsText={$t('form_fields.no_options')}
                  disabled={true}
                />
              </Box>
            </Grid>
            <RemoveItemButton
              action={() => {
                removeGeofence(index);
              }}
              sx={{
                ml: 2,
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                cursor: 'pointer',
              }}
            />

            <Grid item xs={6}>
              <Box display={'flex'}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name={`additionalGeoFences[${index}].radius`}
                  type="number"
                  label={`${$t('form_fields.radius')} (${$t(
                    isFeet ? 'units_of_distance.feet' : 'units_of_distance.meters',
                  )})`}
                  disabled={showAlert}
                />
              </Box>
              <Typography color={'grey'} variant={'body2'}>
                {$t('form_fields.must_be_integer')}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display={'flex'}>
                <AutocompleteFormField
                  control={control}
                  errors={errors}
                  name={`additionalGeoFences[${index}].tag`}
                  getLabel={(item) => item?.name}
                  getValue={(item) => item?.id}
                  label={`${$t('form_fields.tag')}`}
                  list={tags}
                  clearable={true}
                  disabled={showAlert}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      );
    },
    [additionalGeoFencesFields, control, errors, showAlert, tags],
  );

  const CircleGeoFenceForm = useCallback(
    ({ index }: { index: number }) => {
      const key = watchedAdditionalGeoFences[index]?.id;

      return (
        <Card
          sx={{
            mb: 2,
            p: 2,
            mt: 3,
            ml: 2,
            overflow: 'visible',
            width: '100%',
            position: 'relative',
            border: 'none',
            backgroundColor: 'transparent',
            borderRadius: '0px',
          }}
          key={key}
        >
          <Grid container spacing={2}>
            <RemoveItemButton
              action={() => {
                removeGeofence(index);
                deleteGeofence(key);
              }}
              sx={{
                ml: 2,
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                cursor: 'pointer',
              }}
            />

            <Grid item xs={6}>
              <AutocompleteFormField
                disabled={showAlert}
                list={geofenceOptions.filter(
                  (item) => item.id !== GeoFenceTypes.EQUIPMENT,
                )}
                control={control}
                errors={errors}
                name={`additionalGeoFences[${index}].type`}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${$t('form_fields.geo_fence_type')}`}
              />
            </Grid>

            <Grid item xs={6}>
              <AnimateComponentPresence
                identifier={`geofence-radius`}
                isVisible={true}
                animationDuration={0.3}
              >
                <Box display={'flex'}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name={`additionalGeoFences[${index}].radius`}
                    type="number"
                    label={`${$t('form_fields.radius')} (${$t(
                      isFeet ? 'units_of_distance.feet' : 'units_of_distance.meters',
                    )})`}
                    disabled={showAlert}
                  />
                </Box>
                <Typography color={'grey'} variant={'body2'}>
                  {$t('form_fields.must_be_integer')}
                </Typography>
              </AnimateComponentPresence>
            </Grid>

            <Grid item xs={6}>
              <AutocompleteFormField
                control={control}
                errors={errors}
                name={`additionalGeoFences[${index}].tag`}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${$t('form_fields.tag')}`}
                list={tags}
                clearable={true}
                disabled={showAlert}
              />
            </Grid>
          </Grid>
        </Card>
      );
    },
    [additionalGeoFencesFields, showAlert, control, errors, isFeet, tags],
  );

  const PolygonGeoFenceForm = useCallback(
    ({ index }: { index: number }) => {
      const key = watchedAdditionalGeoFences[index]?.id;

      const isPolygonActive = key === activePolygonKey;

      const cardStyle = isPolygonActive
        ? {
            border: `1px solid ${theme.brandV2.colors.treadOrangeDark}`,
            backgroundColor: '#FFF6ED',
            borderRadius: '4px',
          }
        : {
            border: 'none',
            backgroundColor: 'transparent',
            borderRadius: '0px',
          };
      return (
        <Card
          sx={{
            mb: 2,
            p: 2,
            mt: 3,
            ml: 2,
            overflow: 'visible',
            width: '100%',
            position: 'relative',
            ...cardStyle,
          }}
          key={key}
        >
          <Grid container spacing={2}>
            <RemoveItemButton
              action={() => {
                removeGeofence(index);
                deleteGeofence(key);

                if (key === activePolygonKey) {
                  setActivePolygonKey(null);
                }
              }}
              sx={{
                ml: 2,
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                cursor: 'pointer',
              }}
            />
            <Grid item xs={2}>
              <Typography variant={'caption'}>Shape</Typography>
              <EditPolygon
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!showAlert) {
                    setActivePolygonKey(isPolygonActive ? null : key);
                  }
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <AutocompleteFormField
                disabled={showAlert}
                list={geofenceOptions.filter(
                  (item) => item.id !== GeoFenceTypes.EQUIPMENT,
                )}
                control={control}
                errors={errors}
                name={`additionalGeoFences[${index}].type`}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${$t('form_fields.geo_fence_type')}`}
              />
            </Grid>

            <Grid item xs={5}>
              <AutocompleteFormField
                control={control}
                errors={errors}
                name={`additionalGeoFences[${index}].tag`}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${$t('form_fields.tag')}`}
                list={tags}
                clearable={true}
                disabled={showAlert}
              />
            </Grid>
          </Grid>
        </Card>
      );
    },
    [
      additionalGeoFencesFields,
      activePolygonKey,
      showAlert,
      control,
      errors,
      tags,
      additionalGeoFencesPolygons,
    ],
  );

  return (
    <>
      {<PrimaryGeoFence />}
      {additionalGeoFencesFields.length > 0 && (
        <>
          <Typography variant={'body1'} my={2}>
            {$t('form_fields.additional_geo_fences')}
          </Typography>
          <Grid container spacing={2}>
            {additionalGeoFencesFields.map((geo, index) => {
              if (isMovingGeoFence) {
                return <MovingGeoFenceForm index={index} key={geo.id} />;
              }
              const isPolygon =
                watchedAdditionalGeoFences[index]?.type?.id === GeoFenceTypes.POLYGON;
              return isPolygon ? (
                <PolygonGeoFenceForm index={index} />
              ) : (
                <CircleGeoFenceForm index={index} />
              );
            })}
          </Grid>
        </>
      )}

      {!isNone && (
        <Box
          display={'flex'}
          alignItems={'baseline'}
          justifyContent={'start'}
          gap={0.5}
          sx={{
            width: '100%',
            mb: 2,
          }}
        >
          <PseudoLink
            title={`${$t('administration.site.add_geofence')}`}
            action={() => {
              isAddAdditionalGeoFencesDisabled ? null : addNewGeoFence();
            }}
            disabled={isAddAdditionalGeoFencesDisabled}
          />
          {!isMovingGeoFence && (
            <Typography variant={'body2'} color={'grey'} mt={1}>
              {additionalGeoFencesFields
                ? `(${$t('form_fields.geo_fence_number_hint')})`
                : ''}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
