import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { AutocompleteFormField, TextFormField } from '~components/FormFields';
import { Phase } from '~hooks/useProjects/models';

interface PhaseSelectionProps {
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  phases: Phase[];
  isDisabled?: boolean;
}

export const PhaseSelection = ({
  control,
  errors,
  phases,
  isDisabled,
}: PhaseSelectionProps) => (
  <>
    <Grid item xs={6}>
      <AutocompleteFormField
        disabled={isDisabled}
        control={control}
        name="phase"
        errors={errors}
        list={phases}
        label={`${t('form_fields.phase_name')}`}
        isRequired={false}
        defaultValue={undefined}
        getValue={(item) => item.id}
        getLabel={(item) => item.name || ''}
        clearOnBlur={true}
        blurOnSelect={true}
      />
    </Grid>
    <Grid item xs={6}>
      <TextFormField
        control={control}
        errors={errors}
        disabled={true}
        name="phaseCode"
        label={`${t('form_fields.phase_code')}`}
        isRequired={false}
      />
    </Grid>
  </>
);
