import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Driver_Read, Driver_Read_Typeahead } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { useAccount } from '~hooks/useAccount';
import { Driver, DriverTypeahead, useDrivers } from '~hooks/useDrivers';
import { useUsers } from '~hooks/useUsers';
import { useStores } from '~store';
import { ItemNameAndId } from '~types/ItemNameAndId';

import DispatchSearchParam from './DispatchSearchParam';
import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, any>;
  companyId: string;
  sx?: SxProps<Theme>; // Style
}

const DriverSelector = observer(({ allSearchParams, companyId, sx = {} }: Props) => {
  const { getDriversByCompanyIdTypeahead, getDriverByID } = useDrivers();
  const { userStore } = useStores();

  const fetchDriversByCompanyId = async ({
    query,
    cursor,
  }: {
    query: string;
    cursor: string;
  }) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const [driversInternalResponse, driversSharedResponse] = await Promise.all([
      getDriversByCompanyIdTypeahead({
        companyId: company.id,
        shared: false,
        searchParams: {
          query,
          link: {
            type: 'after',
            cursor,
          },
        },
      }),
      getDriversByCompanyIdTypeahead({
        companyId: company.id,
        shared: true,
        searchParams: {
          query,
          link: {
            type: 'after',
            cursor,
          },
        },
      }),
    ]);

    return [...driversInternalResponse.data, ...driversSharedResponse.data];
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncGet={getDriverByID}
          asyncCallback={fetchDriversByCompanyId}
          fieldName={DispatchSearchParam.DRIVER}
          label={$t('form_fields.driver')}
          allSearchParams={allSearchParams}
          defaultValues={[]}
          sx={{
            ...sx,
            ...{
              width: '140px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.fullName}
        />
      </Box>
    </>
  );
});

export { DriverSelector };
