import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { FileAttachment_Read, JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { LoadCard } from '~components/Cards';
import { Drawer } from '~components/Drawer';
import { NotSpecified } from '~components/Helpers';
import { OrderUnitOfMeasure } from '~constants/enums';
import { useFileAttachment } from '~hooks/useFileAttachment';
import { Job, JobLoad, useJob } from '~hooks/useJob';
import { JobLoadCycle } from '~hooks/useJob/models';
import { Ticket, TicketEventType, useTickets } from '~hooks/useTickets';
import { TicketFormDTO } from '~pages/Approvals/ApprovalsComponents/ticketFormSchema';
import { TicketDetails } from '~pages/Approvals/TicketsReviewDataGrid/TicketDetails';
import { SmallButton } from '~pages/Dispatch/components/ordersDispatchStyledComponents';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';

interface Props {
  details: Job;
  sx?: SxProps<Theme>; // Style
  reload: () => void;
}

const Loads = ({ details, sx = {}, reload }: Props) => {
  const { toasterStore, userStore } = useStores();
  const {
    deleteTicketImageById,
    uploadTicketImageById,
    updateTicketById,
    updateState,
    createTicket,
  } = useTickets();
  const theme = useTheme();
  const [selectedTicket, setSelectedTicket] = useState<{
    isDrawerOpen: boolean;
    loadId?: string;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const imagePendingToBeUploaded = useRef<File>();

  const userPermissions = userStore.getPermissions();

  const { isLoading, removeLoad, addLoad, getJobById, getJobLoadCycles } = useJob();
  const { getAllFileAttachmentsById, deleteFileAttachmentById } = useFileAttachment();
  const [cycles, setCycles] = useState<Array<JobLoadCycle>>([]);

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const saveTicket = async (ticketDto: TicketFormDTO) => {
    let ticket: Ticket;
    const isUpdate = Boolean(ticketDto.id);

    if (isUpdate) {
      ticket = await updateTicketById({
        id: String(ticketDto.id),
        data: {
          quantity: ticketDto.quantity,
          unitOfMeasure: ticketDto.unitOfMeasure,
          material: ticketDto.material.id ? { id: ticketDto.material.id } : undefined,
          ticketNumber: ticketDto.ticketNumber,
          imageUrl: ticketDto.imageUrl,
        },
      });
    } else {
      ticket = await createTicket({
        data: {
          job_id: ticketDto.jobId,
          load_id: selectedTicket.loadId,
          material_id: ticketDto.material.id || undefined,
          quantity: ticketDto.quantity,
          ticket_number: ticketDto.ticketNumber,
          unit_of_measure: ticketDto.unitOfMeasure.id,
        },
      });

      if (imagePendingToBeUploaded.current) {
        ticket = await uploadTicketImageById({
          id: ticket.id,
          file: imagePendingToBeUploaded.current,
        });
      }
    }

    if (ticket) {
      const message = isUpdate ? 'approvals.ticket_updated' : 'approvals.ticket_created';

      toasterStore.push(alert(t(message), AlertTypes.success), false, true);
      setSelectedTicket((state) => ({ ...state, ticket }));
      reload();
    }

    return ticket;
  };

  const handleFlagTicket = async (ticket: Ticket) => {
    ticket.flagged = !ticket.flagged;

    const updatedTicket = await updateTicketById({ id: ticket.id, data: ticket });

    toasterStore.push(
      alert(t('approvals.ticket_updated'), AlertTypes.success),
      false,
      true,
    );
    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
    reload();

    return updatedTicket;
  };

  const onRemoveLoad = async (loadId: string) => {
    await removeLoad(loadId);
    await getJobById(details.id);
    reload();
    toasterStore.push(alert(t('loads.load_removed'), AlertTypes.success));
  };

  const onAddNew = () => {
    addLoad(details.id, {
      quantity: 1,
      unitOfMeasure: {
        id: OrderUnitOfMeasure.LOAD,
        name: OrderUnitOfMeasure.LOAD,
      } as ItemNameAndId,
    })
      .then(() => {
        toasterStore.push(alert(t('loads.load_added'), AlertTypes.success));
      })
      .then(() => {
        getJobById(details.id);
      })
      .then(() => {
        reload();
      });
  };

  const getCycleTime = (load: JobLoad): number => {
    let res = 0;

    if (cycles.length) {
      const cycle = cycles.find((cycle) => cycle.fromLoadId === load.id);
      if (cycle) {
        res = cycle.timeMinutes || 0;
      }
    }

    return res;
  };

  useEffect(() => {
    getJobLoadCycles(details.id).then((res) => {
      setCycles(res);
    });
  }, [details]);

  useEffect(() => {
    if (!selectedTicket.isDrawerOpen) {
      imagePendingToBeUploaded.current = undefined;

      // remove added attachments if the drawer was closed without saving the ticket
      const shouldRemoveAttachments = Boolean(
        !selectedTicket.ticket?.id && selectedTicket.loadId,
      );

      if (shouldRemoveAttachments) {
        getAllFileAttachmentsById(
          String(selectedTicket.loadId),
          FileAttachment_Read.file_attachable_type.LOAD,
        ).then((attachments) => {
          attachments.map((attachment) => deleteFileAttachmentById(attachment.id));
        });
      }
    }
  }, [selectedTicket.isDrawerOpen, selectedTicket.loadId, selectedTicket.ticket?.id]);

  return (
    <>
      <Box sx={{ mx: -1, ...sx }}>
        <Box sx={{ opacity: isLoading ? 0.4 : null }}>
          {details.loads.length ? (
            <>
              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                sx={{
                  pb: '4px',
                  my: 0.5,
                  fontWeight: 'bold',
                  borderBottom: `1px solid ${theme.brandV2.colors.treadGray6}`,
                }}
              >
                <Grid item xs={1} sx={{ pl: 1 }}>
                  <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                    {t('loads.number')}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                    {t('loads.status_and_cycle')}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                    {t('loads.alerts')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                    {t('loads.ticket')}
                  </Typography>
                </Grid>
              </Grid>

              {details.loads.map((load, idx) => {
                const currentTicket = details.tickets.find(
                  (ticket) => ticket.loadId === load.id,
                );
                return (
                  <LoadCard
                    key={load.id}
                    index={idx}
                    cycleTime={getCycleTime(load)}
                    deletable={userPermissions.canDeleteLoad}
                    load={load}
                    onClick={() => {
                      setSelectedTicket({
                        isDrawerOpen: true,
                        loadId: load.id,
                        ticket: currentTicket,
                      });
                    }}
                    onRemoveLoad={onRemoveLoad}
                    reload={reload}
                    ticket={currentTicket || null}
                  />
                );
              })}
            </>
          ) : (
            <NotSpecified />
          )}
          {userPermissions.canCreateLoad &&
            ![JobState.REJECTED, JobState.COMPLETED, JobState.CANCELED].includes(
              details.status,
            ) && (
              <Box mt={2}>
                <SmallButton
                  onClick={onAddNew}
                  color={'brandV2Yellow'}
                  disabled={isLoading}
                >
                  {`+ ${t('loads.add')}`}
                </SmallButton>
              </Box>
            )}
        </Box>
      </Box>

      <Drawer
        anchor="right"
        onClose={() => setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }))}
        open={selectedTicket.isDrawerOpen}
        variant="temporary"
      >
        <TicketDetails
          job={details}
          loadId={selectedTicket.loadId}
          ticket={selectedTicket.ticket}
          onFlag={handleFlagTicket}
          onApprove={async (ticket) => {
            const approvedTicket = await doAction(ticket.id, 'approve');

            if (approvedTicket) {
              setSelectedTicket((state) => ({ ...state, ticket: approvedTicket }));
              reload();
            }
          }}
          onRemoveImage={async (ticket) => {
            await deleteTicketImageById({ id: ticket.id });
            const newTicket = ticket;
            newTicket.imageUrl = '';
            setSelectedTicket((state) => ({ ...state, ticket: newTicket }));
          }}
          onUploadImage={async (ticket, image) => {
            if (ticket) {
              const updatedTicket = await uploadTicketImageById({
                id: ticket.id,
                file: image,
              });

              if (updatedTicket) {
                setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
              }
            } else {
              imagePendingToBeUploaded.current = image;
            }
          }}
          onChange={saveTicket}
          onClose={() => {
            setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }));
          }}
        />
      </Drawer>
    </>
  );
};

export { Loads };
