import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { AutocompleteFormField } from '~components/FormFields';

interface Props {
  allSearchParams: Record<string, any>;
  fieldName: string;
  options: Array<any>;
  label: string;
  getOptionLabel: (item: any) => string;
  getOptionValue?: (item: any) => string;
  limitTags?: number;
  sx?: SxProps<Theme>; // Style
  defaultValues?: Array<any>;
  multiple?: boolean;
  onChange?: (value: string | null) => void;
  loadData?: () => void;
  isDataLoading?: boolean;
  isShrinkingLabel?: boolean;
}

const GenericSelector = ({
  allSearchParams,
  fieldName,
  options,
  label,
  getOptionLabel,
  getOptionValue = (item: any) => item.id || '',
  limitTags = 2,
  sx = {},
  defaultValues = [],
  multiple = true,
  onChange,
  loadData,
  isDataLoading,
  isShrinkingLabel,
}: Props) => {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const handleSelectionChange = (_event: React.SyntheticEvent, value: string) => {
    if (onChange && value) {
      onChange(value);
    }
  };

  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [fieldName]: defaultValues as Array<any>,
    },
  });

  const value = watch(fieldName);

  useEffect(() => {
    const searchParamIds = allSearchParams[fieldName]
      ? allSearchParams[fieldName].split('|')
      : [];
    const values = options.filter((item) =>
      searchParamIds.includes(getOptionValue(item)),
    );

    if (options.length && values.length > 0) {
      setValue(fieldName, values);
      trigger(fieldName);
    } else {
      reset({ [fieldName]: [] });
    }
  }, [options, allSearchParams[fieldName]]);

  useEffect(() => {
    if (options.length) {
      const params = new URLSearchParams(allSearchParams);
      if (value.length) {
        params.set(fieldName, value.map((item) => getOptionValue(item)).join('|'));
      } else {
        params.delete(fieldName);
      }

      // Only update the URL if the value has changed
      if (params.get(fieldName) !== currentSearchParams.get(fieldName)) {
        setSearchParams(params);
      }
    }
  }, [JSON.stringify(value)]);

  return (
    <>
      <AutocompleteFormField
        control={control}
        name={fieldName}
        errors={errors}
        list={options}
        label={label}
        getValue={getOptionValue}
        getLabel={getOptionLabel}
        clearOnBlur={true}
        blurOnSelect={true}
        multiple={multiple}
        limitTags={limitTags}
        clearDefaultValue={[]}
        sx={{ ...sx }}
        isDenseText={false}
        defaultValue={options[0]}
        onInput={handleSelectionChange}
        chipMaxWidth={''}
        onClick={() => {
          loadData?.();
        }}
        loading={isDataLoading}
        isShrinkingLabel={isShrinkingLabel}
      />
    </>
  );
};

export { GenericSelector };
