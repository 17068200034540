import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { isEqual } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteFormField } from '~components/FormFields';

interface FilterByMultipleValuesParams {
  options: (string | undefined)[];
  name: string;
  label: string;
  callBack: (filters: []) => void;
  values: string[];
  placeholder?: string;
}

const FilterByMultipleValues = memo(function FilterByMultipleValues({
  name,
  label,
  options,
  callBack,
  values,
  placeholder,
}: FilterByMultipleValuesParams) {
  const [filters, setFilters] = useState<string[]>(values);
  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        [name]: yup.array().of(yup.string()),
      }),
    ),
    mode: 'onBlur',
    defaultValues: {
      [name]: filters.length ? filters : [],
    },
  });
  const watchFilters = watch(name);

  useEffect(() => {
    if (!isEqual(watchFilters, filters)) {
      setFilters(watchFilters);
      callBack(watchFilters as []);
    }
  }, [watchFilters]);

  return (
    <Box display={'flex'} sx={{ width: '100%' }} component={'form'}>
      <AutocompleteFormField
        sx={{ width: '100%', minWidth: '320px' }}
        multiple={true}
        placeholder={placeholder}
        control={control}
        name={name}
        list={options as string[]}
        errors={errors}
        label={label}
        getValue={(item) => item}
        getLabel={(item) => item}
      ></AutocompleteFormField>
    </Box>
  );
}, isEqual);
export { FilterByMultipleValues };
