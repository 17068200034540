import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { t as $t } from 'i18next';
import { CountryCode } from 'libphonenumber-js';
import { capitalize } from 'lodash';
import React from 'react';
import { ControllerProps, FieldErrors, useWatch } from 'react-hook-form';

import {
  AutocompleteFormField,
  CheckBoxFormField,
  PhoneCodeFlagInput,
  TextFormField,
} from '~components/FormFields';
import { FormSectionTitle } from '~components/typographyTitles';
import { inviteTypeOptions } from '~pages/Settings/Accounts/AccountFormParts/constants';

interface Props {
  control: any;
  errors: any;
  selectedCountry: any;
  isBillingContactHidden: boolean;
  selectedBillingCountry: { alpha3: string; code: string; name: string } | undefined;
  selectedAccountId?: string;
}

const ConnectedAccountContact = ({
  control,
  errors,
  selectedCountry,
  isBillingContactHidden,
  selectedBillingCountry,
  selectedAccountId,
}: Props) => {
  const inviteTypes = useWatch({
    control,
    name: 'inviteTypes',
  });
  return (
    <Card sx={{ mb: 2, p: 2, overflow: 'visible' }}>
      {/*Account contact*/}
      <FormSectionTitle title={$t('account.form.contact_information')} />
      <Typography variant="body2" sx={{ mb: 2 }}>
        {$t('account.form.primary_account_contact_hint')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="primaryContact.firstName"
            label={`${$t('form_fields.first_name')}`}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="primaryContact.lastName"
            label={`${$t('form_fields.last_name')}`}
            isRequired={true}
          />
        </Grid>
        {!selectedAccountId && (
          <>
            <Grid item xs={6}>
              <AutocompleteFormField
                control={control}
                errors={errors}
                name={'inviteTypes'}
                list={inviteTypeOptions}
                getValue={(item) => item}
                getLabel={(item) =>
                  item === 'sms' ? item.toUpperCase() : capitalize(item)
                }
                multiple
                label={`${$t('account.form.invite_type')}`}
                isRequired
              />
            </Grid>

            <Grid item xs={6}></Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="primaryContact.email"
            label={`${$t('form_fields.email')}`}
            sx={{ mr: 2 }}
            isRequired={inviteTypes.includes('email')}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneCodeFlagInput
            control={control as unknown as ControllerProps['control']}
            errors={errors as unknown as FieldErrors}
            name="primaryContact.phone"
            label={`${$t('form_fields.phone')}`}
            defaultCountryCode={
              selectedCountry && selectedCountry.code
                ? (selectedCountry.code as CountryCode)
                : undefined
            }
            isRequired={inviteTypes.includes('sms')}
          />
        </Grid>
      </Grid>

      {selectedAccountId && (
        <>
          <CheckBoxFormField
            control={control}
            errors={errors}
            value={false}
            label={$t('account.form.billing_contact_same_as_primary') || ''}
            name={'isBillingContactSame'}
          />

          <Collapse in={!isBillingContactHidden}>
            <FormSectionTitle sx={{ mb: 2 }} title={$t('account.form.billing_contact')} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="billingContact.firstName"
                  label={`${$t('form_fields.first_name')}`}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="billingContact.lastName"
                  label={`${$t('form_fields.last_name')}`}
                  isRequired={true}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="billingContact.email"
                  label={`${$t('form_fields.email')}`}
                  sx={{ mr: 2 }}
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneCodeFlagInput
                  control={control as unknown as ControllerProps['control']}
                  errors={errors as unknown as FieldErrors}
                  name="billingContact.phone"
                  label={`${$t('form_fields.phone')}`}
                  defaultCountryCode={
                    selectedBillingCountry && selectedBillingCountry.code
                      ? (selectedBillingCountry.code as CountryCode)
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export { ConnectedAccountContact };
