import { RateType } from '@treadinc/horizon-api-spec';
import { Dayjs } from 'dayjs';
import _ from 'lodash';

import { InvoiceLineItem } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices';
import {
  dateTimeHelpers,
  HourlyLineItemDTO,
  LoadLineItemDTO,
  NormalizedHourlyLineItem,
  NormalizedLoadLineItem,
  NormalizedTonLineItem,
  TonLineItemDTO,
} from '~pages/Approvals/DriverPay';

type WithID<T> = T & { id: string };
type MaybeWithID<T> = T & { id?: string | undefined | null };

/**
 * Strategy pattern to handle a line item based on its rate type
 */
interface LineItemStrategyInterface<DTO, Normalized extends { id: string }> {
  /**
   * Calculates the line item total quantity
   * @param lineItem The line item
   * @returns The calculated total quantity for the line item
   */
  calculateQuantity(lineItem: InvoiceLineItem): number;

  /**
   * Calculates the line item total amount
   * @param lineItem The line item
   * @returns The calculated total amount for the line item
   */
  calculateTotal(lineItem: InvoiceLineItem): number;

  /**
   * Takes a line item and creates a map having only those fields that are to be used when
   * comparing changes between two line items
   * @param lineItem The line item
   * @returns A map with the fields to be used when comparing changes between two line items
   */
  normalize(lineItem: DTO): Map<keyof Normalized, unknown>;

  /**
   * Takes a line item and its respective edited version and returns a new line item having only
   * the edited fields
   * @param oldLineItem The original line item
   * @param newLineItem The correspondent edited line item
   * @returns A partial line item having only the changed fields
   */
  diffItems(oldLineItem: DTO, newLineItem: DTO): Normalized;

  /**
   * When editing a line item, composes a new line item by taking its initial version and
   * introducing the changes currently taking place
   * @param lineItem The initial line item
   * @param editedLineItem The correspondent line item being currently edited
   * @returns A line item with updated fields
   */
  consolidate(lineItem: InvoiceLineItem, editedLineItem?: DTO): InvoiceLineItem;
}

export class LineItemStrategy {
  private constructor() {}

  public static create(invoice: Invoice) {
    if (!invoice.rateType) {
      throw new Error('Missing rate type in invoice');
    }

    if (invoice.rateType === RateType.RATE_PER_HOUR) {
      return new HourlyLineItemConcreteStrategy(invoice);
    }

    if (invoice.rateType === RateType.RATE_PER_LOAD) {
      return new LoadLineItemConcreteStrategy(invoice);
    }

    return new TonLineItemConcreteStrategy(invoice);
  }
}

/**
 * Base class to consolidate the shared logic between the different strategies
 */
abstract class BaseConcreteStrategy<DTO, Normalized extends { id: string }>
  implements LineItemStrategyInterface<DTO, Normalized>
{
  constructor(protected invoice: Invoice) {}

  abstract calculateQuantity(lineItem: InvoiceLineItem): number;
  abstract calculateTotal(lineItem: InvoiceLineItem): number;
  abstract consolidate(lineItem: InvoiceLineItem, editedLineItem?: DTO): InvoiceLineItem;
  abstract normalize(lineItem: DTO): Map<keyof Normalized, unknown>;

  diffItems(oldLineItem: DTO, newLineItem: DTO) {
    const normalizedOldLineItem = this.normalize(oldLineItem);
    const normalizedNewLineItem = this.normalize(newLineItem);

    const changes = _.differenceWith(
      _.toPairs(normalizedNewLineItem),
      _.toPairs(normalizedOldLineItem),
      _.isEqual,
    ) as [[keyof Normalized, unknown]];

    if (changes.length) {
      changes.push(['id', normalizedNewLineItem.get('id')]);
    }

    return Object.fromEntries(changes) as Normalized;
  }

  /**
   * Takes a list of line items and its respective edited version and returns a new list of
   * partial line items, each of which only contains the changed fields
   * @param oldLineItems The list of original line items
   * @param newLineItems The list of correspondent edited line items
   * @returns A list of partial line items having only the changed fields
   */
  diffItemsCollection(oldLineItems: WithID<DTO>[], newLineItems: MaybeWithID<DTO>[]) {
    const changedLineItems = newLineItems.reduce((acc, lineItem) => {
      const respectiveOldLineItem = oldLineItems.find((li) => {
        return li.id && li.id === lineItem.id;
      });

      if (respectiveOldLineItem) {
        const changes = this.diffItems(respectiveOldLineItem, lineItem);

        if (!_.isEmpty(changes)) {
          acc.push(changes);
        }
      }

      return acc;
    }, [] as Array<Normalized>);

    return changedLineItems;
  }
}

/**
 * Strategy for a per-hour based rate
 */
export class HourlyLineItemConcreteStrategy
  extends BaseConcreteStrategy<HourlyLineItemDTO, NormalizedHourlyLineItem>
  implements LineItemStrategyInterface<HourlyLineItemDTO, NormalizedHourlyLineItem>
{
  calculateQuantity(lineItem: InvoiceLineItem): number {
    if (lineItem.payStartTime && lineItem.payEndTime) {
      const diff = dateTimeHelpers.diffDatesInHours(
        lineItem.payStartTime,
        lineItem.payEndTime,
      );

      const totalBreakTime = (this.invoice.jobSummary?.breakTimeMinutes ?? 0) / 60;
      const billingAdjustment = lineItem.billingAdjustmentMinutes / 60;

      return parseFloat((diff - totalBreakTime + billingAdjustment).toFixed(2));
    }

    return 0;
  }

  calculateTotal(lineItem: InvoiceLineItem) {
    return this.calculateQuantity(lineItem) * lineItem.rate;
  }

  normalize(lineItem: HourlyLineItemDTO) {
    const normalized = new Map<keyof NormalizedHourlyLineItem, unknown>();
    normalized.set('id', lineItem.id);

    if (lineItem.payStartTime) {
      normalized.set('payStartTime', (lineItem.payStartTime as Dayjs).toISOString());
    }

    if (lineItem.payEndTime) {
      normalized.set('payEndTime', (lineItem.payEndTime as Dayjs).toISOString());
    }

    if (lineItem.payStartTime && lineItem.payEndTime) {
      const quantity = this.calculateQuantity(lineItem as InvoiceLineItem);
      normalized.set('quantity', quantity);
    }

    normalized.set('billingAdjustmentMinutes', Number(lineItem.billingAdjustmentMinutes));

    return normalized;
  }

  diffItems(oldLineItem: HourlyLineItemDTO, newLineItem: HourlyLineItemDTO) {
    const normalizedOldLineItem = this.normalize(oldLineItem);
    const normalizedNewLineItem = this.normalize(newLineItem);

    const changes = _.differenceWith(
      _.toPairs(normalizedNewLineItem),
      _.toPairs(normalizedOldLineItem),
      _.isEqual,
    ) as [[keyof NormalizedHourlyLineItem, unknown]];

    if (changes.length) {
      changes.push(['id', normalizedNewLineItem.get('id')]);
    }

    return Object.fromEntries(changes) as NormalizedHourlyLineItem;
  }

  consolidate(lineItem: InvoiceLineItem, editedLineItem?: HourlyLineItemDTO) {
    const rateAsNumber = parseFloat(editedLineItem?.rate?.toString() ?? '');
    const rate = Number.isNaN(rateAsNumber) ? 0 : rateAsNumber;

    const billingAdjustmentMinutesAsNumber = parseFloat(
      editedLineItem?.billingAdjustmentMinutes?.toString() ?? '',
    );
    const billingAdjustmentMinutes = Number.isNaN(billingAdjustmentMinutesAsNumber)
      ? 0
      : billingAdjustmentMinutesAsNumber;

    return new InvoiceLineItem(
      lineItem.id,
      lineItem.quantity,
      rate,
      lineItem.rateType,
      lineItem.totalAmount,
      billingAdjustmentMinutes,
      editedLineItem?.payStartTime ? (editedLineItem.payStartTime as Dayjs) : undefined,
      editedLineItem?.payEndTime ? (editedLineItem.payEndTime as Dayjs) : undefined,
      lineItem.invoiceId,
      lineItem.load,
      lineItem.name,
    );
  }
}

/**
 * Strategy for a per-load based rate
 */
export class LoadLineItemConcreteStrategy
  extends BaseConcreteStrategy<LoadLineItemDTO, NormalizedLoadLineItem>
  implements LineItemStrategyInterface<LoadLineItemDTO, NormalizedLoadLineItem>
{
  calculateQuantity(lineItem: InvoiceLineItem): number {
    return lineItem.quantity;
  }

  calculateTotal(lineItem: InvoiceLineItem) {
    return lineItem.rate;
  }

  normalize(lineItem: LoadLineItemDTO) {
    const normalized = new Map<keyof NormalizedLoadLineItem, unknown>();
    normalized.set('id', lineItem.id);
    normalized.set('rate', Number(lineItem.rate));

    return normalized;
  }

  diffItems(oldLineItem: LoadLineItemDTO, newLineItem: LoadLineItemDTO) {
    const normalizedOldLineItem = this.normalize(oldLineItem);
    const normalizedNewLineItem = this.normalize(newLineItem);

    const changes = _.differenceWith(
      _.toPairs(normalizedNewLineItem),
      _.toPairs(normalizedOldLineItem),
      _.isEqual,
    ) as [[keyof NormalizedHourlyLineItem, unknown]];

    if (changes.length) {
      changes.push(['id', normalizedNewLineItem.get('id')]);
    }

    return Object.fromEntries(changes) as NormalizedHourlyLineItem;
  }

  consolidate(lineItem: InvoiceLineItem, editedLineItem?: LoadLineItemDTO) {
    const rateAsNumber = parseFloat(editedLineItem?.rate?.toString() ?? '');
    const rate = Number.isNaN(rateAsNumber) ? 0 : rateAsNumber;

    return new InvoiceLineItem(
      lineItem.id,
      lineItem.quantity,
      rate,
      lineItem.rateType,
      lineItem.totalAmount,
      lineItem.billingAdjustmentMinutes,
      lineItem.payStartTime,
      lineItem.payEndTime,
      lineItem.invoiceId,
      lineItem.load,
      lineItem.name,
    );
  }
}

/**
 * Strategy for a per-ton based rate
 */
export class TonLineItemConcreteStrategy
  extends BaseConcreteStrategy<TonLineItemDTO, NormalizedTonLineItem>
  implements LineItemStrategyInterface<TonLineItemDTO, NormalizedTonLineItem>
{
  calculateQuantity(lineItem: InvoiceLineItem): number {
    return lineItem.quantity;
  }

  calculateTotal(lineItem: InvoiceLineItem) {
    return lineItem.quantity * lineItem.rate;
  }

  normalize(lineItem: TonLineItemDTO) {
    const normalized = new Map<keyof NormalizedTonLineItem, unknown>();
    normalized.set('id', lineItem.id);
    normalized.set('quantity', Number(lineItem.quantity));
    normalized.set('rate', Number(lineItem.rate));

    return normalized;
  }

  diffItems(oldLineItem: TonLineItemDTO, newLineItem: TonLineItemDTO) {
    const normalizedOldLineItem = this.normalize(oldLineItem);
    const normalizedNewLineItem = this.normalize(newLineItem);

    const changes = _.differenceWith(
      _.toPairs(normalizedNewLineItem),
      _.toPairs(normalizedOldLineItem),
      _.isEqual,
    ) as [[keyof NormalizedTonLineItem, unknown]];

    if (changes.length) {
      changes.push(['id', normalizedNewLineItem.get('id')]);
    }

    return Object.fromEntries(changes) as NormalizedTonLineItem;
  }

  consolidate(lineItem: InvoiceLineItem, editedLineItem?: TonLineItemDTO) {
    const qtyAsNumber = parseFloat(editedLineItem?.quantity?.toString() ?? '');
    const quantity = Number.isNaN(qtyAsNumber) ? 0 : qtyAsNumber;

    const rateAsNumber = parseFloat(editedLineItem?.rate?.toString() ?? '');
    const rate = Number.isNaN(rateAsNumber) ? 0 : rateAsNumber;

    return new InvoiceLineItem(
      lineItem.id,
      quantity,
      rate,
      lineItem.rateType,
      lineItem.totalAmount,
      lineItem.billingAdjustmentMinutes,
      lineItem.payStartTime,
      lineItem.payEndTime,
      lineItem.invoiceId,
      lineItem.load,
      lineItem.name,
    );
  }
}
