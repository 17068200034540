import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  FileAttachment_Read,
  LoadState,
  RateOwnerType,
  RateType,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import _ from 'lodash';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AutocompleteAsyncFormField,
  AutocompleteFormField,
  SingleDateTimeFormField,
  TextFormField,
} from '~components/FormFields';
import { Contacts } from '~components/Order/Contacts';
import { OrderCustomerDetails } from '~components/Order/OrderCustomerDetails';
import { PhaseSelection } from '~components/Order/PhaseSelection';
import { ProjectDetails } from '~components/Order/ProjectDetails';
import { SiteSelection } from '~components/Order/SiteSelection';
import { FormSectionTitle } from '~components/typographyTitles';
import { NoteTypes, unitOfMeasureOptions } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { Notes } from '~formsShared/components';
import { useEquipment } from '~hooks/useEquipment';
import { Job, JobFormPropsWithRates, useJob } from '~hooks/useJob';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Order, useOrders } from '~hooks/useOrders';
import { RateBasicWithValue, useRates } from '~hooks/useRates';
import { jobFormSchema, setDefaultJobValues } from '~pages/Dispatch/JobFormSchema';
import RateDetails, {
  RateDetailsHandler,
} from '~pages/Sales/Orders/NewOrderFormComponents/RateDetails';
import { RateDetailsDTO } from '~pages/Sales/Orders/NewOrderFormComponents/schema';
import { rateToRateBasicWithValue } from '~pages/Sales/Orders/NewOrderFormComponents/utils';
import { createWaypoint } from '~pages/Sales/Orders/OrderForm';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import FileAttachments, {
  FileAttachmentsHandler,
} from '../../formsShared/components/FileAttachments';
import FileAttachmentsReadOnly from './components/FileAttachmentsReadOnly';

interface FormProps {
  defaultJob: Job;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
}

type InitialRateInfo = {
  rate: RateBasicWithValue | null;
  rateType: RateType | null;
  rateValue: number | null;
};

type JobEditFormSubmitCallback = (data: JobFormPropsWithRates) => void;

export type JobEditFormHandler = {
  submit?: (callback: JobEditFormSubmitCallback) => void;
  resetForm?: (callback: () => void) => void;
  fileAttachmentsOnSubmit?: (jobId: string) => Promise<void>;
  fileAttachmentsOnClose?: () => Promise<void>;
};

const JobEditForm = forwardRef(function JobEditForm(
  { defaultJob, onFormStateChange }: FormProps,
  ref: Ref<JobEditFormHandler>,
) {
  const theme = useTheme();
  const fileAttachmentsRef = useRef<FileAttachmentsHandler>(null);
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const { isLoading: isEquipmentLoading, getEquipmentByCompanyId } = useEquipment();
  const {
    getAllMaterials,
    createMaterialByCompanyId,
    isLoading: isMaterialLoading,
  } = useMaterials();
  const { getJobById } = useJob();

  const equipmentOptions = companyAssetsStore.equipment;
  const materialsOptions = companyAssetsStore.allMaterials;
  const servicesOptions = useMemo(
    () => (defaultJob.service?.id ? [defaultJob.service] : []),
    [defaultJob.service],
  );

  const { getAccountRatesUsingOwnerType } = useRates();

  const formMethods = useForm({
    resolver: yupResolver(jobFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...setDefaultJobValues(defaultJob),
      account: defaultJob.order?.account,
      department: defaultJob.order?.department,
      project: defaultJob.project,
      projectExternalId: '',
      phase: defaultJob.order?.phase,
      phaseCode: defaultJob.order?.phase?.code,
      name: defaultJob.order?.name,
      poJobNumber: defaultJob.order?.poJobNumber,
      zone: defaultJob.order?.zone,
      externalId: defaultJob.order?.externalId,
      dispatchNumber: defaultJob.order?.dispatchNumber,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    setError,
    formState: { errors, isValid, isDirty },
  } = formMethods;

  const companyId = userStore.userCompany?.id;
  const isAssignedToVendor = Boolean(defaultJob.vendorJobAssignment?.id);
  const isAssignedToDriver = !isAssignedToVendor && Boolean(defaultJob.driver?.id);

  useEffect(() => {
    // Fetch data if it's not loaded yet
    if (companyId && !equipmentOptions.length) {
      getEquipmentByCompanyId({ companyId });
    }
    if (!materialsOptions.length) {
      getAllMaterials();
    }
  }, [companyId, equipmentOptions.length, materialsOptions.length]);

  useEffect(() => {
    if (defaultJob?.id) {
      getJobById(defaultJob.id).then((job) => {
        setValue('loads', job.loads);
        trigger('loads');
      });
    }
  }, [defaultJob?.id]);

  useImperativeHandle(
    ref,
    () => ({
      submit: async (callBack: (data: JobFormPropsWithRates) => void) => {
        const customerRateDetails = await new Promise<RateDetailsDTO | undefined>(
          (resolve) => {
            if (customerRateDetailsRef.current?.onSubmit) {
              customerRateDetailsRef.current.onSubmit((data) => {
                resolve(data);
              });
            } else {
              resolve(undefined);
            }
          },
        );

        const vendorOrDriverRateDetails = await new Promise<RateDetailsDTO | undefined>(
          (resolve) => {
            if (vendorOrDriverRateDetailsRef.current?.onSubmit) {
              vendorOrDriverRateDetailsRef.current.onSubmit((data) => {
                resolve(data);
              });
            } else {
              resolve(undefined);
            }
          },
        );

        const isAssigned = isAssignedToVendor || isAssignedToDriver;
        const vendorOrDriverRateInfoIsValid = isAssigned
          ? !_.isUndefined(vendorOrDriverRateDetails)
          : true;

        if (customerRateDetails && vendorOrDriverRateInfoIsValid) {
          let assigneeRate: JobFormPropsWithRates['assigneeRate'] | null = null;

          if (isAssigned) {
            assigneeRate = {
              target: isAssignedToVendor ? 'vendor' : 'driver',
              rate: vendorOrDriverRateDetails?.targetRate ?? null,
              rateType: vendorOrDriverRateDetails?.targetRateType ?? null,
              rateValue: vendorOrDriverRateDetails?.targetRateValue ?? null,
            };
          }

          handleSubmit((formData) => {
            callBack({
              ...formData,
              customerRate: customerRateDetails.targetRate ?? null,
              customerRateType: customerRateDetails.targetRateType ?? null,
              customerRateValue: customerRateDetails.targetRateValue ?? null,
              ...(assigneeRate ? { assigneeRate } : {}),
            });
          })();
        }
      },
      resetForm(callBack?: () => void) {
        reset();
        callBack?.();
      },
      fileAttachmentsOnSubmit: async (jobId: string) => {
        await fileAttachmentsRef.current?.onSubmit(jobId);
      },
      fileAttachmentsOnClose: async () => {
        await fileAttachmentsRef.current?.onCancel();
      },
    }),
    [isAssignedToVendor, isAssignedToDriver],
  );

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  const [tmpMaterial, setTmpMaterial] = useState<string>('');
  const onInputMaterial = (event: React.SyntheticEvent, value: string) => {
    setTmpMaterial(value);
  };
  const createMaterial = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createMaterialByCompanyId({
      companyId: userStore.userCompany?.id || '',
      material: {
        name: tmpMaterial,
      } as unknown as Material,
    }).then((material) => {
      toasterStore.push(
        alert(
          t('administration.material.material_created', {
            name: material.name,
          }),
          AlertTypes.success,
        ),
      );
      setValue('material', material);
      trigger('material');
    });
  };

  const { loads } = watch();

  // Fetching Order specific data
  const [orderData, setOrderData] = useState<Order | null>(null);
  const { getOrderById } = useOrders();

  const customerRateDetailsRef = useRef<RateDetailsHandler>(null);
  const vendorOrDriverRateDetailsRef = useRef<RateDetailsHandler>(null);

  const [initialRates, setInitialRates] = useState<
    Partial<Record<RateOwnerType, InitialRateInfo | null>>
  >({});

  const hasInitialCustomerRate = RateOwnerType.CUSTOMER in initialRates;
  const hasInitialVendorRate = RateOwnerType.VENDOR in initialRates;
  const hasInitialDriverRate = RateOwnerType.DRIVER in initialRates;

  const getDefaultRate = useCallback(
    (ownerType: RateOwnerType) => {
      const rateInfo: {
        rate: RateBasicWithValue | null;
        rateType: RateType | null;
        rateValue: number | null;
      } = { rate: null, rateType: null, rateValue: null };

      if (ownerType === RateOwnerType.DRIVER) {
        rateInfo.rate = defaultJob.driverRate ?? null;
        rateInfo.rateType = defaultJob.driverRateType ?? null;
        rateInfo.rateValue = defaultJob.driverRateValue ?? null;
      } else if (ownerType === RateOwnerType.CUSTOMER) {
        rateInfo.rate = defaultJob.customerJobAssignment?.rate ?? null;
        rateInfo.rateType = defaultJob.customerJobAssignment?.rateType ?? null;
        rateInfo.rateValue = defaultJob.customerJobAssignment?.rateValue ?? null;
      } else {
        rateInfo.rate = defaultJob.vendorJobAssignment?.rate ?? null;
        rateInfo.rateType = defaultJob.vendorJobAssignment?.rateType ?? null;
        rateInfo.rateValue = defaultJob.vendorJobAssignment?.rateValue ?? null;
      }

      // Rate card
      if (rateInfo.rate) {
        return { ...rateInfo };
      }

      // Manual rate
      if (!_.isNull(rateInfo.rateType) && !_.isNull(rateInfo.rateValue)) {
        return { rate: null, rateType: rateInfo.rateType, rateValue: rateInfo.rateValue };
      }

      return null;
    },
    [
      defaultJob.customerJobAssignment,
      defaultJob.driverRate,
      defaultJob.driverRateType,
      defaultJob.driverRateValue,
      defaultJob.vendorJobAssignment,
    ],
  );

  const fetchRates = useCallback(
    (
      ownerType: RateOwnerType,
      params: {
        accountId?: string;
        driverId?: string;
        projectId?: string;
        serviceId?: string;
      },
      args?: { query: string; cursor: string },
    ) => {
      if (companyId) {
        return getAccountRatesUsingOwnerType({
          ownerType,
          includeDefaultRates: true,
          accountId: params.accountId,
          driverId: params.driverId,
          projectId: params.projectId,
          serviceId: params.serviceId,
          query: args?.query,
          cursor: args?.cursor,
          limit: 15,
        }).then((response) => {
          const basicRates = response.data.map((rate) => rateToRateBasicWithValue(rate));

          setInitialRates((state) => ({
            ...state,
            [ownerType]: getDefaultRate(ownerType),
          }));

          return { ...response, data: basicRates };
        });
      }
    },
    [companyId],
  );

  const fetchCustomerRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(
        RateOwnerType.CUSTOMER,
        {
          accountId: defaultJob.customerJobAssignment?.customerAccount?.id,
          projectId: defaultJob.project?.id,
          serviceId: defaultJob.service?.id,
        },
        args,
      );
    },
    [
      fetchRates,
      defaultJob.customerJobAssignment?.customerAccount?.id,
      defaultJob.project?.id,
      defaultJob.service?.id,
    ],
  );

  const fetchVendorRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(
        RateOwnerType.VENDOR,
        {
          accountId: defaultJob.vendorJobAssignment?.vendorAccount?.id,
          projectId: defaultJob.project?.id,
          serviceId: defaultJob.service?.id,
        },
        args,
      );
    },
    [
      fetchRates,
      defaultJob.vendorJobAssignment?.vendorAccount?.id,
      defaultJob.project?.id,
      defaultJob.service?.id,
    ],
  );

  const fetchDriverRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(
        RateOwnerType.DRIVER,
        {
          driverId: defaultJob.driver?.id,
          projectId: defaultJob.project?.id,
          serviceId: defaultJob.service?.id,
        },
        args,
      );
    },
    [fetchRates, defaultJob.driver?.id, defaultJob.project?.id, defaultJob.service?.id],
  );

  useEffect(() => {
    if (!hasInitialCustomerRate) {
      fetchCustomerRates();
    }

    if (isAssignedToVendor && !hasInitialVendorRate) {
      fetchVendorRates();
    }

    if (isAssignedToDriver && !hasInitialDriverRate) {
      fetchDriverRates();
    }
  }, [
    fetchCustomerRates,
    fetchVendorRates,
    fetchDriverRates,
    hasInitialCustomerRate,
    hasInitialVendorRate,
    hasInitialDriverRate,
    isAssignedToVendor,
    isAssignedToDriver,
  ]);

  useEffect(() => {
    if (defaultJob) {
      getOrderById(defaultJob.order?.id || '').then((order) => {
        setOrderData(order);
      });
    }
  }, [defaultJob]);

  useEffect(() => {
    defaultJob?.project?.externalId &&
      setValue('projectExternalId', defaultJob?.project?.externalId);
    defaultJob?.project?.id && setValue('project', defaultJob?.project);
    defaultJob?.project?.notes && setValue('projectNotes', defaultJob?.project?.notes);
  }, [defaultJob]);

  useEffect(() => {
    orderData?.account?.id && setValue('account', orderData?.account);
    orderData?.department?.id && setValue('department', orderData?.department);
    orderData?.phase?.id && setValue('phase', orderData?.phase);
    orderData?.phase?.code && setValue('phaseCode', orderData?.phase?.code || '');
    orderData?.name && setValue('name', orderData?.name || '');
    orderData?.poJobNumber && setValue('poJobNumber', orderData?.poJobNumber || '');
    orderData?.zone && setValue('zone', orderData?.zone || '');
    orderData?.externalId && setValue('externalId', orderData?.externalId || '');
    orderData?.notes && setValue('orderNotes', orderData?.notes || '');
    orderData?.internalNotes && setValue('internalNotes', orderData?.internalNotes || '');
    orderData?.dispatchNumber &&
      setValue('dispatchNumber', orderData?.dispatchNumber || '');
  }, [orderData]);

  return (
    <Box component="div" sx={{ mt: 0, py: 2 }} data-test-id="job-order-form">
      <FormProvider {...formMethods}>
        <Grid container spacing={2}>
          {/*Left side*/}
          <Grid item xs={6}>
            {/* Project details */}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 1 }} title={t('order.form.project_details')} />
              <Grid container spacing={2}>
                <ProjectDetails
                  control={control}
                  errors={errors}
                  companyId={defaultJob.company?.id}
                  readOnly
                />

                {orderData?.phase?.id && (
                  <PhaseSelection
                    control={control}
                    errors={errors}
                    phases={[]}
                    isDisabled={true}
                  />
                )}
              </Grid>
            </Card>
            {/* Order Customer Details */}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle
                title={$t('order.form.order_customer_details')}
                sx={{ mb: 1 }}
              />
              <OrderCustomerDetails control={control} errors={errors} disabled />
            </Card>
            {/* Job Details */}
            <Card sx={{ mb: 2, p: 2 }}>
              <FormSectionTitle title={$t('dispatch.job.details')} sx={{ mb: 1 }} />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AutocompleteFormField
                    control={control}
                    name="service"
                    errors={errors}
                    list={servicesOptions}
                    label={`${$t('form_fields.service')}`}
                    isRequired={true}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleDateTimeFormField
                    dateLabel={$t('form_fields.load_at_date') || ''}
                    timeLabel={$t('form_fields.load_at_time') || ''}
                    control={control}
                    errors={errors}
                    name={'jobStartAt'}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteFormField
                    control={control}
                    name="material"
                    groupBy={(item) => item?.typeName}
                    errors={errors}
                    list={materialsOptions}
                    label={`${$t('form_fields.materials')}`}
                    isRequired={true}
                    loading={isMaterialLoading}
                    disabled={isMaterialLoading}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                    onInput={onInputMaterial}
                    noOptionsText={
                      tmpMaterial ? (
                        <Chip
                          size={'small'}
                          variant={'filled'}
                          color={'primary'}
                          onClick={createMaterial}
                          disabled={isMaterialLoading}
                          sx={{ cursor: 'pointer' }}
                          label={$t('common.add_new_name', {
                            name: tmpMaterial || '',
                          })}
                        />
                      ) : (
                        $t('form_fields.no_options')
                      )
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="quantity"
                    type={'number'}
                    label={`${$t('form_fields.quantity')}`}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteFormField
                    control={control}
                    name="unitOfMeasure"
                    errors={errors}
                    list={unitOfMeasureOptions}
                    label={`${$t('form_fields.unit')}`}
                    isRequired={true}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                  />
                </Grid>

                <Grid item xs={6}>
                  <AutocompleteFormField
                    control={control}
                    name="equipment"
                    errors={errors}
                    groupBy={(item) => item?.typeName}
                    list={equipmentOptions}
                    label={`${$t('form_fields.truck')}`}
                    loading={isEquipmentLoading}
                    isRequired={false}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteFormField
                    control={control}
                    name="additionalEquipment"
                    errors={errors}
                    groupBy={(item) => item?.typeName}
                    list={equipmentOptions}
                    label={`${$t('form_fields.trailer')}`}
                    loading={isEquipmentLoading}
                    multiple={false}
                    isRequired={false}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="priority"
                    label={`${$t('form_fields.priority')}`}
                    type="number"
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Card>
            {/* Contacts */}
            <Contacts defaultData={defaultJob} />
            {/* Loads */}
            {loads.length ? (
              <Card sx={{ mb: 2, p: 2 }}>
                <FormSectionTitle title={t('loads.title')} />
                {loads.map((load, idx) => (
                  <Grid container spacing={2} key={load.id} sx={{ mt: 0.5 }}>
                    <Grid item xs={2}>
                      <Typography sx={{ pt: 5 }}>
                        {$t('loads.load_no', { number: idx + 1 })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFormField
                        control={control}
                        errors={errors}
                        name={`loads[${idx}].quantity`}
                        label={`${$t('form_fields.quantity')}`}
                        isRequired={true}
                        disabled={[LoadState.CANCELED, LoadState.COMPLETED].includes(
                          load.status,
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutocompleteFormField
                        control={control}
                        name={`loads[${idx}].unitOfMeasure`}
                        errors={errors}
                        list={unitOfMeasureOptions}
                        label={`${$t('form_fields.unit')}`}
                        isRequired={true}
                        disabled={[LoadState.CANCELED, LoadState.COMPLETED].includes(
                          load.status,
                        )}
                        getValue={(item) => item.id}
                        getLabel={(item) => item.name}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Card>
            ) : null}
          </Grid>

          {/* Right side */}
          <Grid item xs={6}>
            <Card sx={{ p: 2, mb: 2, overflow: 'visible', minHeight: theme.spacing(20) }}>
              <FormSectionTitle sx={{ mb: 1 }} title={t('order.form.location_details')} />

              <SiteSelection
                errors={errors}
                allowSuggestions={false}
                companyId={companyId}
                orderId={defaultJob.order?.id}
                projectId={defaultJob.project?.id}
                isRequired={true}
                hideAddDefaultSiteAction={true}
                pickUpSite={defaultJob?.pickUpWayPoints?.[0]?.site}
                dropOffSite={defaultJob?.dropOffWayPoints?.[0]?.site}
                onPickUpSiteChange={(site) => {
                  if (site) {
                    const pickUpWayPoint = createWaypoint(
                      site,
                      WaypointType.PICKUP,
                      10,
                      defaultJob?.pickUpWayPoints?.[0]?.id,
                    );
                    setValue('pickUpWayPoint', pickUpWayPoint, { shouldDirty: true });
                  } else if (isDirty && !site) {
                    setError('pickUpWayPoint', {
                      types: { required: 'Site is required' },
                    });
                  }
                }}
                onDropOffSiteChange={(site) => {
                  if (site) {
                    setValue(
                      'dropOffWayPoint',
                      createWaypoint(
                        site,
                        WaypointType.DROP_OFF,
                        20,
                        defaultJob?.dropOffWayPoints?.[0]?.id,
                      ),
                      { shouldDirty: true },
                    );
                  } else if (isDirty && !site) {
                    setError('dropOffWayPoint', {
                      types: { required: 'Site is required' },
                    });
                  }
                }}
              />
            </Card>

            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 1 }} title={t('order.form.rate_details')} />

              <Box display="flex" flexDirection="column" gap={3}>
                {hasInitialCustomerRate && (
                  <RateDetails
                    ref={customerRateDetailsRef}
                    fetchRates={fetchCustomerRates}
                    isEditing
                    rateCardControlLabel={`${t('order.form.customer_rate_card')}`}
                    targetRate={initialRates.customer?.rate ?? null}
                    targetRateType={initialRates.customer?.rateType ?? null}
                    targetRateValue={initialRates.customer?.rateValue ?? null}
                    matchedAttributes={[
                      {
                        label: t('form_fields.account'),
                        isMatched: Boolean(
                          defaultJob.customerJobAssignment?.customerAccount?.id,
                        ),
                      },
                      {
                        label: t('form_fields.project'),
                        isMatched: Boolean(defaultJob.project?.id),
                      },
                      {
                        label: t('form_fields.service'),
                        isMatched: Boolean(defaultJob.service?.id),
                      },
                    ]}
                  />
                )}

                {(isAssignedToVendor || isAssignedToDriver) &&
                  (hasInitialVendorRate || hasInitialDriverRate) && (
                    <Box display="flex" gap={2}>
                      {isAssignedToVendor && (
                        <AutocompleteAsyncFormField
                          asyncCallback={_.noop}
                          disabled
                          control={control}
                          errors={errors}
                          getLabel={(item) => item?.label || ''}
                          getValue={(item) => item?.value || ''}
                          label={`${t('form_fields.vendor')}`}
                          name="vendor"
                        />
                      )}

                      {isAssignedToDriver && (
                        <AutocompleteAsyncFormField
                          asyncCallback={_.noop}
                          disabled
                          control={control}
                          errors={errors}
                          getLabel={(item) => item?.label || ''}
                          getValue={(item) => item?.value || ''}
                          label={`${t('form_fields.driver')}`}
                          name="driver"
                        />
                      )}
                    </Box>
                  )}

                {(hasInitialVendorRate || hasInitialDriverRate) && (
                  <RateDetails
                    ref={vendorOrDriverRateDetailsRef}
                    fetchRates={isAssignedToVendor ? fetchVendorRates : fetchDriverRates}
                    isEditing
                    rateCardControlLabel={
                      isAssignedToVendor
                        ? `${t('order.form.vendor_rate_card')}`
                        : `${t('order.form.driver_rate_card')}`
                    }
                    targetRate={
                      isAssignedToVendor
                        ? initialRates.vendor?.rate ?? null
                        : initialRates.driver?.rate ?? null
                    }
                    targetRateType={
                      isAssignedToVendor
                        ? initialRates.vendor?.rateType ?? null
                        : initialRates.driver?.rateType ?? null
                    }
                    targetRateValue={
                      isAssignedToVendor
                        ? initialRates.vendor?.rateValue ?? null
                        : initialRates.driver?.rateValue ?? null
                    }
                    matchedAttributes={[
                      {
                        label: isAssignedToVendor
                          ? t('form_fields.account')
                          : t('form_fields.driver'),
                        isMatched: true,
                      },
                      {
                        label: t('form_fields.project'),
                        isMatched: Boolean(defaultJob.project?.id),
                      },
                      {
                        label: t('form_fields.service'),
                        isMatched: Boolean(defaultJob.service?.id),
                      },
                    ]}
                  />
                )}
              </Box>
            </Card>

            <Card sx={{ p: 2, mb: 2 }}>
              <FileAttachments
                fileAttachableId={defaultJob?.id}
                fileAttachableType={FileAttachment_Read.file_attachable_type.JOB}
                control={control}
                errors={errors}
                form={formMethods}
                name="fileAttachments"
                ref={fileAttachmentsRef}
              />
            </Card>

            {defaultJob && (defaultJob.project?.id || defaultJob.order?.id) && (
              <Card sx={{ p: 2, pt: 0, mb: 2 }}>
                {defaultJob.project?.id && (
                  <FileAttachmentsReadOnly
                    label={$t('form_fields.project_attachments') as string}
                    fileAttachableId={defaultJob.project.id}
                    fileAttachableType={FileAttachment_Read.file_attachable_type.PROJECT}
                  />
                )}
                {defaultJob.order?.id && (
                  <FileAttachmentsReadOnly
                    label={$t('form_fields.order_attachments') as string}
                    fileAttachableId={defaultJob.order.id}
                    fileAttachableType={FileAttachment_Read.file_attachable_type.ORDER}
                  />
                )}
              </Card>
            )}

            {/* Notes */}
            <Notes defaultTab={NoteTypes.JOB} />
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
});

export { JobEditForm };
