import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CheckBoxFormField } from '~components/FormFields';
import { Company, useCompany } from '~hooks/useCompany';
import { useStores } from '~store';
import UserStore from '~store/UserStore';
import { alert, AlertTypes } from '~types/AlertTypes';

const settingSchema = yup.object().shape({
  autoCompleteOrderEnabled: yup.boolean().required(),
});

type SettingDTO = yup.InferType<typeof settingSchema>;

function AutoOrderCompletionSetting() {
  const { userStore, toasterStore } = useStores();
  const { updateCompany } = useCompany();
  const [isSaving, setIsSaving] = useState(false);

  const { control, watch } = useForm<SettingDTO>({
    resolver: yupResolver(settingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { autoCompleteOrderEnabled: userStore.userCompany.autoCompleteOrder },
  });

  const isAutoCompleteOrderEnabled = watch('autoCompleteOrderEnabled');
  const previousIsAutoCompleteOrderEnabled = useRef(isAutoCompleteOrderEnabled);

  useEffect(() => {
    const valueDidChange =
      isAutoCompleteOrderEnabled !== previousIsAutoCompleteOrderEnabled.current;

    if (valueDidChange) {
      setIsSaving(true);

      updateCompany({
        company: {
          id: userStore.userCompany.id,
          autoCompleteOrder: isAutoCompleteOrderEnabled,
        } as Company,
      }).then(() => {
        previousIsAutoCompleteOrderEnabled.current = isAutoCompleteOrderEnabled;

        setIsSaving(false);
        toasterStore.push(
          alert(
            t('administration.configuration.configuration_saved'),
            AlertTypes.success,
          ),
        );
      });
    }
  }, [isAutoCompleteOrderEnabled]);

  return (
    <Box>
      <CheckBoxFormField
        control={control}
        disabled={isSaving}
        label={`${t('administration.configuration.settings.auto_order_completion.auto_complete_order_checkbox')}`}
        name="autoCompleteOrderEnabled"
        value={false}
      />
    </Box>
  );
}

export default {
  component: AutoOrderCompletionSetting,
  canAccess: (userStore: UserStore) => {
    return userStore.getPermissions().canEditCompany;
  },
};
