import { Active, DndContext, DragOverlay, Over } from '@dnd-kit/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog';
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from '~pages/Dispatch/components/drivers/constants';
import { DriverSchedulerFiltersBar } from '~pages/Dispatch/components/drivers/DriverSchedulerFiltersBar';
import { JobCard, JobPlacement } from '~pages/Dispatch/components/drivers/JobCard';
import { TimeSlotCellGrid } from '~pages/Dispatch/components/drivers/TimeSlotCellGrid';
import { UnassignedJobs } from '~pages/Dispatch/components/drivers/UnassignedJobs';
import {
  HEADER_PANEL_Z_INDEX,
  LoadingSpinner,
} from '~pages/Dispatch/components/ordersDispatchStyledComponents';
import {
  ConfirmChangeReason,
  useDriverScheduler,
} from '~pages/Dispatch/hooks/useDriverScheduler';
import { useStores } from '~store';
import { hexToRgba } from '~utils/utilFunctions';

import { INewDispatchViewComponent } from '../../NewDispatch';
import DriversViewEffects from './DriversViewEffects';

interface DriversViewProps extends INewDispatchViewComponent {}

const DriversView = ({ children }: DriversViewProps) => {
  const theme = useTheme();
  const { driverSchedulerStore } = useStores();
  const {
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    isConfirmingChange,
    setIsConfirmingChange,
    assignDriverAndUpdateJobTransaction,
    unassignDriverTransaction,
  } = useDriverScheduler();
  const confirmationDialogRef = useRef<ModalDialogHandler>(null);
  const isLoading =
    driverSchedulerStore.isLoadingUnassignedJobs || driverSchedulerStore.isLoadingDrivers;

  useEffect(() => {
    if (isConfirmingChange.state) {
      confirmationDialogRef.current?.open();
    } else {
      confirmationDialogRef.current?.close();
      setIsConfirmingChange((prev) => ({ ...prev, content: '' }));
    }
  }, [isConfirmingChange.state]);

  const determineCallBack = async () => {
    if (isConfirmingChange.reason === ConfirmChangeReason.DRIVER_CHANGE) {
      await assignDriverAndUpdateJobTransaction(
        isConfirmingChange.props.over,
        isConfirmingChange.props.active,
      );
    } else {
      await unassignDriverTransaction(isConfirmingChange.props.active);
    }
  };

  const handleDenyConfirmation = () => {
    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });
    driverSchedulerStore.setDraggedJob(null);
  };

  return children(
    <>
      <DriversViewEffects />
      <DriverSchedulerFiltersBar />
    </>,
    <Box
      sx={{ height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)` }}
      overflow={'hidden'}
    >
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        {isLoading && (
          <Box
            position="absolute"
            top={HEADER_HEIGHT}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={HEADER_PANEL_Z_INDEX + 4}
            height={'100%'}
            bgcolor={hexToRgba(theme.brandV2.colors.treadGray8, 0.5)}
          >
            <LoadingSpinner isVisible={true} />
          </Box>
        )}
        <Box display="flex">
          <UnassignedJobs />
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Box
              sx={{
                flexGrow: 1,
                height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px )`,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ flex: 1, width: '100%', background: 'common.white' }}>
                <TimeSlotCellGrid />
              </Box>
            </Box>
          </Box>
        </Box>

        <DragOverlay>
          {driverSchedulerStore.draggedJob ? (
            <>
              <JobCard
                jobId={driverSchedulerStore.draggedJob.id}
                jobPlacement={JobPlacement.DRAGGING}
              />
            </>
          ) : null}
        </DragOverlay>
      </DndContext>
      <ModalDialog
        ref={confirmationDialogRef}
        loading={false}
        title={t('actions.confirm_action')}
        content={isConfirmingChange.content}
        confirmButtonText={t('actions.confirm')}
        onCancel={handleDenyConfirmation}
        onClose={handleDenyConfirmation}
        callBack={determineCallBack}
      />
    </Box>,
  );
};

export default observer(DriversView);
