import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { DispatchSearchParam } from '~components/Order/Selectors';
import { OrderStatusFilter } from '~constants/enums';
import { Nullable } from '~types/Nullable';

export interface DispatchOrderState {
  selectedOrderId?: Nullable<string>;
  isOrderDialogOpen: boolean;
}
interface DispatchOrders {
  orderStatusFilter: string;
  setOrderStatusFilter: Dispatch<SetStateAction<OrderStatusFilter>>;
  orderDispatch: DispatchOrderState;
  setOrderDispatch: Dispatch<SetStateAction<DispatchOrderState>>;
  createNewOrder: () => void;
}

export const useDispatchOrders = (): DispatchOrders => {
  const [currentSearchParams] = useSearchParams();
  const location = useLocation();
  const [orderDispatch, setOrderDispatch] = useState<DispatchOrderState>({
    isOrderDialogOpen: false,
  });

  const [orderStatusFilter, setOrderStatusFilter] = useState<OrderStatusFilter>(
    OrderStatusFilter.ACTIVE,
  );

  useEffect(() => {
    if (location.pathname.includes('orders')) {
      const status = currentSearchParams.get(
        DispatchSearchParam.STATUS,
      ) as OrderStatusFilter;
      setOrderStatusFilter(status || OrderStatusFilter.ACTIVE);
    }
  }, [currentSearchParams, location.pathname]);

  const createNewOrder = () => {
    setOrderDispatch({
      isOrderDialogOpen: true,
      selectedOrderId: null,
    });
  };

  return {
    orderStatusFilter,
    setOrderStatusFilter,
    orderDispatch,
    setOrderDispatch,
    createNewOrder,
  };
};
