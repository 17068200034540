import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Menu from '~components/Menu/Menu';
import FilterMenuItem, {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~pages/Dispatch/components/filters/FilterMenuItem';
import useFetchers, {
  FetchFunction,
} from '~pages/Dispatch/components/filters/useFetchers';
import useDriverSchedulerFilters, {
  assignedJobsFilterKeyByJobSelectedFilter,
  TypeaheadedFilterState,
} from '~pages/Dispatch/hooks/useDriverSchedulerFilters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { JobsSelectedFilter } from '../../constants';
import AppliedFiltersChips, {
  AppliedFiltersChipsCollectedData,
} from '../../filters/AppliedFiltersChips';
import { ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '../../OrdersDispatchFiltersBar';
import { SmallButton } from '../../ordersDispatchStyledComponents';

type NextPageCursorsState = Partial<
  Record<JobsSelectedFilter, TypeaheadedFilterState['cursor']>
>;

const AssignedJobsFilters = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<JobsSelectedFilter>();

  const {
    appliedJobsFilters,
    updateAppliedJobsFilters,
    availableJobsOptions,
    updateAvailableJobsOptions,
    appliedJobsFilterChipsCollectedData,
    updateAppliedJobsFilterChipsCollectedData,
  } = useDriverSchedulerFilters();

  const { userStore, driverSchedulerStore } = useStores();
  const companyId = userStore.userCompany?.id;

  const [nextPageCursors, setNextPageCursors] = useState<NextPageCursorsState>({});
  const fetchers = useFetchers(companyId);

  const fetchersByJobsSelectedFilter: Partial<Record<JobsSelectedFilter, FetchFunction>> =
    useRef({
      [JobsSelectedFilter.CUSTOMERS]: fetchers.fetchCustomers,
      [JobsSelectedFilter.DISPATCH_NUMBERS]: fetchers.fetchOrdersDispatchNumbers,
      [JobsSelectedFilter.DROP_OFF_SITES]: fetchers.fetchSites,
      [JobsSelectedFilter.PICK_UP_SITES]: fetchers.fetchSites,
      [JobsSelectedFilter.PROJECTS]: fetchers.fetchProjects,
      [JobsSelectedFilter.PROJECTS_EXTERNAL_IDS]: fetchers.fetchProjectExternalIds,
    }).current;

  const filterChipsData = useMemo(() => {
    const appliedFilters = _.pick(
      driverSchedulerStore.assignedJobsFilters,
      Object.values(assignedJobsFilterKeyByJobSelectedFilter),
    );

    const collectedData = Object.entries(appliedJobsFilterChipsCollectedData).reduce(
      (obj, [key, collectedValues]) => {
        const filter =
          assignedJobsFilterKeyByJobSelectedFilter[key as JobsSelectedFilter];

        obj[filter] = collectedValues;

        return obj;
      },
      {} as AppliedFiltersChipsCollectedData,
    );

    return { appliedFilters, collectedData };
  }, [
    JSON.stringify(driverSchedulerStore.assignedJobsFilters),
    appliedJobsFilterChipsCollectedData,
  ]);

  const hasFiltersApplied =
    Object.values(filterChipsData.appliedFilters).filter((value) => {
      return Boolean(value);
    }).length > 0;

  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const handleRemoveAllFilters = useCallback((filters: string | string[]) => {
    const filtersArray = Array.isArray(filters) ? filters : [filters];
    const filtersObject = Object.fromEntries(
      filtersArray.map((filter) => [filter, undefined]),
    );

    driverSchedulerStore.setAssignedJobsFilters(filtersObject, true);
  }, []);

  const prepareForNewPageRequest = (key: JobsSelectedFilter) => {
    // We know that all records has been fetched if the cursor is explictly "undefined"
    const hasNextPageCursor = 'cursor' in availableJobsOptions[key];

    if (!hasNextPageCursor) {
      return;
    }

    const allPagesFetched = _.isUndefined(availableJobsOptions[key].cursor);

    if (allPagesFetched) {
      return;
    }

    updateAvailableJobsOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  };

  const applySearchResponse = (key: JobsSelectedFilter, data: FilterMenuOption[]) => {
    updateAvailableJobsOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        options: _.uniqBy([...state[key].options, ...data], (i) => i.value),
      },
    }));

    updateAppliedJobsFilterChipsCollectedData(key, data);
  };

  const getFetchMoreCallback = useCallback(() => {
    if (!selectedFilter) {
      return undefined;
    }

    const allPagesFetched =
      'cursor' in availableJobsOptions[selectedFilter] &&
      _.isUndefined(availableJobsOptions[selectedFilter].cursor);

    if (allPagesFetched) {
      return undefined;
    }

    return () => {
      updateAvailableJobsOptions((state) => ({
        ...state,
        [selectedFilter]: {
          ...state[selectedFilter],
          cursor: nextPageCursors[selectedFilter],
        },
      }));
    };
  }, [selectedFilter, availableJobsOptions]);

  const fetch = (filter: JobsSelectedFilter) => {
    const fetcher = fetchersByJobsSelectedFilter[filter];

    if (fetcher && availableJobsOptions[filter].loading) {
      const { searchValue, cursor } = availableJobsOptions[filter];

      fetcher(searchValue, cursor).then((response) => {
        setNextPageCursors((state) => ({
          ...state,
          [filter]: response.nextPageCursor,
        }));
        applySearchResponse(filter, response.data);
      });
    }
  };

  useEffect(() => {
    fetch(JobsSelectedFilter.CUSTOMERS);
  }, [availableJobsOptions.customers.loading]);

  useEffect(() => {
    fetch(JobsSelectedFilter.DISPATCH_NUMBERS);
  }, [availableJobsOptions.dispatchNumbers.loading]);

  useEffect(() => {
    fetch(JobsSelectedFilter.DROP_OFF_SITES);
  }, [availableJobsOptions.dropOffSites.loading]);

  useEffect(() => {
    fetch(JobsSelectedFilter.PICK_UP_SITES);
  }, [availableJobsOptions.pickUpSites.loading]);

  useEffect(() => {
    fetch(JobsSelectedFilter.PROJECTS);
  }, [availableJobsOptions.projects.loading]);

  useEffect(() => {
    fetch(JobsSelectedFilter.PROJECTS_EXTERNAL_IDS);
  }, [availableJobsOptions.projectsExternalIds.loading]);

  useEffect(() => {
    if (selectedFilter) {
      prepareForNewPageRequest(selectedFilter);
    }
  }, [
    selectedFilter,
    availableJobsOptions.customers.cursor,
    availableJobsOptions.dispatchNumbers.cursor,
    availableJobsOptions.dropOffSites.cursor,
    availableJobsOptions.pickUpSites.cursor,
    availableJobsOptions.projects.cursor,
    availableJobsOptions.projectsExternalIds.cursor,
  ]);

  useEffect(() => {
    if (selectedFilter) {
      updateAvailableJobsOptions((state) => ({
        ...state,
        [selectedFilter]: {
          loading: false,
          options: [],
          searchValue: state[selectedFilter].searchValue,
        },
      }));

      applyDebouncedFilter(() => {
        updateAvailableJobsOptions((state) => ({
          ...state,
          [selectedFilter]: {
            loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
            options: [],
            searchValue: appliedJobsFilters[selectedFilter].search,
          },
        }));
      });
    }

    return () => {
      applyDebouncedFilter.cancel();
    };
  }, [
    selectedFilter,
    appliedJobsFilters.customers.search,
    appliedJobsFilters.dispatchNumbers.search,
    appliedJobsFilters.dropOffSites.search,
    appliedJobsFilters.pickUpSites.search,
    appliedJobsFilters.projects.search,
    appliedJobsFilters.projectsExternalIds.search,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            startIcon={<FilterList />}
            sx={{
              '&.MuiButton-root': {
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                ...(hasFiltersApplied
                  ? {
                      borderBottomLeftRadius: theme.brandV2.borderRadius,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: theme.brandV2.borderRadius,
                      borderTopRightRadius: 0,
                    }
                  : { borderRadius: theme.brandV2.borderRadius }),
              },
            }}
          >
            {t('common.filters')}
          </SmallButton>
        }
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={appliedJobsFilters[selectedFilter].search}
            onSearchValueChange={(value) => {
              updateAppliedJobsFilters((state) => ({
                ...state,
                [selectedFilter]: {
                  ...state[selectedFilter],
                  search: value,
                },
              }));
            }}
            loadingReason={availableJobsOptions[selectedFilter].loading || undefined}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.${_.snakeCase(selectedFilter)}`) })}`}
            options={availableJobsOptions[selectedFilter].options}
            selectedOptions={
              driverSchedulerStore.assignedJobsFilters[
                assignedJobsFilterKeyByJobSelectedFilter[selectedFilter]
              ] ?? []
            }
            onSelectedOptionsChange={(selectedOptions) => {
              driverSchedulerStore.setAssignedJobsFilters(
                {
                  [assignedJobsFilterKeyByJobSelectedFilter[selectedFilter]]:
                    selectedOptions.length > 0 ? selectedOptions : undefined,
                },
                true,
              );
            }}
            onFetchMore={getFetchMoreCallback()}
          />
        )}

        {!selectedFilter &&
          Object.values(JobsSelectedFilter).map((filter) => (
            <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
                  {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {(
                    driverSchedulerStore.assignedJobsFilters[
                      assignedJobsFilterKeyByJobSelectedFilter[filter]
                    ] ?? []
                  ).length > 0 && (
                    <Check
                      sx={{ fontSize: '16px', color: theme.brandV2.colors.treadOrange }}
                    />
                  )}

                  <ChevronRight sx={{ fontSize: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>

      <AppliedFiltersChips
        {...filterChipsData}
        onRemoveFilter={handleRemoveAllFilters}
        onRemoveAllFilters={handleRemoveAllFilters}
      />
    </Box>
  );
});

export default AssignedJobsFilters;
