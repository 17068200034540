import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';

import DataGrid, { HeaderNavigation } from '~components/DataGrid';
import type { DriverDay as DriverDayT } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { PaginationLink } from '~interfaces/pagination';
import { useStores } from '~store/RootStore';

import { DRIVER_DAY_COLUMNS } from './columnDefinitions';
import { DriverDayDetails } from './DriverDayDetails';

const calculateTotalHrs = (
  shiftStart: Date | null,
  shiftEnd: Date | null,
  breakTime: number,
) => {
  if (!shiftStart || !shiftEnd) {
    return 0;
  }

  const shiftDuration = shiftEnd.getTime() - shiftStart.getTime();
  const breakDuration = breakTime * 60 * 1000;

  return (shiftDuration - breakDuration) / (1000 * 60 * 60);
};

type DriverDayRow = {
  id: number;
  driverDayId: string;
  date: Date;
  driver: string;
  truckNo: string | null;
  vendor: string | null;
  shiftStart: Date | null;
  shiftEnd: Date | null;
  break: number;
  totalHrs: number;
  jobCt: number;
  loadCt: number;
  tickets: number;
  materials: string[];
};

const transformDriverDayToDriverDayRow = (
  id: number,
  driverDay: DriverDayT,
): DriverDayRow => ({
  id,
  driverDayId: driverDay.id,
  date: driverDay.driverDayDate,
  driver: driverDay.driver.fullName,
  truckNo: driverDay.equipmentExternalIds[0] ?? null,
  vendor: driverDay.vendorName,
  shiftStart: driverDay.startedShiftAt,
  shiftEnd: driverDay.endedShiftAt,
  break: driverDay.totalBreakMinutes,
  totalHrs: calculateTotalHrs(
    driverDay.startedShiftAt,
    driverDay.endedShiftAt,
    driverDay.totalBreakMinutes,
  ),
  jobCt: driverDay.jobCount,
  loadCt: driverDay.loadsCount,
  tickets: driverDay.ticketsCount,
  // TODO: waiting for API updates, but will use total_delivered_quantities
  materials: new Array(4).fill(0).map((_, index) => `Material ${index}`),
});

export const DriverDay = observer(() => {
  const { driverDayStore, userStore } = useStores();
  const {
    fetchDriverDays,
    isLoadingDriverDays,
    subscribeToDriverDayUpdates,
    subscribeToJobUpdates,
    driverDaySubscription,
    jobSubscription,
  } = useDriverDays();

  useEffect(() => {
    fetchDriverDays();
  }, []);

  useEffect(() => {
    if (!driverDaySubscription && userStore.userCompany?.id) {
      subscribeToDriverDayUpdates();
    }
    return () => {
      driverDaySubscription?.unsubscribe();
    };
  }, [driverDaySubscription, userStore.userCompany?.id]);

  useEffect(() => {
    if (!jobSubscription && userStore.userCompany?.id) {
      subscribeToJobUpdates();
    }
    return () => {
      jobSubscription?.unsubscribe();
    };
  }, [jobSubscription, userStore.userCompany?.id]);

  const handlePageChange = useCallback(
    (pagination: PaginationLink) => {
      fetchDriverDays(pagination);
    },
    [fetchDriverDays],
  );

  const columns = useMemo(() => {
    return [
      ...DRIVER_DAY_COLUMNS,
      {
        field: 'actions',
        headerName: `${t('actions.actions')}`,
        width: 96,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        renderHeader: () => (
          <HeaderNavigation
            aria-label="pagination area"
            count={driverDayStore.driverDays.length}
            loading={isLoadingDriverDays}
            pagination={driverDayStore.pagination}
            callback={handlePageChange}
          />
        ),
        renderCell: () => <></>,
      },
    ];
  }, [isLoadingDriverDays, driverDayStore.driverDays, driverDayStore.pagination]);

  return (
    <Box>
      <DataGrid
        id="driver-day-grid"
        rows={driverDayStore.driverDays.map((driverDay, index) =>
          transformDriverDayToDriverDayRow(index, driverDay),
        )}
        columns={columns}
        loading={isLoadingDriverDays}
        disableColumnFilter
        hideQuickFilter
        getDetailPanelContent={(args) => {
          return <DriverDayDetails driverDayId={args.row.driverDayId} />;
        }}
      ></DataGrid>
    </Box>
  );
});
