import { Settlement_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { omit } from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Settlement } from '~hooks/useSettlements';
import { GetPaginationParams, PaginationLink } from '~interfaces/pagination';
import { SettlementDTO } from '~pages/Approvals/DriverPay';
import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import {
  SettlementSearchParamName,
  SettlementSearchParams,
} from '~store/SettlementsStore';

export default function useSettlements() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { settlementsStore } = useStores();

  const createSettlement = async (data: SettlementDTO) => {
    try {
      setIsCreating(true);

      const response = await connection.post<Settlement_Read>(
        `${API_VERSION}/settlements`,
        Settlement.deparse(data),
        {},
        $t('error_messages.settlements.failed_to_create') as string,
      );

      const newSettlement = Settlement.parse(response);
      settlementsStore.addSettlement(newSettlement);

      return newSettlement;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to create settlement');
    } finally {
      setIsCreating(false);
    }
  };

  const getSettlements = async (link?: PaginationLink) => {
    try {
      setIsLoading(true);

      const params: GetPaginationParams & SettlementSearchParams = {
        'page[limit]': settlementsStore.pagination.limit,
        // Omit category from searchParams since it is already in the URL and not accepted as a query param.
        ...omit(settlementsStore.searchParams, [
          SettlementSearchParamName.SETTLEMENT_CATEGORY,
        ]),
      };

      if (link && settlementsStore.pagination[link]) {
        params[`page[${link}]`] = settlementsStore.pagination[link];
      }

      const category =
        settlementsStore.searchParams[SettlementSearchParamName.SETTLEMENT_CATEGORY];

      if (!category) {
        throw new Error('Settlements category not set.');
      }

      const response = await connection.getPaginated<Settlement_Read>(
        `${API_VERSION}/settlements/${category}`,
        { params },
        $t('error_messages.settlements.failed_to_fetch') as string,
      );

      const { data, pagination } = response;
      const settlements = data.map((settlement) => Settlement.parse(settlement));

      settlementsStore.setSettlements(settlements);
      settlementsStore.setPagination(pagination);
      settlementsStore.updatePageNumber(link);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to retrieve settlements');
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeToSettlementsRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.SettlementUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Settlement_Read }) => {
            const settlement = Settlement.parse(data);

            settlementsStore.updateSettlement(settlement, true);
          },
        },
      );
    }

    return subscription;
  };

  const updateSettlement = async (settlementId: string, data: SettlementDTO) => {
    try {
      setIsUpdating(true);

      const response = await connection.patch<Settlement_Read>(
        `${API_VERSION}/settlements/${settlementId}`,
        Settlement.deparseUpdate(data),
        {},
        $t('error_messages.settlements.failed_to_update') as string,
      );

      const updatedSettlement = Settlement.parse(response);
      settlementsStore.updateSettlement(updatedSettlement);

      return updatedSettlement;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to update settlement');
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    createSettlement,
    getSettlements,
    isCreating,
    isLoading,
    isUpdating,
    subscribeToSettlementsRTU,
    updateSettlement,
  };
}
