import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import Edit from '@mui/icons-material/Edit';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Sms from '@mui/icons-material/Sms';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  GridCellEditStopReasons,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { get, isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DataGridSmallButton, HeaderNavigation } from '~components/DataGrid';
import DataGrid, {
  DataGridBottomBarButton,
  DataGridTableAPI,
} from '~components/DataGrid/DataGrid';
import { DialogHeader, ModalDialog } from '~components/Dialog';
import {
  AssignJobToView,
  BulkAssignJobsDialog,
  BulkSendJobsDialog,
  EquipmentAssignment,
  SendTextDialog,
  Status as JobStatus,
  useJobsDataGridBulkActions,
} from '~components/Job';
import {
  renderDateEditCell,
  renderTimeEditCell,
  SelectEditCell,
  TextEditCell,
} from '~components/Job/InlineEditFields';
import { SimpleMenu } from '~components/Menu';
import { BasicTooltip } from '~components/Tooltip';
import { JobStatusFilter } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Job, useJob } from '~hooks/useJob';
import { JobFormPropsWithRates } from '~hooks/useJob/models';
import { JobEventType } from '~hooks/useJob/useJob';
import { Site } from '~hooks/useSites';
import Send from '~images/send.svg?react';
import { PaginationLink } from '~interfaces';
import {
  acceptedJobStates,
  activeJobStates,
  allJobStates,
  assignedJobStates,
  doneJobStates,
  droppingJobStates,
  editableJobStates,
  loadingJobStates,
  nonEditableJobStates,
  pendingJobStates,
  rejectedJobStates,
  sentJobStates,
} from '~pages/Dispatch/constants/jobStates';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat, minutesToHoursMins } from '~utils/dateTime';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { isActionClicked } from '~utils/utilFunctions';

import { JobDetailsContainer as JobDetails } from './JobDetailsContainer';
import { JobEditForm } from './JobEditForm';

interface Props {
  orderId?: string;
  // Search value in orders datagrid filters on the job list
  orderSearchValue?: string;
  jobs?: Job[];
  searchValue: string;
  jobStatusFilter?: string;
  isNestedFromOrdersGrid?: boolean;
}

interface SendTextDialogState {
  isOpen: boolean;
  job?: Job;
}

type BulkAssignJobsDialogState = {
  isOpen: boolean;
  selectedVendorAccountId: string | null;
};

export const canSendInvitation = (job: Job) => {
  const { editable: userCanEditJob, driver, vendorJobAssignment, status } = job;
  const jobIsEligibleToBeSent =
    !!(driver || vendorJobAssignment) &&
    ![...nonEditableJobStates, JobState.ACCEPTED, JobState.REJECTED].includes(status);
  const jobIsAssignedToVendor = !!vendorJobAssignment;

  if (!jobIsEligibleToBeSent) {
    return false;
  }

  if (userCanEditJob) {
    const userCanAccessDriver = !!driver?.id;
    if (userCanAccessDriver || jobIsAssignedToVendor) {
      return true;
    }
  }

  return false;
};

export const canAcceptJob = (job: Job) => {
  const { status, driver, vendorJobAssignment, editable } = job;

  return editable && [JobState.SENT].includes(status) && !driver && !vendorJobAssignment;
};

const JobsOrderDataGrid = observer(
  ({
    orderId = '',
    orderSearchValue = '',
    jobs: providedJobs,
    searchValue,
    jobStatusFilter,
    isNestedFromOrdersGrid,
  }: Props) => {
    const theme = useTheme();

    const { userStore, ordersStore, jobStore, companyAssetsStore, toasterStore } =
      useStores();
    const {
      getJobsByOrder,
      cloneJob,
      updateJob,
      doEvent,
      isLoading,
      getAllJobs,
      isUpdating: isJobUpdating,
      getJobTimeline,
      subscribeToJobUpdates,
      subscribeToBulkAssignJobsUpdates,
      subscribeToBulkSendJobsUpdates,
      bulkAssignJobs,
      bulkSendJobs,
      subscription,
    } = useJob();

    // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
    const loadingRef = useRef(isLoading);
    useEffect(() => {
      loadingRef.current = isLoading;
    }, [isLoading]);

    const dispatchFilters = jobStore.dispatchFilter;

    const { setSearchQueryValue } = useDataGridSearch();
    let jobFilters = jobStore.jobFilter;

    const { selectedRows, setSelectedRows, setJobsList, assignableJobs, sendableJobs } =
      useJobsDataGridBulkActions();
    const [bulkAssignJobsDialog, setBulkAssignJobsDialog] =
      useState<BulkAssignJobsDialogState>({
        isOpen: false,
        selectedVendorAccountId: null,
      });
    const [bulkSendJobsDialog, setBulkSendJobsDialog] = useState({ isOpen: false });

    switch (jobStatusFilter) {
      case JobStatusFilter.ACTIVE:
        jobFilters = { ...jobFilters, states: activeJobStates };
        break;
      case JobStatusFilter.PENDING:
        jobFilters = { ...jobFilters, states: pendingJobStates };
        break;
      case JobStatusFilter.ASSIGNED:
        jobFilters = { ...jobFilters, states: assignedJobStates };
        break;
      case JobStatusFilter.SENT:
        jobFilters = { ...jobFilters, states: sentJobStates };
        break;
      case JobStatusFilter.REJECTED:
        jobFilters = { ...jobFilters, states: rejectedJobStates };
        break;
      case JobStatusFilter.ACCEPTED:
        jobFilters = { ...jobFilters, states: acceptedJobStates };
        break;
      case JobStatusFilter.LOADING:
        jobFilters = { ...jobFilters, states: loadingJobStates };
        break;
      case JobStatusFilter.DROPPING:
        jobFilters = { ...jobFilters, states: droppingJobStates };
        break;
      case JobStatusFilter.DONE:
        jobFilters = { ...jobFilters, states: doneJobStates };
        break;
      default:
        break;
    }
    // Merge dispatchFilters and jobFilters
    const mergedFilters = { ...dispatchFilters, ...jobFilters };

    const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
    const [isJobDialogOpen, setIsJobDialogOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const jobActionsRef = useRef<any>(null);
    const modalDialogRef = useRef<any>(null);
    const [sendTextDialog, setSendTextDialog] = useState<SendTextDialogState>({
      isOpen: false,
    });

    const openBulkAssignJobsDialog = useCallback(() => {
      setBulkAssignJobsDialog({ isOpen: true, selectedVendorAccountId: null });
    }, []);

    const closeBulkAssignJobsDialog = useCallback(() => {
      setBulkAssignJobsDialog((state) => ({ ...state, isOpen: false }));
    }, []);

    const openBulkSendJobsDialog = useCallback(() => {
      setBulkSendJobsDialog({ isOpen: true });
    }, []);

    const closeBulkSendJobsDialog = useCallback(() => {
      setBulkSendJobsDialog({ isOpen: false });
    }, []);

    const openSendTextDialog = useCallback((job: Job) => {
      setSendTextDialog({ isOpen: true, job });
    }, []);

    const closeSendTextDialog = useCallback(() => {
      setSendTextDialog((state) => ({ ...state, isOpen: false }));
    }, []);

    const materialsOptions = companyAssetsStore.allMaterials;

    // The 2 sources of job data will be from either the parent order if this datagrid is nested in an order data grid,
    // Or the jobStore which will be called on the initial load of the component if an orderId is not provided
    const jobs = providedJobs || jobStore.jobs;

    const selectedJob = useMemo(() => {
      return jobStore.jobById(selectedId as string) || null;
    }, [selectedId, jobStore]);

    const userPermissions = useMemo(() => {
      return userStore.getPermissions();
    }, [userStore.getPermissions()]);

    useEffect(() => {
      if (!subscription && userStore.userCompany?.id) {
        subscribeToJobUpdates();
      }
      return () => {
        subscription?.unsubscribe?.();
      };
    }, [subscription, userStore.userCompany?.id]);

    useEffect(() => {
      const fetchJobs = async () => {
        if (orderId) {
          await getJobsByOrder(orderId, orderSearchValue, ordersStore.dispatchFilter);
        } else if ((jobStore.areFilterOptionsLoaded && !providedJobs) || !providedJobs) {
          await getAllJobs({} as PaginationLink, searchValue, mergedFilters);
        }
      };

      fetchJobs();
    }, [
      JSON.stringify(jobFilters),
      JSON.stringify(dispatchFilters),
      searchValue,
      orderSearchValue,
      jobStore.areFilterOptionsLoaded,
    ]);

    // Update 'jobs' state if updates to the JobsStore occurs

    const getFilteredCompanyJobs = async (
      link?: PaginationLink,
      searchQuery?: string,
    ) => {
      await getAllJobs(link || ({} as PaginationLink), searchQuery, mergedFilters);
    };

    const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
      setCurrentFormDirty(isDirty);
    };

    const handleClose = async () => {
      await jobActionsRef.current?.fileAttachmentsOnClose();
      setIsJobDialogOpen(false);
    };

    const onSuccess = async (job: Job) => {
      if (selectedId) {
        getJobTimeline(selectedId);
      }
      await jobActionsRef.current?.fileAttachmentsOnSubmit(job.id);
      setSelectedId(null);
      setIsJobDialogOpen(false);
      toasterStore.push(
        alert(t('dispatch.job.updated', { name: job?.jobId }), AlertTypes.success),
      );
    };
    const copyRow = (id: string) => {
      return cloneJob(id).then((job: Job) => {
        toasterStore.push(
          alert(t('dispatch.job.copied', { name: job?.jobId }), AlertTypes.success),
        );
      });
    };

    const onSubmitJobFormCallback = (data: JobFormPropsWithRates) => {
      if (selectedId) {
        updateJob(selectedId, data).then((job) => {
          onSuccess(job);
        });
      }
    };

    const onSubmitJobForm = () => {
      jobActionsRef.current?.submit(onSubmitJobFormCallback);
    };

    const editRow = (id: string) => {
      setSelectedId(id);
      setIsJobDialogOpen(true);
    };

    const doAction = (
      id: string,
      action: JobEventType,
      showToastMessage: boolean = true,
    ) => {
      doEvent(id, action).then((response) => {
        getJobTimeline(response.id);

        setSelectedId(null);
        modalDialogRef.current?.close();

        if (showToastMessage) {
          toasterStore.push(
            alert(
              t('dispatch.job.updated', { name: response.jobId }),
              AlertTypes.success,
            ),
          );
        }
      });
    };

    const cancelJob = (id: string) => {
      setSelectedId(id);
      modalDialogRef.current?.open();
    };

    const handleBulkAssignJobsClick = useCallback(() => {
      if (bulkAssignJobsDialog.selectedVendorAccountId && assignableJobs.length) {
        bulkAssignJobs(assignableJobs, bulkAssignJobsDialog.selectedVendorAccountId);
      }

      closeBulkAssignJobsDialog();
    }, [
      bulkAssignJobsDialog.selectedVendorAccountId,
      assignableJobs,
      closeBulkAssignJobsDialog,
    ]);

    const handleBulkSendJobsClick = useCallback(() => {
      if (sendableJobs.length) {
        bulkSendJobs(sendableJobs, []);
      }

      closeBulkSendJobsDialog();
    }, [sendableJobs, closeBulkSendJobsDialog]);

    useEffect(() => {
      setJobsList(jobs);
    }, [jobs]);

    const handleCellEditStop: GridEventListener<'cellEditStop'> = (params, event) => {
      if (
        [
          GridCellEditStopReasons.tabKeyDown,
          GridCellEditStopReasons.cellFocusOut,
        ].includes(
          // @ts-ignore
          params.reason,
        )
      ) {
        event.defaultMuiPrevented = true;
      }
    };

    const processRowUpdate = (newRow: GridRowModel, jobModel: Job) => {
      if (jobModel.editable) {
        return updateJob(jobModel.id, newRow, true)
          .then((job) => {
            toasterStore.push(
              alert(t('dispatch.job.updated', { name: job.jobId }), AlertTypes.success),
            );
            return job;
          })
          .catch(() => {
            jobStore.updateJob(jobModel);
          });
      }
    };

    const [now, setNow] = useState<dayjs.Dayjs>(dayjs());

    useEffect(() => {
      const intervalId = setInterval(() => {
        setNow(dayjs());
      }, 60 * 1000); // Every one minute

      return () => clearInterval(intervalId);
    }, []);

    const nonRoutableSiteAlertEnabled = useFeatureFlag({
      featureFlagKey: FeatureFlags.nonRoutableAlerts,
    });

    const columns: GridColDef[] = useMemo(
      () =>
        [
          {
            field: 'jobStartAt',
            flex: 1,
            headerName: `${t('dispatch.job.start_time')}`,
            hideable: false,
            editable: userPermissions.canEdtJob,
            valueGetter: (params) => new Date(params.row.jobStartAt),
            renderCell: (params) => {
              return params.row.id ? (
                <BasicTooltip title={dateFormat(params.value)}>
                  <Typography fontSize="12px">
                    {dateFormat(params.value, 'hh:mm A')}
                  </Typography>
                </BasicTooltip>
              ) : null;
            },
            renderEditCell: renderTimeEditCell,
          },
          {
            field: 'scheduled_date',
            flex: 1,
            headerName: `${t('dispatch.job.start_date')}`,
            hideable: false,
            editable: userPermissions.canEdtJob,
            valueGetter: (params) => new Date(params.row.jobStartAt),
            renderCell: (params) => {
              return params.row.id ? (
                <BasicTooltip title={dateFormat(params.value)}>
                  <Typography fontSize="12px">
                    {dateFormat(params.value, 'MM/DD/YYYY')}
                  </Typography>
                </BasicTooltip>
              ) : null;
            },
            renderEditCell: renderDateEditCell,
          },
          {
            field: 'jobId',
            flex: 1,
            headerName: `${t('form_fields.job_id')}`,
            valueGetter: (params) => params.row.jobId,
          },
          {
            field: 'orderDispatchNumber',
            flex: 1,
            headerName: `${t('dispatch.order.order_dispatch_number')}`,
            valueGetter: (params) => params.row.order?.dispatchNumber,
          },
          {
            field: 'vendor',
            flex: 1,
            headerName: `${t('form_fields.vendor')}`,
            valueGetter: (params) => {
              return params.row.vendorJobAssignment?.vendorAccount?.name;
            },
            renderCell: (params) => {
              return params.row.id && !canAcceptJob(params.row) ? (
                <AssignJobToView
                  jobId={params.row.id}
                  driver={params.row.driver}
                  vendor={params.row?.vendorJobAssignment?.vendorAccount}
                  status={params.row.status}
                  editable={
                    params.row.editable && params.row.vendorJobAssignment?.vendorAccount
                  }
                  companyId={
                    params.row?.customerJobAssignment?.customerAccount
                      ?.accountBasicCompanyId
                  }
                  valueToDisplay={'vendor'}
                />
              ) : null;
            },
          },
          {
            field: 'driver',
            flex: 1,
            headerName: `${t('form_fields.driver')}`,
            valueGetter: (params) => {
              const driverName = `${params.row.driver?.firstName} ${params.row.driver?.lastName}`;
              return params.row.driver ? driverName : null;
            },
            renderCell: (params) => {
              return params.row.id && !canAcceptJob(params.row) ? (
                <AssignJobToView
                  jobId={params.row.id}
                  driver={params.row.driver}
                  vendor={params.row?.vendorJobAssignment?.vendorAccount}
                  status={params.row.status}
                  editable={params.row.editable && params.row.driver}
                  companyId={
                    params.row?.customerJobAssignment?.customerAccount
                      ?.accountBasicCompanyId
                  }
                  valueToDisplay={'driver'}
                />
              ) : null;
            },
          },
          {
            field: 'equipment',
            flex: 1,
            headerName: `${t('form_fields.truck')}`,
            type: 'singleSelect',
            valueOptions: [...new Set(jobs.map((row) => row?.equipment?.name || ''))],
            valueGetter: (params) => params.row.equipment?.name,
            renderCell: (params) => {
              const { status, editable, driver, vendorJobAssignment } = params.row;
              const canBeEdit =
                editable &&
                (editableJobStates.includes(status) ||
                  (!!(driver || vendorJobAssignment) &&
                    !nonEditableJobStates.includes(status)));
              return (
                <EquipmentAssignment
                  name={'equipment'}
                  nameOrigin={'_equipment'}
                  job={params.row}
                  editable={canBeEdit}
                  companyId={userStore.userCompany.id}
                />
              );
            },
          },
          {
            field: 'truckNumber',
            flex: 1,
            headerName: `${t('form_fields.truck_number')}`,
            valueGetter: (params) => params.row?.equipment?.companyShare?.externalId,
          },
          {
            field: 'additionalEquipment',
            headerName: `${t('form_fields.trailer')}`,
            flex: 1,
            type: 'singleSelect',
            valueOptions: [
              ...new Set(jobs.map((row) => row?.additionalEquipment?.name || '')),
            ],
            valueGetter: (params) => params.row.additionalEquipment?.name,
            renderCell: (params) => {
              const { status, editable, driver, vendorJobAssignment } = params.row;
              const canBeEdit =
                editable &&
                (editableJobStates.includes(status) ||
                  (!!(driver || vendorJobAssignment) &&
                    !nonEditableJobStates.includes(status)));

              return (
                <EquipmentAssignment
                  name={'additionalEquipment'}
                  nameOrigin={'_additional_equipment'}
                  job={params.row}
                  editable={canBeEdit}
                  companyId={userStore.userCompany.id}
                />
              );
            },
          },
          {
            field: 'quantity',
            flex: 1,
            editable: true,
            headerName: `${t('form_fields.qty')}`,
            type: Number,
            valueGetter: (params) =>
              params.row.quantity ? Number(params.row.quantity) : null,
            renderCell: (params: GridValueGetterParams) => {
              return (
                <Typography fontSize="12px">
                  {params.value} {params.row.unitOfMeasure?.name}
                </Typography>
              );
            },
            renderEditCell: (params: GridValueGetterParams) => {
              return (
                <TextEditCell
                  id={params.id as string}
                  value={params.value}
                  name={'quantity'}
                  type={'number'}
                  field={params.field}
                  api={params.api}
                />
              );
            },
          },
          {
            field: 'material',
            flex: 1,
            headerName: `${t('form_fields.materials')}`,
            type: 'singleSelect',
            valueOptions: [...new Set(jobs.map((row) => row?.material?.name))],
            editable: userPermissions.canEdtJob,
            valueGetter: (params) => params.row.material?.name,
            renderCell: (params) => {
              return <Typography fontSize="12px">{params.row.material?.name}</Typography>;
            },
            renderEditCell: (params) => {
              const value = materialsOptions.find(
                (eq) => eq?.id === params.row._material?.id,
              );
              return (
                <SelectEditCell
                  id={params.id as string}
                  name={'_material'}
                  field={params.field}
                  value={value}
                  optionsList={materialsOptions}
                  api={params.api}
                  disabled={!params.row?._editable}
                />
              );
            },
          },
          {
            field: 'pickUpSite',
            flex: 1,
            headerName: `${t('form_fields.pick_up_site')}`,
            valueGetter: (params: GridValueGetterParams) => {
              return get(params.row, 'pickUpWayPoints[0].site.name', '');
            },
          },
          {
            field: 'dropOffSite',
            flex: 1,
            headerName: `${t('form_fields.drop_off_site')}`,
            valueGetter: (params: GridValueGetterParams) => {
              return get(params.row, 'dropOffWayPoints[0].site.name', '');
            },
          },
          {
            field: 'statusChangedAt',
            flex: 1,
            headerName: `${t('form_fields.status_time')}`,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params: GridValueGetterParams) => {
              const { status, statusChangedAt } = params.row;
              const showStatusTime =
                ![JobState.CANCELED, JobState.COMPLETED].includes(status) &&
                statusChangedAt;

              // To prevent showing negative time and re-trigger timer if row is via RTU/Event
              const statusTime = statusChangedAt
                ? minutesToHoursMins(
                    now.unix() - statusChangedAt?.unix() > 0
                      ? Math.floor((now.unix() - statusChangedAt.unix()) / 60)
                      : 0,
                  )
                : '';

              return (
                <>
                  {showStatusTime && statusTime && (
                    <Typography fontSize="12px">{statusTime}</Typography>
                  )}
                </>
              );
            },
          },
          {
            field: 'loadCycleAvg',
            headerName: `${t('form_fields.load_cycle_avg')}`,
            type: 'number',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridValueGetterParams) => {
              return params.value ? (
                <>
                  {
                    <Typography variant="body1">
                      {minutesToHoursMins(params.value)}
                    </Typography>
                  }
                </>
              ) : null;
            },
          },
          {
            field: 'status',
            minWidth: 156,
            headerName: `${t('form_fields.status')}     `,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            align: 'right',
            headerAlign: 'center',
            hideable: false,
            renderCell: (params) => {
              const { id, status } = params.row;

              const canSendInvites = canSendInvitation(params.row);
              let showDriverActions = false;
              let showStatus = false;
              let showVendorActions = false;
              const showDisabledSendButton = [
                JobState.CREATED,
                JobState.REASSIGN,
              ].includes(status);
              const isPaused = !!params.row.paused_at;

              if (canAcceptJob(params.row)) {
                showVendorActions = true;
              } else if (
                canSendInvites ||
                !allJobStates.includes(status) ||
                showDisabledSendButton
              ) {
                showDriverActions = true;
              } else {
                showStatus = true;
              }
              return (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                >
                  {showVendorActions && (
                    <>
                      <DataGridSmallButton
                        variant="contained"
                        color="success"
                        onClick={() => doAction(id, 'accept')}
                      >
                        {t('actions.accept')}
                      </DataGridSmallButton>

                      <DataGridSmallButton
                        variant="contained"
                        color="error"
                        onClick={() => doAction(id, 'reject')}
                      >
                        {t('actions.decline')}
                      </DataGridSmallButton>
                    </>
                  )}

                  {showDriverActions && (
                    <DataGridSmallButton
                      sendButton
                      variant="contained"
                      color="primary"
                      onClick={() => doAction(id, 'request')}
                      startIcon={<Send />}
                      disabled={
                        !canSendInvites || status === 'sent' || showDisabledSendButton
                      }
                    >
                      {t('actions.send')}
                    </DataGridSmallButton>
                  )}

                  {showStatus && <JobStatus status={status} isPaused={isPaused} />}
                </Box>
              );
            },
          },
          ...(nonRoutableSiteAlertEnabled
            ? [
                {
                  field: 'alerts',
                  headerName: t('actions.alerts'),
                  sortable: false,
                  filterable: true,
                  disableColumnMenu: true,
                  hideable: false,
                  display: 'flex',
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params: any) => {
                    const hasNonRoutableSite = params.row.waypoints?.some(
                      ({ siteNested }: { siteNested: Site }) => !siteNested?.routable,
                    );
                    return params.row.id ? (
                      <Box>
                        {hasNonRoutableSite && (
                          <BasicTooltip title={t('common.address_confirmation_required')}>
                            <WarningAmber color="primary" />
                          </BasicTooltip>
                        )}
                      </Box>
                    ) : null;
                  },
                },
              ]
            : []),
          {
            field: 'actions',
            width: 114,
            type: 'actions',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            disableReorder: true,
            headerAlign: 'center',
            hideable: false,
            renderHeader: () => (
              <HeaderNavigation
                count={jobStore.jobs?.length || 0}
                loading={loadingRef.current}
                pagination={jobStore.pagination}
                callback={getFilteredCompanyJobs}
                altText={`${t('actions.actions')}`}
                searchQuery={searchValue}
              />
            ),
            renderCell: (params) => {
              const { editable, status, loadCycleAvg, driver, vendorJobAssignment } =
                params.row;
              const actions = [];

              if (userPermissions.canEdtJob && editable) {
                actions.push({
                  title: `${t('dispatch.job.edit_job')}`,
                  icon: <Edit />,
                  callBack: () => editRow(params.row.id),
                });
              }

              if (userPermissions.canCreateJob) {
                actions.push({
                  title: `${t('dispatch.job.clone_job')}`,
                  icon: <FileCopyOutlined />,
                  callBack: () => {
                    copyRow(params.row.id).then(() => {
                      if (params.row.status === JobState.REJECTED) {
                        doAction(params.row.id, 'cancel', false);
                      }
                    });
                  },
                });
              }

              if (userPermissions.canEdtJob && editable) {
                const nextJobEvents = params.row.stateEvents;
                const isRejected = params.row.status === JobState.REJECTED;

                nextJobEvents.forEach((event: JobEventType) => {
                  if (['complete', 'cancel', 'assign'].includes(event)) {
                    const isCancellable = event === 'cancel';

                    if (isRejected && isCancellable) {
                      actions.push({
                        title: `${t('actions.move_to', { event: t(`actions.${event}`) })}`,
                        icon: <TrendingFlat />,
                        callBack: () => doAction(params.row.id, event),
                      });
                    }

                    return;
                  }

                  actions.push({
                    title: `${t('actions.move_to', { event: t(`actions.${event}`) })}`,
                    icon: <TrendingFlat />,
                    callBack: () => doAction(params.row.id, event),
                  });
                });
              }

              if (
                userPermissions.canEdtJob &&
                editable &&
                ([
                  JobState.SENT,
                  JobState.ACCEPTED,
                  JobState.TO_PICKUP,
                  JobState.TO_DROPOFF,
                  JobState.ARRIVED_DROPOFF,
                  JobState.ARRIVED_DROPOFF,
                  JobState.LOADED,
                  JobState.UNLOADED,
                  JobState.IN_REVIEW,
                ].includes(status) ||
                  !!(driver || vendorJobAssignment)) &&
                ![
                  JobState.COMPLETED,
                  JobState.FLAGGED,
                  JobState.SIGNED_OFF,
                  JobState.PAID,
                  JobState.CANCELED,
                  JobState.LOAD_COMPLETED,
                ].includes(status)
              ) {
                actions.push({
                  title: `${t('dispatch.job.mark_as_completed')}`,
                  icon: <CheckCircleOutlined />,
                  callBack: () => doAction(params.row.id, 'complete'),
                });
              }

              if (
                userPermissions.canDeleteJob &&
                editable &&
                ![JobState.REJECTED, JobState.CANCELED, JobState.COMPLETED].includes(
                  status,
                )
              ) {
                actions.push({
                  title: `${t('dispatch.job.cancel_job')}`,
                  icon: <CancelOutlined />,
                  callBack: () => cancelJob(params.row.id),
                });
              }

              const hasPhone = Boolean(driver?.phone);

              actions.push({
                callBack: () => openSendTextDialog(params.row),
                disabled: !hasPhone,
                icon: <Sms />,
                title: `${t('actions.send_text')}`,
              });

              return params.row.id ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    justifyContent: loadCycleAvg ? 'space-between' : 'end',
                  }}
                >
                  {loadCycleAvg ? (
                    <Typography variant="body1">
                      {minutesToHoursMins(loadCycleAvg)}
                    </Typography>
                  ) : null}

                  <SimpleMenu options={actions}>
                    <MoreHoriz />
                  </SimpleMenu>
                </Box>
              ) : null;
            },
          },
        ] as GridColDef[],
      [jobs, now],
    );

    const initialState = useMemo(
      () => ({
        sorting: {
          sortModel: [
            {
              field: 'jobStartAt',
              sort: 'asc',
            },
          ],
        },
        columns: {
          columnVisibilityModel: {
            // Material: false,
            eta: false,
          },
        },
      }),
      [],
    );

    const onChangeQuickFilter = (searchQuery: string) => {
      setSearchQueryValue(searchQuery);
    };

    useEffect(() => {
      if (isNil(userStore.userCompany?.id)) return;

      subscribeToBulkAssignJobsUpdates((data) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.assigned_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
        );
      });

      subscribeToBulkSendJobsUpdates((data) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.sent_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
        );
      });
    }, [userStore.userCompany?.id]);

    const dataGridRef = useRef<DataGridTableAPI>(null);

    return (
      <Box sx={{ py: 0, background: '#fff' }} className={'jobs-datagrid'}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' },
            '& .MuiDataGrid-columnHeader': {
              borderTop: `solid 1px ${theme.palette.divider}`,
              borderBottom: `solid 1px ${theme.palette.divider}`,
              borderLeft: `solid 1px ${theme.palette.divider}`,
              '&:last-child': {
                borderRight: `solid 1px ${theme.palette.divider}`,
              },
            },
          }}
          id={`dispatch-jobs-datagrid${orderId ? `-nested` : ''}`}
          ref={dataGridRef}
          columns={columns}
          rows={jobs as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          getDetailPanelContent={({ row }) => <JobDetails details={row as Job} />}
          onCellEditStop={handleCellEditStop}
          processRowUpdate={processRowUpdate}
          onChangeFilter={onChangeQuickFilter}
          filterMode="server"
          hideToolbar
          selectedRowIDs={isNestedFromOrdersGrid ? undefined : selectedRows}
          onSelectedRowIDsChange={setSelectedRows}
          bottomBarProps={{
            elements: (
              <>
                <DataGridBottomBarButton
                  disabled={sendableJobs.length === 0}
                  onClick={openBulkSendJobsDialog}
                >
                  {t('actions.send')}
                </DataGridBottomBarButton>

                <DataGridBottomBarButton
                  disabled={assignableJobs.length === 0}
                  onClick={openBulkAssignJobsDialog}
                >
                  {t('actions.assign')}
                </DataGridBottomBarButton>
              </>
            ),
          }}
        />

        <Dialog
          open={isJobDialogOpen}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            isActionClicked(reason) && handleClose();
          }}
          maxWidth={'lg'}
        >
          <DialogHeader
            closeCallBack={handleClose}
            title={
              <>
                <Typography component={'span'} variant={'h5'}>
                  {t('dispatch.job.edit_job')}{' '}
                  <JobStatus status={selectedJob?.status} sx={{ ml: 1 }} />
                  {selectedJob && (
                    <Typography component="div" variant="subtitle1">
                      {`${selectedJob.jobId}`}
                    </Typography>
                  )}
                </Typography>
              </>
            }
          />
          <DialogContent
            sx={{
              backgroundColor: theme.palette.grey[100],
            }}
          >
            {selectedJob && (
              <JobEditForm
                ref={jobActionsRef}
                defaultJob={selectedJob}
                onFormStateChange={onFormStateChange}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              m: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <LoadingButton
              disabled={isJobUpdating || !currentFormDirty}
              loading={isJobUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitJobForm}
              data-test-id={'job-action-btn'}
              type="button"
              variant="contained"
              color="primary"
              sx={isJobUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {t('actions.submit')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isJobUpdating}
              color="secondary"
              variant="outlined"
            >
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        <ModalDialog
          ref={modalDialogRef}
          loading={isJobUpdating}
          title={t('dispatch.job.cancel_job')}
          content={t('dispatch.job.cancel_description')}
          confirmButtonText={`${t('actions.confirm')}`}
          confirmButtonColor={'error'}
          callBack={() => doAction(selectedId || '', 'cancel')}
        />

        <BulkSendJobsDialog
          isOpen={bulkSendJobsDialog.isOpen}
          onClose={closeBulkSendJobsDialog}
          onConfirm={handleBulkSendJobsClick}
        />

        <BulkAssignJobsDialog
          accountId={bulkAssignJobsDialog.selectedVendorAccountId}
          companyId={userStore.userCompany.id}
          isOpen={bulkAssignJobsDialog.isOpen}
          onClose={closeBulkAssignJobsDialog}
          onConfirm={handleBulkAssignJobsClick}
          onSelectedAccountChange={(account) => {
            setBulkAssignJobsDialog((state) => ({
              ...state,
              selectedVendorAccountId:
                state.selectedVendorAccountId === account.id ? null : account.id,
            }));
          }}
        />

        <SendTextDialog
          isOpen={sendTextDialog.isOpen}
          job={sendTextDialog.job}
          onClose={closeSendTextDialog}
        />
      </Box>
    );
  },
);

export { JobsOrderDataGrid };
