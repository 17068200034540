import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { useStores } from '~store/RootStore';

import { ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '../calendar/CalendarDispatchFiltersBar';
import { SearchInput } from './Filters/SearchInput';

export const DriverSearch = observer(() => {
  const { driverSchedulerStore } = useStores();

  const applyDriversFilter = useCallback(
    _.debounce(() => {
      driverSchedulerStore.setDriversFilters(
        { search: driverSchedulerStore.driverSearch },
        true,
      );
    }, ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS),
    [driverSchedulerStore.driverSearch],
  );

  useEffect(() => {
    applyDriversFilter();

    return () => {
      applyDriversFilter.cancel();
    };
  }, [driverSchedulerStore.driverSearch]);

  return (
    <SearchInput
      onChange={(value) => driverSchedulerStore.setDriversSearch(value)}
      value={driverSchedulerStore.driverSearch}
    />
  );
});
