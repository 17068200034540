import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { Loads } from '~components/Job';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { Job } from '~hooks/useJob/models';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import { useStores } from '~store/RootStore';
import { dateFormat } from '~utils/dateTime';

import { ShiftDetails } from './ShiftDetails';

const emDash = '-';
const getFromToLocationString = (from: string, to: string) => `${from} to: ${to}`;
const formatMinutesToHoursString = (minutes: number): string =>
  `${(minutes / 60).toFixed(2)}h`;

export const DriverDayDetails = observer(({ driverDayId }: { driverDayId: string }) => {
  const [toggledJob, setToggledJob] = useState<Job | null>(null);
  const { driverDayStore } = useStores();
  const { fetchJobsForDriverDay, isLoadingDriverDayJobs } = useDriverDays();

  const jobIdsForDay = driverDayStore.jobIdsByDriverDayId[driverDayId];
  const jobsForDay = jobIdsForDay?.map((jobId) => driverDayStore.jobByJobId[jobId]);

  const driverDay = driverDayStore.driverDays.find((dDay) => dDay.id === driverDayId);

  useEffect(() => {
    fetchJobsForDriverDay(driverDayId);
  }, [driverDayId]);

  useEffect(() => {
    if (!toggledJob && jobsForDay && jobsForDay.length > 0) {
      setToggledJob(jobsForDay?.[0]);
    }
  }, [toggledJob, jobsForDay]);

  if (isLoadingDriverDayJobs || !jobsForDay) {
    return <Skeleton height={300} />;
  }
  return (
    <Box
      component="section"
      sx={{
        display: 'grid',
        gridTemplateColumns: '3fr 2fr',
        gap: 4,
        p: 2,
      }}
    >
      <Box
        aria-label={`job list for ${driverDayId}`}
        component="ul"
        sx={{ display: 'grid', gap: 2 }}
      >
        {jobsForDay.map((job, ind) => (
          <Box
            key={job.id}
            component="li"
            aria-label="job details"
            sx={{
              display: 'grid',
              alignContent: 'start',
              gap: 2,
            }}
          >
            <Box aria-label="job header">
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  bgcolor: 'grey.100',
                  p: 1,
                }}
              >
                <Typography
                  noWrap
                  paragraph
                  sx={{ fontWeight: 'bold', m: 0 }}
                >{`${t('approvals.driver_day.job')} ${ind + 1}: ${job.jobId}`}</Typography>
                <div>•</div>
                <Typography noWrap sx={{ maxWidth: '300px' }}>
                  {getFromToLocationString(
                    job.waypoints[0].site?.name ?? emDash,
                    job.waypoints[1].site?.name ?? emDash,
                  )}
                </Typography>
                <div>•</div>
                <Typography>{job.driverRateType}</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={toggledJob?.id === job.id}
                      onChange={() => {
                        setToggledJob(job);
                      }}
                    />
                  }
                  label={t('approvals.driver_day.show_map_toggle')}
                  sx={{ ml: 'auto' }}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 2, p: 2, color: 'grey.700' }}>
                <Typography variant="subtitle2">{`${t('approvals.driver_day.start_time')}: ${
                  driverDayStore.jobSummaryByJobId[job.id]?.loggingStartedAt
                    ? dateFormat(
                        driverDayStore.jobSummaryByJobId[job.id].loggingStartedAt,
                        'hh:mm A',
                      )
                    : emDash
                }`}</Typography>
                <Typography variant="subtitle2">{`${t('approvals.driver_day.end_time')}: ${
                  driverDayStore.jobSummaryByJobId[job.id]?.loggingEndedAt
                    ? dateFormat(
                        driverDayStore.jobSummaryByJobId[job.id].loggingEndedAt,
                        'hh:mm A',
                      )
                    : emDash
                }`}</Typography>
                <Typography variant="subtitle2">{`${t('approvals.driver_day.total_time')}: ${
                  driverDayStore.jobSummaryByJobId[job.id]?.workTimeMinutes
                    ? formatMinutesToHoursString(
                        driverDayStore.jobSummaryByJobId[job.id]?.workTimeMinutes,
                      )
                    : emDash
                }`}</Typography>
              </Box>
            </Box>
            <Box sx={{ pl: 1 }}>
              <Loads
                key={job.id}
                details={job}
                reload={() => {
                  fetchJobsForDriverDay(driverDayId, true);
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        aria-label="supplemental information"
        component="section"
        sx={{
          display: 'grid',
          alignContent: 'start',
          gap: 2,
          gridTemplateRows: 'auto minmax(360px, 1fr)',
        }}
      >
        {driverDay && <ShiftDetails driverDay={driverDay} />}
        {toggledJob && <TreadLiveMap job={toggledJob} />}
      </Box>
    </Box>
  );
});
