import {
  AccountType,
  AuthMethod,
  BillingStatus,
  CompanyType,
  Currency,
  DateFormat,
  EquipmentOwnership,
  EquipmentState,
  Language,
  MaterialState,
  RateType,
  SaasBillingPlan,
  SiteType,
  StytchMemberStatus,
  SystemOfMeasure,
  TimeFormat,
  TimeZone,
  UnitOfDistance,
  UsageStatus,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';

import { ItemNameAndId } from '~types/ItemNameAndId';
import { splitStringBySentenceChange } from '~utils/utilFunctions';

import countriesList from './countries.json';

const data = {
  date_format: {
    values: Object.values(DateFormat),
    default: DateFormat.MM_DD_YYYY,
  },
  language: { values: Object.values(Language), default: Language.EN },
  time_format: { values: Object.values(TimeFormat), default: TimeFormat._12_HOUR },
  unit_of_distance: {
    values: Object.values(UnitOfDistance),
    default: UnitOfDistance.MILE,
  },
  system_of_measure: {
    values: Object.values(SystemOfMeasure),
    default: SystemOfMeasure.IMPERIAL,
  },
  currency: { values: Object.values(Currency), default: Currency.USD },
  time_zone: {
    values: Object.values(TimeZone),
    default: TimeZone.US_EASTERN,
  },
  usage_status: {
    values: Object.values(UsageStatus),
    default: UsageStatus.DEMO,
  },
  billing_status: {
    values: Object.values(BillingStatus),
    default: BillingStatus.UNPAID,
  },
  saas_billing_plan: {
    values: Object.values(SaasBillingPlan),
    default: SaasBillingPlan.BASIC,
  },
  stytch_member_status: {
    values: Object.values(StytchMemberStatus),
    default: StytchMemberStatus.PENDING,
  },
  company_type: {
    values: Object.values(CompanyType),
  },
  account_type: {
    values: Object.values(AccountType),
    default: AccountType.CUSTOMER,
  },
  site_type: {
    values: Object.values(SiteType).map((item) => splitStringBySentenceChange(item)),
    default: null,
  },
};

// TODO: replace with BE definition. The example is below ^^
enum OrderState {
  CREATED = 'created',
  PENDING_REQUEST = 'pending_request',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}
enum OrderUnitOfMeasure {
  LOAD = 'Load',
  TONNE = 'Tonne',
  TON = 'Ton',
  YARD = 'Yard',
  METER = 'Meter',
  FOOT = 'Foot',
  LITER = 'Liter',
  HOUR = 'Hour',
  BUSHEL = 'Bushel',
  GALLON = 'Gallon',
  CUBIC_METER = 'CubicMeter',
  MILE = 'Mile',
  KILOMETER = 'Kilometer',
  BARREL = 'Barrel',
}

const countries = {
  values: countriesList,
  default: countriesList.find((country) => country.code === 'CA'),
};

const AccountCountriesList = ['CA', 'US'];

// Countries needed for accounts
const AccountCountries = {
  values: countriesList.filter((country) => AccountCountriesList.includes(country.code)),
  default: countriesList.find((country) => country.code === 'CA'),
};

// System roles are default roles that exist in every company
enum SystemRoles {
  PLATFORM_ADMIN = 'Platform Admin',
  COMPANY_ADMIN = 'Company Admin',
  DISPATCHER = 'Dispatcher',
  BILLER = 'Biller',
  DRIVER = 'Driver',
  FOREMAN = 'Foreman',
  REPORTING = 'Reporting',
}
export enum rateTimeUnits {
  HOURLY = 'hourly',
  DAILY = 'daily',
}
export { AccountType } from '@treadinc/horizon-api-spec';

export enum JobEventsActions {
  REQUEST = 'request',
  ACCEPT = 'accept',
  REJECT = 'reject',
  ENROUTE = 'enroute',
  ARRIVE = 'arrive',
  LOAD_MATERIAL = 'load_material',
  UNLOAD_MATERIAL = 'unload_material',
  COMPLETE = 'complete',
  UNASSIGN = 'unassign',
  CANCEL = 'cancel',
  SIGN_OFF = 'sign_off',
}
const unitKeys = Object.keys(OrderUnitOfMeasure) as (keyof typeof OrderUnitOfMeasure)[];
const unitOfMeasureOptions = unitKeys.map((unit) => {
  const value = OrderUnitOfMeasure[unit];
  return { name: unit, id: value };
});
const GeoFenceTypes = {
  CIRCLE: 'circle',
  POLYGON: 'polygon',
  EQUIPMENT: 'equipment',
};
const MovingGeoFencesTags = {
  LOADING: 'loading',
  UNLOADING: 'unloading',
  CHECK_IN: 'check_in',
  SCALING: 'scaling',
  TICKETING: 'ticketing',
};
const geofenceNoneOption = ItemNameAndId.parse({ name: $t('common.none'), id: 'none' });
const geoFenceCircleOption = ItemNameAndId.parse({
  name: $t('geofence.circle'),
  id: GeoFenceTypes.CIRCLE,
});
const geofenceEquipmentOption = ItemNameAndId.parse({
  name: $t('geofence.equipment'),
  id: GeoFenceTypes.EQUIPMENT,
});
const geofencePolygonOption = ItemNameAndId.parse({
  name: $t('geofence.polygon'),
  id: GeoFenceTypes.POLYGON,
});
const geofenceOptions = [
  geoFenceCircleOption,
  ItemNameAndId.parse({ name: $t('geofence.polygon'), id: GeoFenceTypes.POLYGON }),
  ItemNameAndId.parse({ name: $t('geofence.equipment'), id: GeoFenceTypes.EQUIPMENT }),
];

const movingGeoFencesTagsOptions = [
  ItemNameAndId.parse({
    name: $t('geofence.tags.loading'),
    id: MovingGeoFencesTags.LOADING,
  }),

  ItemNameAndId.parse({
    name: $t('geofence.tags.unloading'),
    id: MovingGeoFencesTags.UNLOADING,
  }),
  ItemNameAndId.parse({
    name: $t('geofence.tags.check_in'),
    id: MovingGeoFencesTags.CHECK_IN,
  }),
  ItemNameAndId.parse({
    name: $t('geofence.tags.scaling'),
    id: MovingGeoFencesTags.SCALING,
  }),
  ItemNameAndId.parse({
    name: $t('geofence.tags.ticketing'),
    id: MovingGeoFencesTags.TICKETING,
  }),
];
enum ContactTypes {
  SALES = 'sales',
  FOREMAN = 'foreman',
  COLLABORATOR = 'collaborator',
  SUPERVISOR = 'supervisor',
}
const contactTypesOptions = [
  ItemNameAndId.parse({
    name: $t('form_fields.contact_salesman'),
    id: ContactTypes.SALES,
  }),
  ItemNameAndId.parse({
    name: $t('form_fields.contact_foreman'),
    id: ContactTypes.FOREMAN,
  }),
  ItemNameAndId.parse({
    name: $t('form_fields.contact_collaborator'),
    id: ContactTypes.COLLABORATOR,
  }),
  ItemNameAndId.parse({
    name: $t('form_fields.contact_supervisor'),
    id: ContactTypes.SUPERVISOR,
  }),
];

// @todo Implement SSO, Google Auth & Microsoft Auth login strategies
type AllowedAuthMethod = Exclude<
  AuthMethod,
  | AuthMethod.GOOGLE_AUTH
  | AuthMethod.MICROSOFT_AUTH
  | AuthMethod.SSO
  | AuthMethod.PASSWORD // Excluded because it cannot be disabled by a user
>;

enum NoteTypes {
  ORDER = 'order',
  JOB = 'job',
  PROJECT = 'project',
  INTERNAL = 'internal',
}

enum JobAssignmentType {
  VENDOR = 'vendor',
  DRIVER = 'driver',
}

enum JobStatusFilter {
  ACTIVE = 'active',
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  SENT = 'sent',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  LOADING = 'loading',
  DROPPING = 'dropping',
  DONE = 'done',
  ALL = 'all',
}

enum OrderStatusFilter {
  EXTERNAL = 'external',
  ACTIVE = 'active',
  PENDING = 'pending',
  INPROGRESS = 'inProgress',
  DONE = 'done',
  All = 'all',
}

enum ProjectStatusFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  All = 'all',
}

enum AccountTypes {
  CUSTOMER = 'customer',
  VENDOR = 'vendor',
}

enum AccountTypesFilter {
  CUSTOMER = AccountType.CUSTOMER,
  VENDOR = AccountType.VENDOR,
  ALL = 'all',
}

enum UserTypesFilter {
  USERS = 'users',
  INTERNAL_DRIVERS = 'internal_drivers',
  EXTERNAL_DRIVERS = 'external_drivers',
}

enum EquipmentStatusFilter {
  COMPANY = 'company',
  SHARED = 'shared',
}

enum AddressType {
  PickUp = 'pickUp',
  DropOff = 'dropOff',
}

enum SearchParams {
  ACTIVE = 'active',
  DAY_RANGE = 'day_range',
  DONE = 'done',
  DATE_RANGE = 'date_range',
  DISPATCH_NUMBER = 'dispatch_numbers',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  PROJECT = 'project_ids',
  EXTERNAL_ID = 'external_ids',
  SERVICE = 'service_ids',
  RATE_TYPE = 'rate_types',
  CUSTOMER_ACCOUNT = 'customer_account_ids',
  VENDOR_ACCOUNT = 'vendor_account_ids',
  PICKUP_SITE = 'pickup_site_ids',
  DROPOFF_SITE = 'dropoff_site_ids',
  EQUIPMENT = 'equipment_ids',
  EQUIPMENT_TYPE = 'equipment_type_ids',
  MATERIAL = 'material_ids',
  DRIVER = 'driver_ids',
  STATE = 'states',
  STATUS = 'status',
  TRUCK = 'truck_ids',
  INVOICE_CATEGORY = 'category',
}

export {
  AccountCountries,
  AccountTypes,
  AccountTypesFilter,
  AddressType,
  ContactTypes,
  contactTypesOptions,
  countries,
  data,
  EquipmentOwnership as EquipmentOwnershipEnum,
  EquipmentState as EquipmentStates,
  EquipmentStatusFilter,
  geoFenceCircleOption,
  geofenceEquipmentOption,
  geofenceNoneOption,
  geofenceOptions,
  geofencePolygonOption,
  GeoFenceTypes,
  JobAssignmentType,
  JobStatusFilter,
  MaterialState as MaterialStates,
  MovingGeoFencesTags,
  movingGeoFencesTagsOptions,
  NoteTypes,
  OrderState,
  OrderStatusFilter,
  OrderUnitOfMeasure,
  ProjectStatusFilter,
  RateType as RateTypes,
  SearchParams,
  SiteType as SiteTypes,
  SystemRoles,
  UnitOfDistance as UnitOfDistanceEnum,
  unitOfMeasureOptions,
  UserTypesFilter,
  WaypointType as WaypointTypes,
};

export type { AllowedAuthMethod };
