import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { useEquipment } from '~hooks/useEquipment';
import { useMaterials } from '~hooks/useMaterials';
import { useProjects } from '~hooks/useProjects';
import { useServices } from '~hooks/useServices';
import { useStores } from '~store';

const navItems = [
  {
    title: 'Projects',
    link: 'projects',
  },
  {
    title: 'Orders',
    link: 'orders',
  },
];
export const Sales = () => {
  const theme = useTheme();
  const { companyAssetsStore, userStore } = useStores();
  const { getEquipmentByCompanyId } = useEquipment();
  const { getAllMaterials } = useMaterials();
  const { getAllServices } = useServices();
  const equipmentList = companyAssetsStore.equipment;
  const materialsList = companyAssetsStore.materials;
  const serviceList = companyAssetsStore.services;
  const companyId = userStore.userCompany?.id;
  useEffect(() => {
    // Fetch data if it's not loaded yet
    if (companyId && !equipmentList.length) {
      getEquipmentByCompanyId({ companyId });
    }
    if (!materialsList.length) {
      getAllMaterials();
    }
    if (!serviceList.length) {
      getAllServices();
    }
  }, [companyId, equipmentList.length, materialsList.length, serviceList.length]);

  return (
    <>
      <NavigationTabs
        navItems={navItems}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mt: 2 }}
      ></NavigationTabs>
      <Box sx={{ py: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};
