import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

import { CycleSegment } from './CycleSegment';
import {
  useGetCycleSegments,
  useIndicatorValues,
  useNoSignalBlocks,
  useTimeNormalization,
  useWholeHours,
} from './hooks';
import { SegmentTypes } from './hooks/useGetCycleSegments';
import { HourMarker } from './HourMarker';
import NoSignal from './NoSegment';
import TimeIndicator from './TimeIndicator';

interface CycleTimelineProps {
  pings: NextBillionAssetLocation[];
}

export const CycleTimeline = ({ pings }: CycleTimelineProps) => {
  const minTime = pings[0].createdAt.toISOString();
  const maxTime = pings[pings.length - 1].createdAt.toISOString();

  const { wholeHours, determineInterval } = useWholeHours(minTime, maxTime);
  const normalize = useTimeNormalization(minTime, maxTime);

  const segments = useGetCycleSegments(pings);
  const noSignalBlocks = useNoSignalBlocks(segments);

  // Logging segment is used to display historical data that did not have any job state details
  const hasLogging = segments.some((segment) => segment.type === SegmentTypes.LOGGING);

  const { graphRef, indicatorValues } = useIndicatorValues(
    minTime,
    maxTime,
    noSignalBlocks,
  );

  const interval = determineInterval();

  // Filter wholeHours to show only the necessary hour markers
  const filteredHours = wholeHours.filter((hour, index) => index % interval === 0);

  return (
    <Box
      display={'grid'}
      gridTemplateAreas={`
        "pickUpLabel graph"
        "enRouteLabel graph"
        "dropOffLabel graph"
        "loggingLabel graph"
      `}
      gridTemplateRows={'auto auto auto auto'}
      gridTemplateColumns={'auto 1fr'}
      columnGap={2}
    >
      {/* Row labels */}
      <Typography variant={'body2'} gridArea={'dropOffLabel'}>
        {t('live_map.timeline.dropoff')}
      </Typography>
      <Typography variant={'body2'} gridArea={'enRouteLabel'}>
        {t('live_map.timeline.enroute')}
      </Typography>
      <Typography variant={'body2'} gridArea={'pickUpLabel'}>
        {t('live_map.timeline.pickup')}
      </Typography>
      {hasLogging && (
        <Typography variant={'body2'} gridArea={'loggingLabel'}>
          {t('live_map.timeline.logging')}
        </Typography>
      )}

      {/* Graph */}
      <Box
        ref={graphRef}
        gridArea={'graph'}
        position={'relative'}
        sx={{ backgroundColor: 'white' }}
      >
        {/* Floating line that shows hovered time when on graph */}
        <TimeIndicator
          visible={indicatorValues.indicatorVisible}
          position={indicatorValues.indicatorPosition}
          time={indicatorValues.indicatorTime}
        />

        {/* Hour markers */}
        {filteredHours.map((hour) => (
          <HourMarker key={hour} position={normalize(hour)} time={hour} />
        ))}

        {/* Cycle segments */}
        {segments.map((segment, index) => (
          <CycleSegment
            key={`segment_${index}`}
            startPosition={normalize(segment.start.toISOString())}
            endPosition={normalize(segment.end.toISOString(), true)}
            type={segment.type}
            hasLogging={hasLogging}
          />
        ))}

        {/* Blocks of no data */}
        {noSignalBlocks.map(([start, end]) => (
          <NoSignal
            key={`nosignal_${start}`}
            startPosition={normalize(start.toISOString())}
            endPosition={normalize(end.toISOString(), true)}
          />
        ))}
      </Box>
    </Box>
  );
};
