import Add from '@mui/icons-material/Add';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import Edit from '@mui/icons-material/Edit';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Sms from '@mui/icons-material/Sms';
import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import {
  Account_Read_Nested,
  OrderState,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { t as $t, t } from 'i18next';
import { uniqBy } from 'lodash';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DataGridSmallButton, HeaderNavigation } from '~components/DataGrid';
import DataGrid from '~components/DataGrid/DataGrid';
import { DialogHeader, ModalDialog } from '~components/Dialog';
import { SimpleMenu } from '~components/Menu';
import { SendTextDialog } from '~components/Order';
import { Status as OrderStatus, TruckStatus } from '~components/Order';
import { BasicTooltip } from '~components/Tooltip';
import { OrderStatusFilter } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { useEquipment } from '~hooks/useEquipment';
import { useJob } from '~hooks/useJob';
import { useMaterials } from '~hooks/useMaterials';
import { Order, useOrders } from '~hooks/useOrders';
import { OrderEventType } from '~hooks/useOrders/useOrders';
import { usePermissions } from '~hooks/usePermissions';
import { useServices } from '~hooks/useServices';
import { Site, useSites } from '~hooks/useSites';
import { PaginationLink } from '~interfaces';
import { OrderExpandedBlock } from '~pages/Dispatch';
import { DispatchOrderState } from '~pages/Dispatch/hooks/useDispatchOrders';
import { NewOrderForm } from '~pages/Sales/Orders';
import { OrderFormSchemaInterface } from '~pages/Sales/Orders/orderFormSchema';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat, minutesToHoursMins } from '~utils/dateTime';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { isActionClicked } from '~utils/utilFunctions';

import { createSitesInline } from '../Sales/Orders/helpers';
import { getOrderStatesPerStatus } from './utils';

dayjs.extend(isSameOrBefore);

interface GetCompaniesProps {
  id: string;
}

interface OrdersDataGridProps {
  orderStatusFilter: string;
  searchValue: string;
  orderDispatch: DispatchOrderState;
  setOrderDispatch: Dispatch<SetStateAction<DispatchOrderState>>;
}

interface AddDefaultSiteType {
  siteId: string;
  waypointType: WaypointType;
}

interface SendTextDialogState {
  isOpen: boolean;
  order?: Order;
}

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteType>;

const OrdersDataGrid = observer(
  ({
    orderStatusFilter,
    searchValue,
    orderDispatch,
    setOrderDispatch,
  }: OrdersDataGridProps) => {
    const theme = useTheme();
    const [options, setOptions] = useState<Nullable<CompanyBasic[]>>([]);
    const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
    const cloneModalDialogRef = useRef<any>(null);
    const modalDialogRef = useRef<any>(null);
    const orderActionsRef = useRef<any>(null);
    const {
      createOrder,
      getOrdersDispatch,
      updateOrder,
      doEvent,
      cloneOrder,
      isLoadingOrders,
      isUpdating,
      subscribeToOrderUpdates,
      orderSubscription,
    } = useOrders();
    const { getAllUserAvailableCompanies } = useCompany();
    const { addSiteToOrderDefaultSites, createNewSite } = useSites();
    const { isLoading: isLoadingJobs, getJobsByOrder, createJobFromOrder } = useJob();
    const { isLoading: isPermsLoading, getPermissions } = usePermissions();
    const { toasterStore, userStore, ordersStore, companyAssetsStore } = useStores();
    const { getEquipmentByCompanyId } = useEquipment();
    const { getAllMaterials } = useMaterials();
    const { getAllServices } = useServices();
    const isLoading = isLoadingOrders || isPermsLoading || isLoadingJobs;
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [newDefaultPickUpSites, setNewDefaultPickUpSites] = useState<
      AddDefaultSiteType[]
    >([]);
    const [newDefaultDropOffSites, setNewDefaultDropOffSites] = useState<
      AddDefaultSiteType[]
    >([]);
    const [sendTextDialog, setSendTextDialog] = useState<SendTextDialogState>({
      isOpen: false,
    });
    const resetTempDefaultSitesLists = () => {
      setNewDefaultPickUpSites([]);
      setNewDefaultDropOffSites([]);
    };
    const [toastOpen, setToastOpen] = useState<boolean>(false);
    useEffect(() => {
      setToastOpen(ordersStore.pendingNewOrders);
    }, [ordersStore.pendingNewOrders]);

    // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
    const loadingRef = useRef<boolean>(isLoading);
    useEffect(() => {
      loadingRef.current = isLoading;
    }, [isLoading]);

    const userPermissions = useMemo(() => {
      return userStore.getPermissions();
    }, [userStore.getPermissions()]);

    const companyId = userStore.userCompany?.id;
    const equipmentList = companyAssetsStore.equipment;
    const materialsList = companyAssetsStore.allMaterials;
    const serviceList = companyAssetsStore.services;

    const rows = ordersStore.orders;
    const clonedrows = ordersStore.clonedOrders;

    const dispatchFilters = ordersStore.dispatchFilter;
    const orderFilters = {
      ...ordersStore.orderFilter,
      states: getOrderStatesPerStatus(orderStatusFilter as OrderStatusFilter),
    };
    const filterOptions = { jobs: dispatchFilters, orders: orderFilters };

    const orderColumns: GridColDef[] = useMemo(
      () =>
        [
          {
            field: 'orderId',
            headerName: $t('order.form.order_id'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row?.orderId))],
            groupable: true,
            aggregable: false,
            width: 90,
          },
          {
            field: 'dispatchNumber',
            headerName: $t('order.form.dispatch_number'),
            type: 'singleSelect',
            valueOptions: [
              ...new Set(rows.map((row: Order) => row?.dispatchNumber).filter(Boolean)),
            ],
            groupable: true,
            aggregable: false,
            width: 90,
            renderCell: (params: GridValueGetterParams) => {
              return (
                <>
                  <BasicTooltip title={params.row?.dispatchNumber}>
                    <Typography component="span" variant="body1">
                      {params.row?.dispatchNumber}
                    </Typography>
                  </BasicTooltip>
                </>
              );
            },
          },
          {
            field: 'projectExternalId',
            headerName: $t('order.form.project_external_id'),
            type: 'singleSelect',
            valueOptions: [
              ...new Set(
                rows.map((row: Order) => row?.project?.externalId).filter(Boolean),
              ),
            ],
            groupable: true,
            aggregable: false,
            width: 90,
            renderCell: (params: GridValueGetterParams) => {
              return (
                <>
                  <BasicTooltip title={params.row?.project?.externalId}>
                    <Typography component="span" variant="body1">
                      {params.row?.project?.externalId}
                    </Typography>
                  </BasicTooltip>
                </>
              );
            },
          },
          {
            field: 'name',
            headerName: $t('order.form.name'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row?.name))],
            groupable: true,
            aggregable: false,
            width: 90,
          },
          {
            field: 'externalId',
            headerName: $t('order.form.external_id'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row?.externalId))],
            groupable: true,
            aggregable: false,
            width: 90,
          },
          {
            field: 'po',
            headerName: $t('order.form.po'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row?.poJobNumber))],
            groupable: true,
            aggregable: false,
            width: 90,
          },
          {
            field: 'loadAt',
            headerName: $t('form_fields.start_date_and_time'),
            flex: 1,
            type: 'dateTime',
            valueGetter: (params: GridValueGetterParams) => {
              return params.value ? new Date(params.value) : null;
            },
            renderCell: (params: GridValueGetterParams) => {
              return (
                <Typography fontSize="12px">
                  {params.value ? dateFormat(params.value, 'MM/DD/YYYY hh:mm A') : ''}
                </Typography>
              );
            },
          },
          {
            field: 'sendingAccounts',
            headerName: $t('order.form.for'),
            flex: 1,
            renderCell: (params: GridValueGetterParams) => {
              return (
                <Typography fontSize="12px">
                  {params.row.sendingAccounts?.length
                    ? params.row.sendingAccounts
                        .map((account: Account_Read_Nested) => account?.name)
                        .join(', ')
                    : params.row.account?.name}
                </Typography>
              );
            },
          },
          {
            field: 'state',
            headerName: $t('form_fields.status'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row.state))],
            flex: 1,
            renderCell: (params: GridValueGetterParams) => {
              if (params.row.state === OrderState.PENDING_REQUEST) {
                return (
                  <>
                    <DataGridSmallButton
                      variant="contained"
                      color="success"
                      onClick={() => doAction(params.row.id, 'accept')}
                    >
                      {t('actions.accept')}
                    </DataGridSmallButton>

                    <DataGridSmallButton
                      variant="contained"
                      color="error"
                      onClick={() => doAction(params.row.id, 'reject')}
                    >
                      {t('actions.decline')}
                    </DataGridSmallButton>
                  </>
                );
              }

              return <OrderStatus status={params.value} />;
            },
          },
          {
            field: 'service',
            headerName: $t('order.form.service'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row.service?.name))],
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.service?.name || '';
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.service?.name}>
                <Typography component="span" variant="body1">
                  {params.row.service?.name || ''}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'projectName',
            headerName: $t('order.project_name'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row?.projectName))],
            flex: 1,
            renderCell: (params: GridValueGetterParams) => {
              return (
                <>
                  <BasicTooltip title={params.row?.projectName}>
                    <Typography component="span" variant="body1">
                      {params.row?.projectName}
                    </Typography>
                  </BasicTooltip>
                </>
              );
            },
          },
          {
            field: 'material',
            headerName: $t('form_fields.materials'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row.material?.name))],
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.material?.name || '';
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.material?.name}>
                <Typography component="span" variant="body1">
                  {params.row.material?.name || ''}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'completion',
            headerName: $t('order.form.completion'),
            type: 'singleSelect',
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => {
              const order = params.row as Order;

              return params.row.editable ? (
                <Typography fontSize="12px">
                  {order.deliveredQuantity} / {order.quantity} {order.unitOfMeasure?.name}
                </Typography>
              ) : (
                ''
              );
            },
          },
          {
            field: 'truckStatus',
            headerName: $t('form_fields.truck_status'),
            type: 'singleSelect',
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => {
              return params.row.editable ? <TruckStatus order={params.row} /> : '';
            },
          },
          {
            field: 'pickUpSite',
            headerName: $t('form_fields.pick_up_site'),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return get(params.row, 'pickUpWayPoints[0].site.name', '');
            },
            renderCell: (params: GridValueGetterParams) => {
              const pickUpSiteName = get(params.row, 'pickUpWayPoints[0].site.name');
              return (
                <BasicTooltip title={pickUpSiteName}>
                  <Typography component="span" variant="body1">
                    {pickUpSiteName}
                  </Typography>
                </BasicTooltip>
              );
            },
          },
          {
            field: 'dropOffSite',
            headerName: $t('form_fields.drop_off_site'),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return get(params.row, 'dropOffWayPoints[0].site.name', '');
            },
            renderCell: (params: GridValueGetterParams) => {
              const dropUpSiteName = get(params.row, 'dropOffWayPoints[0].site.name');
              return (
                <BasicTooltip title={dropUpSiteName}>
                  <Typography component="span" variant="body1">
                    {dropUpSiteName}
                  </Typography>
                </BasicTooltip>
              );
            },
          },
          {
            field: 'loadCycleAvg',
            headerName: `${$t('form_fields.load_cycle_avg')}`,
            type: 'number',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridValueGetterParams) => {
              return params.value ? (
                <>
                  {
                    <Typography variant="body1">
                      {minutesToHoursMins(params.value)}
                    </Typography>
                  }
                </>
              ) : null;
            },
          },
          {
            field: 'department',
            type: 'singleSelect',
            valueOptions: [
              ...new Set(
                rows
                  .filter((row) => !!row?.department)
                  .map((row) => row?.department?.name),
              ),
            ],
            headerName: $t('form_fields.department'),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.value?.name;
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.department?.name}>
                <Typography component="span" variant="body1">
                  {params.row.department?.name}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'equipmentType',
            headerName: $t('form_fields.equipment_type'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row.equipmentType?.name))],
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.equipmentType?.name || '';
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.equipmentType?.name}>
                <Typography component="span" variant="body1">
                  {params.row.equipmentType?.name || ''}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'company',
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
            headerName: $t('form_fields.company'),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.value?.legalName;
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.company?.legalName}>
                <Typography component="span" variant="body1">
                  {params.row.company?.legalName}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'account',
            headerName: $t('form_fields.account'),
            type: 'singleSelect',
            valueOptions: [...new Set(rows.map((row: Order) => row.account?.name))],
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.account?.name || '';
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params?.value}>
                <Typography variant="body1" component="span">
                  {params?.value}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'dispatcher',
            headerName: $t('form_fields.dispatcher'),
            // Type: 'singleSelect',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.dispatcher?.name || '';
            },
            renderCell: (params: GridValueGetterParams) => (
              <BasicTooltip title={params.row?.dispatcher?.name}>
                <Typography component="span" variant="body1">
                  {params.row?.dispatcher?.name || ''}
                </Typography>
              </BasicTooltip>
            ),
          },
          {
            field: 'truckCount',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            headerName: $t('form_fields.trucks'),
            flex: 1,
            renderCell: (params: GridValueGetterParams) => {
              return params.row.editable ? (
                <>{$t('common.trucks', { count: params.value })}</>
              ) : (
                ''
              );
            },
          },
          {
            field: 'truckDelay',
            type: 'number',
            headerName: $t('form_fields.truck_delay'),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return Number(params.row.truckDelay || '0');
            },
            renderCell: (params: GridValueGetterParams) => {
              return params.row.editable ? (
                <BasicTooltip title={params.row?.truckDelay}>
                  <Typography component="span" variant="body1">
                    {params.row.truckDelay || ''}
                  </Typography>
                </BasicTooltip>
              ) : (
                ''
              );
            },
          },
          {
            field: 'totalDeliveredQuantity',
            headerName: $t('dispatch.order.delivered_qty'),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return Number(params.row.deliveredQuantity || 0);
            },
            renderCell: (params: GridValueGetterParams) => {
              return params.row.editable ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: `${
                      params.value ? theme.palette.success.main : theme.palette.grey[600]
                    }`,
                  }}
                >
                  {params.value} {params.row.unitOfMeasure?.name}
                </Typography>
              ) : (
                ''
              );
            },
          },
          {
            field: 'quantity',
            headerName: $t('dispatch.order.ordered_qty'),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
              return Number(params.row.quantity || 0);
            },
            renderCell: (params: GridValueGetterParams) => {
              return params.row.editable ? (
                <Typography variant="body1">
                  {params.value} {params.row.unitOfMeasure?.name}
                </Typography>
              ) : (
                ''
              );
            },
          },
          // {
          //   Field: 'orderProgress',
          //   HeaderName: $t('form_fields.progress'),
          //   HeaderAlign: 'left',
          //   Align: 'left',
          //   Type: 'number',
          //   Flex: 1,
          //   MinWidth: 200,
          //   ValueGetter: (params: GridValueGetterParams) => {
          //     Return (params.row.totalDeliveredQuantity / params.row.quantity) * 100;
          //   },
          //   RenderCell: (params: GridValueGetterParams) => {
          //     Return (
          //       <Box display="flex" flex="row">
          //         <Typography
          //           Variant="body2"
          //           Sx={{
          //             Color: `${
          //               Params.value ? theme.palette.success.main : theme.palette.grey[600]
          //             }`,
          //           }}
          //         >
          //           {params.row.totalDeliveredQuantity} {params.row.unitOfMeasure?.name}
          //         </Typography>
          //         <Typography variant="body2" sx={{ mx: 0.5 }}>
          //           {$t('common.of')}
          //         </Typography>
          //         <Typography variant="body2">
          //           {Number(params.row.quantity || '0')} {params.row.unitOfMeasure?.name}{' '}
          //           {$t('common.delivered')}
          //         </Typography>
          //       </Box>
          //     );
          //   },
          // },
          {
            field: 'alerts',
            headerName: $t('actions.alerts'),
            sortable: false,
            filterable: true,
            disableColumnMenu: true,
            hideable: false,
            display: 'flex',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
              const nonRoutableSiteAlertEnabled = useFeatureFlag({
                featureFlagKey: FeatureFlags.nonRoutableAlerts,
              });
              const hasNonRoutableSite = params.row.waypoints?.some(
                ({ siteNested }: { siteNested: Site }) => !siteNested?.routable,
              );
              const showNonRoutableSiteAlert =
                nonRoutableSiteAlertEnabled && hasNonRoutableSite;
              const tooltipMessage =
                (showNonRoutableSiteAlert && t('common.address_confirmation_required')) ||
                (params.row.actionRequired && t('dispatch.order.pending_jobs'));

              return params.row.id ? (
                <Box>
                  {(params.row.actionRequired || showNonRoutableSiteAlert) && (
                    <BasicTooltip title={tooltipMessage}>
                      <WarningAmber color="primary" />
                    </BasicTooltip>
                  )}
                </Box>
              ) : null;
            },
          },
          {
            field: 'actions',
            headerName: $t('actions.actions'),
            type: 'actions',
            width: 114,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            hideable: false,
            renderHeader: () => (
              <HeaderNavigation
                count={ordersStore.orders?.length || 0}
                loading={loadingRef.current}
                pagination={ordersStore.pagination}
                callback={getFilteredCompanyOrders}
                altText={`${$t('actions.actions')}`}
                searchQuery={searchValue}
              />
            ),
            renderCell: (params) => {
              const { editable, state } = params.row;
              const actions = [];

              if (userPermissions.canEditOrder && editable) {
                actions.push({
                  title: `${t('dispatch.order.edit_order')}`,
                  icon: <Edit />,
                  callBack: () => editRow(params.row.id),
                });
              }

              if (userPermissions.canCreateOrder) {
                actions.push({
                  title: `${t('dispatch.order.clone_order')}`,
                  icon: <FileCopyOutlined />,
                  callBack: () => copyRow(params.row.id),
                });
              }

              if (
                editable &&
                ![
                  OrderState.REJECTED,
                  OrderState.CANCELED,
                  OrderState.COMPLETED,
                ].includes(state)
              ) {
                if (userPermissions.canDeleteOrder) {
                  actions.push({
                    title: `${t('dispatch.order.cancel_order')}`,
                    icon: <CancelOutlined />,
                    callBack: () => cancelOrder(params.row.id),
                  });

                  if (state !== OrderState.CREATED) {
                    actions.push({
                      title: `${t('dispatch.order.complete_order')}`,
                      icon: <CheckCircleOutlined />,
                      callBack: () => doAction(params.row.id, 'complete'),
                    });
                  }
                }

                if (userPermissions.canCreateJob) {
                  actions.push({
                    title: `${t('dispatch.order.add_job')}`,
                    icon: <Add />,
                    callBack: () => {
                      createJobFromOrder(params.row.id).then((job) => {
                        toasterStore.push(
                          alert(
                            $t('dispatch.order.added_job', { name: job.jobId }),
                            AlertTypes.success,
                          ),
                        );
                      });
                    },
                  });
                }

                actions.push({
                  title: `${t('dispatch.order.text_all_drivers')}`,
                  icon: <Sms />,
                  callBack: () => openSendTextDialog(params.row),
                });
              }

              return params.row.id ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <SimpleMenu options={actions}>
                    <MoreHoriz />
                  </SimpleMenu>
                </Box>
              ) : null;
            },
          },
        ] as GridColDef[],
      [rows, userPermissions],
    );

    const initialState = useMemo(
      () => ({
        columns: {
          columnVisibilityModel: {
            department: false,
            equipmentType: false,
            company: false,
            account: false,
            dispatcher: false,
            truckCount: false,
            truckDelay: false,
            totalDeliveredQuantity: false,
            quantity: false,
            name: false,
            externalId: false,
            po: false,
          },
        },
      }),
      [],
    );

    useEffect(() => {
      // Fetch data if it's not loaded yet
      if (companyId && !equipmentList?.length) {
        getEquipmentByCompanyId({ companyId });
      }
      if (!materialsList?.length) {
        getAllMaterials();
      }
      if (!serviceList?.length) {
        getAllServices();
      }
    }, [companyId, equipmentList?.length, materialsList?.length, serviceList?.length]);

    useEffect(() => {
      if (!orderSubscription && userStore.userCompany?.id) {
        subscribeToOrderUpdates();
      }

      return () => {
        orderSubscription?.unsubscribe?.();
      };
    }, [orderSubscription, userStore.userCompany?.id]);

    useEffect(() => {
      if (userStore.userCompany?.id) {
        getCompanies({
          id: userStore.userCompany.id,
        });
      }
    }, []);

    useEffect(() => {
      getPermissions();
    }, []);

    // Refetch orders when search or filter query changes
    // OR updates to an order(s) occurred
    useEffect(() => {
      if (ordersStore.areFilterOptionsLoaded || ordersStore.orderUpdatesOccurred) {
        getOrdersDispatch({} as PaginationLink, searchValue, filterOptions);
      }
    }, [
      JSON.stringify(filterOptions),
      ordersStore.areFilterOptionsLoaded,
      searchValue,
      ordersStore.orderUpdatesOccurred,
    ]);

    const onFormStateChange = ({
      isDirty,
      siteId,
      waypointType,
    }: NewOrderFormStateChange) => {
      setCurrentFormDirty(isDirty);
      // Need to store the saved sites that a user added to the
      // New order so that we can save them to the order when
      // It is created.
      if (siteId) {
        waypointType === WaypointType.PICKUP &&
          setNewDefaultPickUpSites((pickUpSites) =>
            uniqBy([...pickUpSites, { siteId, waypointType }], 'siteId'),
          );
        waypointType === WaypointType.DROP_OFF &&
          setNewDefaultDropOffSites((dropOffSites) =>
            uniqBy([...dropOffSites, { siteId, waypointType }], 'siteId'),
          );
      }
    };

    const findStoredOrder = useCallback((orderId: string, source: Order[]) => {
      const storedOrder = source.find(({ id }) => id === orderId);

      return storedOrder ?? null;
    }, []);

    const selectedOrder = useMemo(() => {
      const orderId = orderDispatch.selectedOrderId;

      if (!orderId) {
        return null;
      }

      let order: Order | null = findStoredOrder(orderId, rows);

      if (!order) {
        order = findStoredOrder(orderId, clonedrows);
      }

      return order;
    }, [orderDispatch.selectedOrderId, rows, clonedrows, findStoredOrder]);

    const handleClose = async () => {
      setOrderDispatch((prevState) => ({
        ...prevState,
        isOrderDialogOpen: false,
      }));
      await orderActionsRef.current?.fileAttachmentsOnClose();
      resetTempDefaultSitesLists();
    };
    const onSuccess = async (order: Order) => {
      await orderActionsRef.current?.fileAttachmentsOnSubmit(order.id);
      setOrderDispatch((prevState) => ({
        ...prevState,
        selectedOrderId: null,
        isOrderDialogOpen: false,
      }));
      resetTempDefaultSitesLists();
      toasterStore.push(
        alert(
          orderDispatch.selectedOrderId
            ? $t('order.order_updated', { name: order?.orderId })
            : $t('order.order_created', { name: order?.orderId }),
          AlertTypes.success,
        ),
      );
    };
    const onNewOrderSuccess = async (order?: Order) => {
      if (order) {
        onSuccess(order);

        const newDefaultSites = [...newDefaultPickUpSites, ...newDefaultDropOffSites];

        // Add reduce loop and make sequential calls
        // To save sites to new orders to avoid race condition
        // https://jrsinclair.com/articles/2019/how-to-run-async-js-in-parallel-or-sequential/
        if (newDefaultSites?.length > 0) {
          newDefaultSites.reduce(
            async (acc, site) => {
              await acc;
              return await addSiteToOrderDefaultSites({
                orderId: order.id,
                siteId: site.siteId,
                waypointType: site.waypointType,
              });
            },
            Promise.resolve({} as Site),
          );

          resetTempDefaultSitesLists();
        }
      }
    };

    const onSubmitOrderForm = () => {
      orderActionsRef.current?.submit(onSubmitOrderFormCallBack);
    };
    const onSubmitOrderFormCallBack = async (data: OrderFormSchemaInterface) => {
      if (orderDispatch.selectedOrderId) {
        const newData = await createSitesInline(
          data,
          createNewSite,
          userStore.userCompany?.siteRadius,
        );
        updateOrder({
          order: newData as OrderFormSchemaInterface,
          callBack: onSuccess,
        }).then(() => orderActionsRef.current?.updateProjectDefaultSites());
      } else {
        const newData = await createSitesInline(
          data,
          createNewSite,
          userStore.userCompany?.siteRadius,
        );
        createOrder({
          order: newData as OrderFormSchemaInterface,
          callBack: onNewOrderSuccess,
        });
      }
    };

    const editRow = (id: string) => {
      setOrderDispatch({
        selectedOrderId: id,
        isOrderDialogOpen: true,
      });
    };

    const cancelOrder = (id: string) => {
      setSelectedId(id);
      modalDialogRef.current?.open();
    };

    const copyRow = (id: string) => {
      setOrderDispatch((prevState) => ({
        ...prevState,
        selectedOrderId: id,
      }));
      cloneModalDialogRef.current?.open();
    };

    const openSendTextDialog = useCallback((order: Order) => {
      setSendTextDialog({ isOpen: true, order });
    }, []);

    const handleCopyWithAssignees = async (id: string, includeAssignees: boolean) => {
      let clonedOrderId = '';

      try {
        const order = await cloneOrder(id, includeAssignees);
        clonedOrderId = order.id;

        toasterStore.push(
          alert(
            $t('dispatch.order.copied', { name: order?.orderId }),
            AlertTypes.success,
          ),
        );
      } finally {
        cloneModalDialogRef.current?.close();

        if (clonedOrderId) {
          editRow(clonedOrderId);
        }
      }
    };

    const doAction = (id: string, action: OrderEventType) => {
      return doEvent(id, action).then((response) => {
        setSelectedId(null);
        modalDialogRef.current?.close();

        const toasterMessage =
          action === 'cancel' ? 'dispatch.order.canceled' : 'dispatch.order.completed';
        toasterStore.push(
          alert($t(toasterMessage, { name: response.orderId }), AlertTypes.success),
        );
        return response.orderId;
      });
    };

    const getFilteredCompanyOrders = (link?: PaginationLink, searchQuery?: string) => {
      getOrdersDispatch(link || ({} as PaginationLink), searchQuery, filterOptions);
    };

    const getCompanies = ({ id }: GetCompaniesProps) => {
      getAllUserAvailableCompanies({
        id: id,
        callBack: setOptions,
      });
    };

    const handleToastClick = () => {
      getFilteredCompanyOrders(undefined, searchValue);
      setToastOpen(false);
    };

    const toastAction = (
      <Button variant="text" size="small" onClick={handleToastClick}>
        <Typography color={theme.brandV2.colors.treadBlack}>
          {t('order.refresh_data').toUpperCase()}
        </Typography>
      </Button>
    );

    return (
      <>
        <Box className={'dispatch-datagrid'}>
          <DataGrid
            sx={{ "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' } }}
            id="job-per-orders-data-grid"
            columns={orderColumns}
            rows={rows as unknown as Record<string, any>[]}
            loading={isLoading}
            initialState={initialState}
            filterMode="server"
            getDetailPanelContent={({ row }) => (
              <OrderExpandedBlock
                order={row as Order}
                orderSearchValue={searchValue}
                searchValue={searchValue}
              />
            )}
            hideToolbar
          />

          <ModalDialog
            ref={modalDialogRef}
            loading={isUpdating}
            title={$t('dispatch.order.cancel_order')}
            content={$t('dispatch.order.cancel_description')}
            confirmButtonText={`${$t('actions.confirm')}`}
            confirmButtonColor={'error'}
            callBack={() => {
              doAction(selectedId || '', 'cancel').then(() => {
                // Refetch the jobs under the order
                if (selectedId) {
                  getJobsByOrder(selectedId, searchValue, ordersStore.dispatchFilter);
                }
              });
            }}
          />

          <ModalDialog
            ref={cloneModalDialogRef}
            loading={isUpdating}
            title={$t('dispatch.order.clone_order')}
            content={$t('dispatch.order.clone_description')}
            confirmButtonColor={'primary'}
            cancelButtonText={`${$t('dispatch.order.copy_without_assignments')}`}
            confirmButtonText={`${$t('dispatch.order.copy_with_assignments')}`}
            callBack={() =>
              handleCopyWithAssignees(orderDispatch.selectedOrderId || '', true)
            }
            onCancel={() =>
              handleCopyWithAssignees(orderDispatch.selectedOrderId || '', false)
            }
          />
        </Box>

        <Dialog
          open={orderDispatch.isOrderDialogOpen}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            isActionClicked(reason) && handleClose();
          }}
          maxWidth={'lg'}
        >
          <DialogHeader
            closeCallBack={handleClose}
            title={
              <>
                <Typography component={'span'} variant={'h5'}>
                  {orderDispatch.selectedOrderId
                    ? t('order.update_order')
                    : t('order.create_order')}
                </Typography>
                <Typography>{selectedOrder?.orderId}</Typography>
              </>
            }
          />
          <DialogContent
            sx={{
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <NewOrderForm
              defaultOrder={selectedOrder}
              ref={orderActionsRef}
              onFormStateChange={onFormStateChange}
              company={options?.length === 1 ? options[0] : null}
            />
          </DialogContent>
          <DialogActions
            sx={{
              m: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <LoadingButton
              disabled={isUpdating || !currentFormDirty}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitOrderForm}
              data-test-id={'create-order-btn'}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.${orderDispatch.selectedOrderId ? 'submit' : 'create'}`)}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        <SendTextDialog
          isOpen={sendTextDialog.isOpen}
          order={sendTextDialog.order}
          onClose={() => setSendTextDialog({ isOpen: false })}
        />
        <Snackbar
          open={toastOpen}
          autoHideDuration={null}
          message={t('order.new_orders_available')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            sx: {
              background: theme.palette.brandV2Yellow.main,
              color: theme.palette.common.black,
            },
          }}
          action={toastAction}
        />
      </>
    );
  },
);
export { OrdersDataGrid };
