import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { SxProps } from '@mui/system';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';
import { usePrevious } from '~utils/hooks/usePrevious';

import CustomerSelector from './copy-vendor-assignments-form/CustomerSelector';
import DateRangeSelector from './copy-vendor-assignments-form/DateRangeSelector';
import SearchBar from './copy-vendor-assignments-form/SearchBar';
import { LoadingSpinner, OverflowAwareText } from './ordersDispatchStyledComponents';

export const CONTROLS_HEIGHT_IN_PX = 36;

enum FetchReason {
  INITIAL_FETCH = 'initial_fetch',
  INFINITE_SCROLL = 'infinite_scroll',
  FILTERS_CHANGED = 'filters_changed',
}

interface CopyVendorAssignmentsFormProps {
  companyId: string;
  onSelectedOrderChange: (order: Order) => void;
  orderId?: string;
}

const CopyVendorAssignmentsForm = observer(
  ({ companyId, onSelectedOrderChange, orderId }: CopyVendorAssignmentsFormProps) => {
    const { ordersDispatchStore } = useStores();
    const { getCompanyOrdersForCopyVendorAssignment } = useOrdersDispatch();

    const [ordersFetchState, setOrdersFetchState] = useState({
      shouldFetch: false,
      reason: FetchReason.INITIAL_FETCH,
    });

    const infiniteScroll = useInfiniteScroll();

    const isLoading =
      ordersDispatchStore.isLoadingCopyVendorAssignmentsOrders &&
      ordersFetchState.reason !== FetchReason.INFINITE_SCROLL;
    const isLoadingMore =
      ordersDispatchStore.isLoadingCopyVendorAssignmentsOrders &&
      ordersFetchState.reason === FetchReason.INFINITE_SCROLL;

    const fetchOrders = () => {
      getCompanyOrdersForCopyVendorAssignment().then(() => {
        infiniteScroll.notify();
      });
    };

    const handleDateRangeChange = useCallback((startDate?: string, endDate?: string) => {
      ordersDispatchStore.setCopyVendorAssignmentsFilters({ startDate, endDate }, true);
    }, []);

    const handleCustomerAccountsChange = useCallback((customerAccountIds: string[]) => {
      ordersDispatchStore.setCopyVendorAssignmentsFilters({ customerAccountIds }, true);
    }, []);

    const handleSearchValueChange = useCallback((search: string) => {
      ordersDispatchStore.setCopyVendorAssignmentsFilters({ search }, true);
    }, []);

    useEffect(() => {
      return () => {
        ordersDispatchStore.resetCopyVendorAssignmentsOrders();
      };
    }, []);

    const previousFilters = usePrevious(ordersDispatchStore.copyVendorAssignmentsFilters);
    const filtersDidChange =
      JSON.stringify(previousFilters) !==
      JSON.stringify(ordersDispatchStore.copyVendorAssignmentsFilters);

    useEffect(() => {
      if (ordersFetchState.shouldFetch) {
        fetchOrders();
      }
    }, [ordersFetchState.shouldFetch]);

    useEffect(() => {
      setOrdersFetchState((state) => {
        if (isLoading || isLoadingMore) {
          return { ...state, shouldFetch: false };
        }

        if (
          infiniteScroll.endReached &&
          ordersDispatchStore.hasMoreCopyVendorAssignmentsOrders
        ) {
          return { shouldFetch: true, reason: FetchReason.INFINITE_SCROLL };
        }

        if (filtersDidChange) {
          return { shouldFetch: true, reason: FetchReason.FILTERS_CHANGED };
        }

        return state;
      });
    }, [
      isLoading,
      isLoadingMore,
      infiniteScroll.endReached,
      ordersDispatchStore.hasMoreCopyVendorAssignmentsOrders,
      filtersDidChange,
    ]);

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          borderBottom={`solid 1px ${theme.palette.divider}`}
          display="flex"
          flex={0}
          gap={1}
          p={theme.spacing(1, 2)}
        >
          <DateRangeSelector onChange={handleDateRangeChange} />

          <CustomerSelector
            companyId={companyId}
            onChange={handleCustomerAccountsChange}
          />

          <SearchBar onChange={handleSearchValueChange} />
        </Box>

        <Box flex={1} overflow="auto">
          {isLoading ? (
            <Box display="flex" height="100%" alignItems="center" justifyContent="center">
              <LoadingSpinner isVisible />
            </Box>
          ) : (
            <>
              <OrdersTable
                onSelectedOrderChange={onSelectedOrderChange}
                orders={ordersDispatchStore.copyVendorAssignmentsOrders}
                selectedOrderId={orderId}
              />

              {ordersDispatchStore.copyVendorAssignmentsOrders.length > 0 &&
                ordersDispatchStore.hasMoreCopyVendorAssignmentsOrders && (
                  <Box ref={infiniteScroll.nodeRef}>
                    <LoadingSpinner
                      isVisible={isLoadingMore}
                      loadingIndicatorSize={16}
                      sx={{ my: 2 }}
                    />
                  </Box>
                )}
            </>
          )}
        </Box>
      </Box>
    );
  },
);

interface OrdersTableProps {
  onSelectedOrderChange: (order: Order) => void;
  orders: Order[];
  selectedOrderId?: string;
}

function OrdersTable({
  onSelectedOrderChange,
  orders,
  selectedOrderId,
}: OrdersTableProps) {
  return (
    <Box
      alignItems="center"
      display="grid"
      gridAutoRows="32px"
      gridTemplateColumns="auto repeat(7, 1fr)"
    >
      <TableHeader label="" />
      <TableHeader label={t('order.form.dispatch_number_full')} />
      <TableHeader
        label={t('dispatch.dispatch_v2.copy_vendor_assignments.tread_order_id')}
      />
      <TableHeader label={t('common.date')} />
      <TableHeader label={t('order.form.order_name')} />
      <TableHeader label={t('common.customer')} />
      <TableHeader label={t('form_fields.pick_up')} />
      <TableHeader label={t('form_fields.material')} />

      {orders.map((order) => (
        <React.Fragment key={order.id}>
          <TableCell>
            <RadioButton
              isChecked={selectedOrderId === order.id}
              onCheckedStateChange={() => onSelectedOrderChange(order)}
            />
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.dispatchNumber}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.orderId}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {dayjs(order.createdAt).format('MM/DD/YYYY')}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.name}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {order.sendingAccounts.map((i) => i.name).join(', ')}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {order.waypoints?.[0]?.siteNested?.address?.streetAddress}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.material?.name}</OverflowAwareText>
          </TableCell>
        </React.Fragment>
      ))}
    </Box>
  );
}

interface RadioButtonProps {
  isChecked?: boolean;
  onCheckedStateChange?: () => void;
}

function RadioButton({ isChecked, onCheckedStateChange }: RadioButtonProps) {
  return (
    <FormControl>
      <Radio
        checked={isChecked}
        onChange={onCheckedStateChange}
        sx={{
          '&.MuiRadio-root': { p: 0 },
          '.MuiSvgIcon-root': { width: '18px', height: '18px' },
        }}
      />
    </FormControl>
  );
}

interface TableCell {
  sx?: SxProps;
}

function TableCell({ children, sx }: React.PropsWithChildren<TableCell>) {
  return (
    <Box
      alignItems="center"
      borderTop={`solid 1px ${theme.palette.divider}`}
      display="flex"
      height="100%"
      overflow="hidden"
      p={theme.spacing(0.5, 1)}
      sx={sx}
      width="100%"
    >
      {children}
    </Box>
  );
}

interface TableHeaderProps {
  label: string;
}

function TableHeader({ label }: TableHeaderProps) {
  return (
    <TableCell sx={{ borderTop: 0 }}>
      <OverflowAwareText
        sx={{ textTransform: 'uppercase', color: theme.brandV2.colors.treadGray4 }}
      >
        {label}
      </OverflowAwareText>
    </TableCell>
  );
}

export default CopyVendorAssignmentsForm;
