import Box from '@mui/material/Box';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { SearchBar } from '../Dispatch/components/calendar/SearchBar';
import Tabs from '../Dispatch/components/Tabs';
import LiveMapFilters from './SideBarComponents/LiveMapFilters';
import { LiveMapTabFilter } from './types';

const VIEW_TO_ORDER_STATES: Record<LiveMapTabFilter, OrderState[]> = {
  [LiveMapTabFilter.IN_PROGRESS]: [OrderState.IN_PROGRESS],
  [LiveMapTabFilter.HISTORY]: [
    OrderState.COMPLETED,
    OrderState.CANCELED,
    OrderState.REJECTED,
  ],
};

const LIVE_MAP_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS = 300;

const availableViews = [
  {
    label: t('live_map.filters.in_progress'),
    value: LiveMapTabFilter.IN_PROGRESS,
  },
  {
    label: t('live_map.filters.history'),
    value: LiveMapTabFilter.HISTORY,
  },
];

const TreadLiveTopBar = () => {
  const [view, setViewFilter] = useState(LiveMapTabFilter.IN_PROGRESS);
  const { liveMapStoreNew } = useStores();

  /**
   * Persist the view filter on mount given by persisted filters
   */
  useEffect(() => {
    const firstOrderState = liveMapStoreNew?.filters?.orderStates?.[0];
    const isOrderStateInView =
      firstOrderState && VIEW_TO_ORDER_STATES[view].includes(firstOrderState);

    if (!isOrderStateInView) {
      setViewFilter(LiveMapTabFilter.HISTORY);
    }
  }, []);

  const onViewChange = (view: LiveMapTabFilter) => {
    setViewFilter(view);
    liveMapStoreNew.resetOrders({
      ...liveMapStoreNew.filters,
      orderStates: VIEW_TO_ORDER_STATES[view],
    });
  };

  const applySearchFilter = _.debounce((value: string) => {
    if (liveMapStoreNew.filters.search !== value) {
      liveMapStoreNew.setFilters({ search: value }, true);
    }
  }, LIVE_MAP_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  return (
    <Box
      alignItems="center"
      bgcolor="white"
      borderBottom={`solid 1px ${theme.brandV2.colors.treadGray7}`}
      py={1}
      sx={{ px: 2 }}
    >
      <Tabs
        onChange={onViewChange}
        selected={view}
        tabs={availableViews}
        sx={{
          width: '100%',
          mb: 1,
        }}
      />
      <Box display={'flex'} gap={1}>
        <LiveMapFilters />

        <SearchBar
          alwaysExpanded
          setFilter={applySearchFilter}
          placeHolder={
            t('common.search_by', {
              field: `${t('common.account')}, ${t('common.order_id')}, ${t('common.or')} ${t('common.project_name')}`,
            }) as string
          }
        />
      </Box>
    </Box>
  );
};

export default TreadLiveTopBar;
