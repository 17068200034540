import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';

import { BasicTooltip } from '~components/Tooltip';

interface Props {
  props: React.HTMLAttributes<HTMLElement>;
  option: Record<string, any>;
  getLabel: (value: Record<string, any> | any) => string;
  inputValue: string;
  selected?: boolean;
  isCheckEnabled?: boolean;
}
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SelectOption = ({
  props,
  option,
  getLabel,
  inputValue,
  selected = false,
  isCheckEnabled = true,
}: Props) => {
  const str = getLabel(option);
  const matches = match(str, inputValue, {
    insideWords: true,
  });
  const parts = parse(str, matches);
  return (
    <Box component="li" {...props}>
      {isCheckEnabled && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: '8px', padding: 0 }}
          checked={selected}
        />
      )}
      <BasicTooltip
        title={str}
        placement="right-start"
        enterTouchDelay={500}
        enterDelay={500}
        enterNextDelay={500}
      >
        <Typography noWrap variant="body2">
          {parts.map((part, index) => (
            <span
              key={`${part?.text}-${index}`}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </BasicTooltip>
    </Box>
  );
};

export { SelectOption };
