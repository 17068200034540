import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { t as $t } from 'i18next';
import React, { ReactNode } from 'react';

import { TextFormField } from '~components/FormFields';
import { Nullable } from '~types/Nullable';

import { DataGridCustomizedExport } from './DataGridCustomizedExport';

interface DataGridToolBarProps {
  toolbarAdditionalContent?: Nullable<ReactNode>;
  toolbarSidebarContent?: Nullable<ReactNode>;
  isToolbarSidebarContentBefore?: boolean;
  csvExportCallBack?: () => void;
  excelExportCallBack?: () => void;
  apiRef?: any;
  hideQuickFilter?: boolean;
}
const DataGridToolBar = ({
  toolbarAdditionalContent,
  toolbarSidebarContent,
  isToolbarSidebarContentBefore = true,
  csvExportCallBack,
  excelExportCallBack,
  apiRef,
  hideQuickFilter = false,
}: DataGridToolBarProps) => {
  const csvExportHandler = () => {
    apiRef?.current?.exportDataAsCsv?.();
    csvExportCallBack?.();
  };
  const excelExportHandler = () => {
    apiRef?.current?.exportDataAsExcel?.();
    excelExportCallBack?.();
  };
  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', mb: 2 }}
        >
          <Box display={'flex'} sx={{ mr: 1 }} alignItems={'center'}>
            <Box
              sx={{
                order: isToolbarSidebarContentBefore ? 1 : 2,
                mr: isToolbarSidebarContentBefore ? 2 : 0,
                ml: isToolbarSidebarContentBefore ? 0 : 2,
              }}
              display={'flex'}
            >
              {toolbarSidebarContent}
            </Box>
            <Box display={'flex'} sx={{ order: isToolbarSidebarContentBefore ? 2 : 1 }}>
              <GridToolbarColumnsButton sx={{ mr: 1 }} variant="text" color="secondary" />
              <GridToolbarFilterButton
                componentsProps={{ button: { variant: 'text' } }}
                color="secondary"
              />
              <DataGridCustomizedExport
                onCsvExport={csvExportHandler}
                onExcelExport={excelExportHandler}
              />
            </Box>
          </Box>
          <Box display={'flex'} sx={{ alignItems: 'center' }}>
            {!hideQuickFilter && (
              <GridToolbarQuickFilter
                debounceMs={1000}
                sx={{ mr: toolbarAdditionalContent ? 2 : 0 }}
              />
            )}
            {toolbarAdditionalContent}
          </Box>
        </Box>
      </GridToolbarContainer>
    </>
  );
};

export { DataGridToolBar };
