import { yupResolver } from '@hookform/resolvers/yup';
import { SxProps } from '@mui/system';
import { isEqual } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SingleInputDateRangePicker } from '~components/FormFields';
interface DateRangeFilterProps {
  name: string;
  callBack: (filters: []) => void;
  values: string[] | null[];
  sx?: SxProps;
}
const DateRangeFilter = ({ name, callBack, values, sx }: DateRangeFilterProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        [name]: yup.array(),
      }),
    ),
    mode: 'onBlur',
    defaultValues: {
      [name]: [values?.[0], values?.[1]],
    },
  });
  const watchFilters = watch(name);
  useEffect(() => {
    if (!isEqual(watchFilters, values)) {
      callBack(watchFilters as []);
    }
  }, [watchFilters]);
  return (
    <SingleInputDateRangePicker control={control} errors={errors} name={name} sx={sx} />
  );
};
export { DateRangeFilter };
