import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { t as $t } from 'i18next';
import { capitalize } from 'lodash';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { ControllerProps, FieldErrors, useForm } from 'react-hook-form';

import {
  AutocompleteFormField,
  CheckBoxFormField,
  CompanySelectorFormField,
  PhoneCodeFlagInput,
  TextFormField,
} from '~components/FormFields';
import { FormSectionTitle } from '~components/typographyTitles';
import { countries, data as enums } from '~constants/enums';
import { Account } from '~hooks/useAccount';
import {
  accountFormSchema,
  setDefaultAccountValues,
} from '~pages/Settings/Accounts/accountFormSchema';
import { FormStateChangeProps } from '~src/formsShared';
import { useStores } from '~store';

interface CreateUpdateAccountFormProps {
  defaultAccount: Account | null | undefined;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
}

const AccountForm = forwardRef(function AccountForm(
  { defaultAccount, onFormStateChange }: CreateUpdateAccountFormProps,
  ref: Ref<any>,
) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(accountFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultAccountValues(defaultAccount as Account),
  });
  const isBillingAddressHidden = watch('isBillingAddressSame');
  const isBillingContactHidden = watch('isBillingContactSame');
  const { userStore } = useStores();
  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  return (
    <Box component="form" data-test-id="company-form">
      <Box display={'flex'} flexDirection={'column'} sx={{ mt: 2 }}>
        <FormSectionTitle sx={{ mb: 2 }} title={$t('form_fields.company')} />
        <CompanySelectorFormField
          parentCompanyId={userStore.user.company?.id}
          name={'company'}
          disabled={Boolean(defaultAccount?.company?.id.length)}
          errors={errors}
          control={control}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} sx={{ mt: 4 }}>
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('account.form.account_information')}
        </Typography>

        <Box display={'flex'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'33%'} sx={{ ml: 2 }}>
            <AutocompleteFormField
              control={control}
              multiple={true}
              name="accountTypes"
              errors={errors}
              list={enums.account_type.values}
              label={`${$t('account.form.account_type')}`}
              isRequired={true}
              getValue={(item) => item}
              getLabel={(item) => capitalize(item)}
              defaultValue={[]}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mb: 2 }} width={'100%'}>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name={'department'}
              label={`${$t('form_fields.department')}`}
              isRequired={true}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="externalId"
              label={`${$t('form_fields.external_id')}`}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mb: 2 }} width={'100%'}>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="address.streetAddress"
              label={`${$t('form_fields.address')}`}
              isRequired={true}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="address.premise"
              label={`${$t('form_fields.address_2')}`}
              isRequired={false}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="address.locality"
              label={`${$t('form_fields.city')}`}
              isRequired={true}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
        <Box display={'flex'} width={'100%'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="address.administrativeArea"
              label={`${$t('form_fields.state')}`}
              isRequired={false}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="address.postalCode"
              label={`${$t('form_fields.zip')}`}
              isRequired={true}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <AutocompleteFormField
              control={control}
              name="address.country"
              errors={errors}
              list={countries.values}
              label={`${$t('form_fields.country')}`}
              isRequired={true}
              getValue={(item) => item.code}
              getLabel={(item) => item.name}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
      </Box>
      <CheckBoxFormField
        control={control}
        errors={errors}
        value={false}
        label={$t('account.form.billing_same_as_primary_address') || ''}
        name={'isBillingAddressSame'}
      ></CheckBoxFormField>

      <Collapse in={!isBillingAddressHidden}>
        <Divider sx={{ my: 2 }} />
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('form_fields.billing_address')}
        </Typography>
        <Box display={'flex'} width={'100%'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="billingAddress.streetAddress"
              label={`${$t('form_fields.address')}`}
              isRequired={true}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="billingAddress.premise"
              label={`${$t('form_fields.address_2')}`}
              isRequired={false}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="billingAddress.locality"
              label={`${$t('form_fields.city')}`}
              isRequired={true}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="billingAddress.administrativeArea"
              label={`${$t('form_fields.state')}`}
              isRequired={false}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="billingAddress.postalCode"
              label={`${$t('form_fields.zip')}`}
              isRequired={true}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'33%'}>
            <AutocompleteFormField
              control={control}
              name="billingAddress.country"
              errors={errors}
              list={countries.values}
              label={`${$t('form_fields.country')}`}
              isRequired={true}
              getValue={(item) => item.code}
              getLabel={(item) => item.name}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
      </Collapse>
      <Box display={'flex'} sx={{ flexDirection: 'column', mb: 2 }}>
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('account.form.contact_information')}
        </Typography>

        <Box sx={{ mb: 2 }}>
          <TextFormField
            control={control}
            errors={errors}
            name="primaryContact.name"
            label={`${$t('form_fields.name')}`}
            isRequired={true}
          />
        </Box>
        <Box display={'flex'}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              name="primaryContact.email"
              label={`${$t('form_fields.email')}`}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <PhoneCodeFlagInput
              control={control as unknown as ControllerProps['control']}
              errors={errors as unknown as FieldErrors}
              name="primaryContact.phone"
              label={`${$t('form_fields.phone')}`}
            />
          </Box>
        </Box>
        <CheckBoxFormField
          control={control}
          errors={errors}
          value={false}
          label={$t('account.form.billing_contact_same_as_primary') || ''}
          name={'isBillingContactSame'}
        ></CheckBoxFormField>
      </Box>
      <Collapse in={!isBillingContactHidden}>
        <Divider sx={{ my: 2 }} />
        <Box display={'flex'} sx={{ flexDirection: 'column', mb: 2 }}>
          <Typography variant={'h6'} sx={{ mb: 1 }}>
            {$t('account.form.billing_contact')}
          </Typography>

          <Box sx={{ mb: 2 }}>
            <TextFormField
              control={control}
              errors={errors}
              name="primaryContact.name"
              label={`${$t('form_fields.name')}`}
              isRequired={true}
            />
          </Box>
          <Box display={'flex'} sx={{ mb: 2 }}>
            <Box display={'flex'} width={'50%'}>
              <TextFormField
                control={control}
                errors={errors}
                name="billingContact.email"
                label={`${$t('form_fields.email')}`}
                sx={{ mr: 2 }}
              />
            </Box>
            <Box display={'flex'} width={'50%'}>
              <PhoneCodeFlagInput
                control={control as unknown as ControllerProps['control']}
                errors={errors as unknown as FieldErrors}
                name="billingContact.phone"
                label={`${$t('form_fields.phone')}`}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
        </Box>
      </Collapse>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'100%'} sx={{ mt: 2 }}>
          <TextFormField
            control={control}
            multiline={true}
            rows={2}
            errors={errors}
            name="notes"
            label={`${$t('form_fields.notes')}`}
            isRequired={false}
          />
        </Box>
      </Box>
      {/*Submit buttons are moved to the parent component*/}
    </Box>
  );
});
export { AccountForm };
