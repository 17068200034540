import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextFormField } from '~components/FormFields';
import { useLogin } from '~hooks/useLogin/useLogin';
import { inputTypes } from '~pages/Auth/types';
import { signInWithEmailAndPasswordValidationSchema } from '~pages/Auth/validationSchemas';

import LinkToForgotPassword from './LinkToForgotPassword';

interface SignInWithPasswordFormProps {
  email: string;
  showEmailField: boolean;
}

interface SignInWithEmailAndPasswordData
  extends yup.InferType<typeof signInWithEmailAndPasswordValidationSchema> {}

const SignInWithPasswordForm = ({
  email,
  showEmailField,
}: SignInWithPasswordFormProps) => {
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { login, isLoading } = useLogin();

  const isInputFocused = useMemo(
    () => isEmailInputFocused || isPasswordInputFocused,
    [isEmailInputFocused, isPasswordInputFocused],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SignInWithEmailAndPasswordData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInWithEmailAndPasswordValidationSchema),
    defaultValues: { email },
  });

  const handleToggleInputFocus = useCallback((name: 'email' | 'password') => {
    if (name === 'email') {
      setIsEmailInputFocused((isFocused) => !isFocused);
    } else {
      setIsPasswordInputFocused((isFocused) => !isFocused);
    }
  }, []);

  const handleTogglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((isVisible) => !isVisible);
  }, []);

  const onSubmit = (data: SignInWithEmailAndPasswordData) => {
    login({ ...data });
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      gap={1}
    >
      {showEmailField && (
        <TextFormField
          control={control}
          errors={errors}
          name={inputTypes.email}
          type={inputTypes.email}
          label={`${t('form_fields.email')}`}
          inputProps={{
            'data-test-id': inputTypes.email,
            readOnly: true,
          }}
          onFocus={() => handleToggleInputFocus('email')}
          onBlur={() => handleToggleInputFocus('email')}
        />
      )}

      <TextFormField
        control={control}
        errors={errors}
        name={inputTypes.password}
        type={isPasswordVisible ? inputTypes.text : inputTypes.password}
        label={`${t('form_fields.password')}`}
        inputProps={{ 'data-test-id': inputTypes.password }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility}>
                {isPasswordVisible ? (
                  <VisibilityOff sx={{ width: 18 }} />
                ) : (
                  <Visibility sx={{ width: 18 }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onFocus={() => handleToggleInputFocus('password')}
        onBlur={() => handleToggleInputFocus('password')}
      />

      <LoadingButton
        fullWidth
        color={isInputFocused ? 'primary' : 'inherit'}
        sx={isInputFocused ? undefined : { backgroundColor: '#E1E1E1' }}
        variant="contained"
        type="submit"
        loading={isLoading}
      >
        {t('actions.sign_in_with_password')}
      </LoadingButton>

      <Box display="flex" alignItems="center" justifyContent="center" mt={1} width="100%">
        <LinkToForgotPassword />
      </Box>
    </Box>
  );
};

export default SignInWithPasswordForm;
