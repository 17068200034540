import ChevronLeftOutlined from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';

import { BasicTooltip } from '~components/Tooltip';
import { Pagination, PaginationLink } from '~interfaces/pagination';

interface Props {
  count: number;
  loading: boolean;
  pagination: Pagination;
  callback: (ref: PaginationLink, searchQuery?: string) => void;
  sx?: SxProps<Theme>; // Style
  altText?: string;
  searchQuery?: string;
}
const HeaderNavigation = ({
  count,
  loading,
  pagination,
  callback,
  sx,
  altText,
  searchQuery = '',
}: Props) => {
  const AltElementText = () => (
    <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
      {altText}
    </Typography>
  );
  if (!count) return <AltElementText />;

  return (
    <Box display={'flex'} alignItems={'center'} sx={{ ...sx }}>
      {pagination.after || pagination.before ? (
        <>
          <BasicTooltip title={$t('actions.previous')}>
            {/*In case button is disabled it can be direct child of tooltip  */}
            <span style={{ display: 'flex' }}>
              <IconButton
                size="small"
                color="secondary"
                disabled={loading || !pagination.before}
                onClick={() => {
                  callback('before', searchQuery);
                }}
              >
                <ChevronLeftOutlined />
              </IconButton>
            </span>
          </BasicTooltip>
          <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
            {pagination.page || ''}
          </Typography>
          <BasicTooltip title={$t('actions.next')}>
            {/*In case button is disabled it can be direct child of tooltip  */}
            <span style={{ display: 'flex' }}>
              <IconButton
                size="small"
                color="secondary"
                disabled={loading || !pagination.after}
                onClick={() => {
                  callback('after', searchQuery);
                }}
              >
                <ChevronRightOutlined />
              </IconButton>
            </span>
          </BasicTooltip>
        </>
      ) : (
        <AltElementText />
      )}
    </Box>
  );
};

export { HeaderNavigation };
