import {
  NextBillionGeofence_Read,
  Site_Read,
  Site_Read_Nested,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import area from '@turf/area';
import centroid from '@turf/centroid';
import { Feature, FeatureCollection, Geometry, Properties } from '@turf/helpers';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { GetPaginationParams, PaginationLink } from '~interfaces';
import connection from '~services/connectionModule';
import { SiteWithAdditionalGeoFences } from '~src/pages/Settings/Administration/Sites/GeoFenceForm';
import { useStores } from '~store';

import { Geofence, Site } from './models';

interface ToggleProps {
  id: string;
  isActive: boolean;
}
interface GetCompanySitesProps {
  companyId: string;
  callBack?: (sites: Site[]) => void;
  link?: PaginationLink;
  searchQuery?: string;
}
interface GetCompanySitesTypeaheadProps {
  companyId: string;
  callBack?: (sites: Site[], query: string) => Promise<void>;
  query?: string;
  cursor?: string;
  limit?: number;
}
interface GetCompanySitesTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}
interface SiteToProjectDefaultSitesParams {
  projectId: string;
  siteId: string;
  waypointType: WaypointType;
}
interface GetProjectDefaultSitesParams {
  projectId: string;
  waypointType: WaypointType;
}
interface SiteToOrderDefaultSitesParams {
  orderId: string;
  siteId: string;
  waypointType: WaypointType;
}
interface GetOrderDefaultSitesParams {
  orderId: string;
  waypointType: WaypointType;
}

export const useSites = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTypeahead, setIsLoadingTypeahead] = useState(false);
  const [isSaving, setIsSavingState] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { companyAssetsStore, ordersStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();
  const getAllSites = (link?: PaginationLink, searchQuery?: string) => {
    setIsLoading(true);

    let params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.sitesPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && companyAssetsStore.sitesPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.sitesPagination[link];
    }

    return connection
      .getPaginated<Site_Read>(
        `${API_VERSION}/sites`,
        { params },
        $t('error_messages.sites.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const list = data.map(Site.parse);
        companyAssetsStore.setSites(list);
        companyAssetsStore.setSitesPagination(pagination);
        companyAssetsStore.updateSitesPageNumber(link);
        return list;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSiteById = async (id: string) => {
    const resp = await connection.get<Site_Read>(
      `${API_VERSION}/sites/${id}`,
      {},
      $t('error_messages.sites.failed_to_fetch_site') as string,
    );
    const site = Site.parse(resp);
    companyAssetsStore.addSite(site);
    return site;
  };

  const getCompanySites = async ({
    companyId,
    callBack,
    link,
    searchQuery,
  }: GetCompanySitesProps) => {
    try {
      setIsLoading(true);

      const params: GetPaginationParams = {
        'page[limit]': companyAssetsStore.sitesPagination.limit,
      };
      if (link && companyAssetsStore.sitesPagination[link]) {
        params[`page[${link}]`] = companyAssetsStore.sitesPagination[link];
      }
      if (searchQuery) {
        params['search[datagrid]'] = searchQuery;
      }

      const { data, pagination } = await connection.getPaginated<Site_Read>(
        `${API_VERSION}/companies/${companyId}/sites`,
        { params },
        $t('error_messages.sites.failed_to_fetch') as string,
      );
      const sites = data.map(Site.parse);
      companyAssetsStore.setSites(sites);
      companyAssetsStore.setSitesPagination(pagination);
      companyAssetsStore.updateSitesPageNumber(link);
      callBack?.(sites);
      return sites;
    } catch (error) {
      throw new Error($t('error_messages.sites.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };
  const getCompanySitesTypeahead = async ({
    companyId,
    callBack,
    query,
    cursor,
    limit,
  }: GetCompanySitesTypeaheadProps) => {
    setIsLoadingTypeahead(true);

    const params: Partial<GetCompanySitesTypeaheadQueryProps> = {
      'page[limit]': limit || 25,
    };

    if (query) {
      params['search[query]'] = query;
    }

    if (cursor) {
      params['page[after]'] = cursor;
    }

    try {
      const { data, pagination } = await connection.getPaginated<Site_Read>(
        `${API_VERSION}/companies/${companyId}/sites/typeahead`,
        {
          params,
        },
        $t('error_messages.sites.failed_to_fetch') as string,
      );
      const list = data.map(Site.parse);

      if (callBack) {
        const results = await callBack(list, query || '');
        return { data: results, pagination };
      }

      return { data: list, pagination };
    } finally {
      setIsLoadingTypeahead(false);
    }
  };

  const createNewSite = (data: SiteWithAdditionalGeoFences) => {
    const additional_geofences = data.additionalGeoFencesDetails?.map((geofence) =>
      Geofence.deparseAdditionalGeoFence(geofence),
    );

    setIsUpdating(true);
    return connection
      .post<Site_Read>(`${API_VERSION}/sites`, Site.deparse(data), {}, undefined, [422])
      .then((resp) => {
        const site = Site.parse(resp);
        companyAssetsStore.addSite(site);
        return site;
      })
      .then(async (site) => {
        if (additional_geofences?.length) {
          const geofences = await bulkAssignAdditionalGeoFencesToSite(
            site.id,
            additional_geofences,
            true,
          );
        }
        return site;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const updateSite = (data: SiteWithAdditionalGeoFences) => {
    const additional_geofences = data.additionalGeoFencesDetails?.map((geofence) =>
      Geofence.deparseAdditionalGeoFence(geofence),
    );

    setIsUpdating(true);
    return connection
      .patch<Site_Read>(
        `${API_VERSION}/sites/${data.id}`,
        Site.deparseUpdate(data),
        {},
        $t('error_messages.sites.failed_to_update') as string,
        [422],
      )
      .then((resp) => {
        const site = Site.parse(resp);
        companyAssetsStore.updateSite(site);
        ordersStore.setOrderUpdatesOccurred(true);

        return site;
      })
      .then(async (site) => {
        if (additional_geofences) {
          await bulkAssignAdditionalGeoFencesToSite(site.id, additional_geofences);
        }
        return site;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const toggleSiteStatus = ({ isActive, id }: ToggleProps) => {
    const url = `${API_VERSION}/sites/${id}/${isActive ? 'deactivate' : 'activate'}`;

    setIsUpdating(true);
    return connection
      .put<Site_Read>(
        url,
        {},
        {},
        $t('error_messages.sites.failed_to_toggle_status', {
          status: isActive ? 'deactivate' : 'activate',
        }) as string,
      )
      .then((resp) => {
        const site = Site.parse(resp);
        companyAssetsStore.updateSite(site);
        return site;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const removeSite = (id: string) => {
    setIsUpdating(true);
    return connection
      .delete(
        `${API_VERSION}/sites/${id}`,
        {},
        $t('error_messages.sites.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.removeSite(id);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const createGeofenceAreaLayer = (
    feature: Geometry | Feature | FeatureCollection<any, Properties>,
  ) => {
    const areaOfFeature = area(feature);
    const centroidOfFeature = centroid(feature);

    return {
      id: 'area-label',
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: centroidOfFeature.geometry.coordinates,
              },
              properties: {
                title: 'Polygon Area: ' + (areaOfFeature / 1000000).toFixed(2) + ' sq km',
                icon: 'marker',
              },
            },
          ],
        },
      },
      layout: {
        'text-field': ['get', 'title'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#000',
      },
    };
  };
  const getProjectDefaultSites = async ({
    projectId,
    waypointType,
  }: GetProjectDefaultSitesParams): Promise<Site[]> => {
    setIsLoading(true);
    try {
      return await connection
        .get<
          Site_Read_Nested[]
        >(`${API_VERSION}/projects/${projectId}/default_sites/${waypointType}`, {}, $t('error_messages.sites.failed_to_fetch_project_default_sites') as string)
        .then((projectSites) => projectSites.map(Site.parseNested));
    } finally {
      setIsLoading(false);
    }
  };
  const addSiteToProjectDefaultSites = async ({
    projectId,
    siteId,
    waypointType,
  }: SiteToProjectDefaultSitesParams): Promise<Site> => {
    setIsSavingState(true);
    try {
      return await connection
        .put<Site_Read_Nested>(
          `${API_VERSION}/projects/${projectId}/default_sites/${waypointType}/${siteId}`,
          {},
          {},
          $t('error_messages.sites.failed_to_add_to_project_default_sites') as string,
        )
        .then((newProjectSite) => Site.parseNested(newProjectSite));
    } finally {
      setIsSavingState(false);
    }
  };
  const deleteSiteFromProjectDefaultSites = async ({
    projectId,
    siteId,
    waypointType,
  }: SiteToProjectDefaultSitesParams): Promise<void | { error: string }> => {
    setIsSavingState(true);
    try {
      return await connection
        .delete<void | {
          error: string;
        }>(
          `${API_VERSION}/projects/${projectId}/default_sites/${waypointType}/${siteId}`,
          {},
          $t(
            'error_messages.sites.failed_to_delete_from_project_default_sites',
          ) as string,
        )
        .then((resp) => resp);
    } finally {
      setIsSavingState(false);
    }
  };
  const getOrderDefaultSites = async ({
    orderId,
    waypointType,
  }: GetOrderDefaultSitesParams): Promise<Site[]> => {
    setIsLoading(true);
    try {
      return await connection
        .get<
          Site_Read_Nested[]
        >(`${API_VERSION}/orders/${orderId}/default_sites/${waypointType}`, {}, $t('error_messages.sites.failed_to_fetch_default_sites') as string)
        .then((orderDefaultSites) => orderDefaultSites.map(Site.parseNested));
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRecentlyCreatedSite = async (siteId: string) => {
    setIsLoading(true);
    try {
      toggleSiteStatus({ id: siteId, isActive: true }).then(() => removeSite(siteId));
    } finally {
      setIsLoading(false);
    }
  };

  const addSiteToOrderDefaultSites = async ({
    orderId,
    siteId,
    waypointType,
  }: SiteToOrderDefaultSitesParams): Promise<Site> => {
    setIsSavingState(true);
    try {
      return await connection
        .put<Site_Read_Nested>(
          `${API_VERSION}/orders/${orderId}/default_sites/${waypointType}/${siteId}`,
          {},
          {},
          $t('error_messages.sites.failed_to_add_to_order_default_sites') as string,
        )
        .then((newOrderSite) => Site.parseNested(newOrderSite));
    } finally {
      setIsSavingState(false);
    }
  };
  const deleteSiteFromOrderDefaultSites = async ({
    orderId,
    siteId,
    waypointType,
  }: SiteToOrderDefaultSitesParams): Promise<void | { error: string }> => {
    setIsSavingState(true);
    try {
      return await connection
        .delete<void | {
          error: string;
        }>(
          `${API_VERSION}/orders/${orderId}/default_sites/${waypointType}/${siteId}`,
          {},
          $t('error_messages.sites.failed_to_delete_from_order_default_sites') as string,
        )
        .then((resp) => resp);
    } finally {
      setIsSavingState(false);
    }
  };

  const getAdditionalGeoFencesForSite = async (id: string) => {
    setIsLoading(true);
    const params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.sitesPagination.limit,
    };
    try {
      return await connection
        .get<
          NextBillionGeofence_Read[]
        >(`${API_VERSION}/sites/${id}/additional_geofences`, { params }, $t('error_messages.sites.failed_to_fetch_additional_geofences') as string)
        .then((geofences) => {
          const additionalGeofences = geofences.map(Geofence.parse);
          companyAssetsStore.setActiveSiteAdditionalGeoFences(additionalGeofences);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const bulkAssignAdditionalGeoFencesToSite = async (
    siteId: string,
    additional_geofences: any[],
    creation = false,
  ) => {
    setIsSavingState(true);
    try {
      return await connection
        .patch<
          NextBillionGeofence_Read[]
        >(`${API_VERSION}/sites/${siteId}/additional_geofences/bulk_assign`, { additional_geofences }, {}, $t('error_messages.sites.failed_to_assign_additional_geofences') as string)
        .then((geofences) => {
          const additionalGeofences = geofences.map(Geofence.parse);
          companyAssetsStore.setActiveSiteAdditionalGeoFences(additionalGeofences);
        });
    } catch (error) {
      if (creation) {
        await deleteRecentlyCreatedSite(siteId).then(() => {
          throw new Error($t('error_messages.sites.failed_to_create') as string);
        });
      } else {
        throw new Error($t('error_messages.sites.failed_to_create') as string);
      }
    } finally {
      setIsSavingState(false);
    }
  };

  return {
    addSiteToOrderDefaultSites,
    addSiteToProjectDefaultSites,
    bulkAssignAdditionalGeoFencesToSite,
    createNewSite,
    deleteSiteFromOrderDefaultSites,
    deleteSiteFromProjectDefaultSites,
    getAdditionalGeoFencesForSite,
    getAllSites,
    getCompanySites,
    getCompanySitesTypeahead,
    getProjectDefaultSites,
    getOrderDefaultSites,
    getSiteById,
    isLoading,
    isSaving,
    isUpdating,
    isLoadingTypeahead,
    removeSite,
    toggleSiteStatus,
    updateSite,
  };
};
