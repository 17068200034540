import { JobState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';

/**
 * Parses the job state and returns the corresponding text representation.
 * @param state - The job state to parse.
 * @returns The text representation of the job state.
 */
const parseJobStateText = (state: JobState) => {
  switch (state) {
    case JobState.CREATED:
      return $t('status.created');
    case JobState.SENT:
      return $t('status.pending');
    case JobState.ACCEPTED:
      return $t('status.accepted');
    case JobState.TO_PICKUP:
      return $t('status.to_pickup');
    case JobState.TO_DROPOFF:
      return $t('status.to_dropoff');
    case JobState.ARRIVED_PICKUP:
      return $t('status.arrived_pickup');
    case JobState.ARRIVED_DROPOFF:
      return $t('status.arrived_dropoff');
    case JobState.LOADED:
      return $t('status.loaded');
    case JobState.UNLOADED:
      return $t('status.unloaded');
    case JobState.REJECTED:
      return $t('status.rejected');
    case JobState.IN_REVIEW:
      return $t('status.in_review');
    case JobState.SIGNED_OFF:
      return $t('status.signed_off');
    case JobState.PAID:
      return $t('status.paid');
    case JobState.COMPLETED:
      return $t('status.completed');
    case JobState.CANCELED:
      return $t('status.canceled');
    case JobState.FLAGGED:
      return $t('status.flagged');
    case JobState.LOAD_COMPLETED:
      return $t('status.load_completed');
    default:
      return state;
  }
};

export { parseJobStateText };
