import Box from '@mui/material/Box';
import {
  InvoiceCategoryFilter,
  InvoiceState,
  SettlementState,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FeatureFlags } from '~constants/featureFlags';
import {
  DriverPayDataGrid,
  DriverPayFilters,
  SettlementsDataGrid,
  Tab,
  TabsGroup,
} from '~pages/Approvals/DriverPay';
import { useStores } from '~store';
import { InvoiceSearchParamName } from '~store/InvoicesStore';
import {
  SettlementSearchParamName,
  SettlementSearchParams,
} from '~store/SettlementsStore';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { usePrevious } from '~utils/hooks/usePrevious';

import ChipsGroup, { Chip } from './components/ChipsGroup';
import {
  areAllRequiredParamsSet,
  deriveInvoiceSearchParamsFromQueryString,
  deriveSettlementsSearchParamsFromQueryString,
  isValidGridAndTabCombination,
  parseQueryStringParams,
} from './driverPayHelpers';

type InvoiceStateTab = Tab & { filters: InvoiceState[] };
type InvoiceCategoryChip = Omit<Chip, 'value'> & { value: InvoiceCategoryFilter };
type SettlementStateTab = Tab & { filters: SettlementState[] };

export enum GridQSParam {
  JOBS = 'jobs',
  SETTLEMENTS = 'settlements',
}

export enum TabQSParam {
  APPROVED = 'approved',
  DONE = 'done',
  GROUPED = 'grouped',
  PENDING = 'pending',
  SENT = 'sent',
}

export const payableInvoiceCategories = [
  InvoiceCategoryFilter.INTERNAL_PAYABLES,
  InvoiceCategoryFilter.OWNER_OPERATOR_PAYABLES,
  InvoiceCategoryFilter.PAYABLES,
  InvoiceCategoryFilter.VENDOR_PAYABLES,
];

export const receivableInvoiceCategories = [
  InvoiceCategoryFilter.ACCOUNT_RECEIVABLES,
  InvoiceCategoryFilter.CONNECTION_RECEIVABLES,
  InvoiceCategoryFilter.RECEIVABLES,
];

export const payableJobsStateTabs: InvoiceStateTab[] = [
  {
    text: `${t(`approvals.driver_pay.tabs.pending`)}`,
    value: TabQSParam.PENDING,
    filters: [
      InvoiceState.CREATED,
      InvoiceState.IN_PROGRESS,
      InvoiceState.PENDING,
      InvoiceState.CUSTOMER_PENDING,
    ],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.approved`)}`,
    value: TabQSParam.APPROVED,
    filters: [InvoiceState.APPROVED, InvoiceState.CUSTOMER_APPROVED],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.grouped`)}`,
    value: TabQSParam.GROUPED,
    filters: [],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.done`)}`,
    value: TabQSParam.DONE,
    filters: [
      InvoiceState.PAID,
      InvoiceState.CUSTOMER_PAID,
      InvoiceState.EXPORTED,
      InvoiceState.VOIDED,
    ],
  },
];

export const receivableJobsStateTabs: InvoiceStateTab[] = [
  {
    text: `${t(`approvals.driver_pay.tabs.pending`)}`,
    value: TabQSParam.PENDING,
    filters: [InvoiceState.CREATED, InvoiceState.IN_PROGRESS, InvoiceState.PENDING],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.approved`)}`,
    value: TabQSParam.APPROVED,
    filters: [InvoiceState.APPROVED],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.grouped`)}`,
    value: TabQSParam.GROUPED,
    filters: [],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.done`)}`,
    value: TabQSParam.DONE,
    filters: [
      InvoiceState.CUSTOMER_PENDING,
      InvoiceState.CUSTOMER_APPROVED,
      InvoiceState.CUSTOMER_PAID,
      InvoiceState.VOIDED,
    ],
  },
];

export const payableSettlementsStateTabs: SettlementStateTab[] = [
  {
    text: `${t(`approvals.driver_pay.tabs.pending`)}`,
    value: TabQSParam.PENDING,
    filters: [SettlementState.PENDING, SettlementState.CUSTOMER_PENDING],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.approved`)}`,
    value: TabQSParam.APPROVED,
    filters: [SettlementState.APPROVED, SettlementState.CUSTOMER_APPROVED],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.done`)}`,
    value: TabQSParam.DONE,
    filters: [SettlementState.PAID, SettlementState.CUSTOMER_PAID],
  },
];

export const receivableSettlementsStateTabs: SettlementStateTab[] = [
  {
    text: `${t(`approvals.driver_pay.tabs.pending`)}`,
    value: TabQSParam.PENDING,
    filters: [SettlementState.PENDING],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.approved`)}`,
    value: TabQSParam.APPROVED,
    filters: [SettlementState.APPROVED],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.sent`)}`,
    value: TabQSParam.SENT,
    filters: [SettlementState.CUSTOMER_PENDING],
  },
  {
    text: `${t(`approvals.driver_pay.tabs.done`)}`,
    value: TabQSParam.DONE,
    filters: [SettlementState.CUSTOMER_APPROVED, SettlementState.CUSTOMER_PAID],
  },
];

const payableJobsChips: InvoiceCategoryChip[] = [
  { value: InvoiceCategoryFilter.PAYABLES, label: t('common.all') },
  { value: InvoiceCategoryFilter.VENDOR_PAYABLES, label: t('common.vendor') },
  { value: InvoiceCategoryFilter.OWNER_OPERATOR_PAYABLES, label: t('common.o_and_o') },
  { value: InvoiceCategoryFilter.INTERNAL_PAYABLES, label: t('common.internal') },
];

const receivableJobsChips: InvoiceCategoryChip[] = [
  { value: InvoiceCategoryFilter.RECEIVABLES, label: t('common.all') },
  { value: InvoiceCategoryFilter.CONNECTION_RECEIVABLES, label: t('common.connection') },
  { value: InvoiceCategoryFilter.ACCOUNT_RECEIVABLES, label: t('common.account') },
];

const gridSelectorTabs: Tab[] = [
  { text: 'Jobs', value: GridQSParam.JOBS },
  { text: 'Settlements', value: GridQSParam.SETTLEMENTS },
];

export type QSParams = {
  category: InvoiceCategoryFilter;
  grid: GridQSParam;
  tab: TabQSParam;
  start_date?: string;
  end_date?: string;
  project_ids?: string;
  customer_account_ids?: string;
  vendor_account_ids?: string;
  truck_ids?: string;
  driver_ids?: string;
  dropoff_site_ids?: string;
  pickup_site_ids?: string;
  rate_types?: string;
};

export type MaybeNullQSParams = {
  [K in keyof QSParams]: QSParams[K] | null;
};

const defaultParams: QSParams = {
  category: InvoiceCategoryFilter.RECEIVABLES,
  grid: GridQSParam.JOBS,
  tab: TabQSParam.PENDING,
};

const DriverPay = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { invoicesStore, settlementsStore } = useStores();
  const settlementsEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.settlements,
  });

  const queryStringParams = useMemo(
    () => parseQueryStringParams(searchParams),
    [searchParams],
  );
  const previousQueryStringParams = usePrevious(queryStringParams);

  const showJobs = queryStringParams.grid === GridQSParam.JOBS;
  const showSettlements = queryStringParams.grid === GridQSParam.SETTLEMENTS;
  const showPayables = Boolean(
    queryStringParams.category &&
      payableInvoiceCategories.includes(queryStringParams.category),
  );
  const showReceivables = !showPayables;

  // Check if the "category" query string param has been set in the correspondent store
  const categorySearchParamIsSet = useMemo(() => {
    if (queryStringParams.category) {
      if (showJobs) {
        return (
          invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_CATEGORY] ===
          queryStringParams.category
        );
      }

      if (showSettlements) {
        const categoryToMatch: SettlementSearchParams[SettlementSearchParamName.SETTLEMENT_CATEGORY] =
          payableInvoiceCategories.includes(queryStringParams.category)
            ? InvoiceCategoryFilter.PAYABLES
            : InvoiceCategoryFilter.RECEIVABLES;

        return (
          settlementsStore.searchParams[SettlementSearchParamName.SETTLEMENT_CATEGORY] ===
          categoryToMatch
        );
      }
    }

    return false;
  }, [
    showJobs,
    showSettlements,
    queryStringParams.category,
    invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_CATEGORY],
    settlementsStore.searchParams[SettlementSearchParamName.SETTLEMENT_CATEGORY],
  ]);

  // Check if the "tab" query string param has been set in the correspondent store
  const statesSearchParamIsSet = useMemo(() => {
    if (queryStringParams.tab) {
      if (showJobs) {
        const tabs = showPayables ? payableJobsStateTabs : receivableJobsStateTabs;
        const selectedTab = tabs.find((tab) => tab.value === queryStringParams.tab);

        if (selectedTab) {
          return _.isEqual(
            invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_STATES],
            selectedTab.filters,
          );
        }

        return false;
      }

      if (showSettlements) {
        const tabs = showPayables
          ? payableSettlementsStateTabs
          : receivableSettlementsStateTabs;
        const selectedTab = tabs.find((tab) => tab.value === queryStringParams.tab);

        if (selectedTab) {
          return _.isEqual(
            settlementsStore.searchParams[SettlementSearchParamName.SETTLEMENT_STATES],
            selectedTab.filters,
          );
        }

        return false;
      }
    }

    return false;
  }, [
    showJobs,
    showSettlements,
    showPayables,
    queryStringParams.tab,
    invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_STATES],
    settlementsStore.searchParams[SettlementSearchParamName.SETTLEMENT_STATES],
  ]);

  // Check if the "startDate" and "endDate" query string params has been set in the invoices store
  const datesSearchParamsAreSet = useMemo(() => {
    if (showJobs) {
      let startDateIsSet = true;
      let endDateIsSet = true;

      if (queryStringParams.start_date) {
        startDateIsSet =
          invoicesStore.searchParams[InvoiceSearchParamName.START_DATE] ===
          queryStringParams.start_date;
      }

      if (queryStringParams.end_date) {
        endDateIsSet =
          invoicesStore.searchParams[InvoiceSearchParamName.END_DATE] ===
          queryStringParams.end_date;
      }

      return startDateIsSet && endDateIsSet;
    }

    return true;
  }, [
    showJobs,
    queryStringParams.start_date,
    queryStringParams.end_date,
    invoicesStore.searchParams[InvoiceSearchParamName.START_DATE],
    invoicesStore.searchParams[InvoiceSearchParamName.END_DATE],
  ]);

  const canRender =
    categorySearchParamIsSet && statesSearchParamIsSet && datesSearchParamsAreSet;

  const updateSearchParams = useCallback(
    (newParams: Array<{ key: string; value: string }>) => {
      setSearchParams((currentParams) => {
        newParams.forEach((param) => {
          currentParams.set(param.key, param.value);
        });

        return currentParams;
      });
    },
    [setSearchParams],
  );

  const handleCategoryChipChange = useCallback(
    (value: string) => updateSearchParams([{ key: 'category', value }]),
    [updateSearchParams],
  );

  const handleGridTabChange = useCallback(
    (value: string) => {
      const isValidCombination = isValidGridAndTabCombination(queryStringParams);

      if (!isValidCombination) {
        updateSearchParams([
          { key: 'grid', value },
          { key: 'tab', value: defaultParams.tab },
        ]);
      } else {
        updateSearchParams([{ key: 'grid', value }]);
      }
    },
    [updateSearchParams, queryStringParams.tab],
  );

  const handleStateTabChange = useCallback(
    (value: string) => updateSearchParams([{ key: 'tab', value }]),
    [updateSearchParams],
  );

  useEffect(() => {
    const allParamsSet = areAllRequiredParamsSet(queryStringParams);

    // All query string params are set, we are now ready to translate them to request params
    // And fetch what needs to be fetched
    if (allParamsSet) {
      if (showJobs) {
        const params = deriveInvoiceSearchParamsFromQueryString(queryStringParams);

        invoicesStore.setSearchParams(params);
      } else if (showSettlements) {
        const params = deriveSettlementsSearchParamsFromQueryString(queryStringParams);

        settlementsStore.setSearchParams(params);
      }
    }
  }, [showJobs, showSettlements, queryStringParams]);

  useEffect(() => {
    const {
      category,
      grid,
      tab,
      start_date,
      end_date,
      project_ids,
      customer_account_ids,
      vendor_account_ids,
      truck_ids,
      driver_ids,
      dropoff_site_ids,
      pickup_site_ids,
      rate_types,
    } = queryStringParams;
    const previousParams = previousQueryStringParams;

    const newSearchParams: QSParams = {
      category: category ?? defaultParams.category,
      grid: grid ?? previousParams?.grid ?? defaultParams.grid,
      tab: tab ?? previousParams?.tab ?? defaultParams.tab,
    };

    const addIfNotNull = <K extends keyof QSParams>(
      key: K,
      value: QSParams[K] | null,
    ) => {
      if (value !== null) newSearchParams[key] = value;
    };

    addIfNotNull('start_date', start_date);
    addIfNotNull('end_date', end_date);
    addIfNotNull('project_ids', project_ids);
    addIfNotNull('customer_account_ids', customer_account_ids);
    addIfNotNull('vendor_account_ids', vendor_account_ids);
    addIfNotNull('truck_ids', truck_ids);
    addIfNotNull('driver_ids', driver_ids);
    addIfNotNull('dropoff_site_ids', dropoff_site_ids);
    addIfNotNull('pickup_site_ids', pickup_site_ids);
    addIfNotNull('rate_types', rate_types);

    const isValidCombination = isValidGridAndTabCombination(newSearchParams);

    if (!isValidCombination) {
      newSearchParams.tab = defaultParams.tab;
    }

    if (!_.isEqual(queryStringParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [queryStringParams, previousQueryStringParams, setSearchParams]);

  if (!canRender) {
    return null;
  }

  return (
    <Box width="100%" display="grid" gap={3}>
      <Box display="flex" alignItems="center" gap={3}>
        {settlementsEnabled && (
          <TabsGroup
            tabs={gridSelectorTabs}
            searchParamName="grid"
            onChange={handleGridTabChange}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={3}>
        {showJobs && showPayables && (
          <TabsGroup
            tabs={payableJobsStateTabs}
            searchParamName="tab"
            onChange={handleStateTabChange}
          />
        )}

        {showJobs && showReceivables && (
          <TabsGroup
            tabs={receivableJobsStateTabs}
            searchParamName="tab"
            onChange={handleStateTabChange}
          />
        )}

        {showSettlements && showPayables && (
          <TabsGroup
            tabs={payableSettlementsStateTabs}
            searchParamName="tab"
            onChange={handleStateTabChange}
          />
        )}

        {showSettlements && showReceivables && (
          <TabsGroup
            tabs={receivableSettlementsStateTabs}
            searchParamName="tab"
            onChange={handleStateTabChange}
          />
        )}
        <DriverPayFilters />
      </Box>

      {showJobs ? <DriverPayDataGrid /> : <SettlementsDataGrid />}
    </Box>
  );
});

export default DriverPay;
