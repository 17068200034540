import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Breakpoint, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import { DefaultTFuncReturn, t as $t } from 'i18next';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { DialogCloseButton } from '../Buttons';

export interface ModalDialogHandler {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

interface ModalDialogProps {
  title: string;
  content: ReactNode;
  maxWidth?: Breakpoint;
  loading?: boolean;
  confirmButtonText?: string | DefaultTFuncReturn;
  confirmButtonColor?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  cancelButtonText?: string | DefaultTFuncReturn;
  callBack: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  confirmedDisabled?: boolean;
}

const ModalDialog = forwardRef(function ModalDialog(
  {
    title,
    content,
    maxWidth,
    loading,
    confirmButtonText,
    confirmButtonColor,
    cancelButtonText,
    callBack,
    onCancel,
    onClose,
    confirmedDisabled,
  }: ModalDialogProps,
  ref: Ref<ModalDialogHandler>,
) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
    toggle() {
      setIsOpen((prev) => !prev);
    },
  }));

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
  }, [isOpen]);

  const onSubmit = () => {
    callBack();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Typography component={'span'} variant={'h5'} sx={{ width: '100%' }}>
          {title}
        </Typography>
        <DialogCloseButton callBack={() => setIsOpen(false)}></DialogCloseButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 4 }}>{content}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          m: 0,
          px: 2,
          py: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <LoadingButton
          onClick={onSubmit}
          loading={loading}
          loadingPosition="start"
          startIcon={<></>}
          type="button"
          variant="contained"
          color={confirmButtonColor || 'error'}
          sx={loading ? { pl: 5, pr: 2 } : { pr: 2 }}
          disabled={confirmedDisabled}
        >
          {confirmButtonText || $t('actions.proceed')}
        </LoadingButton>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onCancel?.();
            setIsOpen(false);
          }}
          sx={{ mr: 2, px: 2 }}
          disabled={loading}
        >
          {cancelButtonText || $t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export { ModalDialog };
