import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import Menu from '~components/Menu/Menu';
import FilterMenuItem, {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~pages/Dispatch/components/filters/FilterMenuItem';
import useFetchers from '~pages/Dispatch/components/filters/useFetchers';
import { useStores } from '~store';
import { JobsFilters } from '~store/DriverSchedulerStore';
import theme from '~theme/AppTheme';

import { JobsSelectedFilter } from '../../constants';
import { ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '../../OrdersDispatchFiltersBar';
import { SmallButton } from '../../ordersDispatchStyledComponents';

type UnassignedJobFilterKeys = keyof Pick<
  JobsFilters,
  | 'customers'
  | 'dispatchNumbers'
  | 'dropOffSites'
  | 'pickUpSites'
  | 'projects'
  | 'projectsExternalIds'
>;

const unassignedJobsFilterKeyBySelectedFilter: Record<
  JobsSelectedFilter,
  UnassignedJobFilterKeys
> = {
  [JobsSelectedFilter.CUSTOMERS]: 'customers',
  [JobsSelectedFilter.PICK_UP_SITES]: 'pickUpSites',
  [JobsSelectedFilter.DROP_OFF_SITES]: 'dropOffSites',
  [JobsSelectedFilter.DISPATCH_NUMBERS]: 'dispatchNumbers',
  [JobsSelectedFilter.PROJECTS]: 'projects',
  [JobsSelectedFilter.PROJECTS_EXTERNAL_IDS]: 'projectsExternalIds',
};

export type AppliedFiltersState = Record<
  JobsSelectedFilter,
  {
    unassignedJobFilterKey: UnassignedJobFilterKeys;
    search: string;
    selected: string[];
  }
>;

type TypeaheadedFilterState = {
  cursor?: string;
  loading: FilterMenuItemLoadingReason | false;
  options: FilterMenuOption[];
  searchValue: string;
};

type FiltersAvailableOptionsState = Record<JobsSelectedFilter, TypeaheadedFilterState>;

type NextPageCursorsState = Partial<
  Record<JobsSelectedFilter, TypeaheadedFilterState['cursor']>
>;

const appliedFiltersInitialState = Object.entries(
  unassignedJobsFilterKeyBySelectedFilter,
).reduce((obj, [filterKey, storeKey]) => {
  obj[filterKey as JobsSelectedFilter] = {
    unassignedJobFilterKey: storeKey,
    search: '',
    selected: [],
  };

  return obj;
}, {} as AppliedFiltersState);

const availableOptionsInitialState = Object.values(JobsSelectedFilter).reduce(
  (obj, filter) => {
    obj[filter] = {
      loading: false,
      options: [],
      searchValue: '',
    };

    return obj;
  },
  {} as FiltersAvailableOptionsState,
);

const UnassignedJobsFilters = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<JobsSelectedFilter>();
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersState>(
    appliedFiltersInitialState,
  );

  const { userStore, driverSchedulerStore } = useStores();
  const companyId = userStore.userCompany?.id;

  const [availableOptions, setAvailableOptions] = useState<FiltersAvailableOptionsState>(
    availableOptionsInitialState,
  );

  const [nextPageCursors, setNextPageCursors] = useState<NextPageCursorsState>({});
  const fetchers = useFetchers(companyId);
  const numberOfActiveFilters = Object.values(appliedFilters).reduce(
    (acc, filter) => acc + filter.selected.length,
    0,
  );

  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, ORDERS_DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const prepareForNewPageRequest = (key: JobsSelectedFilter) => {
    // We know that all records has been fetched if the cursor is explictly "undefined"
    const hasNextPageCursor = 'cursor' in availableOptions[key];

    if (!hasNextPageCursor) {
      return;
    }

    const allPagesFetched = _.isUndefined(availableOptions[key].cursor);

    if (allPagesFetched) {
      return;
    }

    setAvailableOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  };

  const applySearchResponse = (key: JobsSelectedFilter, data: FilterMenuOption[]) => {
    setAvailableOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        options: _.uniqBy([...state[key].options, ...data], (i) => i.value),
      },
    }));
  };

  const getFetchMoreCallback = useCallback(() => {
    if (!selectedFilter) {
      return undefined;
    }

    const allPagesFetched =
      'cursor' in availableOptions[selectedFilter] &&
      _.isUndefined(availableOptions[selectedFilter].cursor);

    if (allPagesFetched) {
      return undefined;
    }

    return () => {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter]: {
          ...state[selectedFilter],
          cursor: nextPageCursors[selectedFilter],
        },
      }));
    };
  }, [selectedFilter, availableOptions]);

  // Customers filter
  useEffect(() => {
    if (availableOptions.customers.loading) {
      const { searchValue, cursor } = availableOptions.customers;

      fetchers
        .fetchCustomers(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({ ...state, customers: nextPageCursor }));
          applySearchResponse(JobsSelectedFilter.CUSTOMERS, data);
        });
    }
  }, [availableOptions.customers.loading]);

  // Dispatch numbers filter
  useEffect(() => {
    if (availableOptions.dispatchNumbers.loading) {
      const { searchValue, cursor } = availableOptions.dispatchNumbers;

      fetchers
        .fetchOrdersDispatchNumbers(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({ ...state, dispatchNumbers: nextPageCursor }));
          applySearchResponse(JobsSelectedFilter.DISPATCH_NUMBERS, data);
        });
    }
  }, [availableOptions.dispatchNumbers.loading]);

  // Projects filter
  useEffect(() => {
    if (availableOptions.projects.loading) {
      const { searchValue, cursor } = availableOptions.projects;

      fetchers
        .fetchProjects(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({ ...state, projects: nextPageCursor }));
          applySearchResponse(JobsSelectedFilter.PROJECTS, data);
        });
    }
  }, [availableOptions.projects.loading]);

  // Projects external ids filter
  useEffect(() => {
    if (availableOptions.projectsExternalIds.loading) {
      const { searchValue, cursor } = availableOptions.projectsExternalIds;

      fetchers
        .fetchProjectExternalIds(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({
            ...state,
            projectsExternalIds: nextPageCursor,
          }));
          applySearchResponse(JobsSelectedFilter.PROJECTS_EXTERNAL_IDS, data);
        });
    }
  }, [availableOptions.projectsExternalIds.loading]);

  // Pick up sites filter
  useEffect(() => {
    if (availableOptions.pickUpSites.loading) {
      const { searchValue, cursor } = availableOptions.pickUpSites;

      fetchers
        .fetchSites(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({ ...state, pickUpSites: nextPageCursor }));
          applySearchResponse(JobsSelectedFilter.PICK_UP_SITES, data);
        });
    }
  }, [availableOptions.pickUpSites.loading]);

  // Drop off sites filter
  useEffect(() => {
    if (availableOptions.dropOffSites.loading) {
      const { searchValue, cursor } = availableOptions.dropOffSites;

      fetchers
        .fetchSites(searchValue, cursor as string)
        .then(({ data, nextPageCursor }) => {
          setNextPageCursors((state) => ({ ...state, dropOffSites: nextPageCursor }));
          applySearchResponse(JobsSelectedFilter.DROP_OFF_SITES, data);
        });
    }
  }, [availableOptions.dropOffSites.loading]);

  useEffect(() => {
    if (selectedFilter) {
      prepareForNewPageRequest(selectedFilter);
    }
  }, [
    selectedFilter,
    availableOptions.customers.cursor,
    availableOptions.dispatchNumbers.cursor,
    availableOptions.dropOffSites.cursor,
    availableOptions.pickUpSites.cursor,
    availableOptions.projects.cursor,
    availableOptions.projectsExternalIds.cursor,
  ]);

  useEffect(() => {
    if (selectedFilter) {
      prepareForNewPageRequest(selectedFilter);
    }
  }, [
    selectedFilter,
    availableOptions.customers.cursor,
    availableOptions.dispatchNumbers.cursor,
    availableOptions.projects.cursor,
    availableOptions.projectsExternalIds.cursor,
  ]);

  useEffect(() => {
    if (selectedFilter) {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter]: {
          loading: false,
          options: [],
          searchValue: state[selectedFilter].searchValue,
        },
      }));

      applyDebouncedFilter(() => {
        setAvailableOptions((state) => ({
          ...state,
          [selectedFilter]: {
            loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
            options: [],
            searchValue: appliedFilters[selectedFilter].search,
          },
        }));
      });
    }

    return () => {
      applyDebouncedFilter.cancel();
    };
  }, [
    selectedFilter,
    appliedFilters.customers.search,
    appliedFilters.dispatchNumbers.search,
    appliedFilters.dropOffSites.search,
    appliedFilters.pickUpSites.search,
    appliedFilters.projects.search,
    appliedFilters.projectsExternalIds.search,
  ]);

  useEffect(() => {
    const filters = Object.values(appliedFilters).reduce(
      (obj, filter) => {
        if (filter.selected.length === 0) {
          obj[filter.unassignedJobFilterKey] = undefined;
        } else {
          obj[filter.unassignedJobFilterKey] = filter.selected;
        }

        return obj;
      },
      {} as Parameters<typeof driverSchedulerStore.setUnassignedJobsFilters>[0],
    );

    driverSchedulerStore.setUnassignedJobsFilters(filters, true);
  }, [
    JSON.stringify(appliedFilters.customers.selected),
    JSON.stringify(appliedFilters.dispatchNumbers.selected),
    JSON.stringify(appliedFilters.dropOffSites.selected),
    JSON.stringify(appliedFilters.pickUpSites.selected),
    JSON.stringify(appliedFilters.projects.selected),
    JSON.stringify(appliedFilters.projectsExternalIds.selected),
  ]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            startIcon={<FilterList />}
            sx={{
              '&.MuiButton-root': {
                p: `0 ${theme.spacing(1)}`,
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: theme.brandV2.borderRadius,
              },
              '.MuiButton-startIcon': {
                margin: 0,
              },
            }}
          >
            {numberOfActiveFilters > 0 && (
              <Box ml={0.4} display={'flex'} alignItems={'center'}>
                {t('common.filters')} +{numberOfActiveFilters}
                <IconButton
                  sx={{
                    position: 'relative',
                    top: '1px',
                    left: '2px',
                    p: '4px',
                    cursor: 'pointer',
                    borderRadius: 'none',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAppliedFilters(appliedFiltersInitialState);
                  }}
                >
                  <Close
                    sx={{
                      width: '16px',
                      height: '16px',
                      color: theme.brandV2.colors.treadBlack,
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </SmallButton>
        }
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={appliedFilters[selectedFilter].search}
            onSearchValueChange={(value) => {
              setAppliedFilters((state) => ({
                ...state,
                [selectedFilter]: {
                  ...state[selectedFilter],
                  search: value,
                },
              }));
            }}
            loadingReason={availableOptions[selectedFilter].loading || undefined}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.${_.snakeCase(selectedFilter)}`) })}`}
            options={availableOptions[selectedFilter].options}
            selectedOptions={appliedFilters[selectedFilter].selected}
            onSelectedOptionsChange={(selectedOptions) => {
              setAppliedFilters((state) => ({
                ...state,
                [selectedFilter]: {
                  ...state[selectedFilter],
                  selected: selectedOptions,
                },
              }));
            }}
            onFetchMore={getFetchMoreCallback()}
          />
        )}

        {!selectedFilter &&
          Object.values(JobsSelectedFilter).map((filter) => (
            <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  color={theme.brandV2.colors.treadBlack}
                  fontSize="12px"
                  fontWeight={600}
                >
                  {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {appliedFilters[filter].selected.length > 0 && (
                    <Check
                      sx={{ fontSize: '16px', color: theme.brandV2.colors.treadOrange }}
                    />
                  )}

                  <ChevronRight sx={{ fontSize: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
});

export default UnassignedJobsFilters;
