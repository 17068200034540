import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { t as $t } from 'i18next';
import React from 'react';
import { ControllerProps, FieldErrors } from 'react-hook-form';

import {
  AutocompleteFormField,
  PhoneCodeFlagInput,
  TextFormField,
} from '~components/FormFields';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
interface AllContactsContactItemProps {
  key: string;
  removeAction: (index: number) => void;
  index: number;
  control: any;
  errors: any;
  arrName: string;
  typeOptions: any;
}
const ContactArrayItem = ({
  key,
  removeAction,
  index,
  control,
  errors,
  arrName,
  typeOptions,
}: AllContactsContactItemProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      key={key}
      sx={{
        position: 'relative',
        mt: 2,
        p: 1,
        border: `1px solid ${theme.palette.action.disabledBackground}`,
      }}
    >
      <RemoveItemButton
        action={() => {
          removeAction(index);
        }}
        sx={{
          ml: 2,
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          cursor: 'pointer',
        }}
      />

      <Grid item xs={12} direction={'row'} container spacing={2}>
        <Grid item xs={4}>
          <TextFormField
            control={control}
            errors={errors}
            name={`${arrName}[${index}].name`}
            label={`${$t('form_fields.name')}`}
            sx={{ mr: 2 }}
            isRequired={false}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteFormField
            control={control}
            name={`${arrName}[${index}].type`}
            errors={errors}
            list={typeOptions}
            label={`${$t('form_fields.contact_type')}`}
            isRequired={false}
            clearable={false}
            getValue={(item) => item?.id}
            getLabel={(item) => item.name || ''}
            sx={{ mr: 2 }}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneCodeFlagInput
            control={control as unknown as ControllerProps['control']}
            errors={errors as unknown as FieldErrors}
            name={`${arrName}[${index}].phone`}
            label={`${$t('form_fields.phone')}`}
            isRequired={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export { ContactArrayItem };
