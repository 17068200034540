import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';

import { Invoice } from '~hooks/useInvoices';
import { driversPayDataGridColumnsDefinition as colsDef } from '~pages/Approvals/DriverPay';
import theme from '~theme/AppTheme';

interface VendorRatePanelProps {
  invoice: Invoice;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const VendorRatePanel = ({ invoice, utils }: VendorRatePanelProps) => {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <PanelField
        label={t('approvals.driver_pay.tabs.driver_pay.vendor_rate_card')}
        content={invoice.rate?.name ?? ''}
      />

      <PanelField
        label={t('approvals.driver_pay.tabs.driver_pay.vendor_rate_type')}
        content={
          invoice.rateType
            ? t(`administration.rates.${_.snakeCase(invoice.rateType)}`)
            : ''
        }
      />

      <PanelField
        label={t('approvals.driver_pay.tabs.driver_pay.vendor_rate')}
        content={
          _.isNil(invoice.rateValue) ? '' : utils.currencyFormatter(invoice.rateValue)
        }
      />
    </Box>
  );
};

interface PanelFieldProps {
  content: string;
  label: string;
}

const PanelField = ({ content, label }: PanelFieldProps) => (
  <Box display="flex" flexDirection="column" gap={0.5} width="250px">
    <Typography>{label}</Typography>
    <Typography
      bgcolor={theme.palette.grey[100]}
      borderRadius={1}
      fontWeight={700}
      px={2}
      py={1}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      minHeight="37px"
    >
      {content}
    </Typography>
  </Box>
);

export default VendorRatePanel;
