import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';

import { TextFormField } from '~components/FormFields';
import { useLogin } from '~hooks/useLogin/useLogin';
import Copyright from '~pages/Auth/Copyrigh';
import FormWrapperBox from '~pages/Auth/FormWrapperBox';
import LinkToSignIn from '~pages/Auth/LinkToSignIn';
import PageWrapWithBackGround from '~pages/Auth/PageWrapWithBackGround';
import { FormInputProps, inputTypes } from '~pages/Auth/types';
import { forgotPasswordValidationSchema } from '~pages/Auth/validationSchemas';

import TermsAndConditions from './TermsAndConditions';

const formInputs: FormInputProps[] = [
  {
    name: inputTypes.email,
    label: 'Email',
    type: inputTypes.email,
    id: inputTypes.email,
  },
];

const ForgotPassword = () => {
  const { triggerResetPassword, isLoading } = useLogin();
  const onSubmit = (data: { email: string }) => {
    triggerResetPassword({ email: data?.email });
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<{ email: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(forgotPasswordValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <PageWrapWithBackGround
      backgroundImageUrl={'../../src/assets/images/trucks-image-grey.jpg'}
    >
      <FormWrapperBox title={t('page_headings.forgot_password')}>
        <Box
          width={'100%'}
          component="form"
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {formInputs.map((input, index) => (
            <TextFormField
              key={`${input.name}-${index}`}
              control={control}
              errors={errors}
              name={input.name}
              label={input.label}
              id={input.id}
              inputProps={{ 'data-test-id': input.id }}
            />
          ))}
          <LoadingButton
            fullWidth
            disabled={!isDirty}
            loading={isLoading}
            size={'small'}
            data-test-id={'submit-button'}
            variant={'contained'}
            color={'primary'}
            sx={{ mt: 1 }}
            type={'submit'}
          >
            {t('actions.sendResetEmail')}
          </LoadingButton>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mt: 4, width: '100%' }}
          >
            <Box display={'flex'}>
              <LinkToSignIn />
            </Box>
          </Box>
        </Box>
        <TermsAndConditions sx={{ mt: 3 }} />
        <Copyright sx={{ mt: 2, mb: 4 }} />
      </FormWrapperBox>
    </PageWrapWithBackGround>
  );
};
export default ForgotPassword;
