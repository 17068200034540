import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useMemo } from 'react';

import { SmallTabs } from '~components/Tabs/SmallTabs';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import {
  DriverPayTab,
  driversPayDataGridColumnsDefinition as colsDef,
} from '~pages/Approvals/DriverPay';

interface DriverPayDataGridExpandedBlockProps {
  invoice: Invoice;
  job: Job;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const DriverPayDataGridExpandedBlock = ({
  invoice,
  job,
  utils,
}: DriverPayDataGridExpandedBlockProps) => {
  const tabs = useMemo(() => {
    return [
      {
        id: 'driver-pay',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_pay'),
        content: <DriverPayTab invoice={invoice} job={job} utils={utils} />,
      },
    ];
  }, []);

  return (
    <Box px={2} py={1}>
      <SmallTabs tabs={tabs} />
    </Box>
  );
};

export default DriverPayDataGridExpandedBlock;
