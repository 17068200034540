import { t } from 'i18next';

import { RateTypes } from '~constants/enums';
import { ItemColorLabelValue } from '~hooks/useRates';

const isTimeRate = (type: RateTypes) => {
  switch (type) {
    case RateTypes.RATE_PER_HOUR:
    case RateTypes.RATE_PER_DAY:
      return true;
    default:
      return false;
  }
};

const rateTypeLabelColor = (type: RateTypes | undefined) => {
  switch (type) {
    case RateTypes.RATE_COMMISSION:
      return ItemColorLabelValue.parse({
        color: 'secondary',
        label: t('administration.rates.rate_commission'),
        value: RateTypes.RATE_COMMISSION,
      });

    case RateTypes.RATE_PER_BUSHEL:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_bushel'),
        value: RateTypes.RATE_PER_BUSHEL,
      });
    case RateTypes.RATE_PER_DAY:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_day'),
        value: RateTypes.RATE_PER_DAY,
      });
    case RateTypes.RATE_PER_HOUR:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_hour'),
        value: RateTypes.RATE_PER_HOUR,
      });
    case RateTypes.RATE_PER_TON:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_ton'),
        value: RateTypes.RATE_PER_TON,
      });
    case RateTypes.RATE_PER_TONNE:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_tonne'),
        value: RateTypes.RATE_PER_TONNE,
      });
    case RateTypes.RATE_PER_LOAD:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_load'),
        value: RateTypes.RATE_PER_LOAD,
      });
    case RateTypes.RATE_PER_YARD:
      return ItemColorLabelValue.parse({
        color: 'info',
        label: t('administration.rates.rate_per_yard'),
        value: RateTypes.RATE_PER_YARD,
      });
    default:
      return ItemColorLabelValue.parse({} as any);
  }
};
const rateFilterOptions = {
  account: 'filter[account_id]',
  equipment: 'filter[equipment_id]',
  project: 'filter[project_id]',
  service: 'filter[service_id]',
  accountType: 'filter[account_type]',
  includeDefaultRates: 'filter[include_default]',
};

const rateFilterUsingOwnerTypeOptions = {
  account: 'filter[account_id]',
  driverId: 'filter[driver_id]',
  equipment: 'filter[equipment_id]',
  includeDefaultRates: 'filter[include_default]',
  ownerType: 'filter[owner_type]',
  project: 'filter[project_id]',
  service: 'filter[service_id]',
  query: 'search[query]',
};

const DEFAULT_COMPANY_RATE_FAKE_ID = 'default_company_rate_id';

export {
  DEFAULT_COMPANY_RATE_FAKE_ID,
  isTimeRate,
  rateFilterOptions,
  rateFilterUsingOwnerTypeOptions,
  rateTypeLabelColor,
};

export const getRateTypeLabel = (type: RateTypes) => {
  const rateLabelWithColor = rateTypeLabelColor(type);
  return rateLabelWithColor
    ? `${t('administration.rates.rate_per_keyword')} ${rateLabelWithColor.label}`
    : '';
};

export const getRateTypeOptions = () =>
  Object.values(RateTypes).map((type) => {
    const rateLabel = getRateTypeLabel(type);
    return {
      label: rateLabel,
      value: type,
    };
  });
