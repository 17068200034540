import { yupResolver } from '@hookform/resolvers/yup';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { NewTimeFormField, TextFormField } from '~components/FormFields';
import { AddOnCharge } from '~hooks/useAddOns';
import { InvoiceLineItem } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import {
  AddOnChargesFormHandler,
  AddOnChargesTableRows,
  dateTimeHelpers,
  driversPayDataGridColumnsDefinition as colsDef,
  HourlyLineItemConcreteStrategy,
  HourlyLineItemDTO,
  hourlylLineItemSchema,
  LineItemsFormHandler,
  LineItemsTableCell,
  LineItemsTableCellProps,
  LineItemsTableTableRow,
  LineItemStrategy,
} from '~pages/Approvals/DriverPay';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';

const headers: Array<{
  text: string;
  textAlign: LineItemsTableCellProps['textAlign'];
  textForEditMode?: string;
  width?: string;
}> = [
  {
    text: 'Line Item',
    textAlign: 'left',
    width: '100%',
  },
  {
    text: `${t('common.date')}`,
    textAlign: 'left',
    width: '85px',
  },
  {
    text: `${t('common.scheduled')}`,
    textAlign: 'right',
    width: '75px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.billed_start_time')}`,
    textAlign: 'right',
    width: '108px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.billed_end_time')}`,
    textAlign: 'right',
    width: '108px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.total_adj_hrs')}`,
    textAlign: 'right',
    width: '75px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.total_break_time_hrs')}`,
    textAlign: 'right',
    width: '79px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.biller_adj_hrs')}`,
    textForEditMode: `${t('approvals.driver_pay.tabs.driver_pay.biller_adj_mins')}`,
    textAlign: 'right',
    width: '102px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.total_quantity')}`,
    textAlign: 'right',
    width: '90px',
  },
  {
    text: `${t('common.rate')}`,
    textAlign: 'right',
    width: '90px',
  },
  {
    text: `${t('common.total')}`,
    textAlign: 'right',
    width: '100px',
  },
];

const lineItemsSchema = yup.object().shape({
  lineItems: yup.array().of(hourlylLineItemSchema),
});

type LineItemsDTO = yup.InferType<typeof lineItemsSchema>;

interface HourlyRateLineItemsTableProps {
  addOnCharges: AddOnCharge[];
  invoice: Invoice;
  isEditing?: boolean;
  job: Job;
  lineItems: InvoiceLineItem[];
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const HourlyRateLineItemsTable = forwardRef<
  LineItemsFormHandler,
  HourlyRateLineItemsTableProps
>(function HourlyRateLineItemsTable(
  { addOnCharges, invoice, isEditing, job, lineItems, utils },
  ref,
) {
  const invoiceId = String(invoice.id);
  const strategy = useRef(LineItemStrategy.create(invoice))
    .current as HourlyLineItemConcreteStrategy;
  const { invoiceLineItemsStore } = useStores();

  const addOnChargesRef = useRef<AddOnChargesFormHandler>({});

  const initialLineItems = useMemo(() => {
    return lineItems.map((lineItem) => {
      return {
        id: String(lineItem.id),
        billingAdjustmentMinutes: lineItem.billingAdjustmentMinutes.toString(),
        payStartTime: lineItem.payStartTime
          ? dayjs
              .tz(lineItem.payStartTime ?? undefined)
              .clone()
              .startOf('minutes')
          : null,
        payEndTime: lineItem.payEndTime
          ? dayjs
              .tz(lineItem.payEndTime ?? undefined)
              .clone()
              .startOf('minutes')
          : null,
        rate: lineItem.rate.toString(),
      };
    });
  }, [lineItems]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LineItemsDTO>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(lineItemsSchema),
    defaultValues: {
      lineItems: _.cloneDeep(initialLineItems) as unknown as HourlyLineItemDTO[],
    },
  });

  useFieldArray({ control, name: 'lineItems' });
  const watchedLineItems = useWatch({ control, name: 'lineItems' });

  const consolidatedLineItemsList = useMemo(() => {
    if (isEditing) {
      return lineItems.map((lineItem, index) => {
        const editedLineItem = watchedLineItems ? watchedLineItems[index] : undefined;

        return strategy.consolidate(lineItem, editedLineItem);
      });
    }

    return lineItems;
  }, [isEditing, lineItems, watchedLineItems]);

  useEffect(() => {
    const invoiceSubtotal = consolidatedLineItemsList.reduce((sum, lineItem) => {
      return sum + strategy.calculateTotal(lineItem);
    }, 0);

    invoiceLineItemsStore.setInvoiceSubtotalByInvoiceId(invoiceId, invoiceSubtotal);
  }, [consolidatedLineItemsList, invoiceId]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: () => {
        return new Promise((resolve) => {
          handleSubmit(async (data) => {
            const lineItemsToSubmit = strategy.diffItemsCollection(
              initialLineItems as unknown as (HourlyLineItemDTO & { id: string })[],
              data.lineItems ?? [],
            );
            const addOnChargesToSubmit =
              (await addOnChargesRef.current.onSubmit?.()) ?? [];

            resolve({ lineItems: lineItemsToSubmit, addOnCharges: addOnChargesToSubmit });
          })();
        });
      },
    }),
    [initialLineItems],
  );

  return (
    <>
      <TableHead>
        <LineItemsTableTableRow isHeader>
          {headers.map(({ text, textForEditMode, width, ...rest }) => (
            <LineItemsTableCell key={text} isHeader width={width} {...rest}>
              {isEditing ? textForEditMode ?? text : text}
            </LineItemsTableCell>
          ))}
        </LineItemsTableTableRow>
      </TableHead>

      <TableBody>
        {consolidatedLineItemsList.map((lineItem, index) => {
          const quantity = strategy.calculateQuantity(lineItem);
          const billerAdjustment = dateTimeHelpers.diffDatesInHours(
            lineItem.payStartTime ?? null,
            lineItem.payEndTime ?? null,
          );
          const lineItemTotal = strategy.calculateTotal(lineItem);

          return (
            <LineItemsTableTableRow key={lineItem.id}>
              <LineItemsTableCell textAlign={headers[0].textAlign}>
                {`Line Item #${index + 1}`}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[1].textAlign}>
                {job.jobStartAt ? dateFormat(job.jobStartAt) : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[2].textAlign}>
                {job.jobStartAt ? dateFormat(job.jobStartAt, 'hh:mm a') : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[3].textAlign}>
                {isEditing ? (
                  <NewTimeFormField
                    control={control}
                    errors={errors}
                    name={`lineItems[${index}].payStartTime`}
                    size="small"
                  />
                ) : lineItem.payStartTime ? (
                  dateFormat(lineItem.payStartTime, 'hh:mm a')
                ) : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[4].textAlign}>
                {isEditing ? (
                  <NewTimeFormField
                    control={control}
                    errors={errors}
                    name={`lineItems[${index}].payEndTime`}
                    size="small"
                  />
                ) : lineItem.payEndTime ? (
                  dateFormat(lineItem.payEndTime, 'hh:mm a')
                ) : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[5].textAlign}>
                {billerAdjustment.toFixed(2)} {t('common.hrs')}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[6].textAlign}>
                {dayjs
                  .duration({ minutes: invoice.jobSummary?.breakTimeMinutes ?? 0 })
                  .asHours()
                  .toFixed(2)}{' '}
                {t('common.hrs')}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[7].textAlign}>
                {isEditing ? (
                  <TextFormField
                    control={control}
                    errors={errors}
                    type="number"
                    name={`lineItems[${index}].billingAdjustmentMinutes`}
                    sx={{
                      width: '70px',
                      ml: 'auto',
                      '& .MuiFormControl-root': { mb: 0 },
                      '& .MuiOutlinedInput-root': { pr: 0 },
                      '& .MuiOutlinedInput-input': { p: '5px 7px', fontSize: '12px' },
                    }}
                    inputProps={{ sx: { textAlign: 'right' } }}
                  />
                ) : (
                  dayjs
                    .duration({ minutes: lineItem.billingAdjustmentMinutes })
                    .asHours()
                    .toFixed(2)
                )}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[8].textAlign}>
                {quantity.toFixed(2)}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[9].textAlign}>
                {utils.currencyFormatter(lineItem.rate)}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[10].textAlign}>
                {utils.currencyFormatter(lineItemTotal)}
              </LineItemsTableCell>
            </LineItemsTableTableRow>
          );
        })}

        <AddOnChargesTableRows
          ref={addOnChargesRef}
          key={addOnCharges.map((addOnCharge) => addOnCharge.id).join()}
          addOnCharges={addOnCharges}
          invoice={invoice}
          isEditing={isEditing}
          lineItemsTableColumnsCount={headers.length}
          utils={utils}
        />
      </TableBody>
    </>
  );
});

export default HourlyRateLineItemsTable;
