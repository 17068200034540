import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useCallback, useEffect } from 'react';
import React from 'react';

import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';

import { LoadingSpinner } from '../ordersDispatchStyledComponents';
import CheckboxFilterMenuItem from './CheckboxFilterMenuItem';
import SearchField from './SearchField';

export const FILTER_MENU_ITEM_HEIGHT_IN_PX = 32;
export const VISIBLE_FILTER_MENU_ITEMS_COUNT = 10;

export type FilterMenuOption = {
  label: string;
  value: string;
};

export enum FilterMenuItemLoadingReason {
  SEARCH_VALUE = 'search_value',
  INFINITE_SCROLL = 'infinite_scroll',
}

interface FilterMenuItemProps {
  focusSearchFieldOnMount?: boolean;
  loadingReason?: `${FilterMenuItemLoadingReason}`;
  onFetchMore?: () => void;
  onSearchValueChange?: (value: string) => void;
  onSelectedOptionsChange: (options: string[]) => void;
  options: FilterMenuOption[];
  searchValue?: string;
  selectAllOptionLabel?: string;
  selectedOptions: string[];
}

export default function FilterMenuItem({
  focusSearchFieldOnMount,
  loadingReason,
  onFetchMore,
  onSearchValueChange,
  onSelectedOptionsChange,
  options,
  searchValue,
  selectAllOptionLabel,
  selectedOptions,
}: FilterMenuItemProps) {
  const infiniteScroll = useInfiniteScroll({ threshold: 0.5 });

  const handleOptionClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const { id } = event.currentTarget;

      if (selectedOptions.length === 0) {
        onSelectedOptionsChange([id]);
      } else {
        const index = selectedOptions.findIndex((value) => value === id);
        const newSelectedOptions = [...selectedOptions];

        if (index > -1) {
          newSelectedOptions.splice(index, 1);
        } else {
          newSelectedOptions.push(id);
        }

        onSelectedOptionsChange(newSelectedOptions);
      }
    },
    [selectedOptions, onSelectedOptionsChange],
  );

  const handleSelectAllOptionClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onSelectedOptionsChange([]);
    },
    [onSelectedOptionsChange],
  );

  useEffect(() => {
    if (!loadingReason && onFetchMore && infiniteScroll.endReached) {
      infiniteScroll.notify();
      onFetchMore();
    }
  }, [onFetchMore, infiniteScroll.endReached, loadingReason]);

  return (
    <>
      {onSearchValueChange && (
        <>
          <SearchField
            isLoading={loadingReason === FilterMenuItemLoadingReason.SEARCH_VALUE}
            onChange={onSearchValueChange}
            setFocusOnMount={focusSearchFieldOnMount}
            value={searchValue ?? ''}
          />
          <Divider sx={{ '&.MuiDivider-root': { mt: 0, mb: 1, mx: 2 } }} />
        </>
      )}

      {selectAllOptionLabel && (
        <>
          <CheckboxFilterMenuItem
            checked={selectedOptions.length === 0}
            label={selectAllOptionLabel}
            onClick={handleSelectAllOptionClick}
          />
          <Divider sx={{ '&.MuiDivider-root': { mx: 2 } }} />
        </>
      )}

      {options.length > 0 && (
        <Box
          maxHeight={FILTER_MENU_ITEM_HEIGHT_IN_PX * VISIBLE_FILTER_MENU_ITEMS_COUNT}
          overflow="auto"
        >
          {options.map((option, index) => (
            <React.Fragment key={option.value}>
              <CheckboxFilterMenuItem
                checked={selectedOptions.includes(option.value)}
                id={option.value}
                label={option.label}
                onClick={handleOptionClick}
              />

              {index === options.length - 1 && onFetchMore && (
                <Box
                  ref={infiniteScroll.nodeRef}
                  alignItems="center"
                  display="flex"
                  height={FILTER_MENU_ITEM_HEIGHT_IN_PX}
                  justifyContent="center"
                >
                  <LoadingSpinner
                    loadingIndicatorSize={16}
                    isVisible={
                      loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL
                    }
                  />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </>
  );
}
