import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { BasicTooltip } from '~components/Tooltip';
import { useEquipment } from '~hooks/useEquipment';
import { Job, useJob } from '~hooks/useJob';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';

interface Props {
  name: string;
  // Hot it's originally stored in the model structure. This is added for backward compatibility with the inline edit tool
  nameOrigin: string;
  job: Job;
  editable?: boolean;
  sx?: SxProps<Theme>; // Style
  companyId: string;
}

const EquipmentAssignment = ({
  name,
  nameOrigin,
  job,
  editable = false,
  sx = {},
  companyId,
}: Props) => {
  const theme = useTheme();
  const { companyAssetsStore, toasterStore } = useStores();
  const { updateJob, isUpdating } = useJob();
  const { getEquipmentByCompanyId } = useEquipment();
  const equipmentOptions = companyAssetsStore.equipment;
  const [value, setValue] = useState<any>(null);
  const [valueText, setValueText] = useState<string>('');

  useEffect(() => {
    const value = get(job, name, null);
    setValue(value);
    setValueText(get(value, 'name', '-'));
  }, [name, job]);

  const options = useMemo(() => {
    return [{ id: null, name: '' }, ...equipmentOptions];
  }, [equipmentOptions]);
  const processUpdate = (value: ItemNameAndId) => {
    if (job?.id && get(job, `${name}.id`, null) !== get(value, 'id', null)) {
      return updateJob(
        job.id,
        {
          ...job,
          [nameOrigin]: value,
        },
        true,
      )
        .then((res: Job) => {
          toasterStore.push(
            alert($t('dispatch.job.updated', { name: res?.jobId }), AlertTypes.success),
          );
        })
        .catch(() => {
          setValue(get(job, name, null));
        });
    } else {
      console.error('job not specified');
    }
  };

  const getValue = (value: ItemNameAndId) => {
    return value?.id;
  };

  const getLabel = (value: ItemNameAndId) => {
    return value?.name;
  };

  return editable ? (
    <Box sx={{ width: '100%', ...sx }}>
      <Autocomplete
        loading={isUpdating}
        disabled={isUpdating}
        disableClearable={true}
        options={options}
        fullWidth
        sx={{ mt: -0.5, width: '100%', minWidth: 200 }}
        getOptionLabel={(item) => getLabel(item)}
        filterSelectedOptions={false}
        isOptionEqualToValue={(option, value) => {
          return getValue(option) === getValue(value);
        }}
        onOpen={() => getEquipmentByCompanyId({ companyId, dispatchable: true })}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label=""
              size="small"
              margin="dense"
              variant="outlined"
            />
          );
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography
              variant={'body1'}
              sx={{
                display: 'inline-flex',
                minHeight: theme.spacing(3),
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {option.name}
            </Typography>
          </Box>
        )}
        onChange={(event, values, reason) => {
          processUpdate(values);
        }}
        value={value}
      />
    </Box>
  ) : (
    <Box sx={{ ...sx }}>
      <BasicTooltip title={valueText}>
        <Typography fontSize="12px">{valueText}</Typography>
      </BasicTooltip>
    </Box>
  );
};

export { EquipmentAssignment };
