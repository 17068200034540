import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';

import { GroupTitle } from '~components/Common';

interface Props {
  children: React.ReactNode;
  title?: string;
  sx?: SxProps<Theme>; // Style
}

const SimpleCard = ({ children, title = '', sx = {} }: Props) => {
  return (
    <Paper elevation={0} sx={{ ...sx }}>
      {title ? (
        <>
          <GroupTitle>
            <>{title}</>
          </GroupTitle>
        </>
      ) : null}
      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  );
};

export { SimpleCard };
