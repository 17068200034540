import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { convertLength } from '@turf/helpers';
import { t } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteFormField, TextFormField } from '~components/FormFields';
import { geoFenceCircleOption, geofenceNoneOption } from '~constants/enums';
import { nameIdSchemaRequired } from '~constants/regexConst';
import { Company, useCompany } from '~hooks/useCompany';
import { useStores } from '~store';
import UserStore from '~store/UserStore';
import { alert, AlertTypes } from '~types/AlertTypes';
import { radiusInProperUnits } from '~utils/sites';

const settingSchema = yup.object().shape({
  siteRadius: yup.number().typeError('Numbers only.'),
  geofenceType: nameIdSchemaRequired,
});

type SettingDTO = yup.InferType<typeof settingSchema>;

const geofenceTypes = [geoFenceCircleOption, geofenceNoneOption];

function GeofenceCreationSetting() {
  const { userStore, toasterStore } = useStores();
  const { updateCompany } = useCompany();
  const [isSaving, setIsSaving] = useState(false);
  const [inSaveMode, setInSaveMode] = useState(false);
  const isFeet = userStore?.userCompany.isFeet || false;

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<SettingDTO>({
    resolver: yupResolver(settingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      siteRadius:
        radiusInProperUnits(isFeet, userStore.userCompany.siteRadius || null) || 0,
      geofenceType: userStore.userCompany.siteRadius
        ? geoFenceCircleOption
        : geofenceNoneOption,
    },
  });

  const geofenceType = watch('geofenceType');

  const saveCompanySiteRadius = (data: SettingDTO) => {
    setIsSaving(true);
    updateCompany({
      company: {
        id: userStore.userCompany.id,
        siteRadius:
          data.geofenceType.id === geoFenceCircleOption.id
            ? isFeet
              ? convertLength(Number(data.siteRadius), 'feet', 'meters')
              : data.siteRadius
            : null,
      } as Company,
    }).then(() => {
      setIsSaving(false);
      setInSaveMode(false);
      toasterStore.push(
        alert(t('administration.configuration.configuration_saved'), AlertTypes.success),
      );
    });
  };

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        {t('administration.configuration.settings.geofence_creation.setting_subtitle')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={3}>
          <AutocompleteFormField
            label={`${t('administration.configuration.settings.geofence_creation.geofence_type')}`}
            control={control}
            errors={errors}
            disabled={!inSaveMode}
            name="geofenceType"
            list={geofenceTypes}
            getLabel={(item) => item?.name}
            getValue={(item) => item?.id}
          />
        </Grid>
        {geofenceType.id === geoFenceCircleOption.id && (
          <Grid item sm={3}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!inSaveMode}
              name="siteRadius"
              type="number"
              label={`${t(
                'administration.configuration.settings.geofence_creation.site_radius',
                { unitOfMeasure: isFeet ? 'ft' : 'm' },
              )}`}
            />
          </Grid>
        )}
        <Grid item sm={1} sx={{ alignSelf: 'flex-end', mb: 1 }}>
          <LoadingButton
            loading={isSaving}
            disabled={inSaveMode && !isDirty}
            variant="contained"
            color={inSaveMode ? 'success' : 'primary'}
            onClick={
              inSaveMode ? handleSubmit(saveCompanySiteRadius) : () => setInSaveMode(true)
            }
          >
            {inSaveMode ? t('actions.save') : t('actions.edit')}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

export default {
  component: GeofenceCreationSetting,
  canAccess: (userStore: UserStore) => {
    return userStore.getPermissions().canEditCompany;
  },
};
