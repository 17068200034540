export enum SelectedFilter {
  CUSTOMERS = 'customers',
  DISPATCH_NUMBERS = 'dispatchNumbers',
  DRIVERS = 'drivers',
  DROP_OFF_SITES = 'dropOffSites',
  JOB_STATES = 'jobStates',
  PICK_UP_SITES = 'pickUpSites',
  PROJECTS = 'projects',
  PROJECTS_EXTERNAL_IDS = 'projectsExternalIds',
  VENDORS = 'vendors',
}

export enum JobsSelectedFilter {
  CUSTOMERS = 'customers',
  DISPATCH_NUMBERS = 'dispatchNumbers',
  DROP_OFF_SITES = 'dropOffSites',
  PICK_UP_SITES = 'pickUpSites',
  PROJECTS = 'projects',
  PROJECTS_EXTERNAL_IDS = 'projectsExternalIds',
}

export enum DriversSelectedFilter {
  SHARED = 'shared',
}
