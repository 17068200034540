import { WaypointType } from '@treadinc/horizon-api-spec';
import { sortBy, uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import { Account } from '~hooks/useAccount';
import { CompanyShare } from '~hooks/useCompanyShares';
import { Department } from '~hooks/useDepartment';
import { Driver } from '~hooks/useDrivers';
import { Equipment, EquipmentTypeItem } from '~hooks/useEquipment';
import { Material } from '~hooks/useMaterials';
import { Rate } from '~hooks/useRates/models';
import { ServiceClass } from '~hooks/useServiceClass';
import { Service } from '~hooks/useServices';
import { Geofence, Site, WayPoint } from '~hooks/useSites';
import { Pagination, PaginationLink } from '~interfaces';
import { RootStore } from '~store/index';
import { getPageNumber } from '~utils/pagination';

class CompanyAssetsStore {
  equipment: Equipment[] = [];
  equipmentTypes: EquipmentTypeItem[] = [];
  materials: Material[] = [];
  services: Service[] = [];
  rates: Rate[] = [];
  sites: Site[] = [];
  activeSiteAdditionalGeoFences: Geofence[] = [];
  userCompanyAccounts: Account[] = [];
  departments: Department[] = [];
  serviceClasses: ServiceClass[] = [];
  companyShares: CompanyShare[] = [];
  drivers: Driver[] = [];
  equipmentPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  equipmentTypePagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  materialsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  accountsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  sitesPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  ratesPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  departmentsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  serviceClassPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  companySharesPagination: Pagination = {
    limit: 25,
    after: '',
    before: '',
    page: 1,
  };
  driversPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  public get pickUpWayPoints() {
    return this.sites.map((site) => {
      //@ts-ignore
      return WayPoint.parse({ type: WaypointType.PICKUP, ordinality: 10, site });
    });
  }
  public get dropOffWayPoints() {
    return this.sites.map((site) => {
      //@ts-ignore
      return WayPoint.parse({ type: WaypointType.DROP_OFF, ordinality: 20, site });
    });
  }
  setEquipment(equipment: Equipment[]) {
    this.equipment = [...sortBy(equipment, 'type', 'name')];
  }
  updateEquipment = (equipment: Equipment) => {
    this.equipment = this.equipment.map((e) => (e.id === equipment.id ? equipment : e));
  };
  deleteEquipment = (equipmentId: string) => {
    this.equipment = this.equipment.filter((e) => e.id !== equipmentId);
  };
  addEquipment = (equipment: Equipment) => {
    this.equipment = [...this.equipment, equipment];
  };
  setEquipmentPagination(pagination: Pagination) {
    this.equipmentPagination = {
      ...this.equipmentPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  setActiveSiteAdditionalGeoFences(geoFences: Geofence[]) {
    this.activeSiteAdditionalGeoFences = geoFences;
  }
  updateEquipmentPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.equipmentPagination = {
        ...this.equipmentPagination,
        page: getPageNumber(this.equipmentPagination.page, link),
      };
    });
  }
  setMaterials(materials: Material[]) {
    this.materials = [...sortBy(materials, 'type', 'name')];
  }
  updateMaterial = (material: Material) => {
    this.materials = this.materials.map((m) => (m.id === material.id ? material : m));
  };
  deleteMaterial = (materialId: string) => {
    this.materials = this.materials.filter((m) => m.id !== materialId);
  };
  addMaterial = (material: Material) => {
    const idx = this.materials.findIndex((m) => m.id === material.id);
    if (idx === -1) {
      this.materials = [...this.materials, material];
    } else {
      this.materials[idx] = material;
      this.materials = [...this.materials];
    }
  };

  public get allMaterials() {
    return sortBy(this.materials, (item) => item.type.name);
  }

  setMaterialsPagination(pagination: Pagination) {
    this.materialsPagination = {
      ...this.materialsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateMaterialsPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.materialsPagination = {
        ...this.materialsPagination,
        page: getPageNumber(this.materialsPagination.page, link),
      };
    });
  }
  setRatesPagination(pagination: Pagination) {
    this.ratesPagination = {
      ...this.ratesPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateRatesPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.ratesPagination = {
        ...this.ratesPagination,
        page: getPageNumber(this.ratesPagination.page, link),
      };
    });
  }
  setAccountsPagination(pagination: Pagination) {
    this.accountsPagination = {
      ...this.accountsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateAccountsPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.accountsPagination = {
        ...this.accountsPagination,
        page: getPageNumber(this.accountsPagination.page, link),
      };
    });
  }
  setUserCompanyAccounts(accounts: Account[]) {
    this.userCompanyAccounts = [...accounts];
  }

  updateAccount(account: Account) {
    this.userCompanyAccounts = this.userCompanyAccounts.map((accItem) =>
      accItem.id === account.id ? account : accItem,
    );
  }
  deleteAccount(id: string) {
    this.userCompanyAccounts = this.userCompanyAccounts.filter(
      (account) => account.id !== id,
    );
  }
  addAccount(account: Account) {
    this.userCompanyAccounts = [...this.userCompanyAccounts, account];
  }
  setRates(rates: Rate[]) {
    this.rates = [...sortBy(rates, 'type', 'name')];
  }
  updateRate = (rate: Rate) => {
    this.rates = this.rates.map((r) => (r.id === rate.id ? rate : r));
  };
  deleteRate = (rateId: string) => {
    this.rates = this.rates.filter((r) => r.id !== rateId);
  };
  addRate = (rate: Rate) => {
    const idx = this.rates.findIndex((r) => r.id === rate.id);

    if (idx === -1) {
      this.rates = [...this.rates, rate];
    } else {
      this.rates[idx] = rate;
      this.rates = [...this.rates];
    }
  };
  setServices(services: Service[]) {
    this.services = [...sortBy(services, 'type', 'name')];
  }

  // Sites management
  setSites(sites: Site[]) {
    this.sites = [...sortBy(sites, 'name')];
  }

  setSitesPagination(pagination: Pagination) {
    this.sitesPagination = {
      ...this.sitesPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateSitesPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.sitesPagination = {
        ...this.sitesPagination,
        page: getPageNumber(this.sitesPagination.page, link),
      };
    });
  }

  addSite = (site: Site) => {
    // Todo: this is a temporary solution, we need to add some cache on the backend
    this.sites = uniqBy([site, ...this.sites], 'id');
  };

  updateSite = (site: Site) => {
    this.sites = this.sites.map((s) => (s.id === site.id ? site : s));
  };

  removeSite = (siteId: string) => {
    this.sites = this.sites.filter((s) => s.id !== siteId);
  };

  // Todo: this is a ideal place for other company assets, i.e. Locations, Vendors, etc.

  // Departments management
  setDepartments(departments: Department[]) {
    this.departments = [...sortBy(departments, 'name')];
  }

  addDepartment = (department: Department) => {
    this.departments = [...this.departments, department];
  };

  updateDepartment = (department: Department) => {
    this.departments = this.departments.map((d) =>
      d.id === department.id ? department : d,
    );
  };

  deleteDepartment = (departmentId: string) => {
    this.departments = this.departments.filter((d) => d.id !== departmentId);
  };

  setDepartmentsPagination(pagination: Pagination) {
    this.departmentsPagination = {
      ...this.departmentsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateDepartmentsPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.departmentsPagination = {
        ...this.departmentsPagination,
        page: getPageNumber(this.departmentsPagination.page, link),
      };
    });
  }

  // EquipmentType management
  setEquipmentTypes(equipmentTypes: EquipmentTypeItem[]) {
    this.equipmentTypes = [...sortBy(equipmentTypes, 'name')];
  }

  addEquipmentType = (equipmentType: EquipmentTypeItem) => {
    this.equipmentTypes = [...this.equipmentTypes, equipmentType];
  };

  updateEquipmentType = (equipmentType: EquipmentTypeItem) => {
    this.equipmentTypes = this.equipmentTypes.map((e) =>
      e.id === equipmentType.id ? equipmentType : e,
    );
  };

  deleteEquipmentType = (equipmentTypeId: string) => {
    this.equipmentTypes = this.equipmentTypes.filter((e) => e.id !== equipmentTypeId);
  };

  setEquipmentTypePagination(pagination: Pagination) {
    this.equipmentTypePagination = {
      ...this.equipmentTypePagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateEquipmentTypePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.equipmentTypePagination = {
        ...this.equipmentTypePagination,
        page: getPageNumber(this.equipmentTypePagination.page, link),
      };
    });
  }

  // Service Class management

  setServiceClasses(serviceClasses: ServiceClass[]) {
    this.serviceClasses = [...sortBy(serviceClasses, 'name')];
  }

  addServiceClass = (serviceClass: ServiceClass) => {
    this.serviceClasses = [...this.serviceClasses, serviceClass];
  };

  updateServiceClass = (serviceClass: ServiceClass) => {
    this.serviceClasses = this.serviceClasses.map((service) =>
      service.id === serviceClass.id ? serviceClass : service,
    );
  };

  deleteServiceClass = (serviceClassId: string) => {
    this.serviceClasses = this.serviceClasses.filter(
      (service) => service.id !== serviceClassId,
    );
  };

  setServiceClassPagination(pagination: Pagination) {
    this.serviceClassPagination = {
      ...this.serviceClassPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateServiceClassPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.serviceClassPagination = {
        ...this.serviceClassPagination,
        page: getPageNumber(this.serviceClassPagination.page, link),
      };
    });
  }

  setCompanyShares(companyShares: CompanyShare[]) {
    this.companyShares = [...companyShares];
  }

  setCompanySharesPagination(pagination: Pagination) {
    this.companySharesPagination = {
      ...this.companySharesPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateCompanySharesPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.companySharesPagination = {
        ...this.companySharesPagination,
        page: getPageNumber(this.companySharesPagination.page, link),
      };
    });
  }

  addCompanyShare = (companyShare: CompanyShare) => {
    this.companyShares = [...this.companyShares, companyShare];
  };

  updateCompanyShare = (companyShare: CompanyShare) => {
    this.companyShares = this.companyShares.map((companyShareItem) =>
      companyShareItem.id === companyShare.id ? companyShare : companyShareItem,
    );
  };

  deleteCompanyShare = (companyShareId: string) => {
    this.companyShares = this.companyShares.filter(
      (companyShare) => companyShare.id !== companyShareId,
    );
  };

  setDrivers(drivers: Driver[]) {
    this.drivers = [...sortBy(drivers, 'name')];
  }

  setDriversPagination(pagination: Pagination) {
    this.driversPagination = {
      ...this.driversPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }
  updateDriversPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.driversPagination = {
        ...this.driversPagination,
        page: getPageNumber(this.driversPagination.page, link),
      };
    });
  }
}

export default CompanyAssetsStore;
