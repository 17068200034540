import Grid from '@mui/material/Grid';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Control, FieldErrors, FieldValues, useWatch } from 'react-hook-form';

import {
  AutocompleteAsyncFormField,
  AutocompleteFormField,
  TextFormField,
} from '~components/FormFields';
import { Account, useAccount } from '~hooks/useAccount';
import { useDepartment } from '~hooks/useDepartment';
import { Project, ProjectState, useProjects } from '~hooks/useProjects';
import { useStores } from '~store';

interface ProjectDetailsProps {
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  companyId?: string | null;
  readOnly?: boolean;
}

export const ProjectDetails = ({
  control,
  errors,
  companyId,
  readOnly = false,
}: ProjectDetailsProps) => {
  const { companyAssetsStore } = useStores();

  const {
    companyRelatedProjects,
    getProjectsByCompanyId,
    getProjectsByCompanyIdTypeahead,
  } = useProjects();
  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getDepartmentByCompanyId } = useDepartment();

  const departmentOptions = companyAssetsStore.departments;
  const watchAccount = useWatch({ control, name: 'account' });

  useEffect(() => {
    if (companyId && !readOnly) {
      getProjectsByCompanyId(companyId || '');
      getDepartmentByCompanyId(companyId);
    }
  }, [companyId, readOnly]);

  const fetchAccountsByCompanyId = async (additionalProps = {}) => {
    if (!companyId || readOnly) {
      return [];
    }
    return getAccountsByCompanyIdTypeahead({
      companyId,
      accountTypes: [AccountType.CUSTOMER],
      ...additionalProps,
    });
  };

  const fetchProjectsByCompanyId = async (additionalProps = {}) => {
    if (!companyId || readOnly) {
      return [];
    }

    return getProjectsByCompanyIdTypeahead({
      companyId: companyId,
      states: [ProjectState.ACTIVE],
      accountId: watchAccount?.id,
      ...additionalProps,
    });
  };

  return (
    <>
      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          control={control}
          disabled={!companyId || readOnly}
          errors={errors}
          name={'account'}
          label={`${t('dispatch.order.customer_account')}`}
          isRequired={true}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          asyncCallback={fetchAccountsByCompanyId}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          disabled={!companyId || readOnly}
          name={'project'}
          label={`${t('order.form.associated_project_name')}`}
          clearable={true}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          asyncCallback={fetchProjectsByCompanyId}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          control={control}
          errors={errors}
          disabled={true}
          name="projectExternalId"
          label={`${t('form_fields.project_id')}`}
          isRequired={false}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteFormField
          control={control}
          name="department"
          groupBy={(item) => item?.typeName}
          errors={errors}
          sx={{ mr: 2 }}
          list={departmentOptions}
          label={`${t('project.form.department')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          clearOnBlur={true}
          blurOnSelect={true}
          clearable={true}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
};
