import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { GenericSelector } from '~components/Order/Selectors/GenericSelector';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';

import DispatchSearchParam from './DispatchSearchParam';

interface Props {
  allSearchParams: Record<string, any>;
  companyId: string;
  sx?: SxProps<Theme>; // Style
}

const EquipmentTypeSelector = observer(
  ({ allSearchParams, companyId, sx = {} }: Props) => {
    const { isLoading, getEquipmentTypesByCompanyId } = useEquipment();

    const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<
      EquipmentTypeItem[]
    >([]);

    // Loads the list of equipment types for this company if there is an equipment types in the URL search params
    // Ideally we should only fetch what is found in the search params, but for now we will fetch all
    const prefetchEquipmentTypes = () => {
      if (equipmentTypesOptions.length === 0) {
        getEquipmentTypesByCompanyId({
          companyId: companyId,
          callBack: setEquipmentTypesOptions,
        });
      }
    };
    useEffect(() => {
      if (allSearchParams[DispatchSearchParam.EQUIPMENT_TYPE]) {
        prefetchEquipmentTypes();
      }
    }, [allSearchParams[DispatchSearchParam.EQUIPMENT_TYPE]]);

    return (
      <>
        <Box sx={{ ...sx }}>
          <GenericSelector
            fieldName={DispatchSearchParam.EQUIPMENT_TYPE}
            options={equipmentTypesOptions}
            label={$t('form_fields.equipment_type')}
            allSearchParams={allSearchParams}
            sx={{ ...sx }}
            getOptionLabel={(item) => item.name || ''}
            loadData={prefetchEquipmentTypes}
            isDataLoading={isLoading}
          />
        </Box>
      </>
    );
  },
);

export { EquipmentTypeSelector };
