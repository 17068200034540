import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { GenericSelector } from '~components/Order/Selectors/GenericSelector';
import { parseJobStateText } from '~utils/jobUtils';

import DispatchSearchParam from './DispatchSearchParam';
interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const jobStates = Object.values(JobState).map((state) => ({
  id: state,
  name: parseJobStateText(state),
}));

const JobStateSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  // Get the default values for the job state selector from the URL search params
  const defaultValues = useMemo(() => {
    const defaultIds = allSearchParams[DispatchSearchParam.STATE]
      ? allSearchParams[DispatchSearchParam.STATE].split('|')
      : [];

    return jobStates.filter((item) => defaultIds.includes(item.id));
  }, [jobStates]);

  return (
    <>
      {defaultValues && defaultValues.length >= 0 && (
        <Box sx={{ ...sx }}>
          <GenericSelector
            fieldName={DispatchSearchParam.STATE}
            options={jobStates}
            label={$t('dispatch.job.job_status')}
            allSearchParams={allSearchParams}
            sx={{ ...sx }}
            getOptionLabel={(item) => item.name || ''}
            defaultValues={defaultValues}
          />
        </Box>
      )}
    </>
  );
});

export { JobStateSelector };
