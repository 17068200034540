import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { Account } from '~hooks/useAccount';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

import { canAcceptJob, canSendInvitation } from '../JobsOrderDataGrid';
import {
  HEADER_PANEL_Z_INDEX,
  OrdersViewBulkActionsBarButton,
} from './ordersDispatchStyledComponents';

enum BulkActionsDialog {
  ASSIGN = 'assign',
  COPY_VENDOR_ASSIGNMENTS = 'copyVendorAssignments',
  SEND = 'send',
}

type CheckedOrdersAndJobState = Record<string, string[]>;

type DialogsState = {
  [BulkActionsDialog.ASSIGN]: { isOpen: boolean; selectedVendorAccountId?: string };
  [BulkActionsDialog.COPY_VENDOR_ASSIGNMENTS]: {
    isOpen: boolean;
    selectedOrderId?: string;
  };
  [BulkActionsDialog.SEND]: { isOpen: boolean };
};

export function useOrdersViewBulkActionsBar(orderJobs: Map<string, Job[]>) {
  const [checkedOrdersAndJobs, setCheckedOrdersAndJobs] =
    useState<CheckedOrdersAndJobState>({});
  const [dialogsState, setDialogsState] = useState<DialogsState>({
    [BulkActionsDialog.ASSIGN]: { isOpen: false },
    [BulkActionsDialog.COPY_VENDOR_ASSIGNMENTS]: { isOpen: false },
    [BulkActionsDialog.SEND]: { isOpen: false },
  });

  const jobsCount = useMemo(() => {
    return Object.values(checkedOrdersAndJobs).flat().length;
  }, [checkedOrdersAndJobs]);

  const sendableJobs = useMemo(() => {
    const selectedJobs = Object.values(checkedOrdersAndJobs).flat();
    const sendableJobIds: string[] = [];

    for (const [_orderId, jobsInOrder] of orderJobs) {
      jobsInOrder.forEach((job) => {
        const isSelected = selectedJobs.includes(job.id);

        if (isSelected) {
          const isSendable = canSendInvitation(job);

          if (isSendable) {
            sendableJobIds.push(job.id);
          }
        }
      });
    }

    return sendableJobIds;
  }, [checkedOrdersAndJobs, JSON.stringify(orderJobs)]);

  const assignableJobs = useMemo(() => {
    const selectedJobs = Object.values(checkedOrdersAndJobs).flat();
    const assignableJobIds: string[] = [];

    for (const [_orderId, jobsInOrder] of orderJobs) {
      jobsInOrder.forEach((job) => {
        const isSelected = selectedJobs.includes(job.id);

        if (isSelected) {
          const hasDriver = Boolean(job.driver);
          const hasVendor = Boolean(job.vendorJobAssignment?.vendorAccount);

          const isAcceptable = canAcceptJob(job);
          const isAssigned = hasDriver || hasVendor;
          const isAssignable = !isAcceptable && !isAssigned;

          if (isAssignable) {
            assignableJobIds.push(job.id);
          }
        }
      });
    }

    return assignableJobIds;
  }, [checkedOrdersAndJobs, JSON.stringify(orderJobs)]);

  const copyableJobs = useMemo(() => {
    return Object.values(checkedOrdersAndJobs).flat();
  }, [checkedOrdersAndJobs]);

  const clearAllCheckedOrdersAndJobs = useCallback(() => {
    setCheckedOrdersAndJobs({});
  }, []);

  const updateCheckedOrdersAndJobs = useCallback(
    (orderId: string, jobId?: string) => {
      const jobsInOrder = orderJobs.get(orderId);

      if (!jobsInOrder) {
        return;
      }

      setCheckedOrdersAndJobs((state) => {
        const newCheckedOrdersAndJobs = _.cloneDeep(state);

        // User is checking a single job
        if (jobId) {
          // Order is currently checked -> check/uncheck that single job
          if (orderId in state) {
            const jobIndex = newCheckedOrdersAndJobs[orderId].findIndex((id) => {
              return id === jobId;
            });

            if (jobIndex > -1) {
              newCheckedOrdersAndJobs[orderId].splice(jobIndex, 1);
            } else {
              newCheckedOrdersAndJobs[orderId].push(jobId);
            }
          }

          // Order is not currently checked -> check the order and the single job
          else {
            newCheckedOrdersAndJobs[orderId] = [jobId];
          }
        }

        // User is checking an order that is already checked -> uncheck the order
        else if (orderId in state) {
          delete newCheckedOrdersAndJobs[orderId];
        }

        // User is checking an order that is not already chcked -> check the order and all the
        // Jobs within the order
        else {
          newCheckedOrdersAndJobs[orderId] = jobsInOrder.map((job) => job.id);
        }

        return newCheckedOrdersAndJobs;
      });
    },
    [orderJobs],
  );

  const updateBulkAssignJobsSelectedAccount = useCallback((account: Account) => {
    setDialogsState((state) => ({
      ...state,
      [BulkActionsDialog.ASSIGN]: {
        isOpen: true,
        selectedVendorAccountId:
          state[BulkActionsDialog.ASSIGN].selectedVendorAccountId === account.id
            ? undefined
            : account.id,
      },
    }));
  }, []);

  const updateBulkCopyAssignmentsSelectedOrder = useCallback((order: Order) => {
    setDialogsState((state) => ({
      ...state,
      [BulkActionsDialog.COPY_VENDOR_ASSIGNMENTS]: {
        isOpen: true,
        selectedOrderId: order.id,
      },
    }));
  }, []);

  const openDialog = useCallback((dialog: `${BulkActionsDialog}`) => {
    setDialogsState((state) => ({
      ...state,
      [dialog]: { isOpen: true },
    }));
  }, []);

  const closeDialog = useCallback((dialog: `${BulkActionsDialog}`) => {
    setDialogsState((state) => ({
      ...state,
      [dialog]: { isOpen: false },
    }));
  }, []);

  return {
    assignableJobs,
    checkedOrdersAndJobs,
    copyableJobs,
    dialogsState,
    jobsCount,
    sendableJobs,
    clearAllCheckedOrdersAndJobs,
    closeDialog,
    openDialog,
    updateBulkAssignJobsSelectedAccount,
    updateBulkCopyAssignmentsSelectedOrder,
    updateCheckedOrdersAndJobs,
  };
}

interface OrdersViewBulkActionsBarProps {
  jobsCount: number;
  onClearAll: () => void;
}

export default function OrdersViewBulkActionsBar({
  children,
  jobsCount,
  onClearAll,
}: React.PropsWithChildren<OrdersViewBulkActionsBarProps>) {
  return (
    <Box
      alignItems="center"
      bottom={theme.spacing(10)}
      display="flex"
      justifyContent="center"
      left={0}
      position="fixed"
      right={0}
      zIndex={HEADER_PANEL_Z_INDEX + 1}
      sx={{ pointerEvents: 'none' }}
    >
      <Box
        alignItems="center"
        bgcolor={theme.palette.grey[900]}
        borderRadius={1}
        boxShadow="0px 2px 40px 0px rgba(0, 0, 0, 0.40);"
        display="flex"
        gap={1}
        p={1}
        sx={{ pointerEvents: 'auto' }}
      >
        <OrdersViewBulkActionsBarButton
          endIcon={<Close sx={{ color: theme.brandV2.colors.treadGray3 }} />}
          onClick={onClearAll}
          sx={{
            backgroundColor: theme.palette.grey[900],
            border: `dashed 1px ${theme.palette.grey[700]}`,
            color: theme.palette.grey[50],
            '&.MuiButton-root:hover': {
              backgroundColor: theme.palette.grey[800],
            },
          }}
        >
          {t('dispatch.dispatch_v2.count_jobs_selected', { count: jobsCount })}
        </OrdersViewBulkActionsBarButton>

        {children}
      </Box>
    </Box>
  );
}
