import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BoxOwnProps } from '@mui/system/Box';
import { InvoiceState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { GridCardWrapper, SimpleCard } from '~components/Cards';
import { RouteMap } from '~components/Job';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useAddOns } from '~hooks/useAddOns';
import { InvoiceLineItem, useInvoiceLineItems } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import {
  driversPayDataGridColumnsDefinition as colsDef,
  LineItemsFormHandler,
  LineItemsTable,
  VendorRatePanel,
} from '~pages/Approvals/DriverPay';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

interface DriverPayTabProps {
  invoice: Invoice;
  job: Job;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

export default function DriverPayTab({ invoice, job, utils }: DriverPayTabProps) {
  const { toasterStore } = useStores();
  const [isEditing, setIsEditing] = useState(false);

  const canEditLineItems = useMemo(() => {
    return [InvoiceState.PENDING, InvoiceState.CUSTOMER_PENDING].includes(invoice.state);
  }, [invoice.state]);

  const lineItemsFormRef = useRef<LineItemsFormHandler>({});
  const { batchUpdateInvoiceLineItems } = useInvoiceLineItems();
  const { batchUpdateAddOnCharges } = useAddOns();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const data = await lineItemsFormRef.current.onSubmit?.();
      let updatesMade = false;

      if (data) {
        try {
          if (data.lineItems.length) {
            const lineItemsToSubmit = data.lineItems as Array<Partial<InvoiceLineItem>>;

            await batchUpdateInvoiceLineItems(String(invoice.id), lineItemsToSubmit);
            updatesMade = true;
          }

          if (data.addOnCharges.length) {
            await batchUpdateAddOnCharges(String(invoice.id), data.addOnCharges);
            updatesMade = true;
          }
        } finally {
          setIsEditing(false);

          if (updatesMade) {
            toasterStore.push(
              alert(
                t('approvals.driver_pay.invoice_updated_successfully'),
                AlertTypes.success,
              ),
            );
          }
        }
      } else {
        setIsEditing(false);
      }
    },
    [invoice.id],
  );

  return (
    <Box display="flex" gap={2}>
      <Box flex={1} overflow="auto">
        <GridCardWrapper>
          <SimpleCard>
            <Box
              m={-2}
              {...(isEditing
                ? {
                    component: 'form',
                    noValidate: true,
                    autoComplete: 'off',
                    onSubmit: handleSubmit,
                  }
                : {})}
            >
              <CardPanel hasBorderBottom>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color={theme.palette.text.secondary} fontWeight={700}>
                    {t('page_headings.driver_pay')}
                  </Typography>

                  {isEditing ? (
                    <Box display="flex" gap={1} alignItems="center">
                      <Button
                        color="inherit"
                        onClick={() => setIsEditing(false)}
                        startIcon={<Close />}
                      >
                        {t('actions.discard')}
                      </Button>

                      <Button type="submit" color="success" startIcon={<Check />}>
                        {t('actions.save')}
                      </Button>
                    </Box>
                  ) : canEditLineItems ? (
                    <Button onClick={() => setIsEditing(true)} startIcon={<Edit />}>
                      {t('actions.edit')}
                    </Button>
                  ) : null}
                </Box>
              </CardPanel>

              <CardPanel hasBorderBottom>
                <VendorRatePanel invoice={invoice} utils={utils} />
              </CardPanel>

              <CardPanel>
                <LineItemsTable
                  invoice={invoice}
                  isEditing={isEditing}
                  job={job}
                  ref={lineItemsFormRef}
                  utils={utils}
                />
              </CardPanel>
            </Box>
          </SimpleCard>
        </GridCardWrapper>
      </Box>

      <Box minWidth="500px" flex={0}>
        <SmallTabs
          tabs={[
            {
              label: 'Map',
              id: `map_${job.id}`,
              content: (
                <Box sx={{ m: -2 }}>
                  <RouteMap job={job} />
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );
}

interface CardPanelProps extends BoxOwnProps {
  hasBorderBottom?: boolean;
}

const CardPanel = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'hasBorderBottom',
})<CardPanelProps>(({ theme, hasBorderBottom }) => ({
  '&.MuiBox-root': {
    padding: theme.spacing(2),
    ...(hasBorderBottom ? { borderBottom: `solid 1px ${theme.palette.divider}` } : {}),
  },
}));

interface ButtonProps extends MuiButtonProps {}

const Button = ({ color = 'primary', ...rest }: ButtonProps) => (
  <MuiButton size="small" sx={{ height: '33px' }} color={color} {...rest} />
);
