import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React from 'react';

const TimeEditCell = (props: GridRenderCellParams<any, any>) => {
  const { id, value, field, row } = props;
  const editable = row?._editable;

  const apiRef = useGridApiContext();

  const handleChange = (newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: dayjs.tz(newValue) });
  };

  const saveChanges = () => apiRef.current.stopCellEditMode({ id, field });

  return (
    <ClickAwayListener onClickAway={saveChanges}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 0.25, width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            value={dayjs.tz(value)}
            disabled={!editable}
            onChange={(value) => handleChange(value)}
            onSubmit={saveChanges}
            sx={{ width: '100%', px: 0, m: 0, '& .MuiInputBase-input': { px: 0.25 } }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />

          {/* Save Button */}
          <IconButton onClick={saveChanges} sx={{ ml: 0.25 }} disabled={!editable}>
            <Check sx={{ height: 18, width: 18 }} />
          </IconButton>
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  );
};

const renderTimeEditCell: GridColDef['renderCell'] = (params) => {
  return <TimeEditCell {...params} />;
};

export { renderTimeEditCell, TimeEditCell };
