import Close from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { JobAssignmentType } from '~constants/enums';
import { Account, useAccount } from '~hooks/useAccount';
import { DriverBasic, GetDriversTypeaheadParams, useDrivers } from '~hooks/useDrivers';
import { Job, useJob } from '~hooks/useJob';
import { User } from '~hooks/useUsers';
import { Pagination } from '~interfaces/pagination';
import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';

const LIST_LIMIT = 10;
interface AssignJobFormProps {
  jobId: string;
  companyId: string;
  isJobAssigned: boolean;
  driver?: User;
  vendor?: Account;
  onSuccess?: (job: Job) => void;
  onCancel?: () => void;
}

interface SelectedAssignment {
  type: JobAssignmentType;
  id: string;
  name: string;
}

type VendorAccountTypeaheadTextFieldProps = TextFieldProps & {
  companyId: string;
  accountId: Nullable<string>;
  onAccountSelect: (account: Account) => void;
  isDisabled?: boolean;
};

type AccountDriversTypeaheadTextFieldProps = TextFieldProps & {
  companyId: string;
  accountId?: Nullable<string>;
  driverId?: Nullable<string>;
  onDriverSelect: (driver: DriverBasic) => void;
  useInternalDrivers: boolean;
};

interface VendorAccountTypeaheadResponse {
  data: Account[];
  pagination: Pagination;
}
export const VendorAccountListWithTypeahead = ({
  companyId,
  accountId,
  onAccountSelect,
  isDisabled = false,
}: VendorAccountTypeaheadTextFieldProps) => {
  const theme = useTheme();
  const initialLoadComplete = useRef<boolean>(false);
  const { getAccountsByCompanyIdTypeahead, isLoading } = useAccount();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPagination, setCurrentPagination] = useState<Pagination>(
    {} as Pagination,
  );
  const [resultsList, setResultsList] = useState<Account[]>([]);
  const hasResults = resultsList.length > 0;
  const fetchAccounts = useCallback(
    async ({
      callback,
      searchParams,
    }: {
      callback?: () => void;
      searchParams?: Omit<GetDriversTypeaheadParams['searchParams'], 'limit'>;
    }) => {
      await getAccountsByCompanyIdTypeahead({
        companyId,
        searchParams: {
          limit: LIST_LIMIT,
          accountTypes: [AccountType.VENDOR],
          isConnected: true,
          ...searchParams,
        },
      }).then((response: Nullable<VendorAccountTypeaheadResponse>) => {
        if (response) {
          const { data: accounts, pagination } = response;
          setResultsList(accounts);
          setCurrentPagination(pagination);
          callback?.();
        }
      });
    },
    [
      companyId,
      getAccountsByCompanyIdTypeahead,
      setCurrentPage,
      setCurrentPagination,
      setResultsList,
    ],
  );
  const onTextFieldChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await fetchAccounts({
      callback: () => setCurrentPage(1),
      searchParams: { query: e.target.value },
    });
  };
  const onTypeaheadInput = useMemo(
    () => debounce(onTextFieldChange, 200),
    [onTextFieldChange],
  );
  const getNextPage = useCallback(async () => {
    currentPagination.after &&
      (await fetchAccounts({
        callback: () => setCurrentPage(currentPage + 1),
        searchParams: {
          link: {
            type: 'after',
            cursor: currentPagination.after,
          },
        },
      }));
  }, [currentPage, currentPagination.after, fetchAccounts, setCurrentPage]);
  const getPrevPage = useCallback(async () => {
    currentPage > 1 &&
      currentPagination.before &&
      (await fetchAccounts({
        callback: () => setCurrentPage(currentPage - 1),
        searchParams: {
          link: {
            type: 'before',
            cursor: currentPagination.before,
          },
        },
      }));
  }, [currentPage, currentPagination.before, fetchAccounts, setCurrentPage]);

  // Fetch first 10 vendor accounts unfiltered
  useEffect(() => {
    !initialLoadComplete.current &&
      !isLoading &&
      fetchAccounts({
        callback: () => (initialLoadComplete.current = true),
      });
  }, [fetchAccounts, initialLoadComplete, isLoading]);

  return (
    <Box sx={{ opacity: isDisabled ? '0.5' : '1' }}>
      <TextField
        fullWidth
        label="Vendor Account"
        variant="outlined"
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: <></>,
        }}
        onChange={onTypeaheadInput}
        disabled={isDisabled}
      />

      {hasResults && (
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
          <IconButton onClick={getPrevPage} disabled={currentPage === 1} sx={{ p: 1 }}>
            <KeyboardArrowLeft />
          </IconButton>

          <Box component="span" sx={{ fontSize: '0.875rem' }}>
            {t('common.page')} {currentPage}
          </Box>

          <IconButton onClick={getNextPage} sx={{ p: 1 }}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}

      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
            <TableCell sx={{ fontWeight: 600 }}>
              {t('form_fields.account_name')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultsList.map((account) => {
            const isSelected = accountId === account.id;
            const hoverState = isSelected
              ? theme.palette.primary.main
              : theme.palette.grey[100];

            return (
              <TableRow
                key={account.id}
                sx={{
                  '&:hover': {
                    cursor: isDisabled ? 'inherit' : 'pointer',
                    backgroundColor: isDisabled ? 'transparent' : hoverState,
                  },
                  backgroundColor: isSelected ? theme.palette.primary.main : 'inherit',
                }}
                onClick={() => {
                  !isDisabled && onAccountSelect(account);
                }}
              >
                <TableCell>{account.name}</TableCell>
              </TableRow>
            );
          })}
          {resultsList.length === 0 && (
            <TableRow>
              <TableCell>{t('common.no_results')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

const AccountDriversListWithTypeahead = ({
  companyId,
  driverId,
  onDriverSelect,
  useInternalDrivers,
}: AccountDriversTypeaheadTextFieldProps) => {
  const theme = useTheme();
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPagination, setCurrentPagination] = useState<Pagination>(
    {} as Pagination,
  );
  const [resultsList, setResultsList] = useState<DriverBasic[]>([]);
  const hasResults = resultsList.length > 0;
  const previousUseInternalDrivers = usePrevious(useInternalDrivers);

  const fetchInternalDrivers = useCallback(
    async ({
      callback,
      searchParams,
    }: {
      callback?: () => void;
      searchParams?: Omit<GetDriversTypeaheadParams['searchParams'], 'limit'>;
    }) => {
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: false,
        searchParams: {
          limit: LIST_LIMIT,
          ...searchParams,
        },
      });

      setResultsList(response?.data || []);
      setCurrentPagination(response?.pagination || ({} as Pagination));
      callback?.();
    },
    [companyId, getDriversByCompanyIdTypeahead, setResultsList, setCurrentPagination],
  );
  const fetchDrivers = useCallback(
    async ({
      callback,
      searchParams,
    }: {
      callback?: () => void;
      searchParams?: Omit<GetDriversTypeaheadParams['searchParams'], 'limit'>;
    }) => {
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: true,
        searchParams: {
          limit: LIST_LIMIT,
          ...searchParams,
        },
      });

      setResultsList(response?.data || []);
      setCurrentPagination(response?.pagination || ({} as Pagination));
      callback?.();
    },
    [
      companyId,
      getDriversByCompanyIdTypeahead,
      setCurrentPage,
      setCurrentPagination,
      setResultsList,
    ],
  );
  const onTextFieldChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value;

      setCurrentQuery(query);
      !useInternalDrivers &&
        (await fetchDrivers({
          callback: () => setCurrentPage(1),
          searchParams: { query },
        }));

      useInternalDrivers &&
        (await fetchInternalDrivers({
          callback: () => setCurrentPage(1),
          searchParams: { query },
        }));
    },
    [
      fetchDrivers,
      fetchInternalDrivers,
      setCurrentPage,
      setCurrentQuery,
      useInternalDrivers,
    ],
  );
  const onTypeaheadInput = useMemo(
    () => debounce(onTextFieldChange, 200),
    [onTextFieldChange],
  );
  const getNextPage = useCallback(async () => {
    if (currentPagination.after) {
      if (!useInternalDrivers) {
        await fetchDrivers({
          callback: () => setCurrentPage(currentPage + 1),
          searchParams: {
            link: {
              type: 'after',
              cursor: currentPagination.after,
            },
          },
        });
      } else {
        await fetchInternalDrivers({
          callback: () => setCurrentPage(currentPage + 1),
          searchParams: {
            link: {
              type: 'after',
              cursor: currentPagination.after,
            },
          },
        });
      }
    }
  }, [currentPage, currentPagination.after, fetchDrivers, setCurrentPage]);
  const getPrevPage = useCallback(async () => {
    if (currentPage > 1 && currentPagination.before) {
      if (!useInternalDrivers) {
        await fetchDrivers({
          callback: () => setCurrentPage(currentPage - 1),
          searchParams: {
            link: {
              type: 'before',
              cursor: currentPagination.before,
            },
          },
        });
      } else {
        await fetchInternalDrivers({
          callback: () => setCurrentPage(currentPage - 1),
          searchParams: {
            link: {
              type: 'before',
              cursor: currentPagination.before,
            },
          },
        });
      }
    }
  }, [currentPage, currentPagination.before, fetchDrivers, setCurrentPage]);

  const loadCompanyInternalDrivers = useCallback(() => {
    fetchInternalDrivers({
      callback: () => setCurrentPage(1),
      searchParams: { query: currentQuery },
    });
  }, [fetchInternalDrivers, currentQuery, setCurrentPage]);

  // Update results list as internal drivers toggle changes
  useEffect(() => {
    if (previousUseInternalDrivers !== useInternalDrivers) {
      useInternalDrivers && loadCompanyInternalDrivers();
      !useInternalDrivers &&
        fetchDrivers({
          searchParams: { query: currentQuery },
        });
    }
  }, [
    currentQuery,
    fetchDrivers,
    loadCompanyInternalDrivers,
    previousUseInternalDrivers,
    useInternalDrivers,
  ]);

  return (
    <>
      <TextField
        fullWidth
        label="Driver"
        variant="outlined"
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: <></>,
        }}
        onChange={onTypeaheadInput}
      />

      {hasResults && (
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
          <IconButton onClick={getPrevPage} disabled={currentPage === 1} sx={{ p: 1 }}>
            <KeyboardArrowLeft />
          </IconButton>

          <Box component="span" sx={{ fontSize: '0.875rem' }}>
            {t('common.page')} {currentPage}
          </Box>

          <IconButton onClick={getNextPage} sx={{ p: 1 }}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}

      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
            <TableCell sx={{ fontWeight: 600 }}>{t('form_fields.driver')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultsList.map((driver: DriverBasic) => {
            const isSelected = driverId === driver.id;

            return (
              <TableRow
                key={driver.id}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : theme.palette.grey[100],
                  },
                  backgroundColor: isSelected ? theme.palette.primary.main : 'inherit',
                }}
                onClick={() => {
                  onDriverSelect(driver);
                }}
              >
                <TableCell data-sentry-mask>
                  {driver.firstName} {driver.lastName}
                </TableCell>
              </TableRow>
            );
          })}

          {resultsList.length === 0 && (
            <TableRow>
              <TableCell>{t('common.no_results')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

const AssignJobForm = ({
  driver,
  vendor,
  isJobAssigned = false,
  companyId,
  jobId,
  onSuccess,
  onCancel,
}: AssignJobFormProps) => {
  const theme = useTheme();
  const { doEvent, assignDriver, assignVendor, isUpdating, unassignDriver } = useJob();
  // Needed to update drivers list
  const [selectedAccountId, setSelectedAccountId] = useState<Nullable<string>>(
    vendor?.id || null,
  );
  // Needed to add selected state to drivers list
  const [selectedDriverId, setSelectedDriverId] = useState<Nullable<string>>(
    driver?.id || null,
  );
  const [selectedAssignment, setSelectedAssignment] =
    useState<Nullable<SelectedAssignment>>(null);
  const [isSubmittingAssignment, setIsSubmittingAssignment] = useState<boolean>(false);
  const [isSubmittingUnassignment, setIsSubmittingUnassignment] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('internal');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };
  const isPreAssigned = !!driver || !!vendor;
  const isValid = !!selectedAssignment;

  const unassign = async () => {
    return driver ? await unassignDriver(jobId) : await doEvent(jobId, 'unassign');
  };
  const onVendorAccountSelect = (account: Account) => {
    setSelectedAccountId((currentAccountId) =>
      currentAccountId === account.id ? null : account.id,
    );
    setSelectedAssignment((currentAssignment) => {
      const isCurrentSelectedAssignment =
        currentAssignment?.id === account.id &&
        currentAssignment?.type === JobAssignmentType.VENDOR;

      return isCurrentSelectedAssignment
        ? null
        : {
            type: JobAssignmentType.VENDOR,
            id: account.id,
            name: account.name,
          };
    });
  };
  const onDriverSelect = (driver: DriverBasic) => {
    setSelectedDriverId((currentDriverId) =>
      currentDriverId === driver.id ? null : driver.id,
    );
    setSelectedAssignment((currentAssignment) => {
      const isCurrentSelectedAssignment =
        currentAssignment?.id === driver.id &&
        currentAssignment?.type === JobAssignmentType.DRIVER;

      return isCurrentSelectedAssignment
        ? null
        : {
            type: JobAssignmentType.DRIVER,
            id: driver.id || '',
            name: `${driver.firstName} ${driver.lastName}`,
          };
    });
  };

  const assignActionMap = useMemo(
    () => ({
      [JobAssignmentType.DRIVER]: assignDriver,
      [JobAssignmentType.VENDOR]: assignVendor,
    }),
    [assignDriver, assignVendor, JobAssignmentType],
  );

  const canUnassign = !(isUpdating || !isPreAssigned || isSubmittingUnassignment);

  const onFormSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (isValid && !isSubmittingAssignment) {
        setIsSubmittingAssignment(true);
        // First call unassign if job is already assigned
        if (isJobAssigned) {
          await unassign();
        }

        if (selectedAssignment) {
          const assignActionResp: Job = await assignActionMap[selectedAssignment.type](
            jobId,
            selectedAssignment.id,
          );

          if (assignActionResp.id) {
            onSuccess?.(assignActionResp);
            setIsSubmittingAssignment(false);
          }
        }
      }
    },
    [assignActionMap, onSuccess, selectedAssignment],
  );

  const onUnassign = useCallback(async () => {
    if (isJobAssigned) {
      setIsSubmittingUnassignment(true);
      const unassignActionResp = await unassign();

      onSuccess?.(unassignActionResp);
      setIsSubmittingUnassignment(false);
    }
  }, [isJobAssigned, onSuccess, unassign]);

  useEffect(() => {
    driver &&
      setSelectedAssignment({
        type: JobAssignmentType.DRIVER,
        id: driver.id,
        name: `${driver.firstName} ${driver.lastName}`,
      });
    !driver &&
      vendor &&
      setSelectedAssignment({
        type: JobAssignmentType.VENDOR,
        id: vendor.id,
        name: vendor.name,
      });
  }, [driver, setSelectedAssignment, vendor]);

  return (
    <Box
      component="form"
      data-test-id="company-form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        maxHeight: '100%',
        my: 1,
      }}
      onSubmit={onFormSubmit}
    >
      <Box sx={{ flex: 1, overflow: 'auto', px: '2px' }}>
        <Card sx={{ p: 2, mb: 2 }}>
          <Grid container>
            <Grid item sm={12} mb={2}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label={t('dispatch.job.internal_drivers')} value={'internal'} />
                <Tab label={t('dispatch.job.external_drivers')} value={'external'} />
                <Tab label={t('dispatch.job.vendors')} value={'vendors'} />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item sm={12} sx={{ minHeight: '600px' }}>
              {activeTab === 'vendors' ? (
                <VendorAccountListWithTypeahead
                  companyId={companyId}
                  accountId={selectedAccountId}
                  onAccountSelect={onVendorAccountSelect}
                />
              ) : (
                <AccountDriversListWithTypeahead
                  companyId={companyId}
                  accountId={selectedAccountId}
                  driverId={selectedDriverId}
                  onDriverSelect={onDriverSelect}
                  useInternalDrivers={activeTab === 'internal'}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>

      <Box
        sx={{
          flex: 0,
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <LoadingButton
          disabled={isUpdating || isSubmittingAssignment || !isValid}
          loading={isSubmittingAssignment}
          loadingPosition="start"
          startIcon={<></>}
          data-test-id={'assign-job-btn'}
          type="submit"
          variant="contained"
          color="primary"
          sx={isSubmittingAssignment ? { pl: 5, pr: 2 } : { pr: 2 }}
        >
          {t('actions.assign')}
        </LoadingButton>
        <Button
          sx={{ mr: 2, px: 2 }}
          disabled={isUpdating}
          color="secondary"
          variant="outlined"
          onClick={onCancel}
        >
          {t('actions.cancel')}
        </Button>

        {selectedAssignment && (
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {t('dispatch.job.assigned_to')}:
            </Typography>

            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                textTransform: 'capitalize',
              }}
              {...(selectedAssignment.type === 'driver'
                ? { 'data-sentry-mask': true }
                : {})}
            >
              {selectedAssignment.name || t('common.none_selected')}

              {selectedAssignment.type && (
                <Typography component="span" variant="body2" sx={{ fontWeight: 600 }}>
                  ({selectedAssignment.type})
                </Typography>
              )}
            </Typography>

            {canUnassign && (
              <Tooltip title={t('actions.unassign')}>
                <IconButton color="error" onClick={onUnassign} size="small">
                  <Close sx={{ fontSize: '.8rem' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { AssignJobForm };
