import ExpandMore from '@mui/icons-material/ExpandMore';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { CopyToClipboard } from '~components/CopyToClipboard/CopyToClipboard';
import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { SystemRoles } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { useLogin } from '~hooks/useLogin';
import { useVersion } from '~hooks/useVersion';
import { Logo } from '~icons';
import { routes } from '~router/routesPaths';
import { useStores } from '~store';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 min',
    mm: '%d min',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});

const ResponsiveAppBar = () => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const theme = useTheme();
  const { logOut } = useLogin();
  const { version } = useVersion();

  const omniReportFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.omniReportFeatureFlag,
  });

  const handleLogout = () => {
    handleClose();
    logOut();
  };
  let timeDiffDisplay = '';
  if (version.time) {
    // Use the duration plugin to convert milliseconds into days and hours
    const versionDate = dayjs.utc(version.time);
    timeDiffDisplay = versionDate.fromNow();
  }

  const userRolesAndCompany = useMemo(() => {
    const { userRoles } = userStore.user ?? {};
    const { legalName } = userStore.userCompany ?? {};

    if (!legalName) {
      return null;
    }

    if (userRoles?.length === 1) {
      return `${userRoles[0].name} • ${legalName}`;
    }

    const additionalRolesCount = (userRoles?.length ?? 0) - 1;
    if (additionalRolesCount > 0) {
      return `${userRoles?.[0].name} + ${additionalRolesCount} • ${legalName}`;
    }

    return legalName;
  }, [userStore.user?.userRoles, userStore.userCompany?.legalName]);

  const navItems = useMemo(() => {
    const list = [
      {
        title: t('page_headings.sales'),
        link: routes.sales.base,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      },
      {
        title: t('page_headings.dispatch'),
        link: routes.dispatch.base,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      },
      {
        title: t('page_headings.approvals'),
        link: routes.approvals.base,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      },
      {
        title: t('page_headings.live_map'),
        link: routes.live,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      },
    ];

    if (
      userStore.user?.userRoles?.some((role) => role.name === SystemRoles.PLATFORM_ADMIN)
    ) {
      list.unshift({
        title: 'Admin',
        link: routes.admin.base,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      });
    }

    if (
      omniReportFeatureFlag.isFeatureFlagEnabled &&
      userStore.user?.userRoles?.some((role) => role.name === SystemRoles.REPORTING)
    ) {
      list.push({
        title: t('page_headings.reports'),
        link: routes.reports,
        sx: { my: 1, color: theme.palette.primary.contrastText },
      });
    }

    // Settings tab to be added to the end of the list
    list.push({
      title: t('page_headings.settings'),
      link: routes.settings.base,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    });

    return list;
  }, [userStore.user?.userRoles, omniReportFeatureFlag.isFeatureFlagEnabled]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ maxWidth: '100%', width: '100%' }}>
        <Toolbar disableGutters variant="dense" sx={{ px: 3 }}>
          {userStore.userCompany.legalName?.length && (
            <Box
              style={{ maxWidth: '100px', marginRight: '50px' }}
              onClick={() => navigate(`${routes.base}`)}
              sx={{
                display: { xs: 'none', md: 'inline-flex' },
                alignItems: 'center',
                mr: 1,
                cursor: 'pointer',
              }}
            >
              {userStore.userCompany?.logoUrl?.length ? (
                <img
                  style={{ width: '100%', height: 'auto' }}
                  alt="User"
                  loading="lazy"
                  src={`${userStore.userCompany.logoUrl}`}
                />
              ) : (
                <Logo height={32} width={168} />
              )}
            </Box>
          )}

          <Box
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <NavigationTabs navItems={navItems} />
          </Box>
          <Divider orientation="vertical" flexItem />

          {!_.isEmpty(userStore.user) && !_.isNull(userRolesAndCompany) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                marginLeft: '20px',
                paddingLeft: '20px',
              }}
              onClick={handleClick}
            >
              <Box
                sx={{
                  width: '170px',
                  flexDirection: 'column',
                }}
              >
                <Tooltip title={userStore.user.fullName}>
                  <Typography variant="body1" color={'black'} noWrap data-sentry-mask>
                    {userStore.user.fullName}
                  </Typography>
                </Tooltip>

                <Tooltip title={userRolesAndCompany}>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {userRolesAndCompany}
                  </Typography>
                </Tooltip>
              </Box>
              <ExpandMore
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                  ml: 1,
                }}
              />
            </Box>
          )}
        </Toolbar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '240px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 50,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem>
          <CopyToClipboard textToCopy={userStore.userCompany.treadId}>
            <Typography component="span">
              <span style={{ color: theme.palette.text.secondary }}>
                {t('page_headings.tread_id')}:{' '}
              </span>
              {userStore.userCompany.treadId}
            </Typography>
          </CopyToClipboard>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Typography component="span">
            <span style={{ color: theme.palette.text.secondary }}>
              {t('page_headings.last_release')}:{' '}
            </span>
            {timeDiffDisplay}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t('page_headings.logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </AppBar>
  );
};
export default observer(ResponsiveAppBar);
