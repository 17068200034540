import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteFormField, TextFormField } from '~components/FormFields';
import { AddOn, AddOnCharge } from '~hooks/useAddOns';
import { Invoice } from '~hooks/useInvoices';
import {
  driversPayDataGridColumnsDefinition as colsDef,
  LineItemsTableCell,
  LineItemsTableTableRow,
} from '~pages/Approvals/DriverPay';
import { useStores } from '~store';
import { usePrevious } from '~utils/hooks/usePrevious';

import {
  AddOnChargeDTO,
  addOnChargeSchema,
  appendAddOnRateTypeToName,
  invoiceHelpers,
  schemaHelpers,
} from './helpers';

interface AddOnChargesTableRowsProps {
  addOnCharges: AddOnCharge[];
  invoice: Invoice;
  isEditing?: boolean;
  lineItemColSpan?: number;
  lineItemsTableColumnsCount: number;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const ADD_ON_CHARGES_TABLE_COLUMNS_COUNT = 4;

const addOnChargesSchema = yup.object().shape({
  addOnCharges: yup.array().of(addOnChargeSchema),
});

type AddOnChargesDTO = yup.InferType<typeof addOnChargesSchema>;

type AddOnSelectOption = {
  id: string;
  name: string;
};

export type AddOnChargesFormHandler = {
  onSubmit?: () => Promise<AddOnChargeDTO[]>;
};

const AddOnChargesTableRows = forwardRef<
  AddOnChargesFormHandler,
  AddOnChargesTableRowsProps
>(function AddOnChargesTableRows(
  {
    addOnCharges,
    invoice,
    isEditing,
    lineItemColSpan = 2,
    lineItemsTableColumnsCount,
    utils,
  },
  ref,
) {
  const { addOnsStore, invoiceLineItemsStore } = useStores();

  const invoiceId = String(invoice.id);
  const lineItemsSubtotal = invoiceLineItemsStore.invoiceSubtotalByInvoiceId[invoiceId];

  const { addOnSelectOptions, addOnsById } = useMemo(() => {
    const selectableAddOns = addOnsStore.selectableAddOnsByInvoiceId[invoiceId] ?? [];

    const [options, indexed] = selectableAddOns.reduce(
      ([options, indexed], addOn) => {
        indexed[String(addOn.id)] = addOn;
        options.push({
          id: String(addOn.id),
          name: appendAddOnRateTypeToName(addOn.name, addOn.addOnRateType),
        });

        return [options, indexed];
      },
      [[] as AddOnSelectOption[], {} as Record<string, AddOn>],
    );

    return {
      addOnSelectOptions: options,
      addOnsById: indexed,
    };
  }, [addOnsStore.selectableAddOnsByInvoiceId[invoiceId]]);

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<AddOnChargesDTO>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(addOnChargesSchema),
    defaultValues: {
      addOnCharges: addOnCharges.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
    },
  });

  const { fields, append, update } = useFieldArray({
    control,
    name: 'addOnCharges',
  });
  const watchedAddOnCharges = useWatch({ control, name: 'addOnCharges' });
  const previousWatchedAddOnCharges = usePrevious(watchedAddOnCharges);

  const handleAddAddOnClick = useCallback(() => {
    const addOnCharge = new AddOnCharge(
      null,
      '',
      0,
      0,
      0,
      0,
      AddOnRateType.RATE_FOR_EACH,
      null,
      { id: '' },
      { id: invoiceId },
    );

    append(schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
  }, [append]);

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const addOnChargeToDelete = watchedAddOnCharges?.[index];

      if (addOnChargeToDelete) {
        update(index, { ...addOnChargeToDelete, _destroy: 1 });
      }
    },
    [update, watchedAddOnCharges],
  );

  // This is to pre-fill the add-on charge DTO based on the selected add-on
  useEffect(() => {
    if (!watchedAddOnCharges || !previousWatchedAddOnCharges) {
      return;
    }

    watchedAddOnCharges.forEach((current, index) => {
      const currentAddOnId = current.addOn?.id;
      const previousAddOnIn = previousWatchedAddOnCharges[index]?.addOn?.id;
      const selectedAddOnDidChange = currentAddOnId && currentAddOnId !== previousAddOnIn;

      // If the selected add-on did change then use the newly selected add-on values
      // As the default values for the add-on charge DTO
      if (selectedAddOnDidChange) {
        const newlySelectedAddOn = addOnsById[currentAddOnId];
        const addOnCharge = new AddOnCharge(
          current.id,
          newlySelectedAddOn.name,
          newlySelectedAddOn.percentage,
          newlySelectedAddOn.quantity,
          newlySelectedAddOn.rate,
          0,
          newlySelectedAddOn.addOnRateType,
          newlySelectedAddOn.externalId,
          { id: currentAddOnId },
          { id: invoiceId },
        );

        update(index, schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
        clearErrors([`addOnCharges.${index}.quantity`, `addOnCharges.${index}.value`]);
      }
    });
  }, [watchedAddOnCharges, previousWatchedAddOnCharges, update, clearErrors, addOnsById]);

  useEffect(() => {
    let total = 0;

    if (watchedAddOnCharges) {
      total = watchedAddOnCharges.reduce((sum, addOnCharge) => {
        const isDeleted = addOnCharge._destroy === 1;

        if (isDeleted) {
          return sum;
        }

        const addOnChargeTotal = invoiceHelpers.calculateAddOnChargeTotal(
          addOnCharge.addOnRateType ? (addOnCharge.addOnRateType as AddOnRateType) : null,
          addOnCharge.quantity,
          addOnCharge.value,
          addOnCharge.value,
          lineItemsSubtotal,
        );

        return sum + addOnChargeTotal;
      }, 0);
    }

    addOnsStore.setAddOnChargesTotalByInvoiceId(invoiceId, total);
  }, [watchedAddOnCharges, lineItemsSubtotal, invoiceId]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: () => {
        return new Promise((resolve) => {
          handleSubmit((data) => {
            resolve(data.addOnCharges ?? []);
          })();
        });
      },
    }),
    [],
  );

  if (isEditing) {
    return (
      <>
        {fields.map((field, index) => {
          const isDeleted = watchedAddOnCharges?.[index]?._destroy === 1;

          if (isDeleted) {
            return null;
          }

          const editedAddOnCharge = watchedAddOnCharges?.[index];
          const isAddOnSelected = Boolean(editedAddOnCharge?.addOn?.id);
          const isRateForEach =
            editedAddOnCharge?.addOnRateType === AddOnRateType.RATE_FOR_EACH;

          const total = invoiceHelpers.calculateAddOnChargeTotal(
            editedAddOnCharge?.addOnRateType
              ? (editedAddOnCharge.addOnRateType as AddOnRateType)
              : null,
            editedAddOnCharge?.quantity,
            editedAddOnCharge?.value,
            editedAddOnCharge?.value,
            lineItemsSubtotal,
          );

          return (
            <LineItemsTableTableRow key={field.id}>
              <LineItemsTableCell colSpan={lineItemColSpan}>
                <Box display="flex" alignItems="center" gap="5px">
                  <IconButton size="small" onClick={() => handleRemoveAddOnClick(index)}>
                    <Close color="error" sx={{ fontSize: '.8rem' }} />
                  </IconButton>

                  <AutocompleteFormField
                    clearable
                    control={control}
                    errors={errors}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                    isRequired
                    list={addOnSelectOptions}
                    name={`addOnCharges[${index}].addOn`}
                    sx={{
                      '& .MuiInputBase-input': { fontSize: '12px', p: '0 !important' },
                      '& .MuiAutocomplete-endAdornment': {
                        right: '5px !important',
                        top: 'calc(50% - 13px)',
                      },
                      '& .MuiSvgIcon-root': { width: '.75em', height: '.75em' },
                    }}
                  />
                </Box>
              </LineItemsTableCell>

              <LineItemsTableCell
                colSpan={
                  lineItemsTableColumnsCount -
                  ADD_ON_CHARGES_TABLE_COLUMNS_COUNT -
                  lineItemColSpan +
                  1
                }
              />

              <LineItemsTableCell textAlign="right">
                {isAddOnSelected && isRateForEach && (
                  <TextFormField
                    control={control}
                    errors={errors}
                    type="number"
                    name={`addOnCharges[${index}].quantity`}
                    sx={{
                      ml: 'auto',
                      '& .MuiFormControl-root': { mb: 0 },
                      '& .MuiOutlinedInput-root': { pr: 0 },
                      '& .MuiOutlinedInput-input': { p: '5px 7px', fontSize: '12px' },
                    }}
                    inputProps={{ sx: { textAlign: 'right' } }}
                  />
                )}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign="right">
                {isAddOnSelected ? (
                  <TextFormField
                    control={control}
                    errors={errors}
                    type="number"
                    name={`addOnCharges[${index}].value`}
                    sx={{
                      ml: 'auto',
                      '& .MuiFormControl-root': { mb: 0 },
                      '& .MuiOutlinedInput-root': { pr: 0, pl: '7px' },
                      '& .MuiOutlinedInput-input': {
                        p: '5px 7px 5px 0px',
                        fontSize: '12px',
                      },
                    }}
                    inputProps={{ sx: { textAlign: 'right' } }}
                    InputProps={{
                      startAdornment: (
                        <Typography mr="5px" fontSize="12px">
                          {isRateForEach ? '$' : '%'}
                        </Typography>
                      ),
                    }}
                  />
                ) : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign="right">
                {isAddOnSelected && utils.currencyFormatter(total)}
              </LineItemsTableCell>
            </LineItemsTableTableRow>
          );
        })}

        <LineItemsTableTableRow>
          <LineItemsTableCell colSpan={lineItemsTableColumnsCount}>
            <Button
              color="primary"
              onClick={handleAddAddOnClick}
              startIcon={<AddCircleOutline />}
            >
              {t('approvals.driver_pay.tabs.driver_pay.add_add_on')}
            </Button>
          </LineItemsTableCell>
        </LineItemsTableTableRow>
      </>
    );
  }

  return addOnCharges.map((addOnCharge) => {
    const isRateForEach = addOnCharge.addOnRateType === AddOnRateType.RATE_FOR_EACH;

    return (
      <LineItemsTableTableRow key={addOnCharge.id}>
        <LineItemsTableCell>
          {appendAddOnRateTypeToName(addOnCharge.name, addOnCharge.addOnRateType)}
        </LineItemsTableCell>

        <LineItemsTableCell
          colSpan={lineItemsTableColumnsCount - ADD_ON_CHARGES_TABLE_COLUMNS_COUNT}
        />

        <LineItemsTableCell textAlign="right">
          {isRateForEach && addOnCharge.quantity}
        </LineItemsTableCell>

        <LineItemsTableCell textAlign="right">
          {isRateForEach
            ? utils.currencyFormatter(addOnCharge.rate)
            : `%${addOnCharge.percentage}`}
        </LineItemsTableCell>

        <LineItemsTableCell textAlign="right">
          {utils.currencyFormatter(
            invoiceHelpers.calculateAddOnChargeTotal(
              addOnCharge.addOnRateType,
              addOnCharge.quantity,
              addOnCharge.rate,
              addOnCharge.percentage,
              lineItemsSubtotal,
            ),
          )}
        </LineItemsTableCell>
      </LineItemsTableTableRow>
    );
  });
});

export default observer(AddOnChargesTableRows);
