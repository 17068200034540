import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { t } from 'i18next';
import { forwardRef, Ref } from 'react';
import { useForm } from 'react-hook-form';

import { FormFieldLabel, TextFormField } from '~components/FormFields';
import { FormErrorType } from '~formsShared';
import { useCompany } from '~hooks/useCompany';
import { Company } from '~hooks/useCompany/models';

interface SearchCompanyByTreadIdFormProps {
  name?: string;
  onFormSubmit?: () => void;
  formSubmitCallback?: (response: Company, treadId: string) => void;
}

interface FormValues {
  treadId: string;
  formSubmit?: FormErrorType;
  [key: string]: string | FormErrorType | undefined;
}

interface SearchCompanyByTreadIdFormRef {
  submit: (callBack: () => void) => void;
  resetForm: (callBack?: () => void) => void;
}

const FormFieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const FormWrapper = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
});

const SearchCompanyByTreadIdForm = forwardRef(function SearchCompanyByTreadIdForm(
  { name = 'treadId', onFormSubmit, formSubmitCallback }: SearchCompanyByTreadIdFormProps,
  ref: Ref<SearchCompanyByTreadIdFormRef>,
) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const { isLoadingCompanies, getCompanyByTreadId } = useCompany();
  const onSubmit = async (data: FormValues) => {
    const treadId = data[name] as string;
    const company = await getCompanyByTreadId({
      treadId,
    }).catch((error) => {
      setError('treadId', { type: '404', message: error.message });
      return error;
    });

    formSubmitCallback?.(company, treadId);
  };
  const submitForm = (data: FormValues) => {
    onFormSubmit?.();
    onSubmit(data);
  };

  return (
    <FormWrapper
      ref={ref}
      component="form"
      data-test-id="search-company-by-tread-id-form"
      onSubmit={handleSubmit(submitForm)}
    >
      <FormFieldWrapper>
        <FormFieldLabel
          variant="body1"
          label={`${t('form_fields.search_by_tread_id')}`}
          sx={{ flexShrink: 0 }}
        />
        <TextFormField
          name={name}
          control={control}
          placeholder={`${t('form_fields.tread_id')}`}
          isRequired={true}
          disabled={isLoadingCompanies}
          errors={errors}
        />
        <LoadingButton
          disabled={isLoadingCompanies || !isDirty || !isValid}
          loading={isLoadingCompanies}
          loadingPosition="start"
          startIcon={<></>}
          data-test-id={'connect-account-btn'}
          type="submit"
          variant="contained"
          color="primary"
          sx={isLoadingCompanies ? { pl: 5, pr: 2 } : { pr: 2 }}
        >
          {t('form_fields.search')}
        </LoadingButton>
      </FormFieldWrapper>
    </FormWrapper>
  );
});

export { SearchCompanyByTreadIdForm };
